import React, { memo, useCallback, useState } from 'react'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import ImageUploader from '../../Core/ImageUploader'

const ImageSelection = ({ files, setFiles }) => {
  const onAddFile = useCallback(
    (file) => {
      setFiles([...files, file])
    },
    [files, setFiles]
  )

  const onRemove = useCallback(
    (candidate) => {
      setFiles(_filter(files, (_file, index) => index !== candidate))
    },
    [files, setFiles]
  )

  return (
    <div className="image-area">
      {_map(files, (file, index) => (
        <ImageUploader key={index} file={file} onDelete={() => onRemove(index)} />
      ))}
      <div className="input-upload-wrapper">
        <ImageUploader onImageSelected={(file) => onAddFile(file)} />
      </div>
    </div>
  )
}

export default memo(ImageSelection)
