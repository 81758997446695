import React, { memo } from 'react'

import OrderInfo from './OrderInfo'
import OrderItems from './OrderItems'
import TimeLine from './TimeLine'
import './styles.scss'

const ClaimInfoTab = ({
  setShowCancelModal,
  onCancelProtection,
  setShowAddModal,
  onAddProtection,
  items,
  timeline,
  orderData,
  setOrderData,
  orderTracking,
  shipping,
  isLoading,
  orderTimeline,
  fulFillmentDetails,
  cancelled,
  orderDelivered,
  reshipOrderLoading,
}) => {
  return (
    <div className="claim-info-tab">
      <div className="mb-3">
        <OrderInfo
          isLoading={isLoading}
          orderData={orderData}
          setOrderData={setOrderData}
          cancelled={cancelled}
          fulFillmentDetails={fulFillmentDetails}
          orderDelivered={orderDelivered}
          setShowCancelModal={setShowCancelModal}
          onCancelProtection={onCancelProtection}
          setShowAddModal={setShowAddModal}
          onAddProtection={onAddProtection}
          reshipOrderLoading={reshipOrderLoading}
        />
      </div>
      <div className="mb-5">
        <OrderItems
          items={items}
          orderData={orderData}
          orderTracking={orderTracking}
          fulFillmentDetails={fulFillmentDetails}
        />
      </div>
      <TimeLine
        timeline={timeline}
        orderData={orderData}
        shipping={shipping}
        orderTimeline={orderTimeline}
      />
    </div>
  )
}

export default memo(ClaimInfoTab)
