import { createContext, useContext } from 'react'

export const AdminContext = createContext({
  stores: [],
  selectedStoreId: -1,
  setSelectedStoreId: (_storeId: string) => {},
  token: '',
  pageSize: 50,
  freeClaims: null,
  onChangeFilter: (_key: any, _value: any) => {},
  fromDate: new Date(),
  toDate: new Date(),
  totalOrders: 0,
  totalProtectedOrders: 0,
  totalRevenue: 0,
  totalProtectedRevenue: 0,
  role: undefined,
  onFetchClaims: () => {},
  isOpenMobileMenu: false,
  toggleMenu: () => {},
  fetchStores: () => {},
  setFilterRefundIssue: (_value: any) => {},
  refundIssueOnly: false,
  openMacroSettings: false,
  setOpenMacroSettings: () => {},
  orderLoading: false,
  userPermission: undefined,
  setSelectedClaimTab: () => {},
  selectedClaimTab: 'new',
})

export const useAdminContext = () => useContext(AdminContext)
