import React, { memo, useMemo } from 'react'
import ProgressBarRB from 'react-bootstrap/ProgressBar'

const ProgressBar = ({ currentPercentage, text }) => {
  const variant = useMemo(() => {
    if (currentPercentage <= 65) {
      return 'info'
    }

    if (currentPercentage < 95) {
      return 'warning'
    }

    return 'danger'
  }, [currentPercentage])

  return (
    <div>
      <ProgressBarRB
        variant={variant}
        now={currentPercentage}
        label={`${currentPercentage.toFixed(2)}%`}
      />
      <div style={{ fontSize: 11 }} className="text-right text-sm mt-1">
        {text}
      </div>
    </div>
  )
}

export default memo(ProgressBar)
