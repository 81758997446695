import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Form } from '../../components/Form'
import { useAdminContext } from '../../context/admin-context'
import { putApi } from '../../helpers/api'
import { getUserId } from '../../helpers/order-helpers'

export const ProfileDetail = () => {
  const { user } = useAuth0()
  const { token } = useAdminContext()
  const [loading, setLoading] = useState(true)
  const userId = useMemo(() => getUserId(user), [user])

  const methods = useForm({
    defaultValues: {
      name: user?.name?.trim() || '',
      aliasName: user?.aliasName?.trim() || '',
      email: user?.email,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })

  const name = methods.watch('name')
  const alias = methods.watch('aliasName')
  useEffect(() => {
    if (name.trim() !== user?.name?.trim() || alias.trim() !== user?.aliasName?.trim()) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [name])

  const onSubmit = async (data: any) => {
    setLoading(true)
    const firstName = data.name.split(' ').slice(0, -1).join(' ')
    const lastName = data.name.split(' ').slice(-1).join(' ')
    const aliasName = data.aliasName
    try {
      await putApi(
        `/users/${userId}/detail`,
        { firstName, lastName, authId: user?.sub, aliasName },
        token
      )
      window.location.reload()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form methods={methods}>
      <div className="title mb-4">My profile</div>
      <div className="section card-wrapper mb-4">
        <h3>Profile Details</h3>
        <Form.Input
          name="name"
          label="Name"
          rules={{ required: { value: true, message: 'This field is required' } }}
        ></Form.Input>
        <Form.ErrorMessage name="name"></Form.ErrorMessage>
        <Form.Input name="aliasName" label="Alias (nickname)"></Form.Input>
        <Form.ErrorMessage name="aliasName"></Form.ErrorMessage>
        <Form.Input name="email" label="Email" data-testid="email" disabled></Form.Input>
        <Form.ErrorMessage name="email"></Form.ErrorMessage>
        <Button disabled={loading} onClick={methods.handleSubmit(onSubmit)}>
          Save Changes
        </Button>
      </div>
    </Form>
  )
}
