import { useMemo, useState } from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import _isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _startCase from 'lodash/startCase'
import { useHistory } from 'react-router-dom'

import ClaimPreIdentified from '../../../assets/claim-portal/claim-pre-identified.svg'
import { views } from '../../../helpers/appViews'
import './index.scss'

export default function Carrier(props) {
  const history = useHistory()
  const firstItem = useMemo(() => _get(props, 'state.customerData.orderItems', {}), [props])
  const storeOrderId = useMemo(() => _get(props, 'state.customerData.store_order_id', ''), [props])
  const [actived, setActived] = useState('')

  const onSubmit = () => {
    if (actived === 'no') {
      props.changeView(views.CLAIMTYPE)
    } else {
      history.push('/submitClaim/resolution')
      let customerData = { ...props.state.customerData }

      const claimTypes = props?.state?.claimTypes?.data
      const claimType = claimTypes.find(
        (item) => item.claim_type_name.toLowerCase() === 'tracking not moving'
      )

      customerData.claimType = claimType
      props.setState({
        ...props.state,
        customerData: customerData,
      })
    }
  }

  const lastCheckpoint = props.orderTracking?.[0]?.orderTracking?.checkpoints?.[0]

  return (
    <div className="body-carrier">
      <Container>
        <Col>
          <h2 className="title">Claims</h2>
          <div className="first-product-wrapper">
            <div className="order-title mb20">
              {`Order #${storeOrderId}`}
              <div className="back-link" onClick={() => props.resetView()}>
                Locate another order
              </div>
            </div>
            {_map(firstItem, (item, index) => (
              <div className="products" key={index}>
                <div className="wrap-img-product">
                  <img src={item.photo} />
                </div>
                <p className="title-product">{item?.item_name || ''}</p>
              </div>
            ))}
            <div className="back-link locate-another-order" onClick={() => props.resetView()}>
              Locate another order
            </div>
          </div>
          <div className="greeting-container mg-greeting">
            <h4 className="text">
              It looks like your order might’ve never made it to UPS as it hasn’t updated it’s
              status since {moment(lastCheckpoint.time).format('MMM, Do')}. Is that what you’re
              trying to resolve today?
            </h4>
            <div className="group-btn">
              <button
                className={`btn-answer ${actived === 'yes' ? 'actived' : ''}`}
                onClick={() => setActived('yes')}
              >
                Yes
              </button>
              <button
                className={`btn-answer ${actived === 'no' ? 'actived' : ''}`}
                onClick={() => setActived('no')}
              >
                No
              </button>
            </div>
            <div className="group-btn-submit">
              <button className={`btn-submit btn-actived`} disabled={!actived} onClick={onSubmit}>
                Continue
              </button>
            </div>
          </div>
        </Col>
        {/* <Col md={6}>
            <div className="content-right">
              <div className="wrap-image">
                <img className="image" src={ClaimPreIdentified} alt="Claim Pre Identified" />
              </div>
            </div>
              </Col> */}
      </Container>
    </div>
  )
}
