export const views = {
  ORDERS: 'ORDERS',
  CONNECTIONS: 'CONNECTIONS',
  ISSUES: 'ISSUES',
  SETTINGS: 'SETTINGS',
  PAYMENTDETAILS: 'PAYMENTDETAILS',
  INVOICEHISTORY: 'INVOICEHISTORY',
  BILLING: 'BILLING',
  PAYOUTS: 'PAYOUTS',
  PAYOUTSLIST: 'PAYOUTSLIST',
  LOGIN: 'LOGIN',
  LOADING: 'LOADING',
  REGISTER: 'REGISTER',
  CLAIM: 'CLAIM',
  CLAIMITEMS: 'CLAIMITEMS',
  BEGINMISSING: 'BEGINMISSING',
  CLAIMMISSING: 'CLAIMMISSING',
  CLAIMDETAILS: 'CLAIMDETAILS',
  CLAIMCONFIRMATION: 'CLAIMCONFIRMATION',
  CLAIMSIGNATURE: 'CLAIMSIGNATURE',
  CLAIMREFUND: 'CLAIMREFUND',
  ORDERREVIEW: 'ORDERREVIEW',
  ANSWERCLAIM: 'ANSWERCLAIM',
  CLAIMTYPE: 'CLAIMTYPE',
  NOTRECEIVE: 'NOTRECEIVE',
  NOTFOUND: 'NOTFOUND' /* Testing 404 route, delete if not needed */,
}
