import React, { useRef, useCallback } from 'react'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import Upload from '../../../../../src/assets/claim-portal/upload.svg'

import './styles.scss'

const ImageUploader = ({ file, onImageSelected, onDelete }) => {
  const imageInput = useRef()

  const onAddImage = () => {
    imageInput.current.click()
  }

  return (
    <>
      <div onClick={() => onAddImage()} className="image-uploader-wrapper">
        {_get(file, 'size', 0) === 0 ? (
          <div className="image-display">
            <div className="image-upload">
              <img className="img-default" src={Upload} alt="upload" />
            </div>
            <div className="information-wrap">
              <p className="title-upload">Upload Photos</p>
            </div>
          </div>
        ) : (
          <div
            className="d-flex image-display"
            style={{
              backgroundImage: `url('${file ? URL.createObjectURL(file) : ''}')`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          ></div>
        )}

        {_get(file, 'size', 0) > 0 && typeof onDelete !== 'undefined' && (
          <div
            className="delete-button-wrapper"
            onClick={(event) => {
              event.stopPropagation()
              onDelete()
            }}
          >
            <span className="btn-delete" />
          </div>
        )}
      </div>
      <input
        type="file"
        accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
        ref={imageInput}
        onChange={(e) => {
          if (e.target?.files[0] && typeof onImageSelected !== 'undefined') {
            // onImageSelected(e.target.files[0])
          }
        }}
        className="file-uploader"
      />
    </>
  )
}

export default ImageUploader
