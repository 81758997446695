import React, { memo, useMemo } from 'react'
import GiftCard from '../../../../../assets/claim-portal/gift-card.svg'
import CreditCard from '../../../../../assets/claim-portal/credit-card.svg'
import './styles.scss'
import _get from 'lodash/get'
import _filter from 'lodash/filter'
import _isEmpty from 'lodash/isEmpty'

import { useClaimPortalContext } from '../../../../../context/claim-portal-context'
import platforms from '../../../../../constants/platforms'

const CardItem = ({ isGiftCard = false, endCharacters, amount }) => {
  const { orderData } = useClaimPortalContext()
  const refundPercentage = useMemo(
    () => parseInt(_get(orderData, 'oneTimeAppeasement.refundPercentage', 100)),
    [orderData]
  )
  const orderProtection = useMemo(
    () =>
      _filter(_get(orderData, 'order_json.line_items', []), {
        title: 'Order Protection',
      }),
    [orderData]
  )
  const platform_id = _get(orderData, 'platform_id', '')

  const refundAmount = !_isEmpty(orderProtection)
    ? amount
    : ((refundPercentage / 100) * amount).toFixed(2)
  const text = useMemo(() => {
    if (platform_id.isMagento()) {
      if (isGiftCard === true) {
        return endCharacters
      }
      return `Card Ending ${endCharacters.toUpperCase()}`
    } else {
      if (isGiftCard === true && endCharacters === '') {
        return 'New Gift Card'
      }
      console.log('isGiftCard', isGiftCard)
      return `${isGiftCard ? 'Gift ' : ''}Card Ending: ${endCharacters.toUpperCase()}`
    }
  }, [isGiftCard, endCharacters, platform_id])
  return (
    <div className="card-payment-item-wrapper">
      <img src={isGiftCard ? GiftCard : CreditCard} alt="card" />
      <div className="card-text">{text}</div>
      <div className="">- ${refundAmount}</div>
    </div>
  )
}

export default memo(CardItem)
