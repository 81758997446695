import { createSlice } from '@reduxjs/toolkit'
import { getSettingsByStoreID } from '../actions/settings'

interface SettingsState {
  brand_100: string
  brand_700: string
  brand_800: string
  brand_900: string
  typeface_name: string
  typeface_woff: string
  typeface_woff2: string
  loading: boolean
}

const radiusMap = {
  ROUNDED_FULL: '100px',
  ROUNDED: '8px',
  SQUARE: '0px',
}

const initialState = {
  brand_100: '',
  brand_700: '',
  brand_800: '',
  brand_900: '',
  typeface_name: '',
  typeface_woff: '',
  typeface_woff2: '',
  loading: true,
  hostname: '',
  page_title: 'Claims - OrderProtection.com',
} as SettingsState

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    load: (state, action) => {
      state.loading = action.payload
    },
    set: (state, action) => {
      state = action.payload
      state.loading = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSettingsByStoreID.pending, (state) => {
        return {
          ...state,
          loading: true,
        }
      })
      .addCase(getSettingsByStoreID.rejected, (state) => {
        return {
          ...state,
          loading: false,
        }
      })
      .addCase(getSettingsByStoreID.fulfilled, (state, action) => {
        if (!action.payload.settings) return {}
        const {
          brand_100,
          brand_700,
          brand_800,
          brand_900,
          background_image,
          radius,
          typeface_woff,
          typeface_woff2,
          typeface_name,
          logo_mobile_width,
          logo_desktop_width,
          letter_spacing,
          text_transform,
          background_color,
          heading_size,
        } = action.payload.settings

        if (brand_100) {
          document.body.style.setProperty('--brand-100', brand_100)
        }

        if (text_transform) {
          document.body.style.setProperty('--text-transform', text_transform.toLowerCase())
        }

        if (letter_spacing || letter_spacing === 0) {
          document.body.style.setProperty('--letter-spacing', letter_spacing)
        }

        if (brand_700) {
          document.body.style.setProperty('--brand-700', brand_700)
        }

        if (brand_800) {
          document.body.style.setProperty('--brand-800', brand_800)
        }

        if (brand_900) {
          document.body.style.setProperty('--brand-900', brand_900)
        }

        if (background_color) {
          document.body.style.setProperty('--background-color', background_color)
        }

        if (heading_size) {
          document.body.style.setProperty('--heading-size', `${heading_size}px`)
        }

        if (background_image) {
          document.body.style.setProperty('--background-image', `url(${background_image})`)
        }

        if (logo_mobile_width) {
          document.body.style.setProperty('--brand-logo-mobile-width', `${logo_mobile_width}px`)
        }

        if (logo_desktop_width) {
          document.body.style.setProperty('--brand-logo-width', `${logo_desktop_width}px`)
        }

        if (typeface_woff && typeface_woff2) {
          const style = `
          @font-face {
            font-family: '${typeface_name}';
            src: url('${typeface_woff2}') format('woff2'),
                url('${typeface_woff}') format('woff');
            font-weight: normal;
            font-style: normal;
          }
        `

          const styleTag = document.createElement('style')
          styleTag.innerHTML = style

          document.head.appendChild(styleTag)

          document.body.style.setProperty('--font-name', `'${typeface_name}'`)
        }

        if (radius) {
          // sometimes i just don't like ts
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          document.body.style.setProperty('--button-radius', radiusMap[radius] || '100px')
        }

        return { loading: false, ...(action.payload.settings || {}) }
      })
  },
})
