import _get from 'lodash/get'
import _find from 'lodash/find'
import _isEmpty from 'lodash/isEmpty'
import platforms from '../constants/platforms'
import { getShippingAddressFromRawJson, getSingleValueFromArray } from './data-transformer'
import { getApi, postApi } from '../helpers/api'

class Order {
  constructor(order, token) {
    this.order = order
    this.token = token
  }
}

class ShopifyOrder extends Order {
  async getVariantData(item) {
    const variantDataRes = await postApi(
      `/shopify/products/variants/${item.variant_id}`,
      {
        storeID: item.store_id,
        variantID: item.variant_id,
      },
      this.token
    )
    return _get(variantDataRes, 'data.variant', {})
  }

  getOriginalItem(rawItem) {
    const rawItemsList = _get(this.order, 'order_json.line_items', [])
    const originalItem = _find(rawItemsList, ['variant_id', parseInt(rawItem.variant_id)])
    return originalItem
  }

  getOrderTracking() {
    const storeId = _get(this.order, 'store_id', 0)
    return getApi(
      `/spf-proxy/${storeId}/orders/${this.order.shopify_order_id}/order-tracking`,
      {},
      this.token
    )
  }

  getOrderTimeline() {
    const storeId = _get(this.order, 'store_id', 0)
    return getApi(
      `/spf-proxy/${storeId}/orders/${this.order.shopify_order_id}/order-timeline`,
      {},
      this.token
    )
  }

  getFulfillmentDetails() {
    const storeId = _get(this.order, 'store_id', 0)
    return getApi(
      `/spf-proxy/${storeId}/orders/${this.order.shopify_order_id}/item-details`,
      {},
      this.token
    )
  }
}

class BigCommerceOrder extends Order {
  async getVariantData(item) {
    const variantDataRes = await postApi(
      `/bigcommerce/products/variants/${item.variant_id}`,
      {
        storeID: item.store_id,
        productID: item.product_id,
        variantID: item.variant_id,
      },
      this.token
    )
    return _get(variantDataRes, 'data.variant', {})
  }

  getOriginalItem(rawItem) {
    const originalItem = rawItem
    originalItem.price /= 1
    return originalItem
  }

  getOrderTimeline() {
    const storeId = _get(this.order, 'store_id', 0)
    return getApi(
      `/bc-proxy/${storeId}/orders/${this.order.shopify_order_id}/order-timeline`,
      {},
      this.token
    )
  }

  getFulfillmentDetails() {
    const storeId = _get(this.order, 'store_id', 0)
    return getApi(
      `/bc-proxy/${storeId}/orders/${this.order.shopify_order_id}/item-details`,
      {},
      this.token
    )
  }
}

class MagentoOrder extends Order {
  async getVariantData(item) {
    const variantDataRes = await postApi(
      `/magento/products/variants/${item.variant_id}`,
      {
        storeId: item.store_id,
        variantId: item.variant_id,
      },
      this.token
    )
    return _get(variantDataRes, 'data', {})
  }

  getOriginalItem(rawItem) {
    const rawItemsList = _get(this.order, 'order_json.line_items', [])
    const originalItem = _find(rawItemsList, ['variant_id', rawItem.variant_id])
    return originalItem
  }

  getFulfillmentDetails() {
    const storeId = _get(this.order, 'store_id', 0)
    return getApi(
      `/magento-proxy/${storeId}/orders/${this.order.order_id}/item-details`,
      {},
      this.token
    )
  }
}

const orderFactory = (order, token = null) => {
  order = {
    ...order,
    get shippingAddress() {
      const orderShipAddress = _get(order, 'shipping_address', '')

      if (_isEmpty(orderShipAddress) === false) {
        return getSingleValueFromArray(orderShipAddress)
      }

      return getShippingAddressFromRawJson(_get(order, 'order_json.shipping_address'))
    },
    get updatedReshipAddress() {
      const orderShipAddress = _get(order, 'claims[0].updated_address', '')

      if (_isEmpty(orderShipAddress) === false) {
        return getShippingAddressFromRawJson(JSON.parse(orderShipAddress))
      }

      return getShippingAddressFromRawJson(_get(order, 'claims[0].updated_address'))
    },
    get shippingPhoneNumber() {
      const orderPhoneNumber = _get(order, 'phone_number', '')
      if (_isEmpty(orderPhoneNumber) === false) {
        return getSingleValueFromArray(orderPhoneNumber)
      }
      return _get(order, 'order_json.shipping_address.phone')
    },
    get shippingEmail() {
      let orderEmail = _get(order, 'order_email', '')
      return getSingleValueFromArray(orderEmail)
    },
    get shippingCustomerName() {
      let customerName = _get(order, 'shipping_customer_name', '')
      return getSingleValueFromArray(customerName)
    },
    get hasMultipleShippingAddresses() {
      const orderShipAddress = _get(order, 'shipping_address', '')
      if (_isEmpty(orderShipAddress)) return false
      const platformId = order.storeData?.platform_id
      if (platformId && platformId === platforms.SHOPIFY) {
        return false
      } else if (platformId && platformId === platforms.BIGCOMMERCE) {
        if (typeof orderShipAddress === 'string') {
          try {
            return JSON.parse(orderShipAddress).length > 1
          } catch {
            return false
          }
        } else if (Array.isArray(orderShipAddress)) {
          return orderShipAddress.length > 1
        }
      }

      return false
    },
    get hasSingleShippingAddress() {
      const orderShipAddress = _get(order, 'shipping_address', '')
      if (_isEmpty(orderShipAddress)) return false
      switch (order.storeData?.platform_id) {
        case platforms.SHOPIFY:
          return true
        case platforms.BIGCOMMERCE:
          try {
            return JSON.parse(orderShipAddress).length === 1
          } catch {
            return false
          }
      }
      return true
    },
  }
  if (order.isShopify()) {
    order = new ShopifyOrder(order, token)
  } else if (order.isBigCommerce()) {
    order = new BigCommerceOrder(order, token)
  } else if (order.isMagento()) {
    order = new MagentoOrder(order, token)
  }
  Object.assign(order, order.order)
  return order
}

export default orderFactory
