import axios from '@/support/utils/request'
import _get from 'lodash/get'
import config from '@/config'

const proxy = config.api.url

/***********
 * QuickBooks
 **********/

/* Create QuickBooks customer */
const createQuickBooksCustomer = async (data: any) => {
  try {
    const res = await axios.post(`${proxy}/quickbooks/createCustomer`, data)
    return res.data
  } catch (e) {
    console.log('createQuickBooksCustomer error:', e)
    return null
  }
}

/* Update QuickBooks customer */
const updateQuickBooksCustomer = async (data: any) => {
  try {
    const res = await axios.post(`${proxy}/quickbooks/updateCustomer`, data)
    return res.data
  } catch (e) {
    console.log('updateQuickBooksCustomer error:', e)
    return null
  }
}

/* Store QuickBooks card */
const storeQuickBooksCard = async (data: any) => {
  try {
    const res = await axios.post(`${proxy}/quickbooks/storeCard`, data)
    return res.data
  } catch (e) {
    console.log('storeQuickBooksCard error:', e)
    return null
  }
}

/***********
 * Connection
 **********/

/* Check if connection exists */
const isNewStore = async (data: any) => {
  try {
    const res = await axios.post(`${proxy}/stores/connections/check`, data)
    return res.data.status
  } catch (e) {
    return false
  }
}

/**************
 * Shopify OAuth
 *************/

/* Connect store by public App */
const connectStoreByPublicApp = (shopifyStoreNameUrl: string, userId: string) => {
  try {
    window.open(`${proxy}/shopify?shop=${shopifyStoreNameUrl}&user_id=${userId}`, '_self')
  } catch (e) {
    console.log('connectStoreByPublicApp error:', e)
  }
}

/******
 * User
 ******/

// Create temp user & merchant
const createTempUser = async () => {
  try {
    const tempUser = await axios.post(`${proxy}/users/temp`)
    return tempUser.data
  } catch (e) {
    console.log(`createTempUser error: ${e}`)
    return null
  }
}

// Update user info
const updateUser = async (data: any) => {
  try {
    const res = await axios.put(`${proxy}/users/${data.userId}/info`, data)
    return res.data
  } catch (e) {
    console.log(`updateUser error: ${e}`)
    return null
  }
}

/*********
 * Merchant
 *********/

// Update emails of merchant
const updateEmailsOfMerchant = async (data: any) => {
  try {
    const res = await axios.put(`${proxy}/merchants/${data.merchantId}`, data)
    return res.data
  } catch (e) {
    console.log(`updateEmailsOfMerchant error: ${e}`)
    return null
  }
}

// Get merchant by user id
const getMerchantByUserId = async (data: any) => {
  try {
    const res = await axios.get(`${proxy}/merchants/users/${data.userId}`, data)
    return res.data
  } catch (e) {
    console.log(`getMerchantByUserId error: ${e}`)
    return null
  }
}

/**************
 * Mail
 *************/

// Send email for expert installation
const sendExpertInstallationEmail = async (data: any) => {
  try {
    const res = await axios.post(`${proxy}/mail/expert-installation`, data)
    return res.data
  } catch (e) {
    console.log(`sendExpertInstallationEmail error: ${e}`)
    return null
  }
}

/**************
 * Shipping Info
 *************/

// Get Lasership delivered image
const getLaserShipImage = async (shippingId: string) => {
  const res = await fetch(`https://t.lasership.com/Track/${shippingId}/json`)
  if (!res.ok) {
    return null
  }
  const data = await res.json()
  return _get(data, 'Events[0].PhotoPath', '')
}

export {
  createQuickBooksCustomer,
  updateQuickBooksCustomer,
  storeQuickBooksCard,
  isNewStore,
  connectStoreByPublicApp,
  createTempUser,
  updateUser,
  updateEmailsOfMerchant,
  getMerchantByUserId,
  sendExpertInstallationEmail,
  getLaserShipImage,
}
