import React, { useState } from 'react'
import { Modal, Button, Spinner } from 'react-bootstrap'
import _map from 'lodash/map'
import _kebabCase from 'lodash/kebabCase'
import './styles.scss'

const UndoClaimModal = ({ show, onHide, onNext, isLoading, rejectedType }) => {
  const [selected, setSelected] = useState('')
  const rejectedName = rejectedType === 5 ? 'Closed' : 'Denied'
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      show={show}
      className="undo-option-modal-wrapper"
      onHide={() => onHide()}
    >
      <Modal.Header closeButton>
        <div className="font-weight-bold">
          <span>Undo Claim</span>
          {isLoading === true && (
            <Spinner size="sm" className="ml-2" animation="border" variant="success" />
          )}
        </div>
      </Modal.Header>
      <Modal.Body className="undo-modal-body">
        <div className="undo-text">
          Are you sure you want to move this claim from "{rejectedName}" to "New Claims"?
        </div>
        <div className="align-center mt-3">
          <Button
            disabled={isLoading}
            className="mr-2 approve-undo"
            onClick={() => onNext(selected)}
          >
            Yes
          </Button>
          <Button disabled={isLoading} variant="secondary" onClick={() => onHide()}>
            No
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default React.memo(UndoClaimModal)
