import axios from '@/support/utils/request'
import config from '@/config'

export const uploader = (file) => {
  const proxy = config.api.url

  return new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('image', file, file.name)

    axios
      .post(`${proxy}/aws/claim/attachment`, formData)
      .then((res) => resolve(res.data.imageKey))
      .catch((e) => reject(e))
  })
}
