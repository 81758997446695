import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import IconLike from '../../../assets/claim-portal/icon-onboarding-like.svg'
import { putApi } from '../../../helpers/api'
import './style.scss'

export default function Welcome({ session, isFirstLoad, isReinstallMode }) {
  const history = useHistory()

  const onSubmit = useCallback(() => {
    putApi('/public-app-onboarding', { account: {}, session }).then(() => {
      history.push(`/onboarding/billing-payouts?session=${session}`)
    })
  }, [session])

  if (isFirstLoad) {
    return <div />
  }

  return (
    <div className="onboarding-block onboarding-block-welcome">
      <div className="onboarding-header">
        <h1 className="onboarding-header__title">
          <img src={IconLike} alt="Icon Like" className="icon" />
          {isReinstallMode === false ? 'Thanks for installing Order Protection!' : 'Welcome Back!'}
        </h1>
        <p className="onboarding-header__description">
          {isReinstallMode === false
            ? `We will now walk you through a few short onboarding steps. This will
          allow you to create an Order Protection Concierge account so you can
          view and manage your account, receive payouts, and more!`
            : 'Looks like you’ve downloaded Order Protection in the past. You’ll now be directed to step 3 of the regular onboarding flow to re-approve recurring billing.'}
        </p>
      </div>
      <button
        className="btn-submit btn-actived"
        onClick={() =>
          isReinstallMode === false
            ? history.push({
                pathname: '/onboarding/account-info',
                search: `?session=${session}`,
              })
            : onSubmit()
        }
      >
        Continue
      </button>
    </div>
  )
}
