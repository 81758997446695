import React from 'react'

// TODO: move into design system
interface SearchNotFoundProps extends SVGSVGElement {
  className: string
}

export const SearchNotFoundIcon = (props: React.SVGProps<SearchNotFoundProps>) => {
  return (
    <svg
      {...props}
      //   width="60"
      //   height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.1376 19.8625C39.6501 19.375 38.8626 19.375 38.3751 19.8625L33.0751 25.1624L27.7751 19.8625C27.2876 19.375 26.5001 19.375 26.0126 19.8625C25.5251 20.35 25.5251 21.1374 26.0126 21.6249L31.3126 26.925L26.0126 32.225C25.5251 32.7125 25.5251 33.5 26.0126 33.9875C26.4876 34.4625 27.3126 34.4625 27.7751 33.9875L33.0751 28.6875L38.3751 33.9875C38.6126 34.225 38.9251 34.3499 39.2626 34.3499C39.6001 34.3499 39.9126 34.225 40.1501 33.9875C40.6376 33.5 40.6376 32.7125 40.1501 32.225L34.8501 26.925L40.1501 21.6249C40.6251 21.1374 40.6251 20.35 40.1376 19.8625Z"
        fill="black"
        fillOpacity="0.38"
      />
      <path
        d="M19.8499 13.625C16.3124 17.1625 14.3624 21.875 14.3624 26.8875C14.3624 31.4375 16.0124 35.8 19.0124 39.2125L18.0874 40.1375L17.1999 39.25C16.7249 38.775 15.8999 38.775 15.4374 39.25L9.2499 45.4375C8.5374 46.15 8.1499 47.0875 8.1499 48.0875C8.1499 49.0875 8.5374 50.0375 9.2499 50.7375C9.9624 51.45 10.8999 51.8375 11.8999 51.8375C12.8999 51.8375 13.8499 51.45 14.5499 50.7375L20.7374 44.55C21.2249 44.0625 21.2249 43.275 20.7374 42.7875L19.8499 41.9L20.7749 40.975C24.1999 43.975 28.5499 45.625 33.0999 45.625C38.1124 45.625 42.8124 43.675 46.3624 40.1375C49.8999 36.6 51.8499 31.8875 51.8499 26.875C51.8499 21.8625 49.8999 17.1625 46.3624 13.6125C39.0499 6.325 27.1624 6.325 19.8499 13.625ZM33.0999 43.1375C28.7624 43.1375 24.6749 41.45 21.6124 38.375C18.5499 35.3 16.8499 31.225 16.8499 26.8875C16.8499 22.55 18.5374 18.4625 21.6124 15.4C24.6749 12.3375 28.7624 10.65 33.0999 10.65C37.4499 10.65 41.5249 12.3375 44.5874 15.4C47.6624 18.475 49.3499 22.55 49.3499 26.8875C49.3499 31.225 47.6624 35.3125 44.5874 38.375C41.5124 41.4375 37.4499 43.1375 33.0999 43.1375ZM11.0124 48.9875C10.5249 48.5 10.5249 47.7125 11.0124 47.225L16.3124 41.925L18.0749 43.7L12.7749 49C12.3124 49.45 11.4749 49.45 11.0124 48.9875Z"
        fill="black"
        fillOpacity="0.38"
      />
    </svg>
  )
}
