import React, { memo } from 'react'
import Badge from '../Badge'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

const OPBadge = ({ isProtected = true, oneTimeAppeasement }) => {
  const icon = isProtected ? (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.2648 2.94533L10.1123 1.42366L16.9598 2.94533C17.1449 2.98647 17.3104 3.08947 17.429 3.23733C17.5476 3.3852 17.6123 3.56909 17.6123 3.75866V12.0812C17.6122 12.9043 17.409 13.7146 17.0205 14.4403C16.6321 15.166 16.0705 15.7846 15.3856 16.2412L10.1123 19.757L4.83897 16.2412C4.1542 15.7847 3.59269 15.1662 3.20426 14.4407C2.81583 13.7152 2.6125 12.905 2.6123 12.082V3.75866C2.61234 3.56909 2.677 3.3852 2.79563 3.23733C2.91426 3.08947 3.07975 2.98647 3.2648 2.94533ZM4.27897 4.427V12.0812C4.27898 12.6299 4.41445 13.1701 4.67336 13.6539C4.93226 14.1377 5.3066 14.5501 5.76314 14.8545L10.1123 17.7545L14.4615 14.8545C14.9179 14.5502 15.2922 14.1379 15.5511 13.6543C15.81 13.1706 15.9455 12.6306 15.9456 12.082V4.427L10.1123 3.132L4.27897 4.427ZM10.9456 8.92366H13.4456L9.27897 14.757V10.5903H6.77897L10.9456 4.757V8.92366Z"
        fill="white"
      />
    </svg>
  ) : oneTimeAppeasement ? (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.6525 2.35499L10.5 0.833328L17.3475 2.35499C17.5326 2.39613 17.698 2.49913 17.8167 2.647C17.9353 2.79487 18 2.97876 18 3.16833V11.4908C17.9999 12.3139 17.7967 13.1243 17.4082 13.85C17.0198 14.5757 16.4582 15.1943 15.7733 15.6508L10.5 19.1667L5.22667 15.6508C4.54189 15.1944 3.98038 14.5759 3.59196 13.8504C3.20353 13.1248 3.0002 12.3146 3 11.4917V3.16833C3.00003 2.97876 3.0647 2.79487 3.18332 2.647C3.30195 2.49913 3.46745 2.39613 3.6525 2.35499ZM4.66667 3.83666V11.4908C4.66668 12.0395 4.80215 12.5798 5.06105 13.0636C5.31996 13.5474 5.69429 13.9598 6.15083 14.2642L10.5 17.1642L14.8492 14.2642C15.3056 13.9598 15.6799 13.5476 15.9388 13.0639C16.1977 12.5803 16.3332 12.0402 16.3333 11.4917V3.83666L10.5 2.54166L4.66667 3.83666Z"
        fill="white"
      />
      <path
        d="M11.543 5.99805V13H9.86328V8.32227L8.3252 9.46973V7.45801L10.4248 5.99805H11.543Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66667 3.83668V11.4908C4.66668 12.0396 4.80215 12.5798 5.06105 13.0636C5.31996 13.5474 5.69429 13.9598 6.15083 14.2642L10.5 17.1642L14.8492 14.2642C15.3056 13.9599 15.6799 13.5476 15.9388 13.0639C16.1977 12.5803 16.3332 12.0403 16.3333 11.4917V3.83668L10.5 2.54168L4.66667 3.83668ZM3.6525 2.35501L10.5 0.833344L17.3475 2.35501C17.5326 2.39615 17.698 2.49915 17.8167 2.64701C17.9353 2.79488 18 2.97877 18 3.16834V11.4908C17.9999 12.314 17.7967 13.1243 17.4082 13.85C17.0198 14.5757 16.4582 15.1943 15.7733 15.6508L10.5 19.1667L5.22667 15.6508C4.54189 15.1944 3.98038 14.5759 3.59196 13.8504C3.20353 13.1248 3.0002 12.3146 3 11.4917V3.16834C3.00003 2.97877 3.0647 2.79488 3.18332 2.64701C3.30195 2.49915 3.46745 2.39615 3.6525 2.35501Z"
        fill="#444444"
      />
      <path
        d="M10.5 8.50536L12.3956 6.60913L13.344 7.55758L11.4478 9.45314L13.344 11.3487L12.3956 12.2971L10.5 10.4009L8.60445 12.2971L7.65601 11.3487L9.55223 9.45314L7.65601 7.55758L8.60445 6.60913L10.5 8.50536Z"
        fill="#444444"
      />
    </svg>
  )
  const text = isProtected ? 'Protected' : oneTimeAppeasement ? 'One-time' : 'Unprotected'
  const className = isProtected
    ? 'info'
    : !isProtected && oneTimeAppeasement
    ? 'one-time'
    : 'warning'
  return <Badge icon={icon} text={text} className={className} />
}

export default memo(OPBadge)
