import './ClaimSignature.scss'
import _get from 'lodash/get'
import _map from 'lodash/map'

import { Col, Form, Button, InputGroup, Modal } from 'react-bootstrap'
import SignaturePad from 'react-signature-pad-wrapper'
import { useRef } from 'react'

import { useState } from 'react'
import { views } from '../../../helpers/appViews'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImage } from '@fortawesome/free-solid-svg-icons'
import ClaimRefund from '../ClaimRefund/ClaimRefund'
import AddUpdatedAddress from '../AddUpdatedAddress/AddUpdatedAddress'

export default function ClaimSignature(props) {
  const [editAddress, enableEditAddress] = useState(false)
  const [checked, setChecked] = useState(false)
  const [showText, setShowText] = useState(true)
  const [signature, toggleSignature] = useState(false)
  const [identification, setIdentification] = useState(props.state.store.store_identification)

  const [error, setError] = useState({
    active: false,
    message: 'Default',
  })

  let padRef = useRef({})

  function clearSignaturePad() {
    padRef.current.toDataURL()
    console.log(padRef.current.toDataURL())
    console.log(padRef.current)
    padRef.current.clear()
    setShowText(true)
  }

  function closeModal(flag) {
    enableEditAddress(false)
  }

  function createClaim() {
    if (showText) {
      alert('You must provide a signature')
    } else {
      const claimType = props.state.customerData.claimType.id
      const claimItems = props.state.customerData.orderItems
      const unstockedItemsIds = props.state.unstockedItems ? props.state.unstockedItems : null
      const updatedAddress = props.state.updatedAddress ? props.state.updatedAddress : ''
      const claimDetails = props.state.customerData.claimDetails
        ? props.state.customerData.claimDetails
        : ''
      const reimbursementGateway = props.state.reimbursementType
        ? props.state.reimbursementType
        : ''

      const userId = props.state.userData.id ? _get(props, 'state.userData.id', null) : null

      claimItems.forEach((item) => (item.is_checked = 'true'))
      const orderId = props.state.customerData.id
      const claimDate = new Date().toISOString().split('T')[0]
      const reimbursementType = props.state.claimType
      const data = {
        claimType,
        claimDetails,
        claimItems,
        orderId,
        claimDate,
        userId,
        reimbursementType,
        unstockedItemsIds,
        updatedAddress,
        reimbursementGateway,
      }
      props.setState({
        ...props.state,
        signaturePad: padRef.current.signaturePad.canvas,
      })
      props.changeView(views.CLAIMCONFIRMATION)
    }
  }

  function getStockedOptions(options) {
    return options.filter((op) => op.inventory_quantity > 0)
  }

  function getUnstockedOptions(options) {
    return options.filter((op) => op.inventory_quantity < 1)
  }

  const stockedItems = props.state.customerData.orderItems
    ? getStockedOptions(props.state.customerData.orderItems)
    : null

  const unstockedItems = props.state.customerData.orderItems
    ? getUnstockedOptions(props.state.customerData.orderItems)
    : null

  return (
    <>
      <Col className="claimSignature">
        <h2>Review and complete your claim.</h2>
        <div className="subheading">
          Claim type: {props.state.claimType == 1 ? 'Issue Refund' : 'Reship Order'}
          Order{' '}
          {props.state.customerData.store_order_id
            ? props.state.customerData.store_order_id
            : ' '}{' '}
          Resolution : {props.state.claimType == 1 ? 'Issue Refund' : 'Reship Order'}{' '}
        </div>

        {props.state.claimType == 2 && (
          <>
            {props.state.customerData &&
              props.state.customerData.order_json &&
              props.state.customerData.order_json.shipping_address && (
                <>
                  <p className="text shipping-text">
                    Shipping Address <br></br>
                    {props.state.customerData.order_json.shipping_address.address1},{' '}
                    {props.state.customerData.order_json.shipping_address.address2},
                    {props.state.customerData.order_json.shipping_address.city},{' '}
                    {props.state.customerData.order_json.shipping_address.province_code},
                    {props.state.customerData.order_json.shipping_address.country},{' '}
                    {props.state.customerData.order_json.shipping_address.zip}
                    <p className="edit" onClick={() => enableEditAddress(true)}>
                      <svg
                        id="pencil-icon"
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.9973 1.73223C15.3363 1.07123 14.4596 0.705566 13.5221 0.705566C12.5892 0.705566 11.7078 1.07123 11.0468 1.73223L1.79748 10.9816C1.69434 11.0847 1.6334 11.216 1.60996 11.3566L0.953643 15.9555C0.925515 16.1571 0.991146 16.3633 1.13647 16.5087C1.25836 16.6352 1.43181 16.7056 1.60527 16.7056C1.6334 16.7056 1.65684 16.7056 1.68496 16.7009L6.35886 16.1196C6.50419 16.1008 6.64014 16.0352 6.74327 15.9321L15.9973 6.67802C17.3615 5.30914 17.3615 3.09642 15.9973 1.73223ZM5.97445 14.8398L2.37409 15.2851L2.88039 11.7551L10.7937 3.84181L13.883 6.93117L5.97445 14.8398ZM15.0738 5.7545L14.8159 6.01233L11.7266 2.92297L11.9844 2.66513C12.3923 2.24322 12.9408 2.01819 13.5267 2.01819C14.1081 2.01819 14.6612 2.24322 15.0738 2.66044C15.4863 3.07767 15.716 3.62148 15.716 4.20747C15.716 4.78878 15.491 5.34196 15.0738 5.7545Z"
                          fill="white"
                        />
                      </svg>
                    </p>
                  </p>
                </>
              )}

            <AddUpdatedAddress
              view={props.view}
              changeView={props.changeView}
              state={props.state}
              setState={props.setState}
              closeModal={closeModal}
            ></AddUpdatedAddress>
          </>
        )}
        <div id="confirmation">
          <InputGroup>
            <Form.Control
              type="checkbox"
              placeholder="Shopify Store Name"
              defaultChecked={checked}
              onChange={() => {
                setChecked(!checked)
              }}
            />
          </InputGroup>
          <p>
            I confirm this address is accurate and understand it cannot be reshipped multiple times
          </p>
        </div>
        <Form className="mt-3">
          <div id="inner-padding">
            <div id="margin">
              {stockedItems &&
                stockedItems.map((product, id) => {
                  if (props.state.claimType == 2) {
                    return (
                      <>
                        <div className="subheading">Items in your claim: </div>

                        <div
                          key={product?.id}
                          className={
                            'group-product' + (product.inventory_quantity <= 0 ? ' unstocked' : '')
                          }
                        >
                          <div className="group-img-product">
                            <img className="img-product" src={product?.photo} alt="" />
                            {product.inventory_quantity <= 0 && (
                              <div className="out-of-stock-text">Out of Stock</div>
                            )}
                          </div>
                          <div className="content-product">
                            <label className="heading">{product?.item_name || ''}</label>
                            {product?.variant_name && (
                              <label className="variant-info">
                                Variant: {product?.variant_name}
                              </label>
                            )}
                          </div>
                        </div>
                      </>
                    )
                  }
                })}
              {unstockedItems &&
                unstockedItems.map((product, id) => {
                  return (
                    <>
                      <div className="subheading">Items in your claim: </div>

                      <div
                        key={product?.id}
                        className={
                          'group-product' + (product.inventory_quantity <= 0 ? ' unstocked' : '')
                        }
                      >
                        <div className="group-img-product">
                          <img className="img-product" src={product?.photo} alt="" />
                          {product.inventory_quantity <= 0 && (
                            <div className="out-of-stock-text">Out of Stock</div>
                          )}
                        </div>
                        <div className="content-product">
                          <label className="heading">{product?.item_name || ''}</label>
                          {product?.variant_name && (
                            <label className="variant-info">Size: {product?.variant_name}</label>
                          )}
                        </div>
                      </div>
                    </>
                  )
                })}
            </div>
          </div>
        </Form>
      </Col>
      <div className="claim-refund-wrapper">
        <ClaimRefund state={props.state} setState={props.setState}></ClaimRefund>
        <div id="button-wrapper">
          <Button
            onClick={() => {
              if (checked) {
                toggleSignature(true)
              }
            }}
            className="submit-button"
          >
            Submit Claim
          </Button>
          <div>Cancel</div>
        </div>
      </div>
      <Modal
        id="signature-modal"
        show={signature}
        onHide={() => toggleSignature(false)}
        animation={false}
      >
        <Modal.Body>
          <Form id="signature-form">
            <div id="warning-signature">
              <b>WARNING: You are about to submit an insurance claim</b>
            </div>
            <div id="submission-text">
              Please note, we will be unable to process a return of your original order once a
              refund or reshipment has been sent.
              <br />
              <br />
              This information may be submitted to the US Post Office. Any falsified information
              will be taken as mail fraud and prosecuted to the fullest extent of the law. In the
              event that your claim is investigated and reveals any fraudulent activity, you may be
              denied the right to return your product(s) for a refund or reshipment.
            </div>
            <div id="signature-header">
              Signature{' '}
              <span className="reset" onClick={() => clearSignaturePad(true)}>
                <svg
                  width="39"
                  height="45"
                  viewBox="0 0 39 45"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.9865 7.82247V0.818359L11.5472 9.06978L19.9865 17.3212V10.2211C23.7873 10.287 27.4474 11.6381 30.3443 14.0446C33.2412 16.4511 35.1961 19.7644 35.8765 23.4211C36.5569 27.0778 35.9208 30.852 34.0763 34.1019C32.2318 37.3517 29.2929 39.8765 25.7594 41.247C22.2259 42.6174 18.316 42.7487 14.6946 41.6187C11.0732 40.4887 7.96392 38.1671 5.89564 35.0486C3.82736 31.9302 2.92775 28.2076 3.3498 24.5139C3.77185 20.8202 5.48951 17.3835 8.21065 14.7882L8.38729 14.6347L6.73866 12.9077L6.56203 13.0804C4.74675 14.7973 3.30509 16.8552 2.32364 19.1305C1.34219 21.4058 0.841185 23.8516 0.850719 26.321C0.850963 29.9706 1.95536 33.5387 4.02492 36.576C6.09448 39.6133 9.03672 41.9842 12.4813 43.3903C15.9259 44.7964 19.719 45.1749 23.3831 44.478C27.0472 43.7812 30.4186 42.0402 33.0729 39.4742C35.7273 36.9083 37.5461 33.6319 38.3003 30.0576C39.0546 26.4832 38.7105 22.7706 37.3116 19.387C35.9126 16.0033 33.5213 13.0999 30.4384 11.0421C27.3556 8.98433 23.7191 7.86412 19.9865 7.82247Z"
                    fill="#444444"
                  />
                </svg>
              </span>
            </div>
            <div
              onMouseMove={() => {
                //setShowText(false);
              }}
              id="signaturePad"
            >
              {showText && (
                <>
                  <div id="signature-text">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5858 0.585786C11.3668 -0.195262 12.6332 -0.195262 13.4142 0.585786C14.1953 1.36683 14.1953 2.63316 13.4142 3.41421L12.6213 4.20711L9.79289 1.37868L10.5858 0.585786Z"
                        fill="#9CA3AF"
                      />
                      <path
                        d="M8.37868 2.79289L0 11.1716V14H2.82842L11.2071 5.62132L8.37868 2.79289Z"
                        fill="#9CA3AF"
                      />
                    </svg>

                    <p>Draw your signature here</p>
                  </div>
                </>
              )}
              <SignaturePad
                ref={padRef}
                onMouseMove={() => {
                  //setShowText(false);
                }}
                options={{ minWidth: 1, maxWidth: 3, penColor: 'black' }}
              />
            </div>
            {identification && (
              <>
                <div id="subtitle">Identification Document</div>
                <div id="identification">Please provide a form of identication.</div>
                <div className="claim-image-upload">
                  <label className="claim-image-label" htmlFor="image-input">
                    <FontAwesomeIcon icon={faFileImage} />+
                  </label>
                  <input
                    id="image-input"
                    type="file"
                    multiple
                    onChange={(event) => props.onFileSelect(event)}
                  />
                </div>
                <span id="image-upload">{props.identityUploadMessage}</span>
              </>
            )}
            <div id="button-form">
              <Button
                variant="primary"
                className="submit-button"
                onClick={() => {
                  createClaim()
                }}
              >
                Submit Claim
              </Button>
              <div onClick={() => toggleSignature(false)}>Cancel</div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <AddUpdatedAddress
        state={props.state}
        setState={props.setState}
        closeModal={closeModal}
        editAddress={editAddress}
      ></AddUpdatedAddress>
    </>
  )
}
