import './ClaimItemsOption.scss'
import { Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages } from '@fortawesome/free-solid-svg-icons'

export default function ClaimItemsOptions(props) {
  console.log(props)

  const isClaimItemsPage = props.view === 'CLAIMITEMS'
  const isClaimConfirmationPage = props.view === 'CLAIMCONFIRMATION'
  const retrieveClaimStatus = () => {
    const claimStatus = props?.state?.customerData?.claimStatus
    if (claimStatus !== 'Dismissed') {
      return claimStatus
    }
    return 'Pending'
  }
  return (
    <Row className="claim-type align-items-center">
      <Col xs={2}>
        {props.claimItem.photo ? (
          <img src={props.claimItem.photo} alt="claimItemPhoto" />
        ) : (
          <h1>
            <FontAwesomeIcon icon={faImages} />
          </h1>
        )}
      </Col>
      <Col xs={6}>
        <p className="font-md font-semibold">{props.claimItem.item_name}</p>
      </Col>

      {isClaimItemsPage ? (
        <Col xs={4}>
          <Row className="h-100 align-items-end">
            <Col className="text-right">
              {props.fulfilled ? (
                <input
                  className="text-center mt-md-0 mt-4"
                  name="itemAffected"
                  type="checkbox"
                  checked={props.claimItem.is_checked}
                  value={props.claimItem.id}
                  onChange={props.handleCheckboxClick}
                />
              ) : (
                <Button className="text-center mt-md-0 mt-4" variant="secondary" disabled>
                  Unfulfilled
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      ) : null}

      {isClaimConfirmationPage ? (
        <Col>
          <Row className="h-100 align-items-end">
            <Col className="text-right">
              {props.claimItem.is_checked ? (
                <Button variant="dark" disabled>
                  {retrieveClaimStatus()}
                </Button>
              ) : props.state.customerData.claimStatus ? (
                <Button variant="success" disabled>
                  Add
                </Button>
              ) : (
                <Button variant="success" onClick={() => props.handleAddItemtoClaim()}>
                  Add
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      ) : null}
    </Row>
  )
}
