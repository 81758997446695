import { useAuth0 } from '@auth0/auth0-react'
import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { Dropdown, NavDropdown } from 'react-bootstrap'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _startCase from 'lodash/startCase'
import _orderBy from 'lodash/orderBy'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import { useAdminContext } from '../../../context/admin-context'
import SearchBox from '../../../components/SearchBox'
import { convertSpecialChar } from '../../../helpers/convert-special-char'
import LockIcon from '../../../assets/lock.svg'
import Logo from '../../../assets/op-logo-black.svg'

import './styles.scss'
import { PermissionSet } from '../../../configuration/permission-set'
import PermissionWrapper from '../../../components/PermissionWrapper'

const AdminAppbar = ({ isAuthenticated }) => {
  const { logout, user } = useAuth0()
  const { selectedStoreId, setSelectedStoreId, isOpenMobileMenu, toggleMenu, userPermission, orderLoading } =
    useAdminContext()
  const showAllStores = useMemo(() => {
    if (typeof userPermission === 'undefined') {
      return false
    }

    return (userPermission.permission || []).includes(PermissionSet.ListAllStore)
  }, [userPermission])

  const selectedStore = useMemo(() => {
    if (typeof userPermission === 'undefined') {
      return {
        store_url: 'Loading...',
      }
    }

    const { stores } = userPermission

    if (selectedStoreId === 0) {
      return {
        store_url: 'all-stores',
      }
    }

    return _find(stores, (store) => store.id === selectedStoreId)
  }, [userPermission, selectedStoreId])

  useEffect(() => {
    if (selectedStoreId === -1 && typeof userPermission !== 'undefined') {
      // No selected store
      const fromLocalStorage = window.localStorage.getItem('persist:root')
      if (!fromLocalStorage) {
        const selectedStoreId = showAllStores ? 0 : userPermission.stores[0]?.id
        setSelectedStoreId(selectedStoreId)
      }
    }
  }, [selectedStoreId, userPermission, showAllStores, user])

  const hasSelectedSpecificStore = useMemo(
    () => selectedStoreId !== 0 && selectedStoreId !== -1,
    [selectedStoreId]
  )
  const getStoreName = useCallback((url) => _startCase(url?.replace('.myshopify.com', '')), [])
  const stopClickPropagation = (event) => event.stopPropagation()

  return (
    <div className="appbar-wrapper">
      <div className="mobile-area">
        <div className="right-items">
          <div
            className={`icon-wrapper ${isOpenMobileMenu ? 'open' : ''}`}
            onClick={() => toggleMenu()}
          >
            <span />
            <span />
            <span />
            <span />
          </div>
          <Link to="/admin">
            <div>
              <img src={Logo} alt="home" />
            </div>
          </Link>
        </div>
        {isAuthenticated === true && (
          <NavDropdown
            className="user-profile"
            title={
              <>
                <img src={user?.picture} alt="avatar" />
              </>
            }
            id="basic-nav-dropdown"
          >
            <PermissionWrapper set={[PermissionSet.ClaimAction]}>
              <>
                <NavDropdown.Item as={Link} to={'/profile'} onClick={stopClickPropagation}>
                  User Profile
                </NavDropdown.Item>
                <NavDropdown.Divider />
              </>
            </PermissionWrapper>
            <NavDropdown.Item
              onClick={() => logout({ returnTo: `${window.location.origin}/admin` })}
            >
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        )}
      </div>
      <div className="left-items">
        <Dropdown className="select-store">
          <Dropdown.Toggle
            className={classnames({
              'store-selected': hasSelectedSpecificStore,
            })}
            variant="success"
            id="dropdown-basic"
            disabled={orderLoading}
          >
            <div className="dropdown-title">
              <img src={LockIcon} alt="" />
              <div className="dropdown-title__text">
                {selectedStore?.store_name && selectedStore?.store_name !== 'Unknown'
                  ? selectedStore?.store_name
                  : getStoreName(selectedStore?.store_url)}
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {showAllStores && (
              <Dropdown.Item onClick={() => setSelectedStoreId(0)}>All Stores</Dropdown.Item>
            )}
            {_map(
              _orderBy(
                userPermission?.stores || [],
                [
                  (store) => {
                    return store.store_name !== 'Unknown'
                      ? convertSpecialChar(store.store_name).toLowerCase()
                      : convertSpecialChar(getStoreName(store?.store_url)).toLowerCase()
                  },
                ],
                ['asc']
              ).filter((item) => item.hide_in_list !== true),
              (store, index) => (
                <Dropdown.Item onClick={() => setSelectedStoreId(store.id)} key={index}>
                  {store.store_name !== 'Unknown'
                    ? store.store_name
                    : getStoreName(store?.store_url)}
                </Dropdown.Item>
              )
            )}
          </Dropdown.Menu>
        </Dropdown>
        <SearchBox stores={userPermission?.stores || []} getStoreName={getStoreName} />
      </div>
      <div className="right-items">
        {isAuthenticated === true && (
          <NavDropdown
            className="user-profile"
            title={
              <>
                <span className="avatar">
                  <img src={user?.picture} alt="avatar" />
                </span>
                <span>{user?.name || user?.nickname}</span>
              </>
            }
            id="basic-nav-dropdown"
          >
            <PermissionWrapper set={[PermissionSet.ClaimAction]}>
              <>
                <NavDropdown.Item as={Link} to={'/profile'} onClick={stopClickPropagation}>
                  User Profile
                </NavDropdown.Item>
                <NavDropdown.Divider />
              </>
            </PermissionWrapper>
            <NavDropdown.Item
              onClick={() => logout({ returnTo: `${window.location.origin}/admin` })}
            >
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        )}
      </div>
    </div>
  )
}

export default memo(AdminAppbar)
