import React, { memo, useCallback, useMemo, useState } from 'react'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _find from 'lodash/find'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import { useClaimPortalContext } from '../../../context/claim-portal-context'
import ClaimOrderDetails from '../Message/ClaimOrderDetails'
import './styles.scss'
import { ClaimTypes } from '../configuration'
import DamageList from './DamageList'

import { useHistory } from 'react-router'
import ButtonUI from '../../CoreUI/ButtonUI'
import LinkButton from '../../CoreUI/LinkButton'
import { Spinner } from 'react-bootstrap'
import { ImageUpload } from '../Core/ImageUpload'

const SelectItems = ({ selectedFiles, setSelectedFiles }) => {
  const { orderData, orderItems, orderTracking, selectedClaimType, onSubmitItem } =
    useClaimPortalContext()
  const isRequired = useMemo(
    () => _get(selectedClaimType, 'require_image', false) === true,
    [selectedClaimType]
  )
  const [selectedItems, setSelectedItems] = useState(
    _map(
      _filter(orderItems, (item) => item.is_checked === true),
      'id'
    )
  )
  const [isRequiredTwo, setIsRequiredTwo] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)
  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  const onNext = useCallback(() => {
    setIsLoading(true)

    if (selectedClaimType.id === 6 || selectedClaimType.id === 3 || selectedClaimType.id === 2) {
      history.push('/submitClaim/select-item')
      //props.changeView(views.BEGINMISSING);
    } else if (selectedClaimType.id === 1) {
      history.push('/submitClaim/resolution')
    }
    setIsLoading(false)
  }, [selectedItems, selectedClaimType])

  const config = useMemo(() => {
    console.log(selectedClaimType)
    const id = _get(selectedClaimType, 'id', -1)

    switch (id) {
      case 1:
        return ClaimTypes.Stolen
      case 2:
        return ClaimTypes.DamageItem
      case 3:
        return ClaimTypes.DefectiveItem
      case 6:
        return ClaimTypes.MissingItem
      default:
        return ClaimTypes.WrongItem
    }
  }, [selectedClaimType])

  return (
    <div className="damage-item-wrapper">
      <div className="container-damage-item-wrapper">
        <div className="inner">
          <div className="inner-column left">
            <label className="title">{config.text}</label>
            <label className="order-number">
              Order #
              {orderData?.isMagento() ? orderData.display_order_id : orderData?.store_order_id}
            </label>

            <label className="sub-text">{config.question}</label>

            <DamageList
              items={orderItems}
              setSelected={setSelectedItems}
              isRequiredTwo={setIsRequiredTwo}
            />

            {selectedClaimType && (selectedClaimType.id === 2 || selectedClaimType.id === 3) && (
              <>
                <div className="upload-area">
                  <label className="sub-head !mb-3">
                    <span>{config.uploadMessage}</span>
                    <span className="">
                      {' '}
                      <span className="color-required">* Required</span>
                    </span>
                  </label>
                  <ImageUpload onChange={setSelectedFiles} />
                </div>
              </>
            )}

            <ButtonUI
              disabled={
                (selectedClaimType &&
                  (selectedClaimType.id === 2 || selectedClaimType.id === 3) &&
                  (isRequiredTwo === false || selectedFiles.length === 0)) ||
                isRequiredTwo === false
              }
              onClick={() => onNext()}
            >
              {isLoading === true ? (
                <Spinner size="sm" className="ml-2" animation="border" variant="light" />
              ) : (
                <span>Continue</span>
              )}
            </ButtonUI>

            <LinkButton className="" onClick={() => history.push('/submitClaim')}>
              Back
            </LinkButton>
          </div>

          <div className="inner-column right">
            <ClaimOrderDetails
              orderItems={orderItems}
              orderData={orderData}
              orderTracking={orderTracking}
              isError={false}
              hideItemList={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(SelectItems)
