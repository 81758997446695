import React, { memo, useEffect, useMemo, useState } from 'react'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _filter from 'lodash/filter'

import _map from 'lodash/map'
import axios from '@/support/utils/request'
import { useClaimPortalContext } from '../../../../context/claim-portal-context'
import './styles.scss'
import { getProxy } from '../../../../helpers/api'
import CardLine from './CardLine'
import platforms from '../../../../constants/platforms'

const RefundOffer = ({ isReship, totalMoney, selectedMethod, setSelectedMethod }) => {
  const { orderData, storePlan } = useClaimPortalContext()
  const [transactionData, setTransactionData] = useState([])
  const refundPercentage = useMemo(
    () => parseInt(_get(orderData, 'oneTimeAppeasement.refundPercentage', 100)),
    [orderData]
  )

  const totalRefund = useMemo(() => totalMoney)
  const orderProtection = useMemo(
    () =>
      _filter(_get(orderData, 'order_json.line_items', []), {
        title: 'Order Protection',
      }),
    [orderData]
  )
  const refundAmount = !_isEmpty(orderProtection)
    ? totalRefund
    : ((refundPercentage / 100) * totalRefund).toFixed(2)

  useEffect(() => {
    const orderId = _get(orderData, 'shopify_order_id', '')

    if (orderData.isShopify() && _isEmpty(orderId) === false) {
      axios
        .get(`${getProxy()}/spf-proxy/${orderData.store_id}/orders/${orderId}/order-transaction`)
        .then((res) => {
          setTransactionData(res.data)
        })
    } else if (orderData.isMagento()) {
      axios
        .get(
          `${getProxy()}/magento-proxy/${orderData.store_id}/orders/${
            orderData.order_id
          }/order-transaction`
        )
        .then((res) => {
          setTransactionData(res.data)
        })
    }
  }, [orderData])

  return (
    <div className="refund-offer-wrapper">
      <div className="method-selection">
        <label id="label-option">
          {isReship === true
            ? 'Any item that is out of stock when the claim is approved (if eligible) will be credited as:'
            : 'Upon approval your claim claim will be credited as:'}
        </label>
        <select
          className="select-payment"
          defaultValue={selectedMethod}
          onChange={(event) => setSelectedMethod(event.target.value)}
        >
          <option value="original">Original Form of Payment(s)</option>
          {storePlan && storePlan.toLowerCase() == 'shopify_plus' && (
            <>
              <option value="store credit">Store Credit</option>
            </>
          )}
        </select>
      </div>
      {orderData.isMagento() && (
        <div className="payment-details">
          {!transactionData?.payment_details?.credit_card_number && (
            <CardLine
              key={1}
              isGiftCard={true}
              amount={totalRefund}
              endCharacters={transactionData?.payment_method ? transactionData?.payment_method : ''}
            />
          )}
          {transactionData?.payment_details?.credit_card_number && (
            <CardLine
              key={1}
              isGiftCard={false}
              amount={totalRefund}
              endCharacters={
                transactionData?.payment_details?.credit_card_number
                  ? transactionData?.payment_details?.credit_card_number
                  : ''
              }
            />
          )}
        </div>
      )}
      {!orderData.isMagento() && (
        <div className="payment-details">
          {selectedMethod === 'original' ? (
            _map(transactionData, (line) => {
              const isGiftCard = _get(line, 'gateway', '') === 'gift_card'
              if (isGiftCard === true) {
                return (
                  <CardLine
                    key={line.id}
                    isGiftCard={true}
                    amount={line.amount}
                    endCharacters={_get(line, 'receipt.gift_card_last_characters', '')}
                  />
                )
              }

              const lastCharacter = _get(line, 'payment_details.credit_card_number', '').replace(
                / /g,
                ''
              )
              if (
                _get(line, 'status', '').toLowerCase() === 'success' &&
                ['sale', 'capture'].includes(_get(line, 'kind', '').toLowerCase())
              ) {
                return (
                  <CardLine
                    key={line.id}
                    isGiftCard={false}
                    amount={totalRefund}
                    endCharacters={lastCharacter.substr(lastCharacter.length - 4)}
                  />
                )
              }
            })
          ) : (
            <CardLine isGiftCard={true} amount={totalRefund} endCharacters="" />
          )}
        </div>
      )}

      <div className="sub-total">
        <label>Total Refund: ${refundAmount}</label>
      </div>
    </div>
  )
}

export default memo(RefundOffer)
