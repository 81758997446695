import React, { RefObject } from 'react'
import _get from 'lodash/get'
import { RegisterOptions, useController, useFormContext } from 'react-hook-form'

export type Props<T = any> = {
  rules?: RegisterOptions
  name: string
  errorGroup?: string[]
  className?: string
  onBlur?: (e: any) => void
  value?: any
  onChange?: (e: any) => void
  emptyValue?: any
  componentType?: 'checkbox' | 'radio' | 'common' | 'select'
  component: React.ComponentType<T>
  inputRef?: RefObject<any> | React.Ref<any>
  valueAs?: (value: any) => any
  changeAs?: (value: any) => any
}

export const Field = <T,>({
  component,
  name,
  rules,
  onBlur: onBlurProp,
  className,
  value: valueProp,
  errorGroup = [],
  emptyValue = '',
  inputRef,
  onChange: onChangeProp,
  valueAs = (value: any) => value,
  changeAs = (value: any) => value,
  componentType,
  ...restProps
}: Props<T>) => {
  const Component = component

  const {
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    setError,
    trigger,
  } = useFormContext()

  const {
    field: { onChange, onBlur, value, ref: fieldRef, ...restField },
  } = useController({ name, rules })

  const isCheckboxType = componentType === 'checkbox'
  const isSelectType = componentType === 'select'

  React.useEffect(() => {
    if (emptyValue && !value) {
      setValue(name, emptyValue, { shouldDirty: false })
    }
  }, [])

  const error = _get(errors, name)
  const errorMessage = error?.message

  const hasGroupError = errorGroup.reduce(
    (acc, cur) => !!acc && !!_get(errors, cur)?.message,
    false
  )

  const values = errorGroup.map((name) => getValues(name))

  React.useEffect(() => {
    if (hasGroupError) {
      errorGroup.forEach((name) => clearErrors(name))
    } else {
      errorGroup.forEach((name) => setError(name, { message: errorMessage as string }))
    }
  }, [clearErrors, errorMessage, setError, ...errorGroup, ...values])

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const _value = e.target?.value ?? (e as any) ?? emptyValue
    isCheckboxType ? onChange(changeAs(e)) : onChange(changeAs(_value))
    onChangeProp?.(e)
    isSelectType && trigger(name)
  }

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    onBlur()
    onBlurProp?.(e)
  }

  const resolveValue = React.useCallback(() => {
    if ([null, undefined].includes(value)) return emptyValue

    if (valueAs) return valueAs(value)

    return value
  }, [JSON.stringify(emptyValue), JSON.stringify(value), valueAs])

  const hasError = !!error || hasGroupError
  return (
    <Component
      onChange={handleChange}
      onBlur={handleBlur}
      error={hasError || (undefined as any)}
      className={className}
      value={resolveValue()}
      ref={inputRef ?? fieldRef}
      checked={isCheckboxType ? !!value : undefined}
      {...restField}
      {...(restProps as any)}
    />
  )
}

export default Field
