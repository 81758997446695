import './ClaimNextButton.scss'
import { Col, Button } from 'react-bootstrap'

export default function ClaimNextButton(props) {
  return (
    <Col className="text-center">
      <Button
        className="w-25 btn-next mt-3 mr-5"
        onClick={props.onNextClick}
        disabled={props.disabled}
      >
        Next
      </Button>
    </Col>
  )
}
