import _padStart from 'lodash/padStart'
import _xor from 'lodash/xor'
import { number } from 'prop-types'

export const formatTime = (dateString) => {
  const date = new Date(dateString)

  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  const strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

export const formatDate = (dateString) => {
  let processString = dateString

  if (isNaN(processString) === false) {
    // this is timestamp
    processString = new Date(parseInt(dateString)).toString()
  }

  const date = new Date(processString)
  const today = new Date()

  const strTime = formatTime(processString)

  if (
    date.getYear() === today.getYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  ) {
    return 'Today at ' + strTime
  } else if (
    date.getYear() === today.getYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate() - 1
  ) {
    return 'Yesterday at ' + strTime
  } else {
    return date.toLocaleDateString([], { month: 'short', day: '2-digit' }) + ' at ' + strTime
  }
}

export const formatDatetime = (unixTimeString) => {
  let date = new Date()
  const number = Number(unixTimeString)

  if (number) {
    date = new Date(parseInt(number))
  }
  const formattedDate = date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  })
  const desiredFormat = formattedDate.replace(', ', ' ')

  return desiredFormat
}

export const formatDatetimeForPayout = (unixTimeString, includeDay = false) => {
  let date
  const number = Number(unixTimeString)

  if (number) {
    if (number.toString().length != 13) {
      date = new Date(number * 1000)
    } else {
      date = new Date(number)
    }
  } else {
    date = new Date(unixTimeString)
  }

  const year = date.getFullYear()

  const options = { month: 'long', timeZone: 'UTC' }
  const month = new Intl.DateTimeFormat('en-US', options).format(date)

  if (includeDay) {
    const day = date.getDate()

    return `${month} ${day}, ${year}`
  }

  return `${month} ${year}`
}

export const formatDatetimeForBilling = (unixTimeString) => {
  let date
  const number = Number(unixTimeString)

  if (number) {
    date = new Date(number)
  } else {
    date = new Date(unixTimeString)
  }

  const options = { month: 'long', timeZone: 'UTC' }
  const month = new Intl.DateTimeFormat('en-US', options).format(date)

  const day = date.getUTCDate()

  return `${month} ${day}`
}

export const getCurrentDateForPayout = (date = null) => {
  let options = { month: 'short', timeZone: 'UTC' }
  let formattedDate = new Date(date)

  if (formattedDate.getMonth() === new Date().getMonth()) {
    options = { month: 'short', timeZone: 'UTC', day: 'numeric' }
    formattedDate = new Date()
  }

  return formattedDate.toLocaleDateString('en-US', options)
}

export const formatMoneyNoDecimal = (cent) => {
  return Number(cent / 100).toFixed(0)
}

export const formatMoney = (dollarsInCents) => {
  const amount = Number(dollarsInCents / 100).toFixed(2)
  const formatted = Number(amount).toLocaleString()

  if (formatted.includes('.')) {
    if (formatted.split('.')[1].length === 1) {
      return formatted + '0'
    } else {
      return formatted
    }
  } else {
    return formatted + '.00'
  }
}

export const formatPercentage = (percentage) => percentage.toFixed(2).replace('.00', '')

export const getInitialDateRange = () => ({
  fromDate: getIntervalDate(new Date(), -7),
  toDate: new Date(),
})

export const getIntervalDate = (date, noDate) => {
  return new Date(date.setDate(date.getDate() + noDate))
}

export const formatOrderDate = (date) => {
  let year = date.getFullYear(),
    month = date.getMonth() + 1,
    day = date.getDate()
  return [year, month, day].join('-')
}

export const Ranges = () => {
  let today = new Date()

  let firstDayofMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  if (today.getDate() === 1) {
    firstDayofMonth = new Date(today.getFullYear(), today.getMonth(), 0)
  }

  let firstDayofWeek = new Date(today) //Monday
  let weekDay = today.getDay() || 7
  if (weekDay !== 1) {
    firstDayofWeek.setHours(-24 * (weekDay - 1))
  }

  let todayStart = new Date(today)
  todayStart.setHours(0, 0, 0, 0)
  let todayEnd = new Date(today)
  todayEnd.setHours(23, 59, 59, 999)

  let yesterdayStart = new Date(today)
  yesterdayStart.setDate(yesterdayStart.getDate() - 1)
  yesterdayStart.setHours(0, 0, 0, 0)
  let yesterdayEnd = new Date(yesterdayStart)
  yesterdayEnd.setHours(23, 59, 59, 999)

  return [
    {
      label: 'Today',
      value: [todayStart, todayEnd],
      closeOverlay: true,
    },
    {
      label: 'Yesterday',
      value: [yesterdayStart, yesterdayEnd],
      closeOverlay: true,
    },
    {
      label: 'This Week',
      value: [firstDayofWeek, today],
      closeOverlay: true,
    },
    {
      label: 'This Month',
      value: [firstDayofMonth, today],
      closeOverlay: true,
    },
  ]
}

export const getClaimTypeFullText = (claimType) => {
  switch (claimType.toLowerCase()) {
    case 'stolen':
    case 'defective':
    case 'lost in transit':
      return 'We’re Sorry You Didn’t Receive Your Package'
    case 'wrong item':
      return 'We’re Sorry You Received Wrong Items'
    case 'damaged':
      return 'We’re Sorry Your Order Was Damaged'
    default:
      return claimType
  }
}

export const getBillingCycleRange = (circleType) => {
  const today = new Date()
  let startDate = new Date()
  let endDate = new Date() + 6
  console.log(circleType)
  if (circleType.toLowerCase() === 'daily') {
    const firstDate = today.getUTCDate()
    console.log(startDate)
    startDate = new Date(today.setUTCDate(firstDate))
    endDate = new Date(today.setUTCDate(firstDate))
  }

  if (circleType.toLowerCase() === 'weekly') {
    const firstDate = today.getUTCDate() - today.getUTCDay() + (today.getUTCDay() === 0 ? -6 : 1)
    const lastDate = firstDate + 6

    startDate = new Date(today.setUTCDate(firstDate))
    const tempDate = new Date()
    endDate = new Date(tempDate.setUTCDate(lastDate))
  }

  if (circleType.toLowerCase() === 'biweekly') {
    if (today.getUTCDate() < 16) {
      startDate = new Date(today.getUTCFullYear(), today.getUTCMonth(), 1)
      endDate = new Date(today.getUTCFullYear(), today.getUTCMonth(), 14)
    } else {
      startDate = new Date(today.getUTCFullYear(), today.getUTCMonth(), 15)
      endDate = new Date(today.getUTCFullYear(), today.getUTCMonth() + 1, 0)
    }
  }

  if (circleType.toLowerCase() === 'monthly') {
    startDate = new Date(today.getUTCFullYear(), today.getUTCMonth(), 1)
    endDate = new Date(today.getUTCFullYear(), today.getUTCMonth() + 1, 0)
  }

  startDate.setUTCHours(0)
  startDate.setUTCMinutes(0)
  startDate.setUTCSeconds(0)
  startDate.setUTCMilliseconds(0)

  endDate.setUTCHours(23)
  endDate.setUTCMinutes(59)
  endDate.setUTCSeconds(59)
  endDate.setUTCMilliseconds(99)

  return {
    startDate,
    endDate,
  }
}

export const dateFormatForTracking = (date) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  return `${monthNames[date.getMonth()]} ${_padStart(date.getDate(), 2, '0')}`
}

export const toggleArray = (currentList, newItem) => _xor(currentList, [newItem])

export function convertToNumber(value) {
  const number = Number(value)
  if (!isNaN(number)) {
    return parseInt(number)
  }
  return null
}
