import React, { memo, useCallback, useState } from 'react'
import _map from 'lodash/map'
import _indexOf from 'lodash/indexOf'
import _filter from 'lodash/filter'
import Checkbox from '../../../CoreUI/Checkbox'
import './styles.scss'
import ClaimPortalItem from '../../Core/ClaimPortalItem'

const ItemLine = memo(({ item, onSelect, selected }) => {
  return (
    <div className={`item ${selected ? 'selected' : ''}`}>
      <div className="checbox-area">
        <Checkbox checked={selected} onChange={() => onSelect()} />
      </div>
      <div className="item-data" onClick={() => onSelect()}>
        <ClaimPortalItem item={item} />
      </div>
    </div>
  )
})

const ItemList = ({ items, selected, setSelected }) => {
  const onSelected = useCallback(
    (itemId) => {
      const isSelected = _indexOf(selected, itemId) !== -1

      if (isSelected) {
        return setSelected(_filter(selected, (item) => item !== itemId))
      }

      return setSelected([...selected, itemId])
    },
    [selected]
  )

  return (
    <div className="item-list-wrapper">
      {_map(items, (item, index) => (
        <ItemLine
          key={index}
          item={item}
          selected={_indexOf(selected, item.id) !== -1}
          onSelect={() => onSelected(item.id)}
        />
      ))}
    </div>
  )
}

export default memo(ItemList)
