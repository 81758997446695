import './ClaimConfirmation.scss'
import { Col, Row } from 'react-bootstrap'
import ClaimItemsOption from '../ClaimItemsOption/ClaimItemsOption'
import ClaimCustomerInformation from '../ClaimCustomerInformation/ClaimCustomerInformation'
import axios from '@/support/utils/request'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _reverse from 'lodash/reverse'
import _sortBy from 'lodash/sortBy'
import _map from 'lodash/map'
import { useState, useEffect, useMemo, useCallback } from 'react'
import Error from '../../Error/Error'
import CommunicationCard from '../../CommunicationCard'
import Commenting from '../../Commenting'
import { getClaimSumissionDate } from '../../../helpers/claim-filter'
import orderFactory from '../../../helpers/order-factory'
import config from '@/config'
import { get } from 'lodash'

export default function ClaimConfirmation(props) {
  const [orderData, setOrderData] = useState(null)
  const [claimImages, setClaimImages] = useState([])
  const [claimMessages, setClaimMessages] = useState([])
  const [viewers, setViewers] = useState([])

  const [error, setError] = useState({
    active: false,
    message: 'Default',
  })

  const proxy = config.api.url

  function handleAddItemtoClaim(orderItem) {
    setError({
      active: false,
      message: 'Default',
    })

    axios
      .post(`${proxy}/claims/checkClaimItem/${orderItem.id}`)
      .then((response) => {
        console.log('responding')
        console.log(response)
        let customerData = { ...props.state.customerData }

        customerData.orderItems.forEach((item) => {
          if (item.id == orderItem.id) {
            item.is_checked = true
          }
        })

        props.setState({
          ...props.state,
          customerData: customerData,
        })
      })
      .catch((e) => {
        console.log(e)
        setError({
          active: true,
          message: e.response.data,
        })
      })
  }

  const fetchOrderData = useCallback(
    (orderId) => {
      axios.get(`${proxy}/orders/${orderId}`).then((res) => {
        setOrderData(res.data)
      })
    },
    [proxy]
  )

  const getClaimImage = useCallback(
    (theClaimId) => {
      axios.get(`${proxy}/aws/claims/${theClaimId}/image`).then((res) => {
        setClaimImages(res.data)
      })
    },
    [proxy]
  )

  const fetchAllClaimsMessage = useCallback((theClaimId) => {
    axios.get(`${proxy}/claims/message/${theClaimId}`).then((res) => {
      setClaimMessages(res.data.messages)
      setViewers(res.data.viewers)
    })
  }, [])

  const getNameFromMessage = (message) => {
    if (message.sender_id === 0) {
      return props.state.customerData.customer_name
    } else {
      if (message.alias_name) {
        return message.alias_name
      }

      return `${_get(message, 'first_name', '')} ${_get(message, 'last_name', '')}`
    }
  }

  const postMessage = useCallback(
    (senderId, attachments, message, claimId, customerEmail, callback) => {
      axios
        .post(`${proxy}/claims/message`, {
          senderId,
          message,
          claimId,
          attachments: attachments.join('|'),
          customerEmail,
        })
        .then((res) => callback(res.data))
    },
    []
  )

  const claimItems =
    props.state.customerData.orderItems &&
    props.state.customerData.orderItems.map((orderItem, id) => {
      return (
        <ClaimItemsOption
          view={props.view}
          state={props.state}
          key={id}
          claimItem={orderItem}
          handleAddItemtoClaim={() => handleAddItemtoClaim(orderItem)}
          fulfilled={orderItem.fulfilled_quantity > 0}
        />
      )
    })

  const customerData = useMemo(() => props.state.customerData, [props.state.customerData])

  useEffect(() => {
    fetchOrderData(customerData.id)
    const claimId = _get(customerData, 'claim_id', '')

    if (claimId !== null && _isEmpty(claimId.toString()) === false) {
      getClaimImage(claimId)
      fetchAllClaimsMessage(claimId)
    }
  }, [customerData, fetchOrderData, getClaimImage])

  const claimItemExtra = useMemo(() => _get(orderData, 'claims[0]'), [orderData])
  const whoViewingTicket = useMemo(() => _get(viewers, 0), [viewers])
  return (
    <>
      <Row>
        <Col md={{ span: 12 }}>
          <div className="greeting-container">
            <h2 className="title">My claim.</h2>
            <p className="sub-text">
              The issue has been reported and updates will be emailed to{' '}
              {orderFactory(props.state.customerData).shippingEmail}
            </p>
            <Error error={error} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 12 }}>
          <div className="final-steps">
            <div className="font-xl">Items in claim</div>
            {claimItems}
          </div>
          <div className="final-steps">
            <div className="font-xl">Communication</div>
            <Row className="">
              <Commenting
                userName={customerData.customer_name}
                postMessage={(message, attachments, onDonePost) =>
                  postMessage(
                    0,
                    attachments,
                    message,
                    _get(customerData, 'claim_id'),
                    '',
                    (messageResponse) => {
                      onDonePost()
                      setClaimMessages([...claimMessages, messageResponse])
                    }
                  )
                }
                macros={[]}
                orderData={orderData}
                isUser
              />
            </Row>

            <Row className="mb-3">
              {_isEmpty(whoViewingTicket) === false && (
                <div className="text-warning">
                  {`${whoViewingTicket.userName} is viewing this ticket...`}
                </div>
              )}
            </Row>

            {_map(_reverse(_sortBy(claimMessages, 'created_at')), (message) => (
              <div key={message.message_id} className="mb-3">
                <CommunicationCard
                  userName={getNameFromMessage(message)}
                  time={message.created_at}
                  message={message.message}
                  twilioImage={message.twilio_media}
                  attachments={message.attachments}
                  attachmentUrls={message.attachmentUrls}
                />
              </div>
            ))}
            <CommunicationCard
              userName={customerData.customer_name}
              attachments={claimImages}
              message={_get(claimItemExtra, 'claim_details', '')}
              time={getClaimSumissionDate(claimItemExtra)}
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="final-steps last">
            <div className="font-xl">Claim Details</div>
            <ClaimCustomerInformation state={props.state} />
          </div>
        </Col>
      </Row>
    </>
  )
}
