import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _some from 'lodash/some'
import React, { useCallback, useMemo, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import IconUser from '../../../assets/claim-portal/icon-onboarding-user.svg'
import { putApi } from '../../../helpers/api'
import './style.scss'

export default function AccountInfo() {
  const history = useHistory()
  const [account, setAccount] = useState({})

  const params = new URLSearchParams(window.location.search)
  const session = params.get('session')

  const onUpdateField = useCallback(
    (event) => {
      setAccount((currentValue) => ({
        ...currentValue,
        [event.target.name]: event.target.value,
      }))
    },
    [setAccount]
  )

  const onSubmit = useCallback(() => {
    putApi('/public-app-onboarding', { account, session }).then(() => {
      history.push(`/onboarding/billing-payouts?session=${session}`)
    })
  }, [account, session])

  const validator = useMemo(() => {
    if (_isEmpty(session) === true || _isEmpty(account) === true) {
      return false
    }

    const keys = [
      'firstName',
      'lastName',
      'password',
      'checkPassword',
      'email',
      'supportEmail',
      'billingEmail',
    ]

    if (_some(keys, (key) => _isEmpty(_get(account, key))) === true) {
      return false
    }

    if (_get(account, 'password') !== _get(account, 'checkPassword')) {
      return false
    }

    return true
  }, [account, session])

  return (
    <div className="onboarding-block">
      <div className="onboarding-header">
        <h1 className="onboarding-header__title">
          <img src={IconUser} alt="Icon Like" className="icon" />
          Account Info
        </h1>
        <p className="onboarding-header__description">
          This information helps us with managing claims for your customers and other account
          operations.
        </p>
      </div>
      <Form className="mt-4 onboarding-form">
        <Row className="mb-3">
          <Col>
            <Form.Label>First Name</Form.Label>
            <Form.Control size="lg" type="text" name="firstName" onChange={onUpdateField} />
          </Col>
          <Col>
            <Form.Label>Last Name</Form.Label>
            <Form.Control size="lg" type="text" name="lastName" onChange={onUpdateField} />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Label>Password</Form.Label>
            <Form.Control size="lg" type="password" name="password" onChange={onUpdateField} />
          </Col>
          <Col>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control size="lg" type="password" name="checkPassword" onChange={onUpdateField} />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Label>Account Email</Form.Label>
            <Form.Control size="lg" type="text" name="email" onChange={onUpdateField} />
            <Form.Text className="text-muted">This will be your main account email.</Form.Text>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Label>Billing Email</Form.Label>
            <Form.Control size="lg" type="text" name="billingEmail" onChange={onUpdateField} />
            <Form.Text className="text-muted">
              All invoices, payments, and accounting related emails will be sent here.
            </Form.Text>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Label>Support Email</Form.Label>
            <Form.Control size="lg" type="text" name="supportEmail" onChange={onUpdateField} />
            <Form.Text className="text-muted">
              If we have questions about a claim or order, we will email you to get a fast solution.
            </Form.Text>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="d-flex align-items-center">
            <button
              disabled={validator === false}
              className="btn-submit btn-actived"
              onClick={(e) => {
                e.preventDefault()
                onSubmit()
              }}
            >
              Continue
            </button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
