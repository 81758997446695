import React from 'react'
import _get from 'lodash/get'
import { useFormContext } from 'react-hook-form'

export type Props = {
  name: string | string[]
  className?: string
  children: (message: string) => React.ReactNode
}

export const ErrorMessage = ({ name, children }: Props) => {
  const {
    formState: { errors },
  } = useFormContext()

  let errorMessage
  for (const _name of [name].flat()) {
    errorMessage = _get(errors, _name)?.message
    if (errorMessage) break
  }

  return errorMessage ? <>{children(errorMessage as string)}</> : null
}
