import './TableDateRangePicker.scss'
import { DateRangePicker } from 'rsuite'

import 'rsuite/dist/styles/rsuite-default.css'
import { getInitialDateRange } from '../../helpers/date-time-helper'

export default function TableDateRangePicker({ dateRange, setDateRange }) {
  let today = new Date()

  let firstDayofMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  if (today.getDate() === 1) {
    firstDayofMonth = new Date(today.getFullYear(), today.getMonth(), 0)
  }

  let firstDayofWeek = new Date(today) //Monday
  let weekDay = today.getDay() || 7
  if (weekDay !== 1) {
    firstDayofWeek.setHours(-24 * (weekDay - 1))
  }

  let todayStart = new Date(today)
  todayStart.setHours(0, 0, 0, 0)
  let todayEnd = new Date(today)
  todayEnd.setHours(23, 59, 59, 999)

  let yesterdayStart = new Date(today)
  yesterdayStart.setDate(yesterdayStart.getDate() - 1)
  yesterdayStart.setHours(0, 0, 0, 0)
  let yesterdayEnd = new Date(yesterdayStart)
  yesterdayEnd.setHours(23, 59, 59, 999)

  const Ranges = [
    {
      label: 'Today',
      value: [todayStart, todayEnd],
      closeOverlay: true,
    },
    {
      label: 'Yesterday',
      value: [yesterdayStart, yesterdayEnd],
      closeOverlay: true,
    },
    {
      label: 'This Week',
      value: [firstDayofWeek, today],
      closeOverlay: true,
    },
    {
      label: 'This Month',
      value: [firstDayofMonth, today],
      closeOverlay: true,
    },
  ]

  const setNewRange = (range) => {
    if (range.length) {
      setDateRange({
        fromDate: range[0],
        toDate: range[1],
      })
    } else {
      const range = getInitialDateRange()

      setDateRange(range)
    }
  }

  return (
    <DateRangePicker
      onChange={(range) => setNewRange(range)}
      appearance={'default'}
      showOneCalendar={true}
      format={'MMM D'}
      placement={'bottomEnd'}
      value={[dateRange.fromDate, dateRange.toDate]}
      ranges={Ranges}
    />
  )
}
