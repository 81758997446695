import React, { memo } from 'react'
import logo from '../../assets/order-protection-logo.svg'
import './styles.scss'
import { useSelector } from 'react-redux'
import { getTheme } from '../../state/selectors/theme'

const Appbar = ({ brandLogo }) => {
  const { background_color, logo: themeLogo } = useSelector(getTheme)

  return (
    <div
      className={`w-full flex items-center justify-center flex-col gap-4 ${
        brandLogo ? 'py-4' : 'pt-8 pb-2'
      }`}
      style={{
        backgroundColor: background_color,
      }}
    >
      {brandLogo && (
        <>
          <img
            id="brand-logo"
            className={` ${brandLogo && 'branded-merchant'}logo max-h-[64px] max-w-[300px]`}
            src={brandLogo}
            alt="home logo"
          />
          {!themeLogo && (
            <span className="ml-3 mr-3 x-logo">
              <svg
                width="15"
                height="12"
                viewBox="0 0 10 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.76513 3.43281L8.06513 0.132812L9.0078 1.07548L5.70779 4.37548L9.0078 7.67548L8.06513 8.61815L4.76513 5.31815L1.46513 8.61815L0.522461 7.67548L3.82246 4.37548L0.522461 1.07548L1.46513 0.132812L4.76513 3.43281Z"
                  fill="#444444"
                />
              </svg>
            </span>
          )}
        </>
      )}
      {!themeLogo && <img className="max-h-[64px]" src={logo} alt="home logo" />}
      {/* <img className="white-label-logo" src="https://i.postimg.cc/c4PZRZvb/armra-logo.webp" alt="home logo" /> */}
      {/*  <img className="white-label-logo" src="https://i.postimg.cc/wBmF31M0/big-daddy.png" alt="home logo" />  */}
    </div>
  )
}

export default memo(Appbar)
