import React, { useMemo, useState } from 'react'
import { Row } from 'react-bootstrap'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import LetteredAvatar from 'react-lettered-avatar'
import Lightbox from 'react-image-lightbox'
import nlp from 'compromise'
import sentenses from 'compromise-sentences'
import Highlighter from 'react-highlight-words'
import { formatDate } from '../../helpers/date-time-helper'
import 'react-image-lightbox/style.css'
import './styles.scss'

const nlpEx = nlp.extend(sentenses)

const getAttachmentName = (url) => {
  if (url.startsWith('http')) {
    return {
      fileName: url,
      isImage: true,
    }
  }

  const parts = url.split('/')
  const fileName = parts[parts.length - 1]
  const fileParts = fileName.split('.')
  const fileExt = fileParts[fileParts.length - 1].toLowerCase()
  const isImage = fileExt === 'png' || fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'gif'

  return {
    fileName,
    isImage,
  }
}

const CommunicationCard = ({
  userName,
  time,
  attachments,
  message,
  attachmentUrls,
  useHighlightFeature,
  twilioImage,
}) => {
  const [imageShow, setImageShow] = useState('')
  const lines = useMemo(() => (_isEmpty(message) === true ? [] : message.split('\n')), [message])
  const questions = useMemo(
    () => (useHighlightFeature ? nlpEx(message).sentences().isQuestion().out('array') : []),
    [message, useHighlightFeature]
  )
  return (
    <Row className="bg-white p-3 communication-card-wrapper d-flex flex-column">
      <div className="communication-card-header align-items-center">
        <div className="d-flex align-items-center">
          <LetteredAvatar
            name={userName}
            size={35}
            backgroundColors={[
              '#2ecc71',
              '#3498db',
              '#8e44ad',
              '#e67e22',
              '#e74c3c',
              '#1abc9c',
              '#2c3e50',
            ]}
          />
          <div className="font-sm font-semibold">{userName}</div>
        </div>
        <div className="font-xs font-text-icon-disabled">{formatDate(time)}</div>
      </div>
      <div className="font-sm font-text-icon-secondary d-flex flex-column text-break">
        {_map(lines, (line, index) => (
          <Highlighter
            className="my-1"
            key={index}
            highlightClassName="highlighter"
            searchWords={questions}
            autoEscape={true}
            textToHighlight={line}
          />
        ))}
        {!_isEmpty(twilioImage) && (
          <div className="media-container">
            {_map(twilioImage, (media) => {
              return JSON.parse(media).type.includes('video') ? (
                <video controls>
                  <source src={JSON.parse(media).url} type={JSON.parse(media).type} />
                </video>
              ) : (
                <a
                  className="twilio-image"
                  target="_blank"
                  href={JSON.parse(media).url}
                  rel="noreferrer"
                >
                  <img width="100px" height="100%" src={JSON.parse(media).url}></img>
                </a>
              )
            })}
          </div>
        )}
      </div>
      {_isEmpty(attachments) === false && (
        <div className="attachment-area p-3 border border-info rounded mt-3 position-relative">
          <div className="bg-white position-absolute text-muted attachment-panel-title px-2 font-weight-bold">
            Attachment
          </div>
          <div className="attachment-content">
            {_map(attachments, (url, index) => {
              const attachmentDetails = getAttachmentName(url)

              if (attachmentDetails.isImage === true) {
                const imgUrl = _get(attachmentUrls, index, url)

                return (
                  <img
                    onClick={() => setImageShow(imgUrl)}
                    className="w-100 image-attachment"
                    key={index}
                    src={imgUrl}
                    alt="Attachment"
                  />
                )
              } else {
                return (
                  <a
                    href={_get(attachmentUrls, index, '')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {attachmentDetails.fileName}
                  </a>
                )
              }
            })}
          </div>
        </div>
      )}
      {_isEmpty(imageShow) === false && (
        <Lightbox mainSrc={imageShow} onCloseRequest={() => setImageShow('')} />
      )}
    </Row>
  )
}

export default CommunicationCard
