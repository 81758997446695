import { useEffect } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getSettingsByStoreID } from '../../state/actions/settings'

export default function StoreSetter() {
  const { store_id } = useParams<{ store_id: string }>()
  const dispatch = useDispatch()
  const query = window.location.search
  const forwardPath = `/submitClaim${query}`

  if (!store_id) {
    return <Redirect to={forwardPath} />
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(getSettingsByStoreID(Number(store_id)))
  }, [])

  sessionStorage.setItem('__op:store_id', store_id)

  return <Redirect to={forwardPath} />
}
