import './MultipleShippingAddresses.scss'
import { Table, Modal } from 'react-bootstrap'

export default function MultipleShippingAddresses({
  modalShow,
  setModalShow,
  shippingAddresses,
  shippingCustomerNames,
  shippingPhoneNumbers,
  shippingEmails,
}) {
  return (
    <Modal
      className="multiple-shipping-modal"
      show={modalShow}
      onHide={() => setModalShow(false)}
      centered
      animation={false}
      size={'lg'}
    >
      <Modal.Header closeButton>
        <Modal.Title>All Shipping Addresses</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive hover>
          <table>
            <thead>
              <tr>
                <th>No</th>
                <th>Customer</th>
                <th>Address</th>
                <th>Phone</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {shippingAddresses.map((address, index) => (
                <tr key={index}>
                  <td className="text-default">{index + 1}</td>
                  <td className="text-default">{shippingCustomerNames[index]}</td>
                  <td className="text-default">{address}</td>
                  <td className="text-default">{shippingPhoneNumbers[index]}</td>
                  <td className="text-default">{shippingEmails[index]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
      </Modal.Body>
    </Modal>
  )
}
