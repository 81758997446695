import './ClaimType.scss'
import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { Col, Row, Modal, Container, Spinner } from 'react-bootstrap'
import ClaimTypeOption from '../ClaimTypeOption/ClaimTypeOption'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdBadge, faLocationArrow, faStore } from '@fortawesome/free-solid-svg-icons'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _some from 'lodash/some'
import _flatten from 'lodash/flatten'

import _filter from 'lodash/filter'
import _startCase from 'lodash/startCase'
import axios from '@/support/utils/request'
import ChangeAddressModal from '../ChangeAddressModal'
import OrderTrackingModal from '../OrderTrackerModal'
import FirstProduct from '../FirstProduct'
import config from '@/config'

export default function ClaimType(props) {
  const [showChangeAddress, setShowChangeAddress] = useState(false)
  const [showOrderTrackingModal, setShowModalTracking] = useState(false)
  const [orderData, setOrderData] = useState(props.state.customerData)
  const [shopifyOrderData, setShopifyOrderData] = useState([])
  const storeId = useMemo(() => _get(orderData, 'store_id', ''), [orderData])
  const shopifyOrderId = useMemo(
    () => _get(orderData, 'order_json.id', _get(orderData, 'shopify_order_id', '')),
    [orderData]
  )
  const [type, setType] = useState('')
  const [isClaimTypeSubmit, setIsClaimTypeSubmit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [modal, setModal] = useState({
    show: false,
    message: '',
  })

  const [storeInfo, setStoreInfo] = useState(null)

  const proxy = config.api.url

  const getOrderData = useCallback(
    (orderShopId, storeId) => {
      axios
        .get(`${proxy}/spf-proxy/${storeId}/orders/${orderShopId}/order-tracking`)
        .then((res) => {
          setShopifyOrderData(res.data)
        })
    },
    [proxy]
  )

  function getShopDetails() {
    axios.post(`${proxy}/claims/storeDetails/response`, props.state.store).then((res) => {
      props.setState({
        ...props.state,
        plan: res.data.shop.plan_name,
      })
    })
  }

  useEffect(() => {
    if (orderData?.platform_id !== 3) {
      getShopDetails(shopifyOrderId, storeId)
    }

    if (showOrderTrackingModal === true && _isEmpty(shopifyOrderData) === true) {
      getOrderData(shopifyOrderId, storeId)
    }
  }, [storeId, showOrderTrackingModal, shopifyOrderData, shopifyOrderId, getOrderData])

  function useForceUpdate(item) {
    const [value, setValue] = useState(0) // integer state
    return () => setValue((value) => value + 1) // update the state to force render
  }
  const forceUpdate = useForceUpdate()

  const onSaveAddress = useCallback(
    (orderId, addressCom, onDone) => {
      axios.put(`${proxy}/orders/${orderId}/update-address`, addressCom).then((res) => {
        const responseOrder = res.data
        onDone(responseOrder)
        setOrderData(responseOrder)
      })
    },
    [proxy]
  )

  const claimTypesToRemoveMap = {
    352: [7],
    353: [7],
    369: [2, 4, 5],
    228: [2],
  }

  const claimTypeOptions = props.state.claimTypes?.data.map((claimType, id) => {
    const idsToRemove = claimTypesToRemoveMap[storeId] || []
    const shouldRemove = idsToRemove.includes(id)

    if (shouldRemove) {
      return null
    }

    return (
      <ClaimTypeOption
        key={id}
        claimType={claimType}
        changeView={props.changeView}
        state={props.state}
        setState={props.setState}
        disabledAction
        isClaimTypeSubmit={isClaimTypeSubmit}
        setIsClaimTypeSubmit={setIsClaimTypeSubmit}
        type={type}
        setType={setType}
        waitToClaim={props.waitToClaim}
        storeId={storeId}
      />
    )
  })

  const anyOrderHasFulfilled = useMemo(() => {
    const items = props.state.customerData.orderItems.filter(
      (item) => item.item_name.toLowerCase() !== 'rush processing'
    )
    const fulfillments = props.state.fulfillments?.fulfillments || []

    return _some(
      _map(_flatten(_map(fulfillments, 'line_items')), (line) => line.id.toString()),
      (itemId) => _map(items, 'line_item_id').includes(itemId)
    )
  }, [props])

  const mainItems = useMemo(
    () =>
      _filter(
        _get(props, 'state.customerData.orderItems', []),
        (item) => _isEmpty(_get(item, 'fulfillments[0].tracking_number', '')) === false
      ),
    [props]
  )

  useEffect(() => {
    async function getStoreContact() {
      let storeId = 3
      const storeContact = await axios.get(`${proxy}/stores/${storeId}/contact`)

      if (storeContact.data && storeContact.data.length > 0) {
        setStoreInfo(storeContact.data[0])
        forceUpdate()
      }
    }
    props.state.customerData && getStoreContact()
  }, [props.state.customerData])

  const firstItem = useMemo(() => _get(props, 'state.customerData.orderItems', {}), [props])
  const storeOrderId = useMemo(() => _get(props, 'state.customerData.store_order_id', ''), [props])

  const onSubmit = () => {
    props.checkOrderIsValid()

    setIsLoading(true)
    switch (type) {
      case 'whereIsMyOrder':
        return setShowModalTracking(true)
      case 'startAReturn':
        return setModal({
          show: true,
          message: 'process a return',
        })
      case 'updateShippingAddress':
        return setShowChangeAddress(true)
      default:
        return setIsClaimTypeSubmit(true)
    }
  }

  return (
    <div className="body-claim-pre-identified">
      <Container>
        <Row>
          <Col md={{ span: 12 }}>
            <div className="greeting-container">
              <h2 className="title">What's the issue you're having?</h2>
              <p className="sub-text">Let's get your order issues resolved.</p>
            </div>

            <div className="content-claim-pre-identified">
              <div className="first-product-wrapper inner right">
                <div className="order-title mb20">
                  {`Order #${
                    orderData?.platform_id === 3 ? orderData.display_order_id : storeOrderId
                  }`}
                </div>

                {_map(firstItem, (item, index) => index < 3 && <FirstProduct item={item} />)}
              </div>

              <div className="grop-claim-type">{claimTypeOptions}</div>
              <div className="group-btn-submit">
                <button
                  className={`btn-submit ${type ? 'btn-actived' : ''}`}
                  disabled={!type}
                  onClick={onSubmit}
                >
                  {isLoading === true ? (
                    <Spinner size="sm" className="ml-2" animation="border" variant="light" />
                  ) : (
                    <span>Continue</span>
                  )}
                </button>

                <div className="back-link" onClick={() => props.resetView()}>
                  Back
                </div>
              </div>
            </div>
          </Col>

          <OrderTrackingModal
            show={showOrderTrackingModal}
            onHide={() => setShowModalTracking(false)}
            spfOrderData={shopifyOrderData}
            orderTime={_get(orderData, 'order_date')}
          />

          <ChangeAddressModal
            mainItems={mainItems}
            onViewTrackingLink={() => {
              setShowChangeAddress(false)
              setShowModalTracking(true)
            }}
            show={showChangeAddress}
            onHide={() => setShowChangeAddress(false)}
            orderData={orderData}
            onSave={onSaveAddress}
          />

          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={false}
            show={modal.show}
            onHide={() => setModal({ show: false, message: '' })}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {storeInfo ? (
                <p>
                  To {modal.message}, please contact
                  <strong> {storeInfo.store_url.replace('.myshopify.com', '')} </strong>
                  directly at
                  {storeInfo.claims_email && (
                    <a
                      href={`mailto:${storeInfo.claims_email}?subject=OrderReturn&body=${
                        props.state.customerData &&
                        props.state.customerData.order_json &&
                        JSON.stringify({
                          id: _get(
                            props.state.customerData.shopifyOrderId,
                            'order_json.id',
                            _get(props.state.customerData, 'shopify_order_id', '')
                          ),
                          store_order_id: props.state.customerData.store_order_id,
                          protection_order_id: props.state.customerData.id,
                          email: _get(
                            props.state.customerData,
                            'order_json.contact_email',
                            _get(props.state.customerData, 'order_email', '')
                          ),
                          total: _get(
                            props.state.customerData,
                            'order_json.order_total',
                            _get(props.state.customerData, 'order_total', '')
                          ),
                          customer: props.state.customerData.customer_name,
                        })
                      }`}
                    >
                      {storeInfo.claims_email}
                    </a>
                  )}
                </p>
              ) : (
                <p>Please contact your store directly through email.</p>
              )}
            </Modal.Body>
          </Modal>
        </Row>
      </Container>
    </div>
  )
}
