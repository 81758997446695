import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import LetteredAvatar from 'react-lettered-avatar'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'
import _forEach from 'lodash/forEach'
import _find from 'lodash/find'
import _keys from 'lodash/keys'
import TextareaAutosize from 'react-textarea-autosize'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'react-sliding-side-panel/lib/index.css'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import './styles.scss'
import { uploader } from '../../helpers/uploader'
import { customerPresetData, orderPresetData } from '../MacroSettingsPanel/macro-preset-data'
import {
  capitalizeString,
  macroParser,
  parseAttachmentMeta,
  macrosKeybyFolder,
  macroNameParser,
} from '../../helpers/macro-helper'
import AttachmentDisplay from '../AttachmentDisplay'
import SelectFolder from '../../components/SelectFolder'

const Commenting = ({ userName, postMessage, orderData, isUser, macros, storeId }) => {
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [currentMacro, setCurrentMacro] = useState({})
  const [selectedAttachment, setSelectedAttachment] = useState(null)
  const [showMangeMacro, setShowManageMacro] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [searchText, setSearchText] = useState('')
  const fileInput = useRef(null)
  const hexcladStore = [352, 353]
  const onClickInsertItem = useCallback(
    (currentMessage, pair) => {
      const itemKey = _get(pair, 'key', '')
      const keyData = _get(orderData, itemKey, '')
      let data = keyData

      if (typeof pair.render !== 'undefined') {
        data = pair.render(keyData)
      }

      if ((data || data === 0) && _isEmpty(data.toString()) === true) {
        alert('Inserting empty data')
      } else {
        if (selectedIndex === 0) {
          setMessage(`${currentMessage}${data} `)
        } else {
          setMessage(
            currentMessage.substring(0, selectedIndex) +
              data +
              currentMessage.substring(selectedIndex, currentMessage.length)
          )
        }
      }
    },
    [orderData, selectedIndex]
  )

  const onUseMacro = useCallback(
    (currentMessage, macro) => {
      setCurrentMacro(macro)
      const parsedMacroParts = macroParser(macro.macro_content)
      let finalString = parsedMacroParts.content
      const templateCodes = finalString.match(/{{[^}]+}}/g)

      _forEach(templateCodes, (code) => {
        const pair = _find(customerPresetData.concat(orderPresetData), { templateCode: code })
        const itemKey = _get(pair, 'key', '')
        const keyData = _get(orderData, itemKey, '')
        let data = keyData
        if (code === '{{customer_first_name}}') data = data.split(' ')[0]
        if (code === '{{customer_last_name}}') data = data.split(' ')[1]

        if (typeof pair.render !== 'undefined') {
          data = pair.render(keyData)
        }

        const regx = new RegExp(code, 'g')
        finalString = finalString.replace(regx, data)
      })

      if (selectedIndex === 0) {
        setMessage(`${currentMessage}${finalString}`)
      } else {
        setMessage(
          currentMessage.substring(0, selectedIndex) +
            finalString +
            currentMessage.substring(selectedIndex, currentMessage.length)
        )
      }
    },
    [orderData, selectedIndex]
  )

  const onDonePost = useCallback(() => {
    setMessage('')
    setLoading(false)
    setSelectedAttachment(null)
    setCurrentMacro({})
  }, [])

  const onSubmit = useCallback(
    (theFile) => {
      setLoading(true)

      if (_isEmpty(_get(theFile, 'name', '')) === true) {
        let currentKey = ''

        if (_isEmpty(currentMacro) === false) {
          const macroMetaData = macroParser(currentMacro.macro_content).meta
          currentKey = _get(parseAttachmentMeta(macroMetaData), 's3Key', '')
        }
        postMessage(message, _isEmpty(currentKey) ? [] : [currentKey], onDonePost)
      } else {
        uploader(theFile).then((res) => {
          postMessage(message, [res], onDonePost)
        })
      }
    },
    [postMessage, message, onDonePost, currentMacro]
  )

  const onShowDialog = useCallback(() => {
    fileInput.current.click()
  }, [fileInput])

  const macroMeta = useMemo(() => {
    if (_isEmpty(currentMacro) === true) {
      return ''
    }

    return macroParser(currentMacro.macro_content).meta
  }, [currentMacro])

  const macrosObject = useMemo(() => {
    const hiddenMacros = macros.filter((macro) => {
      return (
        macro.store_id === storeId ||
        macro.store_id === -1 ||
        (macro.store_id === 352 && hexcladStore.includes(storeId))
      )
    })
    return macrosKeybyFolder(
      hiddenMacros.filter((item) => item?.macro_name?.toLowerCase().indexOf(searchText) > -1)
    )
  }, [macros, searchText, storeId])

  const unfiliteredMacros = useMemo(() => {
    return macrosKeybyFolder(macros)
  }, [macros, searchText])

  const onSearch = (value) => {
    setSearchText(value)
  }

  return (
    <div className="comment-wrapper d-flex flex-column p-3 w-100">
      <div>
        <TextareaAutosize
          className="message-input w-100 p-2 border"
          placeholder="Write your comment here..."
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          onBlur={(event) => setSelectedIndex(event.target.selectionStart)}
        />
      </div>
      <div className="d-flex flex-row justify-content-between mt-2">
        <div className="d-flex align-items-center">
          <input
            type="file"
            ref={fileInput}
            onChange={(e) => {
              setCurrentMacro({})
              setSelectedAttachment(e.target.files[0])
            }}
            className="hidden"
          />
          <Button
            variant="outline-secondary"
            className="go-back-button"
            onClick={() => onShowDialog()}
          >
            <FontAwesomeIcon icon={faPaperclip} />
          </Button>
          <div className="action-dropdown">
            {isUser !== true && (
              <>
                <SelectFolder
                  title={'Macro'}
                  subTitle={'Recommended Macros'}
                  items={_map(_keys(macrosObject), (folderName) => ({
                    title: folderName,
                    children: macrosObject[folderName]?.map((item) => ({
                      id: item.macro_id,
                      name: capitalizeString(macroNameParser(item.macro_name)),
                      ...item,
                    })),
                  }))}
                  onSetting={() => setShowManageMacro(true)}
                  macros={_map(_keys(unfiliteredMacros), (folderName) => ({
                    title: folderName,
                    children: unfiliteredMacros[folderName]?.map((item) => ({
                      id: item.macro_id,
                      name: capitalizeString(macroNameParser(item.macro_name)),
                      ...item,
                    })),
                  }))}
                  onSearch={onSearch}
                  onChoose={(macro) => onUseMacro(message, macro)}
                />
                <Dropdown className="ml-2">
                  <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                    {isUser ? 'User' : 'Customer'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {_map(customerPresetData, (pair, index) => (
                      <Dropdown.Item key={index} onClick={() => onClickInsertItem(message, pair)}>
                        {pair.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="ml-2">
                  <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                    Order
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {_map(orderPresetData, (pair, index) => (
                      <Dropdown.Item key={index} onClick={() => onClickInsertItem(message, pair)}>
                        {pair.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center button-area">
          <LetteredAvatar
            name={userName}
            size={35}
            backgroundColors={[
              '#2ecc71',
              '#3498db',
              '#8e44ad',
              '#e67e22',
              '#e74c3c',
              '#1abc9c',
              '#2c3e50',
            ]}
          />
          <span className="text-defaul small mx-1 d-flex align-items-center">{userName}</span>
          <Button
            className="ml-2"
            onClick={() => onSubmit(selectedAttachment)}
            disabled={loading === true || (!message && !selectedAttachment)}
          >
            Post
          </Button>
        </div>
      </div>
      <div className="row action-dropdown-mobile px-2 pt-2">
        {isUser !== true && (
          <SelectFolder
            title={'Macro'}
            subTitle={'Recommended Macros'}
            items={_map(_keys(macrosObject), (folderName) => ({
              title: folderName,
              children: macrosObject[folderName]?.map((item) => ({
                id: item.macro_id,
                name: capitalizeString(macroNameParser(item.macro_name)),
                ...item,
              })),
            }))}
            onSetting={() => setShowManageMacro(true)}
            onSearch={onSearch}
            macros={_map(_keys(unfiliteredMacros), (folderName) => ({
              title: folderName,
              children: unfiliteredMacros[folderName]?.map((item) => ({
                id: item.macro_id,
                name: capitalizeString(macroNameParser(item.macro_name)),
                ...item,
              })),
            }))}
            onChoose={(macro) => onUseMacro(message, macro)}
          />
        )}
        <Dropdown className="ml-2">
          <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
            {isUser ? 'User' : 'Customer'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {_map(customerPresetData, (pair, index) => (
              <Dropdown.Item key={index} onClick={() => onClickInsertItem(message, pair)}>
                {pair.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="ml-2">
          <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
            Order
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {_map(orderPresetData, (pair, index) => (
              <Dropdown.Item key={index} onClick={() => onClickInsertItem(message, pair)}>
                {pair.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <AttachmentDisplay
        text={_get(
          selectedAttachment,
          'name',
          _get(parseAttachmentMeta(macroMeta), 'fileName', '')
        )}
        onClick={() => {
          setSelectedAttachment(null)
          setCurrentMacro({})
        }}
      />
    </div>
  )
}

export default Commenting
