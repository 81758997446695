import './App.scss'
import { useState, useMemo } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Route, Switch, useHistory } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import ReimbursementOptions from '../AffidavitProcess/AffidavitOptions/ReimbursementOptions'
import Register from '../Register/Register'
import ClaimForm from '../ClaimFormProcess/ClaimForm/ClaimForm'
import Notification from '../Notification/Notification'
import Onboarding from '../Onboarding/Onboarding'
import { store, persistor } from '../../state'
import AdminRoute from '../../routes/admin-route'
import StoreSetter from '../StoreSetter/Setter'

import { useAppData } from '../../hooks/useAppData'
import ConfirmationModal from '../Dialog/ConfirmationModal'
import PublicApp from '../../containers/public-app'

export default function App() {
  const [showModal, setShowModal] = useState(false)
  const defaulModalSettings = useMemo(
    () => ({
      title: 'Are you sure?',
      children: 'Nothing',
      onCancel: {
        text: 'Cancel',
        action: () => setShowModal(false),
      },
      onConfirm: {
        text: 'OK',
        action: () => setShowModal(false),
      },
    }),
    []
  )
  const [modalSettings, setModalSettings] = useState(defaulModalSettings)
  const { state, setState, changeView } = useAppData()

  const history = useHistory()

  const domainParts = window.location.host.split('.')
  const isClaimsSubdomain = domainParts[0] === 'claims'
  const match = /\/claim\/(\d+)/

  if (isClaimsSubdomain && window.location.pathname === '/') {
    history.push('/submitClaim')
  } else if (isClaimsSubdomain) {
    const matchResult = window.location.pathname.match(match)

    if (matchResult) {
      const storeId = matchResult[1]
      history.push(`/claim/${storeId}`)
    }
  }

  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <Container fluid>
            <Switch>
              <Route path="/onboarding">
                <Row className="justify-content-center d-block">
                  <Onboarding />
                </Row>
              </Route>
              <Route path="/app-installation-welcome">
                <PublicApp />
              </Route>
              <Route path="/register" exact>
                <Row className="justify-content-center">
                  <Register checkUserSession={() => history.push('/')} />
                </Row>
              </Route>
              <Route path="/options" exact>
                <Row className="justify-content-center">
                  <ReimbursementOptions />
                </Row>
              </Route>
              <Route path="/submitClaim">
                <Row className="justify-content-center d-block">
                  <ClaimForm changeView={changeView} setState={setState} state={state} />
                </Row>
              </Route>
              <Route path="/claim/:store_id">
                <StoreSetter />
              </Route>
              <Route>
                <AdminRoute />
              </Route>
            </Switch>
          </Container>

          {state.notificationMessage && (
            <Notification message={state.notificationMessage}></Notification>
          )}
        </div>
        <ConfirmationModal
          title={modalSettings.title}
          show={showModal}
          onCancel={modalSettings.onCancel}
          onConfirm={modalSettings.onConfirm}
        >
          {modalSettings.children}
        </ConfirmationModal>
      </PersistGate>
    </ReduxProvider>
  )
}
