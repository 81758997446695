import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _capitalize from 'lodash/capitalize'
import _forEach from 'lodash/forEach'

import { macroMetaKey } from '../components/MacroSettingsPanel/macro-preset-data'

export const macroParser = (macroContent) => {
  if (_isEmpty(macroContent) === true) {
    return {
      content: '',
      meta: '',
    }
  }

  const parts = macroContent.split(macroMetaKey)

  return {
    content: _get(parts, 0, ''),
    meta: _get(parts, 1, ''),
  }
}

export const macroNameParser = (macroName = '') => {
  if (macroName?.split('//')[1]) {
    return macroName.split('//')[1]
  }

  return macroName
}

export const macrosKeybyFolder = (macros) => {
  const macrosObject = {}

  _forEach(macros, (item) => {
    const key = item.macro_name?.split('//')[0]
    if (key !== item.macro_name) {
      if (!macrosObject[key]) {
        macrosObject[key] = [item]
      } else {
        macrosObject[key].push(item)
      }
    } else {
      if (!macrosObject['']) {
        macrosObject[''] = [item]
      } else {
        macrosObject[''].push(item)
      }
    }
  })

  return macrosObject
}

export const parseAttachmentMeta = (attachmentLine) => {
  const regx = /{{([^>]+):([^>]+):([^>]+)}}/g
  const results = regx.exec(attachmentLine)

  return {
    fileName: _get(results, 2, ''),
    s3Key: _get(results, 3, ''),
  }
}

export const capitalizeString = (content) => {
  const arr = content?.split(' ')
  arr?.forEach((e, i) => (arr[i] = _capitalize(e)))

  return arr?.join(' ')
}
