import { getSettingsByStoreID } from '@/state/actions/settings'
import { getTheme } from '@/state/selectors/theme'

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useWhiteLabel = (storeID?: number) => {
  const dispatch = useDispatch()
  const theme = useSelector(getTheme)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(getSettingsByStoreID(storeID))
  }, [])

  const { loading, ...rest } = theme

  return {
    loading,
    ...rest,
  }
}
