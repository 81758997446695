import axios from '@/support/utils/request'
import { Dict } from '../types'
import config from '@/config'

export const getProxy = () => config.api.url

export const getApi = (url: string, params?: Dict, token?: string) => {
  return axios.get(`${getProxy()}${url}`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getApiV1 = (url: string, params: Dict, token: string) => {
  return axios.get(`${config.api.serverless}${url}`, {
    withCredentials: false,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const postApi = (url: string, body: Dict, token: string, useProd = false) => {
  return axios.post(`${useProd ? 'https://back.orderprotection.com' : getProxy()}${url}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const putApi = (url: string, body: Dict, token: string, useProd = false) => {
  return axios.put(`${useProd ? 'https://back.orderprotection.com' : getProxy()}${url}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const deleteApi = (url: string, body: Dict, token: string, useProd = false) => {
  return axios.delete(`${useProd ? 'https://back.orderprotection.com' : getProxy()}${url}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: body,
  })
}
