import React, { memo, useMemo, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _startCase from 'lodash/startCase'

import ArrowRight from '../../../assets/claim-portal/arrow-right.svg'
import NotEligible from '../../../assets/claim-portal/not-eligible.svg'
// import Delivered from '../../../assets/claim-portal/delivered.png';
import { formatMoney } from '../../../helpers/date-time-helper'
import { getOrderTrackingBadge, getStatusName } from '../../../helpers/order-helpers'
import OrderTrackerModal from '../../ClaimFormProcess/OrderTrackerModal'
import ClaimPortalItem from '../Core/ClaimPortalItem'
import './styles.scss'
import { renderStringArray } from '../../../helpers/data-transformer'
import platforms from '../../../constants/platforms'

const ClaimOrderDetails = ({
  orderData,
  orderItems,
  isError,
  orderTracking,
  hideItemList = false,
}) => {
  const [showModal, setShowModal] = useState(false)
  const orderTime = useMemo(() => _get(orderData, 'order_date'), [orderData])
  const trackingStatus = useMemo(() => {
    if (_isEmpty(orderTracking) || _isEmpty(orderData)) {
      return -1
    }

    return getOrderTrackingBadge(orderTracking, orderData)
  }, [orderData, orderTracking])

  return (
    <>
      <div className="header-order mb10">
        <label className={`order-id ${isError === true ? 'error' : ''}`}>
          Order #
          {orderData?.isMagento() ? orderData?.display_order_id : orderData?.store_order_id || ''}
        </label>
        {isError === true && (
          <div className="bage-order">
            <img src={NotEligible} alt="icon" />
            <span className="text">Not Eligible</span>
          </div>
        )}
      </div>
      <Row className="mb10">
        <Col className="info-column">
          <label className="heading">Order date</label>
          <label className="text">
            {orderData?.order_date ? moment(orderData?.order_date)?.format('dddd MM/DD/YYYY') : ''}
          </label>
        </Col>
        <Col className="info-column">
          <label className="heading">Total</label>
          <label className="text">${formatMoney(orderData?.order_total || 0)}</label>
        </Col>
        <Col className="info-column">
          <label className="heading">Order status</label>
          <label className="group-text">
            {/* <img src={Delivered} alt='icon' /> */}

            <span className="text">
              {orderData?.isMagento() ? 'Unfulfilled' : getStatusName(trackingStatus)}
            </span>
          </label>
        </Col>
      </Row>
      <div className="mb10">
        <label className="heading">Delivery Address</label>
        <label className="text">{renderStringArray(orderData?.shipping_address)}</label>
      </div>

      {hideItemList === false && (
        <div className="order-details-item-list">
          {_map(orderItems, (item, index) => (
            <ClaimPortalItem item={item} key={index} displayOutOfStock={true} />
          ))}
        </div>
      )}
    </>
  )
}

export default memo(ClaimOrderDetails)
