import './ReimbursementOptions.scss'
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core'
import { Col, Form, Button, Alert } from 'react-bootstrap'

export default function ReimbursementOptions(props) {
  return (
    <Col md={3} className="login">
      <Form className="form-container p-4 mt-5">
        <RadioGroup aria-label="gender" name="gender1">
          <FormControlLabel
            color="success"
            onClick={(event) => {
              event.preventDefault()
            }}
            value="Tier"
            control={<Radio color="primary" />}
            label="Gift Card"
          />
          <p>Get XXX instantly ready to spend.</p>

          <FormControlLabel
            color="success"
            onClick={(event) => {
              event.preventDefault()
            }}
            value="Ratee"
            control={<Radio color="primary" />}
            label="Reship + Gift Card"
          />
          <p>Get XXX instantly to spend and get the item(s) we still have in stock reshipped</p>
          <FormControlLabel
            color="success"
            onClick={(event) => {
              event.preventDefault()
            }}
            value="Rate"
            control={<Radio color="primary" />}
            label="Refund"
          />
          <p>
            We'll refund your original form of payment for . This may take up to 10 business days
            for your bank to deposit the funds.
          </p>
        </RadioGroup>
      </Form>
    </Col>
  )
}
