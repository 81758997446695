import { Button, Modal, Spinner } from 'react-bootstrap'
import _get from 'lodash/get'
import './AddProtection.scss'
import { useMemo } from 'react'

export default function AddProtection(props) {
  const finishLoadingData = useMemo(
    () => props.isLoading === true || props.isCaculatingOpAddition || !props.expectedInsuranceData,
    [props]
  )

  return (
    <Modal
      className="add-protection-modal"
      data-backdrop="true"
      show={props.modalShow}
      onHide={props.modalShow == false}
      centered
      animation={false}
      closeButton
    >
      <Modal.Header
        className="border-0"
        onClick={() => props.setModalShow(false)}
        closeButton
      ></Modal.Header>
      <Modal.Body>
        <div className="body-protection">
          <div className="font-weight-bold header">
            {props.opUser ? 'Add Protection' : 'Buy protection'} for this order?
          </div>

          {finishLoadingData ? (
            <Spinner className="m-2" animation="grow" />
          ) : (
            <p className="modal-text">
              {!props.opUser ? 'You' : props?.storeName} will be billed $
              {parseFloat(_get(props.expectedInsuranceData, 'variantPrice') / 100).toFixed(2)} to
              add protection to this order.
            </p>
          )}
          <Button
            disabled={finishLoadingData}
            variant="primary"
            className="btn-confirm"
            onClick={() => props.onAddProtection()}
          >
            <span>Yes, {props.opUser ? 'Add' : 'Buy'} Protection</span>
          </Button>
          <span
            className="cancel-link"
            onClick={() => {
              props.setModalShow(false)
            }}
          >
            No, Cancel
          </span>
        </div>
      </Modal.Body>
    </Modal>
  )
}
