import React, { memo, useEffect } from 'react'
import _isEmpty from 'lodash/isEmpty'
import { v1 } from 'uuid'
import { getApi, postApi } from '../../helpers/api'

const getSession = (shop) => {
  const key = `onboarding_${shop}_session`

  if (window.sessionStorage) {
    let sessionFromStorage = window.sessionStorage.getItem(key)

    if (_isEmpty(sessionFromStorage) === true) {
      sessionFromStorage = v1()
      window.sessionStorage.setItem(key, sessionFromStorage)
    }

    return sessionFromStorage
  }

  return ''
}

const PublicAppContainer = () => {
  const params = new URLSearchParams(window.location.search)
  const hmac = params.get('hmac')
  const code = params.get('code')
  const shop = params.get('shop')

  useEffect(() => {
    if (_isEmpty(hmac) === false && _isEmpty(code) === true) {
      getApi('/public-app-onboarding', {
        // Get onboarding URL + session
        shop: params.get('shop'),
      }).then((res) => {
        window.location.href = res.data.finalUrl
      })
    } else {
      if (_isEmpty(code) === false) {
        postApi('/public-app-onboarding', {
          // save session + store to onboarding candidate table
          shop,
          code,
          session: getSession(shop),
        })
      }
    }

    return null
  }, [hmac, code])

  return <div>Hello there {params.toString()}</div>
}

export default memo(PublicAppContainer)
