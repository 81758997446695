import _get from 'lodash/get'
import { useHistory } from 'react-router-dom'
import { isProtectedOrders } from '../../../helpers/claim-filter'
import { renderStringArray } from '../../../helpers/data-transformer'
import './ClaimCustomerInformation.scss'

function getShippingAddress(orderData) {
  let claimAddress = orderData?.claim?.updated_address
  claimAddress = typeof claimAddress === 'string' ? JSON.parse(claimAddress) : claimAddress
  const orderAddress = orderData.order_json.shipping_address
  const { address1, address2, city, province_code, province, zip, country, phone } =
    claimAddress || orderAddress || {}

  return {
    name: orderData.customer_name,
    address1,
    address2,
    city: `${city}, ${province || province_code}`,
    zip,
    country,
    phone,
  }
}

export default function ClaimCustomerInformation(props) {
  const userHasChangedShippingAddress =
    _get(props, 'state.customerData.shipping_address_changed_time', 0) > 0
  const history = useHistory()
  const customerShippingAddress = getShippingAddress(props.state.customerData)

  return (
    <div>
      <div className="section-wrapper">
        <p className="section-title">Customer</p>
        <p className="section-content">{props.state.customerData.customer_name}</p>
        <p className="section-content">
          {props.state.customerData.order_json && props.state.customerData.order_json.email}
        </p>

        <p className="section-title">Protection Order ID</p>
        {isProtectedOrders(props.state.customerData) ? (
          <p className="section-content">{props.state.customerData.protection_order_id}</p>
        ) : (
          <p className="badge badge-warning">Unprotected Order</p>
        )}

        <p className="section-title">Store Order</p>
        <p
          onClick={() => history.push(`/orders/${props.state.customerData.protection_order_id}`)}
          className="section-content"
        >
          {props.state.customerData.isMagento()
            ? props.state.customerData.display_order_id
            : props.state.customerData.store_order_id}
        </p>

        <p className="section-title">Order Date</p>
        <p className="section-content">
          {new Date(
            props.state.customerData.order_date
              ? props.state.customerData.order_date
              : props.state.customerData.claim_date
          ).toLocaleDateString([], {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
          })}
        </p>
      </div>
      <div className="section-wrapper">
        <p className="section-title">Shipping Address</p>
        {
          Object.keys(customerShippingAddress).length && (
            <>
              {Object.values(customerShippingAddress).map((addr, i) => (
                <p key={i} className="section-content">
                  {addr}
                </p>
              ))}
            </>
          )
          /*order_json -> latitude an longtitude are for future display map */
        }
        {props.state.customerData?.shipping_address &&
          !props.state.customerData?.order_json?.shipping_address && (
            <>
              <p className="text-muted concierge-address">
                <div className="text-muted concierge-address">
                  {props.state.customerData.customer_name}
                </div>

                {renderStringArray(props.state.customerData.shipping_address)}
                <div className="text-muted concierge-address">
                  {props.state.customerData.phone_number}
                </div>
              </p>
            </>
          )}
      </div>
      <div className="section-wrapper">
        <p className="section-title">Billing Address</p>
        {
          props.state.customerData &&
            props.state.customerData.order_json &&
            props.state.customerData.order_json.billing_address && (
              <>
                <div className="text-muted concierge-address">
                  {props.state.customerData.billing_name}
                </div>
                <div className="text-muted concierge-address">
                  {props.state.customerData.billing_address}
                </div>
                <div className="text-muted concierge-address">
                  {props.state.customerData.billing_phone_number}
                </div>
              </>
            )
          /*order_json -> latitude an longtitude are for future display map */
        }
      </div>
    </div>
  )
}
