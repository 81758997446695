import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import IconCongratulations from '../../../assets/claim-portal/icon-onboarding-congratulations.svg'
import { deleteApi } from '../../../helpers/api'
import LinkButton from '../../CoreUI/LinkButton'
import './style.scss'

export default function Congratulations() {
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const history = useHistory()

  const params = new URLSearchParams(window.location.search)
  const session = params.get('session')

  const handleFinished = useCallback(
    (selectedIndex) => {
      deleteApi(`/public-app-onboarding/${session}`, { needHelp: selectedIndex === 1 }).then(
        (res) => {
          window.location.href = res.data.url
        }
      )
    },
    [session, history]
  )

  return (
    <div className="onboarding-block onboarding-block-congratulations">
      <div className="onboarding-header">
        <h1 className="onboarding-header__title">
          <img src={IconCongratulations} alt="Icon Congratulations" className="icon" />
          Congratulations!
        </h1>
        <p className="onboarding-header__description">
          The final step is to install the Order Protection cart widget so your customers have the
          option to choose Order Protection at checkout.
        </p>
      </div>
      <div className="onboarding-options">
        <p className="onboarding-options__description">
          You have two options to get the Order Protection cart widget installed:
        </p>
        <div
          onClick={() => setSelectedIndex(0)}
          className={`onboarding-options__item ${selectedIndex === 0 ? 'selected' : ''}`}
        >
          <div className="options-content">
            <h4 className="options-content__title">
              Install the Order Protection cart widget myself
            </h4>
            <h4 className="options-content__description">
              You can install quickly yourself by following our step-by-step installation guide If
              you choose this option we'll redirect you to our installation guide now.
            </h4>
          </div>
        </div>
        <div
          onClick={() => setSelectedIndex(1)}
          className={`onboarding-options__item ${selectedIndex === 1 ? 'selected' : ''}`}
        >
          <div className="options-content">
            <h4 className="options-content__title">
              I want Order Protection to install the cart widget for me
            </h4>
            <h4 className="options-content__description">
              You can request that our onboarding experts install the Order Protection cart widget
              for you. This can take 1 - 5 business days.
            </h4>
          </div>
        </div>
      </div>
      <div className="d-flex flex-row align-items-center">
        <button
          disabled={selectedIndex === -1}
          className="btn-submit btn-actived btn-finish"
          onClick={() => handleFinished(selectedIndex)}
        >
          Finish
        </button>
        <LinkButton
          href="https://orderprotection.zendesk.com/hc/en-us/articles/4403536024091-Installation"
          className="ml-3"
        >
          View the Self-Installation Guide
        </LinkButton>
      </div>
    </div>
  )
}
