import { memo, useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _find from 'lodash/find'
import _kebabCase from 'lodash/kebabCase'

import Highlight from '../../../assets/claim-portal/highlight.svg'
import { claimResolutions } from '../configuration'
import { useClaimPortalContext } from '../../../context/claim-portal-context'

import './styles.scss'
import { Link } from 'react-router-dom'

const ClaimResolution = () => {
  const { params } = useRouteMatch(`/submitClaim/response/:responseType`)
  const { orderData, claimTypeName } = useClaimPortalContext()
  const history = useHistory()
  const responseType = useMemo(() => _get(params, 'responseType', ''), [params])
  const resolution = useMemo(() => {
    if (_isEmpty(responseType) === true) {
      return undefined
    }

    return _find(claimResolutions, (solution) => _kebabCase(solution.name) === responseType)
  }, [responseType])

  if (_isEmpty(resolution) === true) {
    history.push('/submitClaim')

    return <div />
  }

  return (
    <div className="claim-resolution-wrapper">
      <div className={`title ${resolution.name.toLocaleLowerCase()}`}>
        <div className="centered">
          <img className="centered" src={Highlight} alt="Awesome" />
        </div>
        <label>Claim {resolution.name}</label>
      </div>
      <div className="sub-text">
        {claimTypeName} • Order: #{orderData.store_order_id}
      </div>
      <div className="centered">
        <Link to="/submitClaim" className="btn-new">
          View Claim
        </Link>
      </div>
      <div className="centered">
        <img src={resolution.image} alt="" />
      </div>
    </div>
  )
}

export default memo(ClaimResolution)
