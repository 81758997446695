import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const storeSlice = createSlice({
  name: 'store',
  initialState: { storeId: -1 },
  reducers: {
    setStore: (state: StoreState, action: PayloadAction<number>) => {
      state.storeId = action.payload
    },
  },
})

export const { setStore: setStoreAction } = storeSlice.actions
export type StoreState = {
  storeId: number
}

export default storeSlice.reducer
