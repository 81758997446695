import './ClaimTypeOption.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStore } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import { views } from '../../../helpers/appViews'
import { useEffect, useMemo } from 'react'
import _get from 'lodash/get'
import _filter from 'lodash/filter'

import _isEmpty from 'lodash/isEmpty'

const mappingClaimType = (claimId) => {
  switch (claimId) {
    case 1:
      return 'Stolen'
    case 2:
      return 'Damaged Order'
    case 3:
      return 'Defective Item'
    case 4:
      return 'Order Delivered but Not Received'
    case 5:
      return 'Wrong Item'
    case 6:
      return 'Missing Item'
    case 7:
      return 'Returned To Sender'
    case 8:
      return 'Tracking Not Moving'
    default:
      return ''
  }
}

export default function ClaimTypeOption(props) {
  const {
    state,
    claimType,
    storeId,
    disabledAction,
    setIsClaimTypeSubmit,
    waitToClaim,
    setState,
    isClaimTypeSubmit,
    setType,
    type,
  } = props
  const history = useHistory()
  useEffect(() => {
    if (isClaimTypeSubmit && type === claimType?.id) {
      handleClaimTypeSubmit()
    }
  }, [isClaimTypeSubmit])

  function handleClaimTypeSubmit() {
    let customerData = { ...state.customerData }

    customerData.claimType = claimType
    setState({
      ...state,
      customerData: customerData,
    })
    const fulfillments = _filter(_get(props, 'state.fulfillments.fulfillments'), function (o) {
      return o.line_items[0].title != 'Order Protection'
    })

    const isHexcladStore = storeId == 352 || storeId == 353
    const isClaimTypeStolen = claimType.id == 1
    const isClaimTypeDamagedOrder = claimType.id == 2
    const isClaimTypeDefectiveItem = claimType.id == 3
    const isClaimTypeOrderDeliveredButNotReceived = claimType.id == 4
    const isClaimTypeWrongItem = claimType.id == 5
    const isClaimTypeMissingItem = claimType.id == 6
    const isClaimTypeReturnedToSender = claimType.id == 7
    const isClaimTypeTrackingNotMoving = claimType.id == 8

    const conditionActionsMap = [
      {
        condition:
          (isClaimTypeDamagedOrder || isClaimTypeDefectiveItem || isClaimTypeWrongItem) &&
          isHexcladStore,
        action: '/submitClaim/hexclad-returns',
      },
      {
        condition: isClaimTypeWrongItem || isClaimTypeMissingItem,
        action: '/submitClaim/select-item',
      },
      {
        condition:
          (isClaimTypeStolen || isClaimTypeOrderDeliveredButNotReceived) && waitToClaim.status,
        action: '/submitClaim/waitToClaim',
      },
      {
        condition:
          isClaimTypeStolen ||
          (_get(props, 'state.fulfillments.fulfillments', []).length == 1 && claimType.id == 4) ||
          isClaimTypeReturnedToSender ||
          isClaimTypeTrackingNotMoving,
        action: '/submitClaim/resolution',
      },
      {
        condition: isClaimTypeOrderDeliveredButNotReceived && fulfillments?.length > 1,
        action: '/submitClaim/multiple-fulfillments',
      },
      {
        condition: isClaimTypeDamagedOrder || isClaimTypeDefectiveItem,
        action: '/submitClaim/damage-item',
      },
      { condition: true, action: '/submitClaim/resolution' },
    ]

    const { action } = conditionActionsMap.find(({ condition }) => condition) || {}

    history.push(action)

    if (disabledAction) {
      setIsClaimTypeSubmit(false)
    }
  }

  const claimTypeName = useMemo(() => mappingClaimType(_get(props, 'claimType.id', '')), [props])
  if (_isEmpty(claimTypeName) === true) {
    return null
  }

  return (
    <div
      onClick={() => (disabledAction ? setType(claimType?.id) : handleClaimTypeSubmit())}
      className={`claim-type ${type === claimType?.id ? 'claim-type-active' : ''}`}
    >
      <FontAwesomeIcon icon={faStore} /> <span className="type-name">{claimTypeName}</span>
    </div>
  )
}
