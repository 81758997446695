import _find from 'lodash/find'
import ClaimPending from '../../assets/claim-portal/claim-result-pending.svg'
import ClaimApproved from '../../assets/claim-portal/claim-result-approved.svg'
import ClaimDenied from '../../assets/claim-portal/claim-result-denied.svg'

export const messageTypes = Object.freeze({
  UnprotectedOrder: {
    name: 'Protection not purchased',
    text: `Order is not covered by Order Protection, You didn't purchase it at checkout and therefore the claim cannot be processed.`,
    isError: true,
  },
  PastTimeframe: {
    name: 'Past timeframe',
    text: `Your order is no longer eligible to file a claim as it’s been over {claim_filing_window} days of the order date.`,
    isError: true,
  },
  UnfulFilled: {
    name: 'Wait to fulfill',
    text: `We need your order to be shipped before we can help with submitting a claim. We are working on processing your order as fast as possible and will be sending you a tracking number very soon. We appreciate your patience!`,
  },
  WaitTwoDays: {
    name: 'Wait two days',
    text: `We’ll need you to wait at least two days after the shipping status changed to delivered before we contact your shipping company or they’ll just tell us to wait. Once we can confirm this shipment is not arriving we can give you a resolution.`,
  },
  NotDelivered: {
    name: 'Not Delivered',
    text: `Your order must be fully delivered before you can submit a claim. Once all of the items in your order have been delivered you will be able to submit a claim.`,
  },
})

export const claimResolutions = Object.freeze({
  Pending: {
    statusId: 1,
    name: 'Pending',
    image: ClaimPending,
  },
  Approved: {
    statusId: 2,
    name: 'Approved',
    image: ClaimApproved,
  },
  Denied: {
    statusId: 3,
    name: 'Denied',
    image: ClaimDenied,
  },
  Dismissed: {
    statusId: 4,
    name: 'Pending',
    image: ClaimPending,
  },
})

export const ClaimTypes = Object.freeze({
  Stolen: {
    text: 'How would you like to resolve this?',
    descrption: '',
    question: 'We’re sorry to hear your package was stolen.',
    uploadMessage: '',
  },
  DeliveredNotReceived: {
    text: 'How would you like to resolve this?',
    descrption: '',
    question: 'We’re sorry to hear you didn’t receive your package.',
    uploadMessage: '',
  },
  WrongItem: {
    text: 'Which item(s) are wrong?',
    descrption: 'Please select all items in your order that are wrong.',
    question: '',
    uploadMessage: 'Upload a clear photo of the wrong items you received',
  },
  MissingItem: {
    text: 'Which item(s) are missing?',
    descrption: 'Please select all missing items from your order.',
    question: '',
    uploadMessage: 'Upload a clear photo of what you received',
  },
  DamageItem: {
    text: 'Which of the following describes your package?',
    question: 'We’re sorry to hear your order was damaged.',
    uploadMessage: 'Upload a photo of the shipping box/envelope and a photo of the damaged item(s)',
  },
  DamagedItem: {
    text: 'Which items are damaged?',
    question: 'We’re sorry to hear your order was damaged.',
    uploadMessage: 'Upload a photo of the shipping box/envelope and a photo of the damaged item(s)',
  },
  DefectiveItem: {
    text: 'We’re sorry to hear your order has defective item(s).',
    descrption: '',
    question: 'Please select all that apply.',
    uploadMessage: 'Upload a clear photo of the defective items you received',
  },
  TrackingNotMoving: {
    text: 'How can we fix this for you?',
    descrption:
      'Oops! Mistakes can happen in manufacturing and you may have been sent defective items. Please send us a picture of what you received and we’ll match it against what you ordered.',
    question: 'We’re sorry to hear for the inconvenience.',
    uploadMessage: 'Upload a clear photo of the defective items you received',
  },
  ReturnedToSender: {
    text: 'How can we fix this for you?',
    descrption:
      'Oops! Mistakes can happen in manufacturing and you may have been sent defective items. Please send us a picture of what you received and we’ll match it against what you ordered.',
    question: 'We’re sorry for the inconvenience.',
    uploadMessage: 'Upload a clear photo of the defective items you received',
  },
})

export const getClaimResolution = (claimStatusId) =>
  _find(claimResolutions, (res) => res.statusId === parseInt(claimStatusId))
