export const toLocaleString = (n: number | null | undefined | string) => {
  if (typeof n === 'undefined' || n === null) {
    return 0
  }

  if (typeof n === 'string' && isNaN(Number(n))) {
    return n
  }

  return Number(n).toLocaleString()
}
