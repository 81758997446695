import './AddUpdatedAddress.scss'
import { Row, Col, Button, Form, Modal } from 'react-bootstrap'
import { useState } from 'react'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector'

export default function AddUpdatedAddress(props) {
  const [addressOne, setAddressOne] = useState(null)
  const [addressTwo, setAddressTwo] = useState('')
  const [postalCode, setPostalCode] = useState(null)
  const [city, setCity] = useState(null)
  const [region, setRegion] = useState(null)
  const [country, setCountry] = useState(null)

  const handleAddBillingProfile = async () => {
    if (addressOne && postalCode && city && region && country) {
      props.state.customerData.order_json.shipping_address.address1 = addressOne
      props.state.customerData.order_json.shipping_address.address2 = addressTwo
      props.state.customerData.order_json.shipping_address.city = city
      props.state.customerData.order_json.shipping_address.country = country
      props.state.customerData.order_json.shipping_address.province_code = region
      props.state.customerData.order_json.shipping_address.zip = postalCode

      const updatedAddress = {
        address1: addressOne,
        address2: addressTwo,
        city: city,
        country: country,
        province: region,
        zip: postalCode,
      }
      props.setState({
        ...props.state,
        updatedAddress: updatedAddress,
      })
      props.closeModal(false)
    }
  }
  return (
    <Modal show={props.editAddress} onHide={() => props.closeModal()} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Update Address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formShopifyStoreName">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              className="inputField"
              placeholder="Address 1"
              value={addressOne}
              onChange={(event) => {
                setAddressOne(event.target.value)
              }}
            />
          </Form.Group>

          <Form.Group controlId="formShopifyStoreName">
            <Form.Label>Apartment, Suite etc.(optional)</Form.Label>
            <Form.Control
              type="text"
              className="inputField"
              placeholder="Address 2"
              value={addressTwo}
              onChange={(event) => {
                setAddressTwo(event.target.value)
              }}
            />
          </Form.Group>
          <Form.Group controlId="formShopifyStoreName">
            <Form.Label>City</Form.Label>

            <Form.Control
              type="text"
              className="inputField"
              placeholder="City"
              value={city}
              onChange={(event) => {
                setCity(event.target.value)
              }}
            />
          </Form.Group>
          <Form.Group controlId="formShopifyStoreName" className="address-input-container">
            <Row>
              <Col>
                <Form.Label>Country/Region</Form.Label>

                <CountryDropdown
                  className="country-input-dropdown text-muted inputField"
                  value={country}
                  onChange={(val) => setCountry(val)}
                />
              </Col>
              <Col>
                <Form.Label>State/Province</Form.Label>

                <RegionDropdown
                  className="region-input-dropdown text-muted inputField"
                  disableWhenEmpty={true}
                  country={country}
                  value={region}
                  onChange={(val) => setRegion(val)}
                />
              </Col>
              <Col>
                <Form.Label>Postal Code</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Postal Code"
                  value={postalCode}
                  onChange={(event) => {
                    setPostalCode(event.target.value)
                  }}
                />
              </Col>
            </Row>
          </Form.Group>
          <div className="submit-button'container">
            <Button
              variant="primary"
              className="submit-button deliver-button"
              onClick={() => {
                handleAddBillingProfile()
              }}
            >
              Deliver to this address
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
