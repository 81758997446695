import React from 'react'
import { ClockIcon } from '@heroicons/react/24/solid'
import { useHistory } from 'react-router-dom'

const WaitToClaimModal = ({ waitDate }) => {
  const history = useHistory()

  return (
    <div>
      <div className="w-full flex flex-col items-center text-center">
        <div className="w-[30px] h-[30px] text-primary-700 !mb-3">
          <ClockIcon />
        </div>
        <div className="md:text-5xl text-4xl text-typography-primary font-semibold !mb-3 testing">
          Hold that thought!
        </div>
        <div className="md:text-xl text-md text-typography-secondary !mb-8">
          Your order is still within the 48-hour delivery waiting period.
        </div>
        <div className="h-[152px] w-full rounded-3xl bg-gradient-to-t from-amber-100 to-amber-50 !py-8 !mb-8">
          <div className="text-sm text-typography-primary !mb-2">You can file a claim on:</div>
          <div className="md:text-[32px] text-2xl font-semibold text-typography-primary !mb-2">
            {waitDate}
          </div>
          <div className="text-sm text-typography-secondary">
            If you still haven't received your package.
          </div>
        </div>
        <div className="text-xs text-typography-disabled !mb-12">
          Per our policy, unreceived package claims require a waiting period of 48 hours from the
          delivery time before processing, as carriers will sometimes mark a package as delivered
          before arriving at your address.
        </div>
        <hr className="w-full" />
        <div className="!mt-12 text-typography-primary font-semibold md:text-2xl text-xl !mb-5">
          Until then, here are some things you can try in the meantime:
        </div>
        <div className="w-full md:h-[240px] h-[242px] bg-neutral-100 rounded-3xl !py-8 lg:!px-16 !px-6 flex flex-col items-center text-center !mb-5">
          <div className="w-[64px] h-[64px] rounded-full bg-primary-700 !mb-5 flex items-center justify-center text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
              />
            </svg>
          </div>
          <div className="md:text-xl text-lg font-semibold text-black !mb-2">
            Live in a shared building?
          </div>
          <div className="font-sm w-full text-typography-secondary">
            If you have a doorman or concierge, try checking with building staff to see if your
            package was received but misplaced.
          </div>
        </div>
        <div className="w-full md:h-[262px] h-[282px] bg-neutral-100 rounded-3xl !py-8 lg:!px-16 !px-6 flex flex-col items-center text-center !mb-5">
          <div className="w-[64px] h-[64px] rounded-full bg-primary-700 !mb-5 flex items-center justify-center text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
              />
            </svg>
          </div>
          <div className="md:text-xl text-lg font-semibold text-black !mb-2">
            Reach out to neighbors
          </div>
          <div className="font-sm w-full text-typography-secondary">
            Delivery mix ups happen! Try checking with friendly neighbors or posting on neighborhood
            apps like Nextdoor to see if your package was delivered to the wrong address.
          </div>
        </div>
        <div className="w-full md:h-[248px] h-[268px] bg-neutral-100 rounded-3xl !py-8 lg:!px-16 !px-6 flex flex-col items-center text-center !mb-5">
          <div className="w-[64px] h-[64px] rounded-full bg-primary-700 !mb-5 flex items-center justify-center text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
              />
            </svg>
          </div>
          <div className="md:text-xl text-lg font-semibold text-black !mb-2">
            Check camera footage
          </div>
          <div className="font-sm w-full text-typography-secondary">
            If you have a doorbell or security camera on your property, try reviewing the footage
            around the delivery time to see if the delivery was completed.
          </div>
        </div>
        <div className="back-link" onClick={() => history.go(-1)}>
          Back
        </div>
      </div>
    </div>
  )
}

export default WaitToClaimModal
