/* eslint-disable no-undef */
import _get from 'lodash/get'

class ShopifyItem {
  constructor(item) {
    this.item = item
  }

  getStockMessage() {
    if (_get(this.item, 'itemData.variantData.inventory_management') === null) {
      return 'Not Tracked'
    }

    const currentQuantity = _get(this.item, 'itemData.variantData.inventory_quantity', 0)
    if (currentQuantity > 0) {
      return currentQuantity
    }

    if (
      currentQuantity < 1 &&
      _get(this.item, 'itemData.variantData.inventory_policy') === 'continue'
    ) {
      return `${currentQuantity} (Continue Selling When Out of Stock)`
    }

    return 'Out of stock'
  }

  getVariantData() {
    return this.item.variant_title
  }

  isOutOfStockItem() {
    return (
      _get(this.item, 'variantData.inventory_quantity', 9999) <= 0 &&
      _get(this.item, 'variantData.inventory_policy', null) !== 'continue'
    )
  }

  inventoryManagementShopify() {
    return _get(this.item, 'variantData.inventory_management', 0) == 'shopify'
  }

  conciergeRefundable() {
    return this.isOutOfStockItem() && this.inventoryManagementShopify()
  }

  continueWhenOutOfStock() {
    return _get(this.item, 'variantData.inventory_policy') === 'continue'
  }

  remainingItems() {
    const isNotTrack = _get(this.item, 'variantData.inventory_management') === null

    return isNotTrack ? 99999 : _get(this.item, 'variantData.inventory_quantity', 0)
  }
}

class BigCommerceItem {
  constructor(item) {
    this.item = item
  }

  getStockMessage() {
    if (_get(this.item, 'itemData.variantData.product.inventory_tracking') === 'none') {
      return 'Not Tracked'
    }

    const currentQuantity = _get(this.item, 'itemData.variantData.inventory_level', 0)
    if (currentQuantity > 0) {
      return currentQuantity
    }

    return 'Out of stock'
  }

  getVariantData() {
    const variantData = _get(this.item, 'itemData.variantData.option_values', [])
    return variantData
  }

  isOutOfStockItem() {
    return (
      _get(this.item, 'variantData.inventory_level', 0) <= 0 &&
      _get(this.item, 'variantData.product.inventory_tracking', 'none') !== 'none'
    )
  }

  conciergeRefundable() {
    return this.isOutOfStockItem()
  }

  continueWhenOutOfStock() {
    return _get(this.item, 'variantData.product.inventory_tracking', 'none') === 'none'
  }

  remainingItems() {
    const isNotTrack = _get(this.item, 'variantData.product.inventory_tracking', 'none') === 'none'

    return isNotTrack ? 99999 : _get(this.item, 'variantData.inventory_level', 0)
  }
}

class MagentoItem {
  constructor(item) {
    this.item = item
  }

  getStockMessage() {
    if (_get(this.item, 'itemData.variantData.inventory_management') === null) {
      return 'Not Tracked'
    }

    const currentQuantity = _get(this.item, 'itemData.variantData.inventory_quantity', 0)
    if (currentQuantity > 0) {
      return currentQuantity
    }

    if (
      currentQuantity < 1 &&
      _get(this.item, 'itemData.variantData.inventory_policy') === 'continue'
    ) {
      return `${currentQuantity} (Continue Selling When Out of Stock)`
    }

    return 'Out of stock'
  }

  getVariantData() {
    return this.item.variant_title
  }

  isOutOfStockItem() {
    return (
      _get(this.item, 'variantData.inventory_quantity', 9999) <= 0 &&
      _get(this.item, 'variantData.inventory_policy', null) !== 'continue'
    )
  }

  conciergeRefundable() {
    return this.isOutOfStockItem()
  }

  continueWhenOutOfStock() {
    return _get(this.item, 'variantData.inventory_policy') === 'continue'
  }

  remainingItems() {
    const isNotTrack = _get(this.item, 'variantData.inventory_management') === null

    return isNotTrack ? 99999 : _get(this.item, 'variantData.inventory_quantity', 0)
  }
}

class PlatformItem {
  constructor(item, platformId) {
    this.item = item
    this.platformId = platformId

    switch (platformId) {
      case 1:
        this.platformItem = new ShopifyItem(this.item)
        break
      case 2:
        this.platformItem = new BigCommerceItem(this.item)
        break
      case 3:
        this.platformItem = new MagentoItem(this.item)
        break
      default:
        this.platformItem = new ShopifyItem(this.item)
        break
    }
  }

  getStockMessage() {
    return this.platformItem.getStockMessage()
  }

  getVariantData() {
    return this.platformItem.getVariantData()
  }

  continueWhenOutOfStock() {
    return this.platformItem.continueWhenOutOfStock()
  }

  isOutOfStockItem() {
    return this.platformItem.isOutOfStockItem()
  }

  conciergeRefundable() {
    return this.platformItem.conciergeRefundable()
  }

  remainingItems() {
    return this.platformItem.remainingItems()
  }
}

export default PlatformItem
