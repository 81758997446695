import React, { memo, useCallback, useState } from 'react'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import { Col, Row } from 'react-bootstrap'
import { useClaimPortalContext } from '../../../../context/claim-portal-context'

const LineItem = ({ item, lastItem }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const { orderItems } = useClaimPortalContext()

  return (
    <div className={`${lastItem ? 'last-item' : ''} item-area`}>
      <Row className="item-row">
        <Col className="first parent-variant" md={2}>
          <img
            className="item-photo"
            src={
              orderItems.find((element) => element.variant_id == item.variant_id)?.photo != '{}'
                ? orderItems.find((element) => element.variant_id == item.variant_id)?.photo
                : 'https://order-protection-static.s3-us-west-1.amazonaws.com/defaultItemImage.png' ||
                  'https://order-protection-static.s3-us-west-1.amazonaws.com/defaultItemImage.png'
            }
            alt="product"
          />
        </Col>
        {width < 768 && (
          <div className="mobile-contents">
            <Col className="parent-variant" md={5}>
              <div className="variant-information">
                <div className="item-name">{item.name}</div>
                <div className="item-variant">
                  {item.variant_title != '' && item.variant_title === null
                    ? ''
                    : 'Variant: ' + item.variant_title}
                </div>
              </div>
            </Col>
            <Col md={2}>
              <div className="quantity-container">
                <Row className="justify-content-center">
                  <span>Quantity: </span>
                  {item.quantity}
                </Row>
              </div>
            </Col>
            <Col md={2}>
              <div className="price-container">
                <Row className="justify-content-center">
                  <span>Price: </span>
                  {item.price}
                </Row>
              </div>
            </Col>
          </div>
        )}
        {width > 768 && (
          <>
            <Col className="parent-variant" md={5}>
              <div className="variant-information">
                <div className="item-name">{item.name}</div>
                <div className="item-variant">
                  {item.variant_title != '' && item.variant_title === null
                    ? ''
                    : 'Variant: ' + item.variant_title}
                </div>
              </div>
            </Col>
            <Col md={2}>
              <div className="quantity-container">
                <Row className="justify-content-center">
                  <span>Quantity: </span>
                  {item.quantity}
                </Row>
              </div>
            </Col>
            <Col md={2}>
              <div className="price-container">
                <Row className="justify-content-center">
                  <span>Price: </span>
                  {item.price}
                </Row>
              </div>
            </Col>
          </>
        )}
      </Row>
    </div>
  )
}

export default memo(LineItem)
