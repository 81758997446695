import React, { useMemo, useState } from 'react'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { Col, Modal, Row, Form, Button } from 'react-bootstrap'
import { GoogleApiWrapper } from 'google-maps-react'
import { googleApiKey } from '../../../configuration/google-maps'
import GooglePlaceAutoComplete from '../../GooglePlaceAutoComplete'
import './styles.scss'
import ChangeAddressMap from '../ChangeAddressMap'
import orderFactory from '../../../helpers/order-factory'
import CarrierInfo from './CarrierInfo'

const ChangeAddressModal = ({ show, onHide, orderData, onSave, mainItems, onViewTrackingLink }) => {
  const [address, setAddress] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const centerPoint = useMemo(() => _get(address, 'coordinates'), [address])
  const hasShipped = useMemo(() => mainItems.length > 0, [mainItems])
  const originalPoint = useMemo(() => {
    const addressCoordinate = _get(orderData, 'address_coordinate', {})

    if (_isEmpty(addressCoordinate) === false) {
      return addressCoordinate
    }

    const lat = _get(orderData, 'order_json.shipping_address.latitude', 0)
    const lng = _get(orderData, 'order_json.shipping_address.longitude', 0)

    if (lat !== 0 && lng !== 0) {
      return {
        lat,
        lng,
      }
    }

    return undefined
  }, [orderData])

  const displayAddress = useMemo(() => orderFactory(orderData).shippingAddress, [orderData])
  const carrierName = useMemo(
    () => _get(mainItems, '[0].fulfillments[0].tracking_company', 'the carrier'),
    [mainItems]
  )

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      show={show}
      className="change-address-modal"
      onHide={() => onHide()}
    >
      <Modal.Header closeButton>
        <div className="font-weight-bold">Change your Address</div>
      </Modal.Header>
      {hasShipped === true ? (
        <Modal.Body className="change-address-modal-body">
          <Row>
            <Col xs={12}>
              <div>{`Your order has already shipped via ${carrierName}. You must contact them in order to attempt to change the delivery address.`}</div>
              <div className="my-3">
                Click{' '}
                <span className="text-primary tracking-link" onClick={() => onViewTrackingLink()}>
                  here
                </span>{' '}
                to see where is your order
              </div>
              <CarrierInfo carrierName={carrierName} />
            </Col>
          </Row>
          <Button className="mt-3 float-right" onClick={() => onHide()}>
            Close
          </Button>
        </Modal.Body>
      ) : (
        <Modal.Body className="change-address-modal-body">
          <Row>
            <Col xs={12} sm={6}>
              <Form.Label>Current Address (A)</Form.Label>
              <Form.Control type="text" contentEditable={false} defaultValue={displayAddress} />
            </Col>
            <Col xs={12} sm={6}>
              <Form.Label>New Address (B)</Form.Label>
              <div>
                <GooglePlaceAutoComplete onAddressChange={setAddress} />
              </div>
            </Col>
          </Row>
          <div className="map-wrapper mt-3">
            <ChangeAddressMap originalPoint={originalPoint} centerPoint={centerPoint} />
          </div>
          <Button
            disabled={isLoading}
            className="mt-3 float-right"
            onClick={() => {
              setIsLoading(true)
              onSave(orderData.id, address, () => {
                setIsLoading(false)
                onHide()
                setAddress({})
              })
            }}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </Modal.Body>
      )}
    </Modal>
  )
}

const withGoogleMap = GoogleApiWrapper({
  apiKey: googleApiKey,
})(ChangeAddressModal)

export default React.memo(withGoogleMap)
