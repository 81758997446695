import React, { memo, useMemo } from 'react'
import { Row, Col } from 'react-bootstrap'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _flatten from 'lodash/flatten'
import _compact from 'lodash/compact'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _includes from 'lodash/includes'
import _get from 'lodash/get'
import _groupBy from 'lodash/groupBy'
import _sumBy from 'lodash/sumBy'
import _reduce from 'lodash/reduce'

import { formatMoney } from '../../../../helpers/date-time-helper'
import FulFillmentCard from './fulfillment-card'

import Unfulfilled from '../../../../../src/assets/Unfulfilled.svg'
import Fulfilled from '../../../../../src/assets/Fulfilled.svg'

import './styles.scss'

const getFulFillmentItems = (fulFillmentData = []) => {
  return _filter(
    fulFillmentData,
    (obj) =>
      !_isEmpty(
        _filter(
          obj?.line_items || [],
          (item) =>
            !!(
              item?.title?.toLowerCase()?.indexOf('rush processing') === -1 &&
              item?.title?.toLowerCase()?.indexOf('order protection') === -1
            )
        )
      )
  )
}

const OrderItems = ({ items = [], orderData, fulFillmentDetails }) => {
  const orderItemsQuantity = items?.reduce((sum, a) => sum + a.quantity, 0)
  const rawFulfillments = useMemo(
    () => _compact(_flatten(_map(fulFillmentDetails, 'line_items'))),
    [fulFillmentDetails]
  )

  const fulFilledItems = useMemo(
    () =>
      _map(
        _map(_groupBy(rawFulfillments, 'variant_id'), (item, variant_id) => ({
          variant_id: orderData.platform_id === 3 ? variant_id : parseInt(variant_id),
          quantity: _sumBy(item, 'quantity'),
        })),
        (line) => {
          return {
            id: _get(line, 'variant_id', ''),
            quantity: _get(line, 'quantity', 0),
            line_item_id: _get(
              _find(items, (item) => {
                const item_variant_id = _get(item, 'variant_id') || ''
                const line_variant_id = _get(line, 'variant_id') || ''
                return item_variant_id.toString() === line_variant_id.toString()
              }),
              'line_item_id',
              null
            ),
            remaining:
              _get(
                _find(items, (item) => {
                  const item_variant_id = _get(item, 'variant_id') || ''
                  const line_variant_id = _get(line, 'variant_id') || ''

                  return item_variant_id.toString() === line_variant_id.toString()
                }),
                'quantity',
                0
              ) - line.quantity,
          }
        }
      ),
    [rawFulfillments, items]
  )
  const allItemsIdsFromFulfillment = useMemo(
    () => _filter(fulFilledItems, (row) => row.remaining <= 0),
    [fulFilledItems]
  )

  const claim = useMemo(() => _get(orderData, 'claims[0]'), [orderData])
  const currency = _get(orderData, 'order_json.presentment_currency')

  const getProtectionCost = (order) => {
    if (order && order.order_json && order.insurance_cost) {
      return order.insurance_cost
    } else {
      return 0
    }
  }

  const currentTotalDiscountsFromOrderJson = (
    (orderData?.order_json?.current_total_discounts || 0) / 1
  ).toFixed(2)

  const currentTotalDiscountsFromItems = (
    _reduce(items, (sum, item) => sum + _get(item, 'discount', 0), 0) / 100
  ).toFixed(2)

  const currentTotalDiscounts = items
    ? currentTotalDiscountsFromItems
    : currentTotalDiscountsFromOrderJson

  const subtotalPresentmentMoneyAmount = (
    (orderData?.isMagento()
      ? formatMoney(orderData.order_total)
      : orderData?.order_json?.subtotal_price_set?.presentment_money?.amount || 0) / 1
  ).toFixed(2)

  const subtotalIncTaxFromOrderJson = (
    (orderData?.order_json?.current_subtotal_price ||
      orderData?.order_json?.subtotal_inc_tax ||
      0) / 1
  ).toFixed(2)

  const subtotalIncTaxFromItems = (
    _reduce(items, (sum, item) => sum + _get(item, 'price', 0) * _get(item, 'quantity', 1), 0) / 100
  ).toFixed(2)

  const subtotalIncTax = orderData?.isMagento()
    ? (orderData.order_total / 100).toFixed(2)
    : subtotalIncTaxFromItems

  const shippingItemPrice = ((orderData?.order_json?.shipping_lines?.[0]?.price || 0) / 1).toFixed(
    2
  )

  const shippingItemPresentmentMoneyAmount = (
    (orderData?.isMagento()
      ? formatMoney(orderData.order_shipping)
      : orderData?.order_json?.shipping_lines?.[0]?.price_set?.presentment_money?.amount || 0) / 1
  ).toFixed(2)

  const totalPresentmentMoneyAmount = (
    (orderData?.isMagento()
      ? formatMoney(orderData.order_total)
      : orderData?.order_json?.total_price_set?.presentment_money?.amount || 0) / 1
  ).toFixed(2)

  const totalInsured = subtotalIncTax * 100 - currentTotalDiscounts * 100 + shippingItemPrice * 100
  const totalWeight = ((orderData?.order_json?.total_weight || 0) / 453).toFixed(2)

  return (
    <>
      <div className="order-items">
        {!_isEmpty(
          _filter(items, (row) => {
            if (_isEmpty(_get(row, 'variant_id')) === false) {
              return (
                _includes(
                  _map(allItemsIdsFromFulfillment, 'id'),
                  orderData.platform_id === 3 ? row.variant_id : parseInt(row.variant_id)
                ) === false
              )
            }
            return null
          })
        ) && (
          <div className="unfulfillment-card-wrapper card-shadow">
            {_map(
              _filter(
                items,
                (row) =>
                  _includes(
                    _map(allItemsIdsFromFulfillment, 'id'),
                    orderData.platform_id === 3 ? row.variant_id : parseInt(row.variant_id)
                  ) === false
              ),
              (item, index) => (
                <FulFillmentCard
                  key={index}
                  data={{
                    line_items: [
                      {
                        ...item,
                        title: item.item_name,
                        sku: item?.variantData?.sku,
                        quantity:
                          item.quantity -
                          (_find(
                            fulFilledItems,
                            (rowItem) => rowItem.id.toString() === item.variant_id.toString()
                          )?.quantity || 0),
                      },
                    ],
                  }}
                  itemsData={items}
                  claim={claim}
                  orderData={orderData}
                  icon={Unfulfilled}
                />
              )
            )}
          </div>
        )}
        {_map(getFulFillmentItems(fulFillmentDetails), (fulFillmentItem) => (
          <FulFillmentCard
            key={fulFillmentItem.id}
            data={fulFillmentItem}
            itemsData={items}
            claim={claim}
            orderData={orderData}
            icon={Fulfilled}
          />
        ))}

        <div className="rounded p-10 card-shadow">
          <div className="font-semibold text-2xl mb-1">Payment</div>
          <div className="sumary-order">
            <Row className="align-items-center">
              <Col className="d-flex align-items-center" xs={8}>
                <span className="text-default">Discount</span>
                <span className="text-default">
                  {orderData?.order_json?.discount_codes?.[0]?.code}
                </span>
              </Col>
              <Col className="text-right" xs={4}>
                {orderData?.order_json?.presentment_currency === 'USD' || orderData?.isMagento() ? (
                  <span className="text-default">
                    -$
                    {currentTotalDiscounts}
                  </span>
                ) : (
                  <span className="text-default">
                    -{subtotalPresentmentMoneyAmount} {currency}
                  </span>
                )}
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="d-flex align-items-center" xs={8}>
                <span className="text-default w-text-title">Subtotal</span>
                <span className="text-default">{`${orderItemsQuantity} ${
                  orderItemsQuantity > 1 ? 'items' : 'item'
                }`}</span>
              </Col>
              <Col className="text-right" xs={4}>
                {orderData?.order_json?.presentment_currency === 'USD' ? (
                  <span className="text-default">${formatMoney(subtotalIncTax * 100)}</span>
                ) : (
                  <span className="text-default">
                    {subtotalPresentmentMoneyAmount} {currency}
                  </span>
                )}
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="d-flex align-items-center" xs={8}>
                <span className="text-default w-text-title">Shipping</span>
                <span className="text-default">
                  {orderData?.order_json?.shipping_lines?.[0]?.title} ({totalWeight} lbs)
                </span>
              </Col>
              <Col className="text-right" xs={4}>
                {orderData?.order_json?.presentment_currency === 'USD' ? (
                  <span className="text-default">${shippingItemPrice}</span>
                ) : (
                  <span className="text-default">
                    {shippingItemPresentmentMoneyAmount} {currency}
                  </span>
                )}
              </Col>
            </Row>
            <hr />
            <Row className="align-items-center mb-4">
              <Col xs={8}>
                <span className="text-default">Total Insured</span>
              </Col>
              <Col className="text-right" xs={4}>
                {orderData?.order_json?.presentment_currency === 'USD' ? (
                  <span className="text-default">${formatMoney(totalInsured)}</span>
                ) : (
                  <span className="text-default">
                    {totalPresentmentMoneyAmount} {currency}
                  </span>
                )}
              </Col>
            </Row>
            <hr />
            <Row className="align-items-center">
              <Col xs={8}>
                <span className="text-default">Protection Cost</span>
              </Col>
              <Col className="text-right" xs={4}>
                {orderData?.order_json?.presentment_currency === 'USD' ? (
                  <span className="text-default">${formatMoney(getProtectionCost(orderData))}</span>
                ) : (
                  <span className="text-default">
                    {formatMoney(getProtectionCost(orderData))} {currency}
                  </span>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default memo(OrderItems)
