import React, { memo, useCallback, useState } from 'react'
import _map from 'lodash/map'
import _indexOf from 'lodash/indexOf'
import _filter from 'lodash/filter'
import Checkbox from '../../../CoreUI/Checkbox'
import './styles.scss'
import moment from 'moment'
import { Col, Row } from 'react-bootstrap'
import ClaimPortalItem from '../../Core/ClaimPortalItem'
import LineItem from '../LineItem'
import RightArrow from '../../../../assets/claim-portal/right-arrow.svg'
import { useClaimPortalContext } from '../../../../context/claim-portal-context'

const ItemList = ({ item, selected, setSelected, setSelectedItem }) => {
  const [activeItem, setActiveItem] = useState(null)
  const [active, setActive] = useState(null)

  const { orderItems } = useClaimPortalContext()
  const onSelected = useCallback(
    (itemId) => {
      const isSelected = _indexOf(selected, itemId) !== -1

      if (isSelected) {
        return setSelected(_filter(selected, (item) => item !== itemId))
      }

      return setSelected([...selected, itemId])
    },
    [selected]
  )
  const activeCheckbox = (itemId) => {
    if (activeItem == null || !selected.includes(itemId)) {
      setActiveItem(itemId)
      return setSelectedItem(itemId)
    } else {
      setActiveItem(null)
      return setSelectedItem([])
    }
  }
  console.log(activeItem)
  return (
    <div className="fulfillment-list-wrapper">
      <div className="inline-container">
        <div
          className={
            activeItem != null && activeItem == item.id
              ? 'true' + ' inline-information'
              : 'false' + ' inline-information'
          }
        >
          <label className="container-checkbox test">
            <input
              name="fulfillment"
              checked={activeItem == item.id ? true : false}
              onClick={function (event) {
                activeCheckbox(item.id)
                onSelected(item.id)
              }}
              type="checkbox"
            />
            <span className="checkmark" />
          </label>
          <span className="fulfilled-items">{item.line_items.length} item(s) Fulfilled</span>
        </div>
        <div className="tracking-information">
          <span className="shipping-company">{item.tracking_company}</span>
          <div className="tracking-container">
            <span className="tracking-company">{item.tracking_numbers}</span>
          </div>
          <div className="fulfillment-status">
            Delivered on {moment(item.updated_at).format('MMM DD')}
          </div>
        </div>
      </div>
      <Row className="row-header">
        <Col className="items-header" md={2}>
          Item(s)
        </Col>
        <Col className="content-center row-hide" md={5}>
          Item Names
        </Col>
        <Col className="content-center" md={2}>
          Quantity
        </Col>
        <Col className="content-center" md={2}>
          Price
        </Col>
      </Row>

      {active ? (
        item.line_items.map((item, i, { length }) => (
          <LineItem key={item.id} item={item} lastItem={i + 1 === length ? true : false} />
        ))
      ) : (
        <LineItem item={item.line_items[0]} lastItem={true} />
      )}
      {item.line_items.length > 1 ? (
        <Row onClick={() => setActive(!active)} className="show-items">
          {active ? '- Show Less Items' : '+ Show More Items'}
        </Row>
      ) : (
        <Row className="show-items"></Row>
      )}
    </div>
  )
}

export default memo(ItemList)
