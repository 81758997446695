import React, { memo } from 'react'

import './styles.scss'

const AwesomeBadge = ({ text, icon, className }) => {
  return (
    <div className={`badge-wrapper justify-content-center ${className}`}>
      <span>{icon}</span>
      <span>{text}</span>
    </div>
  )
}

export default memo(AwesomeBadge)
