import React, { memo, useMemo } from 'react'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _findIndex from 'lodash/findIndex'
import moment from 'moment'
import { useAuth0 } from '@auth0/auth0-react'
import _cloneDeep from 'lodash/cloneDeep'
import PropTypes from 'prop-types'
import _cond from 'lodash/cond'

import TimelineLetterAvatar from '../../../../components/OrderTimeline/components/timeline-letter-avatar'
import ReadMore from '../../../../helpers/read-more'
import File from '../../../../assets/file.svg'
import './styles.scss'

const TimelineType = {
  Event: 'event',
  Message: 'message',
  ClaimCreated: 'claim_created',
  Shipping: 'shipping',
}

const getAttachmentName = (url) => {
  if (url.startsWith('http')) {
    return {
      fileName: url,
      isImage: true,
    }
  }

  const parts = url.split('/')
  const fileName = parts[parts.length - 1]
  const fileParts = fileName.split('.')
  const fileExt = fileParts[fileParts.length - 1].toLowerCase()
  const isImage = fileExt === 'png' || fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'gif'

  return {
    fileName,
    isImage,
  }
}

const TimelineEventPropTypes = PropTypes.shape({
  type: PropTypes.string,
  data: PropTypes.shape({
    sender_id: PropTypes.number,
    message: PropTypes.string,
    attachmentUrls: PropTypes.arrayOf(PropTypes.string),
    attachments: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    twilio_media: PropTypes.string,
  }),
  time: PropTypes.instanceOf(Date),
})

const ContentTimeLine = React.memo(({ content, orderData, user }) => {
  const goNewTab = (e) => {
    const targetLink = e.target.closest('a')

    if (!targetLink) return
    e.preventDefault()
    window.open(targetLink.href, '_blank')
  }

  const customerName = useMemo(() => {
    if (!content?.data?.sender_id) {
      return orderData?.customer_name
    }

    return `${content?.data?.first_name || ''} ${content?.data?.last_name || user?.name || ''}`
  }, [orderData, user, content])

  const customerAlias = useMemo(() => {
    if (!content?.data?.sender_id) {
      return orderData?.customer_name
    }

    return content?.data?.alias_name || customerName
  }, [orderData, user, content])

  switch (content.type) {
    case TimelineType.Message:
      return (
        <>
          <div
            className={`py4 mb-3 ${
              content?.data?.sender_id ? 'content-message-admin' : 'content-message-customer'
            }`}
          >
            <div className="d-flex align-items-end mb-1">
              <div className="avatar-item-line">
                <TimelineLetterAvatar name={customerAlias} />
              </div>
              <p className="text-default bold big mx-2 mb-0" title={customerName}>
                {customerAlias}
              </p>
              <p className="text-date my-0">{moment(content?.time).format('h:mm a')}</p>
            </div>
            <div className="message">
              <ReadMore text={content?.data?.message} />
            </div>
            {!_isEmpty(content?.data.twilio_media) && (
              <div className="media-container">
                {_map(content?.data.twilio_media, (media) => {
                  return JSON.parse(media).type.includes('video') ? (
                    <video controls>
                      <source src={JSON.parse(media).url} type={JSON.parse(media).type} />
                    </video>
                  ) : (
                    <a
                      className="twilio-image"
                      target="_blank"
                      href={JSON.parse(media).url}
                      rel="noreferrer"
                    >
                      <img width="100px" height="100%" src={JSON.parse(media).url}></img>
                    </a>
                  )
                })}
              </div>
            )}
          </div>
          {!_isEmpty(content?.data?.attachments) &&
            content?.data?.attachments?.map((url, index) => (
              <a
                className="file-time-line my-3"
                href={content?.data?.attachmentUrls?.[index]}
                target="_blank"
                key={index.toString()}
                rel="noreferrer"
              >
                <img className="img-default mr-2" src={File} alt="product" />
                <span className="text-default bold">{getAttachmentName(url)?.fileName}</span>
              </a>
            ))}
        </>
      )

    case TimelineType.ClaimCreated:
      // eslint-disable-next-line
      const isClaimViaStore = content?.data?.user_id !== null
      return (
        <div className="mb-3">
          <p className="text-default mb-1">
            <span className="font-weight-bold">
              {orderData?.customer_name} {isClaimViaStore === true ? '(via Store)' : ''}
            </span>
            <span> submitted a claim </span>
            <span className="font-weight-bold">{_get(orderData, 'claims[0].claim_type_name')}</span>
          </p>
          <p className="text-date">{moment(content?.time).format('h:mm a')}</p>
        </div>
      )
    default:
      return (
        <div className="mb-3">
          <p
            onClick={goNewTab}
            className="text-default mb-1"
            dangerouslySetInnerHTML={{ __html: content?.data?.message }}
          />
          <p className="text-date">{moment(content?.time).format('h:mm a')}</p>
        </div>
      )
  }
})

ContentTimeLine.propTypes = {
  content: TimelineEventPropTypes,
  user: PropTypes.shape({
    data: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      name: PropTypes.string,
      sender_id: PropTypes.string,
    }),
    name: PropTypes.string,
  }),
  orderData: PropTypes.shape({
    customer_name: PropTypes.string,
  }),
}

const TimeLine = ({ timeline, orderData, shipping, orderTimeline }) => {
  const { user } = useAuth0()

  const timelineData = useMemo(() => {
    const data = _cloneDeep(timeline)
    const claim = _get(orderData, 'claims.[0]')

    if (_isEmpty(claim) === false) {
      const readInbox = _get(claim, 'read_inbox', null)
      const read_inbox_at = _get(claim, 'read_inbox_at', null)
      const userReadingMsg = _get(claim, 'read_inbox_user_name', null)
      const eventTime = new Date(+read_inbox_at)
      const dateKey = `${eventTime.getFullYear()}-${
        eventTime.getMonth() + 1
      }-${eventTime.getDate()}`
      const currentDateKeyData = data[dateKey] || []
      const eventExisting = Object.values(data).reduce(
        (prev, items) =>
          prev.concat(items.filter((item) => new Date(item.time).getTime() === +read_inbox_at)),
        []
      )

      if (
        _isEmpty(read_inbox_at) === false &&
        _isEmpty(eventExisting) === true &&
        _isEmpty(data) === false
      ) {
        data[dateKey] = [
          ...currentDateKeyData,
          {
            time: eventTime,
            type: 'event',
            data: {
              message: _cond([
                [(value) => value === 1, () => 'The last message has been seen.'],
                [(value) => value === -1, () => `Dismissed by <b>${userReadingMsg}</b>.`],
              ])(readInbox),
            },
          },
        ]
      }
    }

    Object.keys(data).forEach((key) => {
      data[key].sort((a, b) => {
        if (
          (a.type === TimelineType.ClaimCreated && b.type === TimelineType.Message) ||
          (b.type === TimelineType.ClaimCreated && a.type === TimelineType.Message)
        ) {
          return a.type === TimelineType.ClaimCreated ? 1 : -1
        }

        if (a.type === b.type && a.type === TimelineType.Shipping) {
          return _findIndex(shipping, a) - _findIndex(shipping, b)
        }

        const dateA = a.time.getTime()
        const dateB = b.time.getTime()

        if (dateA === dateB && a.type === b.type) {
          if (a.type === TimelineType.Event) {
            return _findIndex(orderTimeline, b) - _findIndex(orderTimeline, a)
          }
        }

        return dateB - dateA
      })
    })

    return data
  }, [orderData, timeline])

  const timelineKeys = useMemo(
    () => Object.keys(timelineData).sort((a, b) => new Date(b).getTime() - new Date(a).getTime()),
    [timelineData]
  )

  return (
    <div className="time-line-claim bg-white p-10">
      <h1 className="text-2xl font-semibold">Timeline</h1>
      <div className="body-item-line">
        {timelineKeys.map((key) => (
          <div className="time-line" key={key}>
            <div className="date-time-line">
              <span className="text-default">
                {moment(key, 'YYYY-MM-DD')?.format('MM/DD/YYYY')}
              </span>
            </div>
            <div className="content-time-line pr-0">
              {_map(_get(timelineData, key, []), (item, index) => (
                <div key={index} className="wrap-content-message">
                  <ContentTimeLine content={item} user={user} orderData={orderData} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

TimeLine.propTypes = {
  orderData: PropTypes.shape({
    customer_name: PropTypes.string,
  }),
  orderTimeline: PropTypes.arrayOf(TimelineEventPropTypes),
  timeline: PropTypes.objectOf(PropTypes.arrayOf(TimelineEventPropTypes)),
  shipping: PropTypes.array,
}

export default memo(TimeLine)
