import React, { memo } from 'react'
import { Button } from 'react-bootstrap'
import './styles.scss'

const TheButton = (props) => {
  const { className, ...others } = props
  return <Button className={`ob-button ${className}`} {...others} />
}

export default memo(TheButton)
