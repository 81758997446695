import './ClaimDetails.scss'
import { Alert, Col, Row } from 'react-bootstrap'
import { useState, useMemo } from 'react'
import ClaimCustomerInformation from '../ClaimCustomerInformation/ClaimCustomerInformation'
import ClaimNextButton from '../ClaimNextButton/ClaimNextButton'
import Error from '../../Error/Error'
import { getClaimTypeFullText } from '../../../helpers/date-time-helper'

export default function ClaimDetails(props) {
  const [remainingCharacters, setRemainingCharacters] = useState(3000)
  const [enableButton, setEnableButton] = useState(false)

  const [error, setError] = useState({
    active: false,
    message: 'Default',
  })

  function handleTextareaChange(event) {
    const claimDescription = event.target.value
    const customerData = { ...props.state.customerData }
    customerData.claimDetails = claimDescription

    setRemainingCharacters(3000 - claimDescription.length)

    setEnableButton(claimDescription.length > 0 ? true : false)

    props.setState({
      ...props.state,
      customerData: customerData,
    })
  }

  // This function serves as a backup incase the user decides to manipulate the DOM
  // and remove the maxlength property set on the textarea.
  function handleClaimDescriptionSubmit() {
    if (remainingCharacters < 0) {
      setError({
        active: true,
        message: 'Exceeded number of allowed characters.',
      })
    } else {
      if (error) {
        setError({
          active: false,
          message: 'Default',
        })
      }

      props.onNextClick()
    }
  }

  const claimType = useMemo(() => {
    return props.state.customerData.claimType.claim_type_name.toLowerCase() === 'defective'
      ? 'Return to sender'
      : props.state.customerData.claimType.claim_type_name
  }, [props])

  return (
    <>
      <Row>
        <Col md={{ span: 5, offset: 3 }}>
          <div className="greeting-container my-4">
            <h1 className="font-weight-bold">{getClaimTypeFullText(claimType)}</h1>
            <h2 className="text-muted">Tell Us What Happened</h2>
            <Error error={error} setError={setError} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 5, offset: 3 }}>
          <textarea
            className="form-control"
            maxLength="3000"
            rows="5"
            id="details"
            onChange={handleTextareaChange}
          ></textarea>
          <span className="text-muted">
            <small>You have {remainingCharacters} characters remaining.</small>
          </span>
          <ClaimNextButton disabled={!enableButton} onNextClick={handleClaimDescriptionSubmit} />
        </Col>

        <Col md={2}>
          <ClaimCustomerInformation state={props.state} />
        </Col>
      </Row>
    </>
  )
}
