import { createContext, useContext } from 'react'

export const ClaimPortalContext = createContext({
  claimSubmitted: false,
  orderData: null,
  damageId: null,
  setDamageId: (_damageId) => {},
  orderItems: [],
  setOrderData: (_orderData) => {},
  orderTracking: [],
  claimTypeName: '',
  selectedClaimType: undefined,
  onSubmitItem: (_selectedItems, _selectedFiles) => {},
  variantData: [],
  fulfillments: null,
  storePlan: '',
  communicationInfo: null,
  setCommunicationInfo: (_communicationInfo) => {},
})

export const useClaimPortalContext = () => useContext(ClaimPortalContext)
