import React, { memo, useState, useCallback, useEffect, useMemo } from 'react'
import _reject from 'lodash/reject'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _reverse from 'lodash/reverse'
import _sortBy from 'lodash/sortBy'
import _isEmpty from 'lodash/isEmpty'
import { useAuth0 } from '@auth0/auth0-react'
import axios from '@/support/utils/request'
import 'react-image-lightbox/style.css'

import { postApi } from '../../../../helpers/api'
import { getClaimSumissionDate } from '../../../../helpers/claim-filter'
import { getUserId } from '../../../../helpers/order-helpers'
import Commenting from '../../../../components/Commenting'
import CommunicationCard from './CommunicationCard'
import './styles.scss'
import PermissionWrapper from '../../../../components/PermissionWrapper'
import { PermissionSet } from '../../../../configuration/permission-set'
import config from '@/config'

const proxy = config.api.url

const Communication = ({ orderData, onPostMessage }) => {
  const [macros, setMacros] = useState([])
  const [claimImages, setClaimImages] = useState([])
  const [claimMessages, setClaimMessages] = useState([])
  const [token, setToken] = useState('')

  const { isAuthenticated, getIdTokenClaims, user, getAccessTokenSilently } = useAuth0()
  const claimId = orderData?.claims_id || orderData?.claims?.[0]?.id || 0
  const claim_date = useMemo(
    () => getClaimSumissionDate(orderData) || getClaimSumissionDate(_get(orderData, 'claims[0]')),
    [orderData]
  )
  const claim_details = useMemo(
    () => _get(orderData, 'claim_details', _get(orderData, 'claims[0].claim_details', '')),
    [orderData]
  )
  const currentUserId = useMemo(() => getUserId(user), [user])
  const currentStoreId = useMemo(() => _get(orderData, 'store_id', 0), [orderData])
  const customerEmail = useMemo(
    () => _get(orderData, 'order_json.email', _get(orderData, 'order_email')),
    [orderData]
  )

  const onSaveMacro = useCallback(
    (macroData, callback) => {
      const body = {
        ...macroData,
        store_id: currentStoreId,
        user_id: currentUserId,
      }

      const candidateId = _get(macroData, 'macro_id', '')

      axios.post(`${proxy}/macro/${candidateId}`, body).then((res) => {
        const newMacro = res.data

        if (newMacro.macro_id !== candidateId) {
          setMacros([...macros, res.data])
          callback(newMacro)
        } else {
          const newList = _map(macros, (item) => {
            if (item.macro_id === newMacro.macro_id) {
              return newMacro
            }

            return item
          })
          setMacros(newList)
        }
      })
    },
    [macros]
  )

  const onDeleteMacro = useCallback(
    (macroId, onDone) => {
      axios
        .delete(`${proxy}/macro/${macroId}`)
        .then(() => {
          const newList = _reject(macros, (macro) => macro.macro_id === macroId)
          setMacros(newList)

          if (typeof onDone !== 'undefined') {
            onDone()
          }
        })
        .catch((error) => {
          console.error('Delete macro with error', error)
        })
    },
    [macros]
  )

  const listingMacros = useCallback(() => {
    axios.get(`${proxy}/macro`).then((res) => setMacros(res.data))
  }, [])

  const postMessage = (
    senderId,
    attachments,
    message,
    claimId,
    customerEmail,
    senderName,
    callback
  ) => {
    postApi(
      '/claims/message',
      {
        senderId,
        message,
        claimId,
        attachments: attachments.join('|'),
        customerEmail,
        senderName,
      },
      token
    ).then((res) => {
      onPostMessage(res.data)
      const data = {
        ...res.data,
      }

      if (_isEmpty(data.last_name) && _isEmpty(data.first_name)) {
        data.first_name = user?.given_name
        data.last_name = user?.family_name
      }

      callback(data)
    })
  }

  useEffect(() => {
    listingMacros()
  }, [])

  const fetchAllClaimsMessage = useCallback((theClaimId, userName) => {
    axios
      .get(`${proxy}/claims/message/${theClaimId}`, {
        headers: {
          'x-user-reading': userName,
        },
      })
      .then((res) => {
        setClaimMessages(res.data.messages)
      })
  }, [])

  const getClaimImage = useCallback((theClaimId) => {
    axios.get(`${proxy}/aws/claims/${theClaimId}/image`).then((res) => {
      setClaimImages(res.data)
    })
  }, [])

  useEffect(() => {
    if (Number(claimId) > 0) {
      getClaimImage(claimId)
      fetchAllClaimsMessage(claimId, user?.name)
    }
  }, [claimId, user?.name])

  useEffect(() => {
    const retrieveToken = async () => {
      let user = await getIdTokenClaims()

      if (user) {
        setToken(user.__raw)
      } else {
        await getAccessTokenSilently({ ignoreCache: true })
        user = await getIdTokenClaims()
        setToken(user.__raw)
      }
    }

    if (isAuthenticated === true && _isEmpty(token) === true) {
      retrieveToken()
    }
  }, [isAuthenticated, token, getIdTokenClaims, setToken])

  return (
    <div className="communication-container">
      <PermissionWrapper set={[PermissionSet.ClaimAction]}>
        <Commenting
          userName={user?.name}
          orderData={orderData}
          storeId={currentStoreId}
          postMessage={(message, attachments, onDonePost) =>
            postMessage(
              currentUserId,
              attachments,
              message,
              claimId,
              customerEmail,
              user?.name,
              (messageResponse) => {
                onDonePost()
                setClaimMessages([...claimMessages, messageResponse])
              }
            )
          }
          macros={macros}
          onSaveMacro={onSaveMacro}
          onDeleteMacro={onDeleteMacro}
        />
      </PermissionWrapper>
      <div className="container-messages communication-card">
        {(_reverse(_sortBy(claimMessages, 'created_at')) || []).map((message, index) => (
          <CommunicationCard key={index.toString()} message={message} orderData={orderData} />
        ))}
        <CommunicationCard
          message={{
            created_at: new Date(isNaN(claim_date) === false ? parseInt(claim_date) : claim_date),
            message: claim_details,
            attachments: claimImages,
          }}
          orderData={orderData}
        />
      </div>
    </div>
  )
}

export default memo(Communication)
