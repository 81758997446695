import classNames from 'classnames'
import React from 'react'
import { InputProps } from './types'
import { useControllable } from '../../../hooks/useControllable'
import { useStateToggle } from '../../../hooks/useStateToggle'
import { Form } from 'react-bootstrap'
export const Input = React.forwardRef(
  (
    {
      name,
      label,
      className,
      error,
      type = 'text',
      onFocus,
      onBlur,
      value: valueProp,
      onChange: onChangeProp,
      onEnterPress,
      onKeyDown,
      defaultValue,
      disabled,
      ...props
    }: InputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const [value, setValue] = useControllable({
      value: valueProp,
      onChange: onChangeProp,
      defaultValue,
    })

    const inputRef = React.useRef<HTMLInputElement>(null)

    const [, toggleFocus] = useStateToggle()

    const handleInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
      if (props.pattern && !ev.target.checkValidity()) {
        ev.target.value = ''
      }
    }

    const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
      onFocus?.(e)
      toggleFocus()
    }

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
      e.persist()
      onBlur?.(e)
      toggleFocus()
    }

    React.useImperativeHandle(ref, () => inputRef.current as HTMLInputElement)

    const handleKeydown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
      onKeyDown?.(e)

      if (e.key === 'Enter') {
        onEnterPress?.((e as any).target?.value)
      }
    }

    const renderInput = () => {
      const borderClassNames = classNames({
        'border-danger': error,
      })

      return (
        <Form.Control
          ref={inputRef}
          type={type}
          onInput={handleInput}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeydown}
          value={value}
          onChange={setValue}
          className={borderClassNames}
          disabled={disabled}
          data-testid={name}
        ></Form.Control>
      )
    }
    return (
      <Form.Group className={className}>
        {label && <Form.Label>{label}</Form.Label>}
        {renderInput()}
      </Form.Group>
    )
  }
)

Input.displayName = 'Input'
