import platforms from '../constants/platforms'

export function getPlatformType(obj) {
  if (typeof obj === 'object') return obj.platform_id
  if (typeof obj === 'string') {
    if (obj === 'shopify') return platforms.SHOPIFY
    if (obj === 'bigcommerce') return platforms.BIGCOMMERCE
    if (obj === 'magento') return platforms.MAGENTO
  }
  return obj
}

function isShopify() {
  return getPlatformType(this.valueOf()) === platforms.SHOPIFY
}

function isMagento() {
  return getPlatformType(this.valueOf()) === platforms.MAGENTO
}

function isBigCommerce() {
  return getPlatformType(this.valueOf()) === platforms.BIGCOMMERCE
}

// Object - define platform functions
const prototypes = [Object.prototype, Number.prototype, String.prototype]
prototypes.forEach((prototype) => {
  Object.defineProperty(prototype, 'isShopify', {
    enumerable: false,
    value: isShopify,
  })

  Object.defineProperty(prototype, 'isBigCommerce', {
    enumerable: false,
    value: isBigCommerce,
  })

  Object.defineProperty(prototype, 'isMagento', {
    enumerable: false,
    value: isMagento,
  })
})
