import { PlusIcon } from '@heroicons/react/20/solid'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import React, { memo, useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { Button, Col, Form, Modal, Row, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { CountryDropdown } from 'react-country-region-selector'
import { useHistory } from 'react-router-dom'
import Pen from '../../../../assets/pen.svg'
import InfoIcon from '../../../../assets/info-icon.svg'
import MultipleShippingAddresses from '../../../../components/MultipleShippingAddresses/MultipleShippingAddresses'
import OPBadge from '../../../../components/OPBadge'
import PermissionWrapper from '../../../../components/PermissionWrapper'
import { PermissionSet } from '../../../../configuration/permission-set'
import { EXPIRED_AFTER_30_DAYS, OP_SERVICES } from '../../../../constants/admin-order-detail'
import { useAdminContext } from '../../../../context/admin-context'
import { getApi, putApi } from '../../../../helpers/api'

import {
  getResolutionType,
  isProtectedOrders,
  oneTimeAppeasementOrderPage,
} from '../../../../helpers/claim-filter'
import { getArrayFromJSONString } from '../../../../helpers/data-transformer'
import ResolutionCard from './resolution-card'
import './styles.scss'

const FraudAnalyticModal = ({ data, show, onHide }) => (
  <Modal className="fraud-analysis-modal" animation={false} show={show} onHide={() => onHide()}>
    <Modal.Header closeButton>Fraud Analysis</Modal.Header>
    <Modal.Body>
      <div className="d-flex flex-column">
        <div className="level-bar">
          <div className="level">
            <div
              className={`text ${(data?.riskLevel || 'none').toLowerCase() === 'low' ? 'low' : ''}`}
            >
              Low
            </div>
            <div
              className={`text ${
                (data?.riskLevel || 'none').toLowerCase() === 'medium' ? 'medium' : ''
              }`}
            >
              Medium
            </div>
            <div
              className={`text ${
                (data?.riskLevel || 'none').toLowerCase() === 'high' ? 'high' : ''
              }`}
            >
              High
            </div>
          </div>
          <div className="bar">
            <div className={`progress ${(data?.riskLevel || 'none').toLowerCase()}`} />
          </div>
        </div>
        <div className="body-text">Indicators</div>
        {(data?.risks || [])
          .filter((item) => item.display === true)
          .map((item, index) => (
            <div className="item-line" key={`message-id-${index}`}>
              {item.message}
            </div>
          ))}
      </div>
    </Modal.Body>
  </Modal>
)

const OrderInfo = ({
  orderData,
  setOrderData,
  cancelled,
  fulFillmentDetails,
  orderDelivered,
  setShowCancelModal,
  setShowAddModal,
  isLoading,
  reshipOrderLoading,
}) => {
  const isProtectedOrder = useMemo(() => isProtectedOrders(orderData) === true, [orderData])
  const [shouldShowCancelButton, setShouldShowCancelButton] = useState(false)
  const [shouldShowAddButton, setShouldShowAddButton] = useState(false)
  const [magentoRefundable, setMagentoRefundable] = useState(false)

  async function isRefundable(orderData) {
    try {
      const res = await getApi(
        `/magento-proxy/${orderData?.order?.store_id}/orders/${orderData?.order?.order_id}/is-order-refundable`
      )

      return res.data
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const orderIsReady = useMemo(() => orderData?.order?.id, [orderData])

  useEffect(() => {
    const fetchRefundable = async () => {
      const isMagentoOrder = orderData?.order?.isMagento()

      if (isMagentoOrder) {
        const refundable = await isRefundable(orderData)
        setMagentoRefundable(refundable)
      }
    }

    fetchRefundable()
  }, [orderIsReady])

  useEffect(() => {
    async function checkRefundability() {
      const isMagentoOrder = orderData?.order?.isMagento()
      const isProtectedOrder = isProtectedOrders(orderData) === true
      const alreadyCancelled = orderData?.op_cancelled === true
      const hasClaim = orderData.claims?.length || 0 > 0

      const shouldShow =
        alreadyCancelled === false &&
        cancelled === false &&
        isProtectedOrder === true &&
        orderDelivered === false &&
        hasClaim === false &&
        ((isMagentoOrder && magentoRefundable === true) || !isMagentoOrder)

      const shouldShowAdd =
        cancelled === false &&
        isProtectedOrder === false &&
        orderDelivered === false &&
        hasClaim === false &&
        ((isMagentoOrder && magentoRefundable === true) || !isMagentoOrder)

      setShouldShowCancelButton(shouldShow)
      setShouldShowAddButton(shouldShowAdd)
    }
    checkRefundability()
  }, [orderData, cancelled, orderDelivered, magentoRefundable])

  const [modalShow, setModalShow] = useState(false)
  const [shippingForm, setShippingForm] = useState({})

  const [currentOrder, setCurrentOrder] = useState(orderData)
  const [showAnalysisModal, setShowAnalysisModal] = useState(false)
  const [showMultipleShippingModal, setShowMultipleShippingModal] = useState(false)
  const { freeClaims, userPermission } = useAdminContext()
  const history = useHistory()

  const resolutionCardData = useCallback(
    (orderData) => {
      const claim = _get(orderData, 'claims[0]')

      if (_isEmpty(claim)) {
        return {}
      }

      const customerRequested = _get(claim, 'reimbursement_type', 1) === 1 ? 'Refund' : 'Reship'
      const claimStatus = _get(claim, 'claim_status_id', 1)
      const resolutionType = getResolutionType(claim)
      let resolutionTime = _get(claim, 'resolution_date')
      let actionBy = _get(claim, 'resolutionData.actioned_by', '')

      if (resolutionType === EXPIRED_AFTER_30_DAYS) {
        actionBy = _get(claim, 'resolutionData.actioned_by', OP_SERVICES)
      }

      if (_isEmpty(resolutionTime)) {
        const claimDate = new Date(_get(claim, 'claim_date'))

        if (resolutionType === EXPIRED_AFTER_30_DAYS) {
          resolutionTime = new Date(claimDate.getTime() + 30 * 24 * 60 * 60 * 1000)
        } else {
          resolutionTime = claimDate
        }
      }

      const orderIdFromOP = _get(claim, 'reshipData.store_order_id')
      const orderIdFromPlatform = _get(claim, 'reshipData.reship_order_id')
      const creditStore = _get(claim, 'resolutionData.creditStore')
      const creditCustom = _get(claim, 'resolutionData.creditCustomer')
      const includeShipping = _get(claim, 'resolutionData.includeShipping')

      return {
        resolutionType,
        customerRequested,
        claimStatus,
        resolutionTime,
        actionBy,
        orderIdFromOP,
        orderIdFromPlatform,
        creditStore,
        creditCustom,
        includeShipping,
        reshipOrderLoading,
      }
    },
    [orderData, reshipOrderLoading]
  )

  useEffect(() => {
    setCurrentOrder(orderData)
  }, [orderData, setCurrentOrder])

  const onChangeShipping = useCallback(
    (e) => {
      setShippingForm((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }))
    },
    [setShippingForm]
  )

  const saveAddress = useCallback(() => {
    const { id } = orderData

    putApi(`/orders/${id}/update-claim-address`, shippingForm)
      .then((res) => {
        const { shipping_address } = res.data
        setModalShow(false)
        setCurrentOrder((current) => ({
          ...current,
          updatedReshipAddress: shipping_address,
        }))
      })
      .catch((err) => {
        console.error(err)
        alert('Invalid shipping address')
      })
  }, [shippingForm, setModalShow, orderData, setCurrentOrder])

  const viewMultipleShippingAddresses = () => {
    setShowMultipleShippingModal(true)
  }
  const globaleId = _find(_get(orderData, 'order_json.note_attributes', []), ['name', 'GEOrderId'])
  const opUser = userPermission?.role.includes('admin', 'sales', 'concierge')

  const [isOrderProtectable, setIsOrderProtectable] = useState(null)

  async function checkOrderProtectionLimit(orderData) {
    try {
      const response = await getApi(`/is-order-in-protection-limit/${orderData.order.id}`)
      setIsOrderProtectable(response.data.isOrderInProtectionLimit)
    } catch (error) {
      console.error('Error checking if order is protectable:', error)
      setIsOrderProtectable(false) // Assume not protectable if there's an error
    }
  }

  const prevOrderIdRef = useRef()

  useEffect(() => {
    const currentOrderId = orderData?.order?.id
    if (currentOrderId && prevOrderIdRef.current !== currentOrderId) {
      prevOrderIdRef.current = currentOrderId
      checkOrderProtectionLimit(orderData)
    }
  }, [orderData])

  return (
    <div className="card-shadow rounded p-10 text-sm">
      <span className="font-semibold text-2xl mb-4 block">Details</span>
      <Row>
        <Col md={6} lg={4} className="mb-4">
          <p className="heading-default">Order Via</p>
          <div className="store-info">
            <div className="store-name leading-6">{orderData.store_name}</div>
            {_isEmpty(orderData.platform_order_url) === false && (
              <a
                target="_blank"
                className="store-link leading-6"
                rel="noreferrer"
                href={orderData.platform_order_url}
              >
                View order in {orderData.platform_name}
              </a>
            )}
          </div>
          {!_isEmpty(globaleId) && (
            <a
              target="_blank"
              className="store-link leading-6"
              rel="noreferrer"
              href={`https://web.global-e.com/GlobaleAdmin/Orders/Order?orderId=${globaleId.value}`}
            >
              View order in Global-e
            </a>
          )}
        </Col>
        <Col md={6} lg={4} className="mb-4">
          <p className="heading-default">OP Status</p>
          <div className="d-flex flex-row py-1">
            <div className="protection-group-wrapper">
              <OPBadge
                className
                number={orderData?.protection_order_id}
                isProtected={isProtectedOrder}
                oneTimeAppeasement={oneTimeAppeasementOrderPage(orderData, freeClaims) === true}
              />
            </div>

            <PermissionWrapper set={[PermissionSet.CancelProtection]}>
              {shouldShowCancelButton === true && (
                <button
                  disabled={isLoading}
                  className="ml-2 btn-cancel-protection"
                  onClick={() => setShowCancelModal(true)}
                >
                  <span className="btn-icon icon-close" />
                  <span className="cancel-text">Cancel Protection</span>
                </button>
              )}
              {shouldShowAddButton === true && (
                <button
                  disabled={!isOrderProtectable || !_isEmpty(fulFillmentDetails)}
                  className={`ml-2 btn-${isOrderProtectable ? 'add' : 'exceeds'}-protection`}
                  onClick={() => (isOrderProtectable ? setShowAddModal(true) : {})}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip">
                        {isOrderProtectable
                          ? `You can only ${
                              opUser ? 'add protection' : 'buy protection'
                            } until one or more of the items on the order is fulfilled.`
                          : `This order cannot be protected as it exceeds the protectable limit. Please contact your CSM to discuss this further.`}
                      </Tooltip>
                    }
                  >
                    <span
                      className={`btn-${isOrderProtectable ? 'add' : 'exceeds'}-protection`}
                      style={{ display: 'inline-flex', alignItems: 'center' }}
                    >
                      {!isOrderProtectable || !_isEmpty(fulFillmentDetails)
                        ? 'Ineligible'
                        : `${opUser ? 'Add Protection' : 'Buy Protection'}`}
                      <img src={InfoIcon} style={{ marginLeft: '5px' }} />
                    </span>
                  </OverlayTrigger>
                </button>
              )}
            </PermissionWrapper>
          </div>
          <p className="text-default">Protection Order ID: OP{orderData?.protection_order_id}</p>
        </Col>
        <Col md={6} lg={4} className="mb-4">
          <div>
            <p className="heading-default">Resolution</p>
            {_get(orderData, 'claims[0]', null) && (
              <ResolutionCard {...resolutionCardData(orderData)} />
            )}
          </div>
        </Col>

        <Col md={6} lg={4}>
          <p className="heading-default">Customer Information</p>
          <p
            {...((userPermission?.permission || []).includes(PermissionSet.ViewCustomerOrders) && {
              onClick: () =>
                orderData?.order_email && history.push(`/profile/${orderData?.order_email}`),
              className: 'text-link pointer my-0',
            })}
          >{`${orderData?.customer_name || ''}`}</p>
          <p className="text-default my-0">{currentOrder.shippingPhoneNumber}</p>
          <a className="text-default text-link" href={`mailto:${currentOrder.shippingEmail}`}>
            {currentOrder.shippingEmail}
          </a>
          <p className="text-default mt-1">
            Fraud Analysis:
            <span
              onClick={() => setShowAnalysisModal(true)}
              className="ml-1 text-link text-capitalize"
            >
              {(orderData?.risks?.riskLevel || 'N/A').toLowerCase()}
            </span>
          </p>
        </Col>
        <Col md={6} lg={4} className="mb-4">
          <div className="group-heading-shipping my-0">
            <p className="heading-default">Shipping Address </p>
            {((!orderData.storeData?.isBigCommerce() &&
              !!_get(
                currentOrder,
                'order_json.shipping_address.name',
                _get(orderData, 'customer_name')
              ) &&
              currentOrder?.claims?.length) ||
              (orderData.storeData?.isBigCommerce() &&
                currentOrder.hasSingleShippingAddress &&
                currentOrder.hasClaim)) && (
              <div className="icon-pen" onClick={() => setModalShow(true)}>
                <img src={Pen} />
              </div>
            )}
          </div>
          <p className="text-default mb-0">
            {_get(
              currentOrder,
              'order_json.shipping_address.name',
              _get(orderData, 'customer_name', '')
            )}
          </p>
          <p className="text-default my-0">{currentOrder.shippingAddress}</p>
          {currentOrder?.claims && orderData?.claims[0]?.updated_address?.length > 0 ? (
            <p className="heading-default">Updated Reship Address</p>
          ) : (
            ''
          )}
          <p className="text-default my-0">{currentOrder.updatedReshipAddress}</p>
          <p className="text-default my-0">{currentOrder.shippingPhoneNumber}</p>
          {currentOrder.hasMultipleShippingAddresses && (
            <button className="text-button" onClick={viewMultipleShippingAddresses}>
              View More Shipping Addresses
            </button>
          )}
        </Col>
        <Col md={6} lg={4} className="mb-4">
          <p className="heading-default">Billing Address</p>
          <p className="text-default my-0">{orderData?.billing_name || ''}</p>
          <p className="text-default my-0">{orderData?.billing_address || ''}</p>
          <p className="text-default my-0">{orderData?.billing_phone_number || ''}</p>
        </Col>
      </Row>
      <Modal
        className="shipping-form-modal"
        show={modalShow}
        onHide={() => setModalShow(false)}
        animation={false}
      >
        <Modal.Body>
          <Form>
            <p className="title-modal">Edit Updated Shipping Address</p>
            <Form.Group controlId="formShopifyStoreName">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address1"
                value={shippingForm?.address1}
                onChange={onChangeShipping}
              />
            </Form.Group>
            <Row>
              <Col sm={6}>
                <Form.Group controlId="formShopifyStoreName">
                  <Form.Label>Apartment, Suite etc (Optional)</Form.Label>
                  <Form.Control
                    type="text"
                    name="address2"
                    value={shippingForm?.address2}
                    onChange={onChangeShipping}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="formShopifyStoreName">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={shippingForm?.city}
                    onChange={onChangeShipping}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={4}>
                <Form.Group controlId="formShopifyStoreName">
                  <Form.Label>Country/Region</Form.Label>
                  <CountryDropdown
                    className="country-input-dropdown"
                    value={shippingForm?.country}
                    onChange={(value) => onChangeShipping({ target: { name: 'country', value } })}
                  />
                </Form.Group>
              </Col>
              <Col sm={6} md={4}>
                <Form.Group controlId="formShopifyStoreName">
                  <Form.Label>State/Province</Form.Label>
                  <Form.Control
                    type="text"
                    name="province"
                    value={shippingForm?.province}
                    onChange={onChangeShipping}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={4}>
                <Form.Group controlId="formShopifyStoreName">
                  <Form.Label>Postal Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="zip"
                    value={shippingForm?.zip}
                    onChange={onChangeShipping}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="group-btn">
              <Button variant="primary" onClick={saveAddress} disabled={!shippingForm?.address1}>
                Save Address
              </Button>
              <btn className="btn-cancel" onClick={() => setModalShow(false)}>
                Cancel
              </btn>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <FraudAnalyticModal
        show={showAnalysisModal}
        data={orderData?.risks}
        onHide={() => setShowAnalysisModal(false)}
      />
      <MultipleShippingAddresses
        modalShow={showMultipleShippingModal}
        setModalShow={setShowMultipleShippingModal}
        shippingAddresses={getArrayFromJSONString(currentOrder.shipping_address)}
        shippingCustomerNames={getArrayFromJSONString(currentOrder.shipping_customer_name)}
        shippingPhoneNumbers={getArrayFromJSONString(currentOrder.phone_number)}
        shippingEmails={getArrayFromJSONString(currentOrder.order_email)}
      />
    </div>
  )
}

export default memo(OrderInfo)
