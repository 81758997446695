/* eslint-disable react/prop-types */
import React, { memo, useCallback } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import './styles.scss'
import { convertToNumber, formatDatetime } from '../../../../../helpers/date-time-helper'
import { Spinner } from 'react-bootstrap'

const ResolutionCard = ({
  claimStatus = 1,
  resolutionType,
  customerRequested,
  resolutionTime,
  actionBy,
  orderIdFromOP,
  orderIdFromPlatform,
  creditStore,
  includeShipping,
  reshipOrderLoading,
}) => {
  const validUnixTime = convertToNumber(resolutionTime)
  const desiredFormat = formatDatetime(validUnixTime)
  const isCreditStore = creditStore ? 'Yes' : 'No'
  const isIncludeShipping = includeShipping ? 'Yes' : 'No'
  const resolutionMapping = {
    2: 'Approved',
    3: 'Denied',
    5: 'Closed',
  }

  const ReshipLink = ({ orderId, protectedId }) => (
    <div className="resolution-card-row">
      <p className="card-title">Reshipped Order</p>
      <Link to={`/orders/${protectedId}`}>#{orderId}</Link>
    </div>
  )

  const renderAdditionalInfo = useCallback(
    (resolutionType) => {
      if (claimStatus === 2) {
        if (reshipOrderLoading) {
          return (
            <>
              <div className="line"></div>
              <div className="card-resolution-loading">
                <Spinner size="sm" animation="grow" />
              </div>
            </>
          )
        }

        if (resolutionType === 'Reship') {
          return (
            <>
              <div className="line"></div>
              <div className="resolution-card-row">
                <p className="card-title">Credit Store:</p>
                <p className="card-content">{isCreditStore}</p>
              </div>
              <div className="line"></div>
              <ReshipLink orderId={orderIdFromOP} protectedId={orderIdFromPlatform} />
            </>
          )
        } else if (resolutionType === 'Refund') {
          return (
            <>
              <div className="line"></div>
              <div className="resolution-card-row">
                <p className="card-title">Include Shipping:</p>
                <p className="card-content">{isIncludeShipping}</p>
              </div>
              <div className="resolution-card-row">
                <p className="card-title">Credit Store:</p>
                <p className="card-content">{isCreditStore}</p>
              </div>
            </>
          )
        } else {
          return (
            <>
              <div className="line"></div>
              <div className="resolution-card-row">
                <p className="card-title">Include Shipping:</p>
                <p className="card-content">{isIncludeShipping}</p>
              </div>
              <div className="resolution-card-row">
                <p className="card-title">Credit Store:</p>
                <p className="card-content">{isCreditStore}</p>
              </div>
              <div className="line"></div>
              <ReshipLink orderId={orderIdFromOP} protectedId={orderIdFromPlatform} />
            </>
          )
        }
      }
    },
    [resolutionType, orderIdFromOP, orderIdFromPlatform, reshipOrderLoading]
  )

  return (
    <div>
      <div className="resolution-card-row">
        <p className="card-title">Customer Requested:</p>
        <p className="card-content">{customerRequested}</p>
      </div>
      {
        //only accept approved & denied claims
        [2, 3, 5].includes(claimStatus) && (
          <>
            <div className="line"></div>
            <div className="resolution-card-row">
              <p className="card-title">Resolution:</p>
              <p className="card-content">{resolutionMapping[claimStatus]}</p>
            </div>
            <div className="resolution-card-row">
              <p className="card-title">Resolution Type:</p>
              <p className="card-content">{resolutionType}</p>
            </div>
            <div className="resolution-card-row">
              <p className="card-title">Actioned By:</p>
              <p className="card-content">{actionBy}</p>
            </div>
            <div className="resolution-card-row">
              <p className="card-title">Resolution Date:</p>
              <p className="card-content">{desiredFormat}</p>
            </div>
            {renderAdditionalInfo(resolutionType)}
          </>
        )
      }
    </div>
  )
}

ResolutionCard.propTypes = {
  claimStatus: PropTypes.number,
  resolutionType: PropTypes.string,
  customerRequested: PropTypes.string,
  resolutionTime: PropTypes.string,
  actionBy: PropTypes.string,
  orderIdFromOP: PropTypes.string,
  orderIdFromPlatform: PropTypes.string,
  creditStore: PropTypes.bool,
  includeShipping: PropTypes.bool,
}

export default memo(ResolutionCard)
