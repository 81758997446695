import React, { memo } from 'react'
import LetteredAvatar from 'react-lettered-avatar'

const TimeLineLetterAvatar = ({ name }) => (
  <LetteredAvatar
    name={name}
    size={50}
    backgroundColors={['#2ecc71', '#3498db', '#8e44ad', '#e67e22', '#e74c3c', '#1abc9c', '#2c3e50']}
  />
)

export default memo(TimeLineLetterAvatar)
