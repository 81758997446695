import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Card, Spinner } from 'react-bootstrap'
import _debounce from 'lodash/debounce'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import moment from 'moment'
import { Link, useHistory } from 'react-router-dom'

import { useAdminContext } from '../../context/admin-context'
import { postApi } from '../../helpers/api'
import IconOrder from '../../assets/claim-portal/icon-order.svg'

import './styles.scss'
import { useOnClickOutside } from '../../helpers/component-hooks'

const ResultItem = ({ item, hideResultList, storeName, closeMenu }) => {
  const history = useHistory()

  return (
    <Card
      className="result-item d-flex flex-row align-items-center"
      onClick={() => {
        history.push(`/orders/${item.protection_order_id}`)
        hideResultList()
        closeMenu && closeMenu()
      }}
    >
      <div className="image">
        <img src={IconOrder} alt="order" />
      </div>
      <div className="item-data">
        <Card.Title className="font-weight-bold">
          <Link to={`/orders/${item.protection_order_id}`}>
            Order #{item.isMagento() ? item.display_order_id : item.store_order_id} ({storeName})
          </Link>
        </Card.Title>
        <Card.Subtitle>
          Placed by {item.customer_name} on {moment(item.order_date).format('DD MMMM [at] HH:mm A')}
        </Card.Subtitle>
      </div>
    </Card>
  )
}

const SearchBox = ({ type, stores, getStoreName, closeMenu }) => {
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [results, setResults] = useState([])
  const [showResultBox, setShowResultBox] = useState(false)
  const { selectedStoreId, token } = useAdminContext()
  const resultWrapper = useRef()

  const triggerSearch = useCallback(
    _debounce((keyword) => {
      if (_isEmpty(keyword) === false) {
        setLoading(true)
        postApi(
          '/admin/search-order',
          {
            term: keyword.replace(/#/g, '').trim(),
            storeId: selectedStoreId,
          },
          token
        )
          .then((res) => {
            setResults(res.data)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }, 1000),
    [selectedStoreId, token]
  )

  useEffect(() => {
    triggerSearch(keyword)

    return triggerSearch.cancel
  }, [keyword])

  useEffect(() => {
    setShowResultBox(_isEmpty(keyword) === false && _isEmpty(results) === false)
  }, [keyword, results])

  useOnClickOutside(resultWrapper, () => setShowResultBox(false))

  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflowY = showResultBox ? 'hidden' : 'auto'
  }, [showResultBox])

  return (
    <div className={`search-box-wrapper ${type === 'sidebar' ? 'search-box-sidebar' : ''}`}>
      <input
        className="search-input"
        placeholder="Search"
        onChange={(event) => setKeyword(event.target.value)}
      />
      {showResultBox && (
        <div className="result-screen" ref={resultWrapper}>
          <h5 className="result-title">Store Results</h5>
          <div className="results-wrapper">
            {loading ? (
              <div className="wrap-loading">
                <Spinner animation="border" variant="success" />
              </div>
            ) : (
              _map(results, (item, index) => (
                <ResultItem
                  item={item}
                  key={index}
                  storeName={getStoreName(
                    stores?.find((row) => row.id === item.store_id)?.store_url
                  )}
                  hideResultList={() => setShowResultBox(false)}
                  closeMenu={closeMenu}
                />
              ))
            )}
          </div>
          <div className="result-footer d-flex justify-content-between">
            <span>Searching ID with ‘{keyword}’</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(SearchBox)
