import React, { memo, useCallback, useMemo, useState } from 'react'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _find from 'lodash/find'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import { useClaimPortalContext } from '../../../context/claim-portal-context'
import ClaimOrderDetails from '../Message/ClaimOrderDetails'
import './styles.scss'
import { ClaimTypes } from '../configuration'
import ItemList from './ItemList'
import ImageSelection from './ImageSelection'
import { useHistory } from 'react-router'
import ButtonUI from '../../CoreUI/ButtonUI'
import LinkButton from '../../CoreUI/LinkButton'
import { Spinner } from 'react-bootstrap'
import { ImageUpload } from '../Core/ImageUpload'

const SelectItems = ({ selectedFiles, setSelectedFiles }) => {
  const { orderData, orderItems, orderTracking, selectedClaimType, onSubmitItem } =
    useClaimPortalContext()
  const isRequired = useMemo(
    () => _get(selectedClaimType, 'require_image', false) === true,
    [selectedClaimType]
  )
  const [selectedItems, setSelectedItems] = useState(
    _map(
      _filter(orderItems, (item) => item.is_checked === true),
      'id'
    )
  )
  const history = useHistory()
  const [width, setWidth] = useState(window.innerWidth)
  const [isLoading, setIsLoading] = useState(false)

  const onNext = useCallback(() => {
    setIsLoading(true)
    onSubmitItem(selectedItems)
    history.push('/submitClaim/resolution')
    setIsLoading(false)
  }, [selectedItems])

  const config = useMemo(() => {
    const id = _get(selectedClaimType, 'id', -1)

    switch (id) {
      case 5:
        return ClaimTypes.WrongItem
      case 2:
        return ClaimTypes.DamagedItem
      case 3:
        return ClaimTypes.DefectiveItem
      case 6:
        return ClaimTypes.MissingItem
      default:
        return ClaimTypes.WrongItem
    }
  }, [selectedClaimType])

  return (
    <div className="select-item-wrapper">
      <div className="container-select-item-wrapper">
        <div className="inner">
          <div className="inner-column left">
            <label className="title">{config.text}</label>
            <label className="sub-text">{config.descrption}</label>
            {width && width < 500 && (
              <>
                <div className="inner-column right">
                  <ClaimOrderDetails
                    orderItems={orderItems}
                    orderData={orderData}
                    orderTracking={orderTracking}
                    isError={false}
                    hideItemList={true}
                  />
                </div>
              </>
            )}
            <label className="sub-text">{config.question}</label>
            <ItemList items={orderItems} selected={selectedItems} setSelected={setSelectedItems} />
            {selectedClaimType?.id == 5 && (
              <>
                <div className="upload-area">
                  <label className="question mb-4">
                    <span>{config.uploadMessage}</span>
                    {isRequired === true && <span className="require-text">* (required)</span>}
                  </label>
                  <ImageUpload onChange={setSelectedFiles} />
                </div>
              </>
            )}

            {isLoading === true ? (
              <Spinner size="sm" className="ml-2" animation="border" variant="light" />
            ) : (
              <ButtonUI
                disabled={
                  selectedItems.length === 0 || (isRequired === true && selectedFiles.length === 0)
                }
                onClick={() => onNext()}
              >
                Continue
              </ButtonUI>
            )}
            <LinkButton className="" onClick={() => history.push('/submitClaim')}>
              Back
            </LinkButton>
          </div>
          {width && width > 500 && (
            <>
              <div className="inner-column right">
                <ClaimOrderDetails
                  orderItems={orderItems}
                  orderData={orderData}
                  orderTracking={orderTracking}
                  isError={false}
                  hideItemList={true}
                />
              </div>
            </>
          )}
        </div>
        <div className="block-bottom">
          <div className="d-flex flex-row align-items-center"></div>
        </div>
      </div>
    </div>
  )
}

export default memo(SelectItems)
