import { useState, useEffect } from 'react'
import axios from '@/support/utils/request'
import { useHistory } from 'react-router-dom'
import config from '@/config'
axios.defaults.withCredentials = true

export function useAppData() {
  const [state, setState] = useState({
    userData: {},
    loading: false,
    session: null,
    connectedStores: [],
    selectedStore: null,
    customerData: {},
    claimTypes: [],
    reviewedOrder: null,
    reviewedOrderItems: null,
    reviewedOrderClaimItems: null,
    acceptedClaimItems: null,
    paymentData: null,
    notificationMessage: null,
    realOrderItems: null,
    filter: null,
  })

  const proxy = config.api.url

  useEffect(() => {
    async function checkView() {
      if (window.location.pathname === '/orderReview') {
        await getReviewedOrderData()
      }
    }
    checkView()
  }, [])

  useEffect(() => {
    getSessionData()
    getAuthorizeNetData()
  }, [state.session])

  useEffect(() => {
    if (state.selectedStore) {
      //Disabling this function for now to prevent load times
      getAcceptedClaimItems()
    }
  }, [state.selectedStore])

  const getAcceptedClaimItems = async function () {
    if (state.selectedStore) {
      const acceptedClaimItemsData = await axios.get(
        `${proxy}/stores/${state.selectedStore.id}/items/claim/accepted`
      )
      if (acceptedClaimItemsData.data.length) {
        setState({
          ...state,
          acceptedClaimItems: acceptedClaimItemsData.data,
        })
      } else {
        setState({
          ...state,
          acceptedClaimItems: null,
        })
      }
    }
  }

  const getReviewedOrderData = async function () {
    //Get all the items from the db and set it to state
    if (state.reviewedOrder) {
      const orderItems = await axios.get(
        `${proxy}/stores/orders/${state.reviewedOrder.protection_order_id}/items`
      )

      for (var i = 0; i < orderItems.data.length; i++) {
        const data = {
          storeID: state.reviewedOrder.store_id,
          variantID: orderItems.data[i].variant_id,
        }

        const variantInfo = await axios.post(
          `${proxy}/shopify/products/variants/${orderItems.data[i].variant_id}`,
          data
        )

        orderItems.data[i].variantInfo = variantInfo

        //Prevents "Default Title" to be shown as title information for items without a title
        if (variantInfo.data.variant.title == 'Default Title') {
          variantInfo.data.variant.title = null
        }
      }

      setState({
        ...state,
        loading: false,
        reviewedOrderItems: orderItems.data,
      })
    }
  }

  /*
  // Custom router redirecting users to appropriate view based on URL
  const checkAccessedUrl = function () {
    const pathname = window.location.pathname;

    if (pathname === "/") {
      // Detect whether the current domain is claims.orderprotection.com
      var domainParts = window.location.host.split(".");
      var isClaimsSubdomain = domainParts[0] === "claims";

      return isClaimsSubdomain ? handleHistory('/claims') : null;
    }
  };
  */

  const setOrderReviewData = async function (order) {
    setState({
      ...state,
      customerData: order,
    })
    console.log(state)
  }

  const changeView = (viewType) => {
    setState({
      ...state,
      loading: true,
    })
    setTimeout(() => {
      setState({
        ...state,
        view: viewType,
        loading: false,
      })
    }, 500)
  }

  const history = useHistory()
  const handleHistory = (url) => {
    history.push(url)
  }

  const changeSelectedStore = (store) => {
    console.log(store)
    axios.post(`${proxy}/claims/storeDetails/response`, store).then((res) => {
      console.log(res)
      store.plan = res.data.shop.plan_name
    })
    setState({
      ...state,
      selectedStore: store,
    })
    handleHistory('/orders')
  }

  const formatMoney = function (dollarsInCents) {
    const amount = Number(dollarsInCents / 100).toFixed(2)
    let formatted = Number(amount).toLocaleString()
    if (formatted.includes('.')) {
      if (formatted.split('.')[1].length === 1) {
        return formatted + '0'
      } else {
        return formatted
      }
    } else {
      return formatted + '.00'
    }
  }

  const checkUserSession = async (redirectUrl) => {
    try {
      const response = await axios.get(`${proxy}/sessionCheck`)

      if (response.data.length > 0 || Object.keys(response.data).length > 0) {
        setState((prevState) => {
          handleHistory('/')
          return {
            ...prevState,
            userData: response.data,
            session: response.data.id,
            redirectUrl,
          }
        })
      } else {
        const path = window.location.pathname

        if (path != '/onboarding' && path !== '/submitClaim' && path !== '/options') {
          handleHistory('/login')
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleLogout = () => {
    return axios.post(`${proxy}/logout`).then(() => {
      setState((prevState) => ({
        ...prevState,
        userData: [],
        session: 0,
      }))
      handleHistory('/login')
    })
  }

  const getAuthorizeNetData = async () => {
    if (state.session) {
      const authorizeNetData = await axios.post(`${proxy}/authorizeNet/profile/get`, {
        merchantID: state.session,
      })

      setState((prevState) => ({
        ...prevState,
        paymentData: authorizeNetData.data.creditCard ? authorizeNetData.data : null,
      }))
    }
  }

  const getSessionData = async () => {
    try {
      if (state.session) {
        const userData = await axios.get(`${proxy}/users/${state.session}`)

        var userStoresData = {}
        var managedConcierge = { data: null }

        if (userData.data && userData.data.user_type === 'admin') {
          userStoresData = await axios.get(`${proxy}/stores/admin/${userData.data.id}`)
          managedConcierge = await axios.get(`${proxy}/merchants/admin/${userData.data.id}`)
        } else if (userData.data && userData.data.user_type === 'concierge') {
          userStoresData = await axios.get(`${proxy}/stores/admin/${userData.data.id}`)
        } else {
          userStoresData = await axios.get(`${proxy}/stores/merchants/${userData.data.id}`)
        }
        const customerSupportStores = await axios.get(
          `${proxy}/stores/customerSupport/${state.session}`
        )

        if (userStoresData.data && userStoresData.data.length > 0) {
          console.log(userStoresData.data[0], 'set default selected store')
        }

        for (let i = 0; i < customerSupportStores.data.length; i++) {
          let currentStore = customerSupportStores.data[i]
          const currentStoreOrdersData = await axios.get(
            `${proxy}/stores/${currentStore.id}/orders`
          )
          customerSupportStores.data[i].orders = currentStoreOrdersData.data
          const currentStoreClaims = await axios.get(`${proxy}/stores/${currentStore.id}/claims`)
          customerSupportStores.data[i].claims = currentStoreClaims.data
          customerSupportStores.data[i].reshippedOrders = getReshippedOrders(
            currentStoreOrdersData.data
          )
        }
        const selectedStore = localStorage.getItem('selectedStore')
          ? JSON.parse(localStorage.getItem('selectedStore'))
          : 0
        setState((prevState) => ({
          ...prevState,
          connectedStores: userStoresData.data,
          selectedStore: userStoresData.data.length ? userStoresData.data[selectedStore] : null,
          customerSupportStores: customerSupportStores.data,
          managedConcierge: managedConcierge.data,
        }))

        axios
          .post(`${proxy}/claims/storeDetails/response`, userStoresData.data[selectedStore])
          .then((res) => {
            console.log(res)
            userStoresData.data[selectedStore].plan = res.data.shop.plan_name
            setState((prevState) => ({
              ...prevState,
              selectedStore: res.data.shop ? userStoresData.data[selectedStore] : null,
            }))
          })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getReshippedOrders = (orders) => {
    const reshippedOrders = []
    for (let i = 0; i < orders.length; i++) {
      if (orders[i].reship_status) {
        reshippedOrders.push(orders[i])
      }
    }

    return reshippedOrders
  }

  return {
    state,
    setState,
    changeView,
    changeSelectedStore,
    checkUserSession,
    handleLogout,
    handleHistory,
    formatMoney,
    setOrderReviewData,
    getReviewedOrderData,
  }
}
