import React, { memo, useEffect, useMemo, useState, useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _compact from 'lodash/compact'
import _groupBy from 'lodash/groupBy'
import _find from 'lodash/find'
import _sum from 'lodash/sum'
import _forEach from 'lodash/forEach'
import { getApi, postApi } from '../../helpers/api'
import { Tab, Tabs, Spinner, Alert } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import ClaimInfo from './tabs/claim-info'
import {
  getApprovalClaimStatus,
  getClaimSumissionDateForTimeline,
  isCancelledOrder,
} from '../../helpers/claim-filter'
import { useAdminContext } from '../../context/admin-context'
import { useAuth0 } from '@auth0/auth0-react'
import _filter from 'lodash/filter'
import moment from 'moment'
import _flatten from 'lodash/flatten'
import _includes from 'lodash/includes'
import _sumBy from 'lodash/sumBy'

import './styles.scss'
import Communication from './tabs/communication'
import ClaimDenyOptionModal from '../../components/OrderDetails/ClaimDenyOptionModal'
import GlobalEModal from '../../components/OrderDetails/GlobalEModal'
import UndoClaimModal from '../../components/OrderDetails/UndoClaimModal'
import CancelProtection from '../../components/CancelProtection/CancelProtection'

import AnswerClaim from './answer-claim'
import IconRushBadge from '../../assets/rush-badge.svg'
import DeniedResolution from '../../assets/denied-resolution.svg'
import RefundResolution from '../../assets/refund-resolution.svg'
import ReshipmentResolution from '../../assets/reshipment-resolution.svg'
import { ClosedIcon } from '../../containers/admin-main/side-bar/icon'
import GlobaleLogo from '../../assets/global-e-logo.png'

import ProgressBar from '../../components/ProgresBar'
import { formatMoneyNoDecimal } from '../../helpers/date-time-helper'
import PermissionWrapper from '../../components/PermissionWrapper'
import { PermissionSet } from '../../configuration/permission-set'
import orderFactory from '../../helpers/order-factory'
import AddProtection from '../../components/AddProtection/AddProtection'

const TimelineType = {
  Event: 'event',
  Message: 'message',
  ClaimCreated: 'claim_created',
  Shipping: 'shipping',
}

const transformTimeLines = (timeLines) =>
  _map(timeLines, (item) => ({
    time: new Date(item.createdAt),
    data: { message: item.message },
    type: TimelineType.Event,
  }))

const transformMessages = (messages) =>
  _map(messages, (message) => ({
    time: new Date(message.created_at),
    data: message,
    type: TimelineType.Message,
  }))

const transformTracking = (records, defaultLocation) =>
  _map(records, (record) => ({
    time: new Date(_get(record, 'time')),
    type: TimelineType.Shipping,
    data: {
      ...record,
      message: `${record.message} <i>(${record.location || defaultLocation})</i>`,
    },
  }))

const transformLineItems = (lineItems) => {
  const processItems = _filter(lineItems, (item) => item.vendor !== 'Order Protection')

  return processItems.map((item) => ({ ...item, line_item_id: item.id }))
}

const TicketViewers = ({ viewers, showApproveClaim }) => {
  const { user } = useAuth0()

  const viewerText = useMemo(() => {
    if (
      _isEmpty(user) ||
      _isEmpty(viewers) ||
      (viewers.length === 1 && viewers[0].userName === user.name)
    ) {
      return 'Nobody else is'
    }

    const yourself = viewers.find(({ userName }) => userName === user.name)
    const others = viewers.filter(({ userName }) => userName !== user.name)
    others.sort((f, s) => s.viewAt - f.viewAt)

    const displayedViewers = _compact([yourself].concat(others)).slice(0, 3)
    let joinedList = _map(displayedViewers, 'userName').join(', ')

    if (displayedViewers.length > 2) {
      joinedList = joinedList.replace(/, ([^,]*), ([^,]*)$/, ', $1, and $2')
    } else {
      joinedList = joinedList.replace(/, ([^,]*)$/, ' and $1')
    }

    return `${joinedList.replace(yourself.userName, 'You')} are`
  }, [user, viewers])

  return (
    <div className="w-full text-right">
      <div className="d-flex d-md-none flex-row justify-content-end flex-wrap mb-3">
        <div className="text-default medium">{viewerText} viewing this claim</div>
      </div>
      <div
        className={`${
          showApproveClaim
            ? 'flex flex-row justify-end'
            : 'flex d-md-block flex-row justify-content-end flex-wrap mt-3 viewer'
        }`}
      >
        <div className="text-default medium">{viewerText} viewing this claim</div>
      </div>
    </div>
  )
}

const ClaimActionArea = ({
  isLoading,
  claimStatusId,
  onDismiss,
  onDeny,
  onUndo,
  onClickApproveClaim,
  isShowingApproval,
  showGlobalEModal,
  globaleId,
  retryRefund,
  markAsRefunded,
  claim,
  markAsRead,
}) => {
  const ActionButton = useCallback(
    () => (
      <>
        <button onClick={() => onDeny()} className="btn-default mb-2 pink">
          <span className="btn-icon icon-close" />
          <span className="btn-text">Deny</span>
        </button>
        <button
          onClick={() => (globaleId ? showGlobalEModal() : onClickApproveClaim())}
          className="btn-default mb-2 purple"
        >
          <span className="btn-icon icon-check" />
          <span className="btn-text">Approve</span>
        </button>
      </>
    ),
    [onClickApproveClaim]
  )

  const claimStatusImage = useMemo(() => {
    const { reship_status, claim_status_id } = claim

    if (claim_status_id === 2) {
      if (reship_status) {
        return ReshipmentResolution
      } else {
        return RefundResolution
      }
    }
    return DeniedResolution
  }, [claim])

  if (isLoading === true) {
    return (
      <div>
        <Spinner animation="grow" />
      </div>
    )
  }

  if (isShowingApproval === true) {
    return (
      <button onClick={() => onClickApproveClaim()} className="btn-default mb-2 pink">
        <span className="btn-icon icon-close" />
        <span className="btn-text">Cancel</span>
      </button>
    )
  }

  switch (claimStatusId) {
    case 1:
      return (
        <>
          <button onClick={() => onDismiss()} className="btn-default mb-2 gray">
            <span className="btn-icon icon-minus" />
            <span className="btn-text">Dismiss</span>
          </button>
          <ActionButton />
        </>
      )
    case 2:
      return (
        <div className="d-flex flex-row text-right">
          {claim.refunded === false && (
            <span className="refund-issue-message">
              <span onClick={() => markAsRefunded()} className="hyper-text">
                Mark as Refunded
              </span>
              <span className="mx-1">-</span>
              <span onClick={() => retryRefund()} className="hyper-text">
                Retry Refund
              </span>
            </span>
          )}
          {claim.read_inbox === null && (
            <button onClick={markAsRead} className="btn-default btn-dismiss gray">
              <span className="btn-icon icon-minus" />
              <span className="btn-text">Dismiss</span>
            </button>
          )}
          {isLoading === false && (
            <div className="claim-status-card">
              <img alt="claim status" src={claimStatusImage} />
              <div className="claim-status-text">
                <span className="claim-status-title">Approved</span>
                <span>{getApprovalClaimStatus(claim)}</span>
              </div>
            </div>
          )}
        </div>
      )
    case 4:
      return <ActionButton />
    case 5:
      return (
        <>
          {isLoading === false && (
            <div className="flex">
              <PermissionWrapper set={[PermissionSet.ClaimAction]}>
                <div className="btn-default undo-button" onClick={() => onUndo()}>
                  Undo Closure
                </div>
              </PermissionWrapper>
              <div className="claim-status-card closed-claim-card">
                <ClosedIcon className="claim-status" alt="closed status" />
                <div className="claim-status-text">
                  <span className="claim-status-title closed-text">Closed</span>
                </div>
              </div>
            </div>
          )}
        </>
      )
    case 3:
      return (
        <>
          {claim.read_inbox === null && (
            <button onClick={markAsRead} className="btn-default btn-dismiss gray">
              <span className="btn-icon icon-minus" />
              <span className="btn-text">Dismiss</span>
            </button>
          )}
          <PermissionWrapper set={[PermissionSet.ClaimAction]}>
            <div className="btn-default undo-button" onClick={() => onUndo()}>
              Undo Denial
            </div>
          </PermissionWrapper>

          <div className="claim-status-card denied-claim-card">
            <img alt="claim status" src={claimStatusImage} />
            <div className="claim-status-text">
              <span className="claim-status-title denied-text">Denied</span>
              <span className="denied-text">
                {_get(claim, 'resolutionData.resolution', _get(claim, 'denied_reason', 'Unknown'))}
              </span>
            </div>
          </div>
        </>
      )
  }
}

const getRushProcessingItems = (fulFillmentData = []) =>
  _filter(
    fulFillmentData,
    (obj) =>
      !_isEmpty(
        _filter(
          _get(obj, 'line_items', []),
          (item) => item?.title?.toLowerCase()?.indexOf('rush processing') > -1
        )
      )
  )

const OrderDetailsPage = () => {
  const { token, onFetchClaims, userPermission } = useAdminContext()
  const { user } = useAuth0()
  const match = useRouteMatch('/orders/:protectionId/:currentTab?')
  const protectionId = useMemo(() => match.params?.protectionId || '', [match])
  const currentTab = useMemo(
    () => (typeof match.params?.currentTab === 'undefined' ? 0 : 1),
    [match]
  )
  const [orderData, setOrderData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [, setOrderFetching] = useState(true)
  const [showClaimOption, setShowClaimOption] = useState(false)
  const [showGlobalEModal, setShowGlobalEModal] = useState(false)

  const [creditData, setCreditData] = useState()
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [approveUndoClaim, setApproveUndoClaim] = useState(false)

  const [items, setItems] = useState([])
  const [claimItems, setClaimItems] = useState([])
  const [orderTracking, setOrderTracking] = useState([])
  const [fulFillmentDetails, setFulFillmentDetails] = useState([])
  const [shipping, setShipping] = useState([])
  const [orderTimeline, setOrderTimeLine] = useState([])
  const [showAlert, setShowAlert] = useState('')
  const [reshipOrderLoading, setReshipOrderLoading] = useState(false)
  const [barWidth, setBarWidth] = useState(0)
  const [showApproveClaim, setShowApproveClaim] = useState(false)
  const [timelineMessages, setTimelineMessage] = useState([])
  const [originalMessages, setOriginalMessages] = useState([])
  const [viewers, setViewers] = useState([])
  const [currentActiveTab, setCurrentActiveTab] = useState(currentTab)
  const history = useHistory()
  const claim = useMemo(() => _get(orderData, 'claims[0]', {}), [orderData])
  const claimResolution = useMemo(() => _get(claim, 'resolutionData', {}), [claim])
  const cancelledOrder = useMemo(() => isCancelledOrder(orderData), [orderData])
  const [expectedOpVariant, setExpectedOpVariant] = useState(null)
  const [isCaculatingOpAddition, setIsCaculatingOpAddition] = useState(false)

  const setClaimStatus = useCallback(
    (newStatus, claimId, message = '', onDone) => {
      setIsLoading(true)
      let data = {
        newStatus,
      }

      if (newStatus === 'Dismissed') {
        data.dismissedBy = _get(user, 'name', _get(user, 'nickname'))
      }

      if (_isEmpty(message) === false) {
        data = {
          ...data,
          name: user?.name,
          message,
        }
      }
      postApi(`/claims/${claimId}/claimStatus/response`, data, token).then(() => {
        onDone()
      })
    },
    [token, setIsLoading]
  )

  const markAsRead = useCallback(() => {
    setIsLoading(true)

    const data = { claimId: claim.id, dismissedBy: _get(user, 'name', _get(user, 'nickname')) }

    postApi(`/claims/admin-actions/mark-as-read`, data, token).then(() => {
      onDoneAnyAction()
    })
  }, [claim, token])

  const claimCreated = useMemo(() => {
    if (_isEmpty(claim) === false) {
      const claimDate = getClaimSumissionDateForTimeline(claim)

      return {
        time: new Date(isNaN(claimDate) === false ? parseInt(claimDate) : claimDate),
        data: claim,
        type: TimelineType.ClaimCreated,
      }
    }

    return {}
  }, [claim])

  const claimMessage = useMemo(() => {
    if (_isEmpty(claim) === false) {
      const claimDate = getClaimSumissionDateForTimeline(claim)

      return {
        time: new Date(isNaN(claimDate) === false ? parseInt(claimDate) : claimDate),
        data: {
          message: _get(claim, 'claim_details', 'Claim created'),
          sender_id: 0,
        },
        type: TimelineType.Message,
      }
    }

    return {}
  }, [claim])

  const claimResolutionMessage = useMemo(() => {
    if (_isEmpty(claimResolution) === false) {
      const { date, first_name, last_name, resolution, status, actioned_by } = claimResolution
      const name = !actioned_by ? [first_name, last_name].join(' ') : actioned_by

      return {
        time: new Date(date),
        data: {
          message: `<b>${name}</b> has ${status} the claim (${resolution})`,
        },
        type: TimelineType.Event,
      }
    }

    return {}
  }, [claimResolution])
  useEffect(() => {
    // Fetch order data

    if (_isEmpty(protectionId) === false) {
      setOrderFetching(true)
      getApi(`/stores/getPlatformByOrderId/${protectionId}`, {}, token).then((res) => {
        if (res.data.isMagento()) {
          getApi(`/magento-proxy/${res.data.store_id}/orders/${protectionId}/order_json`, {}, token)
            .then((res) => {
              setOrderFetching(false)
              setOrderData(res.data)
            })
            .catch(() => {
              setOrderFetching(false)
            })
        } else {
          getApi(`/orders/${protectionId}`, {}, token)
            .then((res) => {
              setOrderData(res.data)
              setOrderFetching(false)
            })
            .catch(() => setOrderFetching(false))
        }
      })
    }
  }, [protectionId, token])

  const getTaxPrice = useCallback(
    (taxLines) => _sum(_map(taxLines, (tax) => parseFloat(_get(tax, 'price', '0')) * 100)),
    []
  )

  const calculatePrice = useCallback((item) => {
    const itemQuantity = item?.quantity === 0 ? item?.refunded_quantity : item?.quantity
    const totalDiscount = item?.discount || 0
    const totalPrice = item.price * itemQuantity
    const totalTax = item.totalTax
    const totalSum = totalPrice - totalDiscount + totalTax
    const totalSumByItem = totalSum / itemQuantity

    return +totalSumByItem.toFixed(2)
  }, [])

  const handleOnPostMessage = (_message) => {
    setOriginalMessages([...originalMessages, _message])
  }

  useEffect(() => {
    setTimelineMessage(transformMessages(originalMessages))
  }, [originalMessages])

  useEffect(() => {
    const opId = _get(orderData, 'protection_order_id', 0)
    const claimId = _get(orderData, 'claims[0].id', -1)
    const platform = _get(orderData, 'platform_id', -1)

    setIsLoading(true)

    if (opId !== 0) {
      getApi(`/stores/orders/${opId}/items`, {}, token).then((res) => {
        const processListItems = _isEmpty(_get(res, 'data', []))
          ? transformLineItems(_get(orderWrapper, 'order_json.line_items', []))
          : _get(res, 'data', [])

        Promise.all(
          _map(processListItems, async (rawItem) => {
            let originalItem = orderWrapper.getOriginalItem(rawItem)
            let originalPrice = Math.ceil(parseFloat(_get(originalItem, 'price', '0')) * 100)

            if (_isEmpty(originalItem)) {
              originalItem = rawItem
              originalPrice = Math.ceil(parseFloat(_get(originalItem, 'price', '0')) * 1)
            }

            let item = {
              ...rawItem,
              taxable: originalItem?.taxable,
              totalTax: getTaxPrice(originalItem?.tax_lines),
            }

            if (!platform.isShopify) {
              Object.assign(item, {
                price: originalPrice,
              })
            }

            try {
              const variantData = await orderWrapper.getVariantData(item)
              return {
                ...item,
                variantData,
              }
            } catch (e) {
              console.log(e)

              const newVariantData = {
                inventory_quantity: 0,
                inventory_policy: 'deny',
                price: (item.price / 100).toFixed(2),
                sku: '',
                title: '',
                id: parseInt(item.variant_id),
              }

              return {
                ...item,
                variantData: newVariantData,
              }
            }
          })
        ).then((res) => {
          setItems(res)
        })
      })

      if (platform.isShopify()) {
        orderWrapper.getOrderTracking().then((res) => {
          const trackingDataOrder = _get(res, 'data', [])

          setOrderTracking(trackingDataOrder)
          setShipping(
            transformTracking(
              _get(trackingDataOrder, '[0].orderTracking.checkpoints', []),
              [
                _get(trackingDataOrder, '[0].origin_location.city', ''),
                _get(trackingDataOrder, '[0].origin_location.province_code', ''),
              ].join(', ')
            )
          )
        })
      }

      if (!platform.isMagento()) {
        orderWrapper.getOrderTimeline().then((res) => {
          setOrderTimeLine(transformTimeLines(res.data))
        })
      }

      orderWrapper.getFulfillmentDetails().then((res) => {
        setFulFillmentDetails(res.data)
        setIsLoading(false)
      })
    }

    if (claimId !== -1) {
      getApi(`/claims/message/${claimId}`, {}, token).then((res) => {
        const { messages, viewers } = res.data
        setOriginalMessages(messages)
        //setTimelineMessage(transformMessages(messages))
        setViewers(viewers)
      })

      getApi(`/claims/${claimId}/${opId}/items`, {}, token).then((res) => {
        setClaimItems(res.data)
      })
    } else {
      setTimelineMessage([])
      setViewers([])
      setClaimItems([])
    }
  }, [orderData, token])

  const allTimeLines = useMemo(
    () =>
      _groupBy(
        _compact(
          timelineMessages
            .concat(orderTimeline)
            .concat(_isEmpty(claimCreated) ? [] : [claimCreated])
            .concat(_isEmpty(claimMessage) ? [] : [claimMessage])
            .concat(_isEmpty(claimResolutionMessage) ? [] : [claimResolutionMessage])
            .concat(shipping)
        ),
        (item) => {
          const date = new Date(item.time)

          return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        }
      ),
    [claimCreated, claimMessage, claimResolutionMessage, timelineMessages, shipping, orderTimeline]
  )

  const postDeniedClaimMessage = useCallback(
    (message, claimId, userName, callback) => {
      postApi(
        '/claims/message',
        {
          senderId: '',
          message,
          claimId,
          userName,
          isDenyClaim: true,
        },
        token
      ).then(() => {
        callback()
      })
    },
    [token]
  )

  const postUndoClaim = useCallback(
    (claimId, callback) => {
      postApi(
        '/claims/undo',
        {
          claimId,
        },
        token
      ).then(() => {
        callback()
      })
    },
    [token]
  )

  const onDoneAnyAction = useCallback(() => {
    getApi(`/orders/${protectionId}`, {}, token).then((res) => {
      setOrderData(res.data)
      onFetchClaims()
      setIsLoading(false)
    })
  }, [onFetchClaims, setIsLoading, token, protectionId])

  const onSubmitClaimDeny = useCallback(
    (message) => {
      setIsLoading(true)
      setShowClaimOption(false)

      const claimId = _get(claim, 'id')
      setClaimStatus('Denied', claimId, message, () => {
        onDoneAnyAction()
      })
    },
    [user, claim, onFetchClaims, setIsLoading]
  )

  const onSubmitUndoClaim = useCallback(() => {
    setIsLoading(true)
    setApproveUndoClaim(false)

    const claimId = _get(claim, 'id')

    postUndoClaim(claimId, () => {
      onDoneAnyAction()
    })
  }, [user, claim, onFetchClaims, setIsLoading])

  console.log('customer requested items', items)

  const finalItems = useMemo(
    () =>
      _map(items, (item) => ({
        ...item,
        isClaim:
          item.variant_id && item.product_id
            ? _get(
                _find(claimItems, (line) => line.id === item.id),
                'is_checked',
                false
              )
            : true,
        tracking: orderTracking?.find(
          (itemOT) => String(itemOT.variant_id) === String(item?.variant_id)
        ),
        itemPrice: calculatePrice(item),
      })),
    [items, claimItems, orderTracking]
  )
  const reshipItemsRequested = useMemo(
    () => _get(claimItems, '[0].reship_items_requested', []),
    [claimItems]
  )
  const rushProcessing = useMemo(
    () => getRushProcessingItems(fulFillmentDetails),
    [fulFillmentDetails]
  )
  const rawFulfillments = useMemo(
    () => _compact(_flatten(_map(fulFillmentDetails, 'line_items'))),
    [fulFillmentDetails]
  )
  const fulFilledItems = useMemo(
    () =>
      _map(
        _map(_groupBy(rawFulfillments, 'variant_id'), (item, variant_id) => ({
          variant_id: parseInt(variant_id),
          quantity: _sumBy(item, 'quantity'),
        })),
        (line) => {
          return {
            id: _get(line, 'variant_id', ''),
            quantity: _get(line, 'quantity', 0),
            remaining:
              _get(
                _find(finalItems, (item) => {
                  const item_variant_id = _get(item, 'variant_id') || ''
                  const line_variant_id = _get(line, 'variant_id') || ''

                  return item_variant_id.toString() === line_variant_id.toString()
                }),
                'quantity',
                0
              ) - line.quantity,
          }
        }
      ),
    [rawFulfillments, finalItems]
  )
  const allItemsIdsFromFulfillment = useMemo(
    () => _filter(fulFilledItems, (row) => row.remaining <= 0),
    [fulFilledItems]
  )
  const unFulfillmentRequiresShipping = useMemo(
    () =>
      _filter(
        finalItems,
        (row) =>
          _includes(_map(allItemsIdsFromFulfillment, 'id'), parseInt(row.variant_id)) === false &&
          row?.tracking?.requires_shipping
      ),
    [allItemsIdsFromFulfillment, finalItems]
  )
  const hasDelivered = useMemo(
    () =>
      fulFillmentDetails?.filter(
        (item) =>
          item?.shipment_status?.toLowerCase() === 'delivered' ||
          item?.trackingData?.status?.toLowerCase() === 'delivered'
      ),
    [fulFillmentDetails]
  )

  const compareRushProcessingTime = useCallback(() => {
    if (_isEmpty(rushProcessing)) {
      return false
    }
    const hours = moment().diff(moment(orderData.order_date), 'hours')
    const latestTrackingRecord = hasDelivered?.[0]?.trackingData?.checkpoints?.[0]
    const hoursDelivered = latestTrackingRecord?.time
      ? moment(latestTrackingRecord?.time).diff(moment(orderData.order_date), 'hours')
      : 0

    return (
      _isEmpty(unFulfillmentRequiresShipping) &&
      ((_isEmpty(hasDelivered) && hours > 24) || hoursDelivered > 24)
    )
  }, [rushProcessing, unFulfillmentRequiresShipping, fulFillmentDetails, orderData])

  const onSubmit = useCallback(
    (processItems, creditStore, creditCustomer, includeShipping) => {
      setIsLoading(true)

      if (orderData.isMagento()) {
        var flag = true
        if (processItems.length > 0) {
          _map(processItems, (processItem) => {
            if (processItem.resolutionData.isReship == false) {
              flag = false
            }
          })

          if (flag == true) {
            //alert("reship")
            postApi(
              '/claims/admin-actions/approve',
              {
                processItems,
                orderData,
                creditStore,
                creditCustomer,
                includeShipping,
                username: user.name,
              },
              token
            ).then((res) => {
              onDoneAnyAction()
              setShowApproveClaim(false)
            })
          } else {
            if (fulFillmentDetails.length >= 0) {
              postApi(
                '/claims/admin-actions/approve',
                {
                  processItems,
                  orderData,
                  creditStore,
                  creditCustomer,
                  includeShipping,
                  username: user.name,
                },
                token
              ).then((res) => {
                onDoneAnyAction()
                setShowApproveClaim(false)
              })
            } else {
              setShowAlert(
                'Please complete the shipment of this product from magento admin panel before refund process.'
              )
              setIsLoading(false)
            }
          }
        } else {
          //show error message when empty
          console.log('show error message when empty')
        }
      } else {
        postApi(
          '/claims/admin-actions/approve',
          {
            processItems,
            orderData,
            creditStore,
            creditCustomer,
            includeShipping,
            username: user.name,
          },
          token
        ).then((res) => {
          onDoneAnyAction()
          setShowApproveClaim(false)
          if (res.data.resolutionType == 'reship') {
            setReshipOrderLoading(true)
            postApi('/claims/admin-actions/reship-link', { claimId: claim.id }, token).then(
              (res) => {
                setOrderData((order) => ({
                  ...order,
                  claims: [{ ...order.claims[0], reshipData: res.data.reshippedOrder }],
                }))
                setReshipOrderLoading(false)
              }
            )
          }
        })
      }
    },
    [setIsLoading, orderData, token, onDoneAnyAction, setShowClaimOption]
  )

  const trackingBasedData = useMemo(
    () => _filter(fulFillmentDetails, (fulfill) => fulfill.tracking_number !== null),
    [fulFillmentDetails]
  )

  const orderDelivered = useMemo(
    () =>
      _isEmpty(
        _filter(
          _map(items, (item) => {
            const fulfillments = _get(item, 'fulfillments', [])
            let deliveredItem = 0

            _forEach(fulfillments, (fulfillment) => {
              const status = _get(
                _find(
                  trackingBasedData,
                  (tracking) => tracking.tracking_number === fulfillment.tracking_number
                ),
                'trackingData.status',
                ''
              )

              if (status.toLowerCase() === 'delivered') {
                deliveredItem = deliveredItem + fulfillment.quantity
              }
            })

            return {
              ...item,
              remainingItems: item.quantity - deliveredItem,
            }
          }),
          (line) => line.remainingItems > 0
        )
      ) === true,
    [items, trackingBasedData]
  )

  const rejectedType = _get(claim, 'claim_status_id', null)
  const onCalculatePriceToAdd = useCallback(() => {
    const orderId = _get(orderData, 'id')
    setIsCaculatingOpAddition(true)
    getApi(`/calc-variant-tier/${orderId}`)
      .then((res) => {
        setExpectedOpVariant(res.data)
        setIsCaculatingOpAddition(false)
      })
      .catch(() => {
        setExpectedOpVariant(null)
        setIsCaculatingOpAddition(false)
      })
  }, [orderData])

  const onCancelProtection = useCallback(
    (cancelSubscription = false) => {
      setIsLoading(true)
      postApi(
        '/admin/cancel-protect',
        { protectionId: protectionId, userName: user.name, cancelSubscription: cancelSubscription },
        token
      ).then(() => {
        setShowCancelModal(false)
        onDoneAnyAction()
      })
    },
    [user, token, protectionId, onDoneAnyAction]
  )
  const onShowAddModal = useCallback(
    (next) => {
      if (!expectedOpVariant) {
        onCalculatePriceToAdd()
      }

      setShowAddModal(next)
    },
    [onCalculatePriceToAdd, expectedOpVariant]
  )
  const onAddProtection = useCallback(() => {
    setIsLoading(true)
    postApi(
      '/admin/add-protection',
      { protectionId: protectionId, opVariant: expectedOpVariant },
      token
    ).then(() => {
      setShowAddModal(false)
      onDoneAnyAction()
    })
  }, [user, token, protectionId, onDoneAnyAction, expectedOpVariant])

  useEffect(() => {
    const errorMessage = JSON.stringify(_get(claim, 'refund_response', {}))
    setShowAlert(claim.refunded !== false ? '' : errorMessage)
  }, [claim])

  const retryRefund = useCallback(() => {
    setShowAlert('')
    setIsLoading(true)
    postApi('/claims/admin-actions/retry-refund', { claimId: claim.id }, token)
      .then(() => {
        setShowCancelModal(false)
        onDoneAnyAction()
      })
      .catch((e) => {
        setIsLoading(false)
        if (e.response.data.message === 'refund_error') {
          setShowCancelModal(false)

          setShowAlert(JSON.stringify(e.response.data.refund_response))
        }
      })
  }, [token, claim, onDoneAnyAction, setIsLoading])

  const markAsRefunded = useCallback(() => {
    setShowAlert('')
    setIsLoading(true)
    postApi('/claims/admin-actions/mark-as-refunded', { claimId: claim.id }, token)
      .then(() => {
        onDoneAnyAction()
      })
      .catch((e) => {
        setIsLoading(false)
        if (e.response.data.message === 'refund_error') {
          setShowAlert(JSON.stringify(e.response.data.refund_response))
        }
      })
  }, [token, claim, onDoneAnyAction, setIsLoading])

  const claimStatus = useMemo(() => _get(claim, 'claim_status_id'), [claim])

  useEffect(() => {
    if (claimStatus === 1 || claimStatus === 4) {
      if ((userPermission?.permission || []).includes(PermissionSet.ClaimViewShareRev)) {
        postApi('/claims/admin-actions/get-credit', { storeId: orderData?.store_id }, token).then(
          (res) => {
            setCreditData(res.data)
          }
        )
      }
    }
  }, [userPermission, orderData, token, claimStatus])

  const creditMessage = useMemo(() => {
    // Disable revshare component when approve or deny a claim
    if (typeof creditData === 'undefined' || [2, 3].includes(claim.claim_status_id)) {
      return undefined
    }

    if (creditData?.onlyShareRev === true) {
      return {
        precentage: 0,
        text: 'Rev Share Only Store',
      }
    }

    if (creditData?.sharePercentage === 0) {
      return {
        precentage: 0,
        text: (
          <>
            <span>No rev share — </span>
            <span className="font-weight-bold">
              ${formatMoneyNoDecimal(creditData?.totalReumbursed)} in reimbursed claims
            </span>
            <span> this month</span>
          </>
        ),
      }
    }

    return {
      precentage: (creditData?.totalReumbursed / (creditData?.totalShareRev || 1)) * 100,
      text: (
        <>
          <span>Used </span>
          <span className="font-weight-bold">
            ${formatMoneyNoDecimal(creditData?.totalReumbursed)} in reimbursed claims
          </span>
          <span> out of </span>
          <span className="font-weight-bold">
            ${formatMoneyNoDecimal(creditData?.totalShareRev)} in rev share
          </span>
          <span> this month</span>
        </>
      ),
    }
  }, [creditData, claim])

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setBarWidth(node.getBoundingClientRect().width)
    }
  }, [])
  const claimStatusId = _get(claim, 'claim_status_id', null)
  const orderWrapper = orderFactory(orderData, token)
  const globaleId = _find(_get(orderData, 'order_json.note_attributes', []), ['name', 'GEOrderId'])
  const opUser = userPermission?.role.includes('admin', 'sales', 'concierge')

  return (
    <div className="order-details-wrapper-new-skin max-w-[1440px] mx-auto">
      {orderData === null ? (
        <div>Loading...</div>
      ) : (
        <div className="body-order-details">
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="breadcrumb-container-wrap">
              {/* <BreadCrumb claim={claim} isLoading={orderFetching} orderId={_get(orderData, 'id')} /> */}
            </div>
            {creditMessage && (
              <PermissionWrapper set={[PermissionSet.ClaimViewShareRev]}>
                <div style={{ width: barWidth }} className="mt-2 mt-md-0 mb-3 mb-md-0 px-0">
                  <ProgressBar
                    currentPercentage={creditMessage.precentage}
                    text={creditMessage.text}
                  />
                </div>
              </PermissionWrapper>
            )}
          </div>
          {showAlert.length > 0 && (
            <Alert variant="danger" onClose={() => setShowAlert('')} dismissible>
              <Alert.Heading>Refund issue!</Alert.Heading>
              <p>
                <span>This order is getting refund issue</span>
                <br />
                <span>{showAlert}</span>
              </p>
            </Alert>
          )}

          <div className="flex-row align-items-center header-order-detail mb-2">
            <div className="d-flex  order-header">
              <div className={`font-4xl ${cancelledOrder ? 'strike-text' : ''}`}>
                Order #{orderData?.store_order_id}
              </div>
              {!_isEmpty(rushProcessing) && (
                <div className={`bage-rush mb-2 ml-2 ${compareRushProcessingTime() ? '' : 'good'}`}>
                  <img src={IconRushBadge} />
                  Rush Processing: {compareRushProcessingTime() ? 'SLA Breached' : 'Good'}
                </div>
              )}
              {!_isEmpty(globaleId) && (
                <div className={`global-e-logo-wrapper`}>
                  <img src={GlobaleLogo}></img>
                </div>
              )}
            </div>
            <PermissionWrapper set={[PermissionSet.ClaimAction]}>
              {claimStatusId && (
                <div className="btn-group" ref={isLoading ? null : measuredRef}>
                  <ClaimActionArea
                    isLoading={isLoading}
                    claimStatusId={claimStatusId}
                    onDismiss={() => {
                      setClaimStatus('Dismissed', _get(claim, 'id'), '', () => {
                        onDoneAnyAction()
                      })
                    }}
                    onDeny={() => setShowClaimOption(true)}
                    onUndo={() => setApproveUndoClaim(true)}
                    onClickApproveClaim={() => setShowApproveClaim(!showApproveClaim)}
                    showGlobalEModal={() => setShowGlobalEModal(!showGlobalEModal)}
                    globaleId={globaleId}
                    isShowingApproval={showApproveClaim}
                    claim={claim}
                    retryRefund={retryRefund}
                    markAsRefunded={markAsRefunded}
                    markAsRead={markAsRead}
                  />
                </div>
              )}
            </PermissionWrapper>
            {!claimStatusId && !isLoading && (
              <div className="submit-claim">
                <a
                  href={`/claim/${orderData?.store_id}?email=${encodeURIComponent(
                    _get(orderData, 'order_email') || ''
                  )}&op_id=${_get(orderData, 'store_order_id')}&fromEmail=true`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Submit Claim
                </a>
              </div>
            )}
          </div>
          <TicketViewers viewers={viewers} showApproveClaim={showApproveClaim} />

          <Accordion activeKey={showApproveClaim ? 'approve_claim' : ''}>
            <div>
              <Accordion.Collapse eventKey="approve_claim">
                <AnswerClaim
                  items={finalItems}
                  order={orderData}
                  tracking={orderTracking}
                  reshipItemsRequested={reshipItemsRequested}
                  isLoading={isLoading}
                  onSubmitClaim={onSubmit}
                />
              </Accordion.Collapse>
            </div>
          </Accordion>
          <Tabs
            ani
            className="order-details-tab-wrapper"
            activeKey={currentActiveTab}
            onSelect={(eventKey) => {
              setCurrentActiveTab(eventKey)
              history.push(
                `${match.url.replace(/\/communication/g, '')}${
                  parseInt(eventKey) === 1 ? '/communication' : ''
                }`
              )
            }}
          >
            <Tab eventKey={0} title={<span className="text-default medium">Claim Info</span>}>
              <ClaimInfo
                items={finalItems}
                timeline={allTimeLines}
                orderData={orderFactory(orderData)}
                setOrderData={setOrderData}
                orderTracking={orderTracking}
                shipping={shipping}
                orderTimeline={orderTimeline}
                fulFillmentDetails={fulFillmentDetails}
                cancelled={cancelledOrder}
                orderDelivered={orderDelivered}
                setShowCancelModal={setShowCancelModal}
                onCancelProtection={onCancelProtection}
                setShowAddModal={onShowAddModal}
                onAddProtection={onAddProtection}
                isLoading={isLoading}
                reshipOrderLoading={reshipOrderLoading}
                onCalculateOpAddition={onCalculatePriceToAdd}
              />
            </Tab>
            <Tab
              eventKey={1}
              title={<span className="text-default medium">Communication</span>}
              disabled={_isEmpty(orderData?.claims)}
            >
              <Communication orderData={orderData} onPostMessage={handleOnPostMessage} />
            </Tab>
          </Tabs>
        </div>
      )}
      <ClaimDenyOptionModal
        show={showClaimOption}
        onHide={() => setShowClaimOption(false)}
        isLoading={isLoading}
        onNext={(selectedValue) => onSubmitClaimDeny(selectedValue)}
      />
      <GlobalEModal
        show={showGlobalEModal}
        onHide={() => setShowGlobalEModal(false)}
        globaleId={globaleId}
        isLoading={isLoading}
        onNext={() => {
          setShowApproveClaim(!showApproveClaim)
          setShowGlobalEModal(false)
        }}
      />
      <UndoClaimModal
        show={approveUndoClaim}
        onHide={() => setApproveUndoClaim(false)}
        isLoading={isLoading}
        rejectedType={rejectedType}
        onNext={() => onSubmitUndoClaim()}
      />
      <CancelProtection
        isLoading={isLoading}
        fulfillments={rawFulfillments}
        onCancelProtection={onCancelProtection}
        modalShow={showCancelModal}
        setModalShow={setShowCancelModal}
        isSubscription={orderData.isSubscription}
      />
      <AddProtection
        isLoading={isLoading}
        opUser={opUser}
        onAddProtection={onAddProtection}
        storeName={orderData?.store_name}
        modalShow={showAddModal}
        setModalShow={setShowAddModal}
        isSubscription={orderData.isSubscription}
        isLoadingOpCalculation={isCaculatingOpAddition}
        expectedInsuranceData={expectedOpVariant}
      />
    </div>
  )
}

export default memo(OrderDetailsPage)
