import request from '@/support/utils/request'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getSettingsByStoreID = createAsyncThunk(
  'settings/getByStoreID',
  async (store_id?: number) => {
    const action = store_id ? `/settings/${store_id}` : '/settings/white-label'

    try {
      const response = await request.get(action)
      return response.data
    } catch (error) {
      return { settings: null }
    }
  }
)
