import React, { useState, useMemo, useEffect } from 'react'

export default function ReadMore({ text }) {
  const [isReadMore, setIsReadMore] = useState(true)
  const [windowDimensions, setWindowDimensions] = useState(window.innerWidth)

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }

  const maxLength = useMemo(() => {
    if (windowDimensions < 767) {
      return 50
    }
    if (windowDimensions < 992) {
      return 100
    }
    if (windowDimensions < 1600) {
      return 150
    }
    return 200
  }, [windowDimensions])

  return (
    <div>
      <div
        className="text-default medium mb-1 pre-line"
        dangerouslySetInnerHTML={{ __html: isReadMore ? text?.slice(0, maxLength) || '' : text }}
      />
      {text?.length > maxLength && (
        <span onClick={toggleReadMore} className="read-more">
          {isReadMore ? 'Read more' : ' Show less'}
        </span>
      )}
    </div>
  )
}
