export const PermissionSet = Object.freeze({
  ListAllStore: 'store:list-all',
  ClaimViewShareRev: 'claim:view-share-rev-bar',
  ClaimAction: 'claim:action-buttons',
  CancelProtection: 'order:cancel-protection',
  ViewCustomerOrders: 'order:list-customer-orders',
  BillingView: 'billing:view-page',
  BillingPayment: 'billing:edit-payment',
  BillingSettings: 'billing:edit-settings',
  ViewStoreSettings: 'settings:view-store-settings-page',
  ViewWidgetSettings: 'settings:view-widget-settings',
  EditWidgetSettings: 'settings:edit-widget-settings',
  ViewPriceSettings: 'settings:view-price-settings',
  EditPriceSettings: 'settings:edit-price-settings',
  User: 'user:edit-users',
  UserAssignClaimAction: 'user:claim-action',
  AdminMenu: 'menu:admin-menu',
  AnalyticStorePerformance: 'analytic:view-store-performance',
})
