import React from 'react'
import { Modal, Button, Spinner } from 'react-bootstrap'
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'
import _uniqBy from 'lodash/uniqBy'
import OrderStatus from '../../OrderStatus'
import './styles.scss'
import { useMemo } from 'react'

const OrderTrackerModal = ({ show, onHide, spfOrderData, orderTime }) => {
  const renderItems = useMemo(() => _uniqBy(spfOrderData, 'orderTracking.number'), [spfOrderData])

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      show={show}
      className="change-address-modal"
      onHide={() => onHide()}
    >
      <Modal.Header closeButton>
        <div className="font-weight-bold">Your order tracking</div>
      </Modal.Header>
      <Modal.Body className="change-address-modal-body">
        <div>
          {_isEmpty(spfOrderData) === true ? (
            <div className="d-flex">
              <Spinner className="mx-auto mt-3" animation="border" variant="success" />
            </div>
          ) : (
            _map(renderItems, (itemLine) => (
              <OrderStatus
                key={itemLine.product_id}
                shippingData={itemLine}
                orderTime={orderTime}
              />
            ))
          )}
        </div>
        <Button className="mt-3 float-right" onClick={() => onHide()}>
          Close
        </Button>
      </Modal.Body>
    </Modal>
  )
}

export default React.memo(OrderTrackerModal)
