import React, { memo, AnchorHTMLAttributes, HTMLAttributes } from 'react'
import './styles.scss'

type LinkButtonProps = AnchorHTMLAttributes<HTMLAnchorElement> &
  HTMLAttributes<HTMLDivElement> & {
    classNameNew?: string
    children: React.ReactNode
  }

const LinkButton = (props: LinkButtonProps) => {
  const { className, href, ...others } = props

  if (href) {
    return (
      <a
        className={`link-button ${className}`}
        rel="noreferrer"
        href={href}
        target="_blank"
        {...others}
      >
        {props.children}
      </a>
    )
  }

  return <div className={`link-button ${className}`} {...others} />
}

export default memo(LinkButton)
