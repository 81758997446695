import React, { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import CloseIcon from '../../../../assets/close-icon-fill.svg'

interface ImageUploadProps {
  onChange: (files: File[]) => void
}

const validImageTypes = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/bmp',
  'image/tiff',
]

export const ImageUpload = ({ onChange }: ImageUploadProps) => {
  const [files, setFiles] = useState<Array<File>>([])

  const handleFile = (acceptedFiles: File[]) => {
    const uploadedFiles = acceptedFiles?.filter((file: File) => {
      const fileType = file['type']
      if (validImageTypes.includes(fileType)) {
        return file
      }
    })
    setFiles([...files, ...uploadedFiles])
  }

  const removeImage = (i: unknown) => {
    setFiles(files.filter((x) => x.name !== i))
  }

  useEffect(() => {
    onChange(files)
  }, [files, onChange])

  return (
    <>
      <div className="flex items-center w-full flex-row flex-wrap">
        <div className="flex flex-wrap gap-1 mt-2">
          {files.map((file, key) => {
            return (
              <div key={key} className="overflow-hidden relative">
                <img
                  src={CloseIcon}
                  onClick={() => {
                    removeImage(file.name)
                  }}
                  className="mdi mdi-close absolute right-1 hover:text-white cursor-pointer p-2"
                ></img>
                <div></div>
                <img className="h-40 w-40 rounded-md" src={URL.createObjectURL(file)} />
              </div>
            )
          })}
        </div>
        <Dropzone onDrop={handleFile}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                className="flex flex-col justify-center w-full h-full !mt-5 border-2 border-primary-700 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100"
                {...getRootProps()}
              >
                <div className="flex flex-col items-center justify-center py-8">
                  <svg
                    aria-hidden="true"
                    className="w-10 h-10 !mb-3"
                    fill="none"
                    stroke="#5B1FD3"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    ></path>
                  </svg>
                  <p className="mb-0 text-sm text-typography-primary">
                    {isDragActive ? (
                      <p>Drop here</p>
                    ) : (
                      <p>
                        <span className="font-semibold">Click to upload</span> or drag and drop
                      </p>
                    )}
                  </p>
                </div>
                <input id="dropzone-file" {...getInputProps()} />
              </div>
            )
          }}
        </Dropzone>
      </div>
    </>
  )
}
