import React, { memo, useEffect, useState } from 'react'
import _map from 'lodash/map'
import _indexOf from 'lodash/indexOf'
import TrackShipmentSVG from '../../../../assets/claim-portal/track-shipment.svg'
import _get from 'lodash/get'
import _reverse from 'lodash/reverse'

import _filter from 'lodash/filter'
import './styles.scss'
import { getApi, postApi } from '../../../../helpers/api'
import { useClaimPortalContext } from '../../../../context/claim-portal-context'

const TrackShipment = memo(
  ({
    fulfillments,
    setSelectedItem,
    selectedItem,
    selectedItems,
    selectedFulfillments,
    setSelectedFulfillments,
  }) => {
    const [trackedInformation, setTrackedInformation] = useState(null)

    const [item, setItem] = useState(null)

    const { orderData } = useClaimPortalContext()

    useEffect(() => {
      if (item == null) {
        getApi(
          `/spf-proxy/${orderData.store_id}/orders/${orderData.shopify_order_id}/item-details`
        ).then((res) => {
          console.log(res)
          setTrackedInformation(res.data)
          return setSelectedFulfillments(res.data)
        })
      }
    }, [])
    useEffect(() => {
      setItem(selectedItem)
      if (selectedItems.length == 0) {
        return setSelectedFulfillments(trackedInformation)
      } else {
        return setSelectedFulfillments(
          _filter(trackedInformation, function (o) {
            return selectedItems.includes(o.id)
          })
        )
      }
    }, [selectedItem])

    const setActive = (itemId) => {
      return setSelectedItem(itemId)
    }
    return (
      <div className="shipment-wrapper">
        <div className="shipment-container">
          <div className="shipment-header">Track Shipment</div>
          <div className="image-container">
            <img className={`img-default icon-actived`} src={TrackShipmentSVG} alt="damages" />
          </div>
          <div className="overall-information">
            <div className="tracking-number">
              Tracking Number
              {(selectedFulfillments?.length > 1 || selectedItems.length == 0) &&
              trackedInformation &&
              trackedInformation.length > 0 ? (
                <span>
                  <select
                    className="fulfillment-dropdown"
                    onChange={(event) => setActive(event.target.value)}
                  >
                    {selectedFulfillments?.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.tracking_number}
                      </option>
                    ))}
                  </select>
                </span>
              ) : (
                <span>
                  {trackedInformation &&
                    _get(
                      trackedInformation?.find((fulfillment) => fulfillment.id == item),
                      'tracking_number',
                      _get(selectedFulfillments, '[0].tracking_number', 'Unknown')
                    )}
                </span>
              )}
            </div>
            <div className="last-location">
              Last Location
              <span>
                {trackedInformation &&
                  _get(
                    trackedInformation?.find((fulfillment) =>
                      fulfillment.id == item ? item : _get(selectedFulfillments, '[0]', null)
                    ),
                    'trackingData.checkpoints[0].location',
                    _get(
                      trackedInformation,
                      '[0].trackingData?.checkpoints[0]?.location',
                      'Unknown'
                    )
                  )}
              </span>
            </div>
            <div className="courier">
              Courier{' '}
              <span>
                {' '}
                {trackedInformation &&
                  _get(
                    trackedInformation?.find((fulfillment) =>
                      fulfillment.id == item ? item : _get(selectedFulfillments, '[0]', 'Unknown')
                    ),
                    'tracking_company',
                    _get(selectedFulfillments, '[0].tracking_company', 'Unkown')
                  )}
              </span>
            </div>
            <div className="container-stages">
              <div className="wrapper">
                <ul className="sessions">
                  {selectedFulfillments ? (
                    _get(
                      selectedFulfillments?.find((fulfillment) =>
                        fulfillment.id == item ? item : null
                      ),
                      'trackingData.checkpoints',
                      _get(selectedFulfillments, '[0].trackingData.checkpoints', [])
                    ).map((item, index) => (
                      <div className="information-container" key={index}>
                        <li>
                          <div className="shipment-message">{item.message}</div>
                          <div className="time">{item.time}</div>
                        </li>
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default memo(TrackShipment)
