/* eslint-disable eqeqeq */
import _difference from 'lodash/difference'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _sum from 'lodash/sum'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import './styles.scss'

import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { useClaimPortalContext } from '../../../context/claim-portal-context'
import SignatureModal from '../../ClaimPortalRoutes/Core/SignatureModal'
import ButtonUI from '../../CoreUI/ButtonUI'
import LinkButton from '../../CoreUI/LinkButton'
import ClaimPortalItem from '../Core/ClaimPortalItem'
import RefundOffer from '../Core/RefundOffer'

import { getArrayFromJSONString } from '../../../helpers/data-transformer'
import PlatformItem from '../../../helpers/integrations/item-adapter'
import MultipleShippingAddresses from '../../MultipleShippingAddresses/MultipleShippingAddresses'

const ItemListArea = ({ isReship, itemList }) =>
  itemList.length > 0 && (
    <>
      <label className="sub-head">
        Items in your claim:
      </label>
      <div className="summary-item-area">
        {_map(itemList, (item, index) => (
          <ClaimPortalItem item={item} key={index} displayOutOfStock />
        ))}
      </div>
    </>
  )

const Submission = ({
  selectedResolution,
  onSubmitClaim,
  reimbursementGateway,
  setReimbursementGateway,
}) => {
  const { orderData, setOrderData, orderItems, selectedClaimType, variantData } =
    useClaimPortalContext()

  const [text, setText] = useState('')
  const [editAddress, enableEditAddress] = useState(false)
  const [addressOne, setAddressOne] = useState(null)
  const [addressTwo, setAddressTwo] = useState('')
  const [postalCode, setPostalCode] = useState(null)
  const [city, setCity] = useState(null)
  const [checked, setChecked] = useState(false)
  const [updatedAddress, setUpdatedAddress] = useState(null)

  const [region, setRegion] = useState(null)
  const [country, setCountry] = useState(null)
  const [showSignPad, setShowSignPad] = useState(false)
  const history = useHistory()
  const isReship = useMemo(() => selectedResolution === 2, [selectedResolution])
  const [isLoading, setIsLoading] = useState(false)
  const [showMultipleShippingModal, setShowMultipleShippingModal] = useState(false)

  const selectedItems = useMemo(
    () =>
      _map(
        _filter(orderItems, (item) => item.is_checked === true),
        (itemLine) => ({
          ...itemLine,
          variantData: _get(
            _find(variantData, (line) => line.id === itemLine.id),
            'variantData',
            {}
          ),
        })
      ),
    [orderItems, variantData]
  )
  const refundAbleItems = useMemo(
    () =>
      isReship === true
        ? _filter(selectedItems, (item) => {
            const platformItem = new PlatformItem(item, _get(orderData, 'platform_id', 1))
            return platformItem.conciergeRefundable()
          })
        : selectedItems,
    [selectedItems]
  )
  const reshipAbleItems = useMemo(
    () => _difference(selectedItems, refundAbleItems),
    [selectedItems, refundAbleItems]
  )
  const totalRefundMoney = useMemo(
    () => _sum(_map(refundAbleItems, (item) => item.price * item.quantity - (item.discount || 0))),
    [selectedItems]
  )

  const onSubmitClaimFinally = useCallback(
    (canvasData) => {
      setIsLoading(true)
      onSubmitClaim(canvasData, text, variantData, updatedAddress)
    },
    [onSubmitClaim, text]
  )
  function closeModal(flag) {
    enableEditAddress(false)
  }

  const handleAddBillingProfile = async () => {
    if (addressOne && postalCode && city && region && country) {
      const newShippingAddress = `${addressOne} ${addressTwo} ${city}, ${region} ${postalCode} ${country}`
      orderData.shipping_address = newShippingAddress
      orderData.postal_code = postalCode

      const updatedAddress = {
        address1: addressOne,
        address2: addressTwo,
        city: city,
        country: country,
        province: region,
        zip: postalCode,
      }
      setOrderData(orderData)
      setUpdatedAddress(updatedAddress)
      closeModal(false)
    }
  }

  const viewMultipleShippingAddresses = () => {
    setShowMultipleShippingModal(true)
  }

  const enableButton = useMemo(() => text.length > 0, [text])

  return (
    <>
      <div className={`submission-wrapper  ${showSignPad ? '' : ''}`}>
        <div className="content-submission-wrapper">
          <div className="inner">
            <div className="inner-column left">
              <label className="title">Review and complete your claim.</label>
              <p className="sub-text">
                Issue: {selectedClaimType?.claim_type_name} • Order #{orderData.store_order_id}
                {isReship ? 'Reship' : 'Refund'}
              </p>

              <div className="item-summary">
                <ItemListArea itemList={refundAbleItems} isReship={false} />
                <ItemListArea itemList={reshipAbleItems} isReship={true} />
              </div>

              <div className="final-steps">
                <p className="sub-head">
                  Tell us what happened? <span className="color-required">*</span>
                </p>
                <textarea className="p-2" onChange={(e) => setText(e.target.value)} value={text} />
                {_isEmpty(refundAbleItems) === false && (
                  <RefundOffer
                    items={refundAbleItems}
                    isReship={isReship}
                    totalMoney={(totalRefundMoney / 100).toFixed(2)}
                    selectedMethod={reimbursementGateway}
                    setSelectedMethod={setReimbursementGateway}
                  />
                )}
              </div>

              {selectedResolution && selectedResolution == 2 && (
                <>
                  {orderData &&
                    _get(
                      orderData,
                      'order_json.shipping_address',
                      _get(orderData, 'shipping_address')
                    ) && (
                      <>
                        <div className="final-steps last">
                          <div className="ship-title">
                            <p className="sub-head">
                              Confirm your shipping address{' '}
                              <span className="color-required">*</span>
                            </p>{' '}
                            {orderData.hasMultipleShippingAddresses && (
                              <button
                                className="text-button"
                                onClick={viewMultipleShippingAddresses}
                              >
                                View More Shipping Addresses
                              </button>
                            )}
                            {(!orderData.storeData?.isBigCommerce() ||
                              orderData.hasSingleShippingAddress) && (
                              <span
                                className="link-button left"
                                onClick={() => enableEditAddress(true)}
                              >
                                Edit
                              </span>
                            )}
                          </div>
                          <p className="address">{orderData.shippingAddress}</p>

                          <div id="confirmation">
                            <div className="checkbox-area">
                              <label className="container-checkbox">
                                <input type="checkbox" checked={checked} />
                                <span
                                  className="checkmark"
                                  onClick={() => {
                                    setChecked(!checked)
                                  }}
                                />
                              </label>
                            </div>
                            <p
                              onClick={() => {
                                setChecked(!checked)
                              }}
                            >
                              I confirm this address is accurate and understand it cannot be
                              reshipped multiple times
                            </p>
                          </div>
                        </div>
                      </>
                    )}

                  <Modal className="update-address_modal" show={editAddress} animation={false}>
                    <Modal.Header closeButton>
                      <Modal.Title>Edit Shipping Address</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Group controlId="formShopifyStoreName">
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            type="text"
                            className="inputField"
                            value={addressOne}
                            onChange={(event) => {
                              setAddressOne(event.target.value)
                            }}
                          />
                        </Form.Group>

                        <Form.Group controlId="formShopifyStoreName">
                          <Row>
                            <Col>
                              <Form.Label className="address2-label">
                                Apt, Suite, etc. (Optional)
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="inputField"
                                value={addressTwo}
                                onChange={(event) => {
                                  setAddressTwo(event.target.value)
                                }}
                              />
                            </Col>
                            <Col>
                              <Form.Label>City</Form.Label>

                              <Form.Control
                                type="text"
                                className="inputField"
                                value={city}
                                onChange={(event) => {
                                  setCity(event.target.value)
                                }}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group
                          controlId="formShopifyStoreName"
                          className="address-input-container"
                        >
                          <Row>
                            <Col>
                              <Form.Label>Country</Form.Label>

                              <CountryDropdown
                                className="country-input-dropdown inputField"
                                value={country}
                                onChange={(val) => setCountry(val)}
                                blacklist={['PR']}
                              />
                            </Col>
                            <Col>
                              <Form.Label>State/Province/Region</Form.Label>

                              <RegionDropdown
                                className="region-input-dropdown inputField"
                                disableWhenEmpty={true}
                                country={country}
                                value={region}
                                customOptions={[' Puerto Rico']}
                                onChange={(val) => setRegion(val)}
                              />
                            </Col>
                            <Col>
                              <Form.Label>Postal Code</Form.Label>

                              <Form.Control
                                type="text"
                                className="postal-code"
                                value={postalCode}
                                onChange={(event) => setPostalCode(event.target.value)}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                        <div className="submit-button_container">
                          <Button
                            variant="primary"
                            className="btn-new"
                            onClick={() => {
                              handleAddBillingProfile()
                            }}
                          >
                            Save Address
                          </Button>
                          <div
                            className="link-button"
                            onClick={() => {
                              closeModal()
                            }}
                          >
                            Cancel
                          </div>
                        </div>
                      </Form>
                    </Modal.Body>
                  </Modal>
                </>
              )}
            </div>

            <div className="d-flex flex-column align-items-start">
              <ButtonUI
                disabled={enableButton === false || (selectedResolution == 2 && !checked)}
                onClick={() => setShowSignPad(true)}
              >
                {isLoading === true ? (
                  <Spinner size="sm" className="ml-2" animation="border" variant="light" />
                ) : (
                  <span>Continue</span>
                )}
              </ButtonUI>

              <LinkButton className="" onClick={() => history.goBack()}>
                Back
              </LinkButton>
            </div>

            <div className="inner-column right"></div>
          </div>
        </div>
      </div>
      {showSignPad && (
        <SignatureModal
          className="custom-modal"
          show={showSignPad}
          isLoading={isLoading}
          onHide={() => setShowSignPad(false)}
          onFinish={(imageCanvas) => onSubmitClaimFinally(imageCanvas)}
        />
      )}
      <MultipleShippingAddresses
        modalShow={showMultipleShippingModal}
        setModalShow={setShowMultipleShippingModal}
        shippingAddresses={getArrayFromJSONString(orderData.shipping_address)}
        shippingCustomerNames={getArrayFromJSONString(orderData.shipping_customer_name)}
        shippingPhoneNumbers={getArrayFromJSONString(orderData.phone_number)}
        shippingEmails={getArrayFromJSONString(orderData.order_email)}
      />
    </>
  )
}

export default memo(Submission)
