import './init'
import './helpers/prototype'
import '@orderprotection/react/dist/op.css'
import './main.css'

import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ToastContainer } from 'react-toastify'
import App from './components/App/App'
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/custom.scss'
import 'react-toastify/dist/ReactToastify.min.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import config from './config'
import pkg from '../package.json'
// import { makeServer } from './support/utils/test-server'

const ActiveUser = lazy(() => import('./routes/active-user'))
const SubscriptionPages = lazy(() => import('./routes/subscription-route'))

Sentry.init({
  dsn: config.sentry.dsn,
  integrations: [new BrowserTracing()],
  environment: config.sentry.environment,
  enabled: config.sentry.shouldReport,
})

document.querySelector('meta[name=version]').setAttribute('content', pkg.version)

// makeServer()

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/accept-invitation/:token?">
          <Suspense fallback={<div>Loading...</div>}>
            <ActiveUser />
          </Suspense>
        </Route>
        <Route path="/subscriptions/smartrr">
          <Suspense fallback={<div>Loading...</div>}>
            <SubscriptionPages />
          </Suspense>
        </Route>
        <Route>
          <Sentry.ErrorBoundary fallback="An error has occurred">
            <App />
          </Sentry.ErrorBoundary>
        </Route>
      </Switch>
    </Router>
    <ToastContainer />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals()
