import _isEmpty from 'lodash/isEmpty'
import { Button, Modal, Spinner } from 'react-bootstrap'
import './CancelProtection.scss'

import _filter from 'lodash/filter'

export default function CancelProtection(props) {
  const hideModal = (e) => {
    const target = e.target.closest('span')
    if (target) {
      props.setModalShow(false)
    }
  }
  console.log(props.fulfillments)
  const existingFulfillments = _filter(props.fulfillments, 'requires_shipping')
  console.log(existingFulfillments)
  return (
    <Modal
      className="cancel-protection-modal"
      data-backdrop="true"
      show={props.modalShow}
      onHide={props.modalShow == false}
      centered
      animation={false}
    >
      <Modal.Header onClick={(e) => hideModal(e)} closeButton>
        <Modal.Title>
          {!_isEmpty(existingFulfillments) ? 'Are you sure?' : 'Cancel Protection?'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="body-protection">
          {!_isEmpty(existingFulfillments) ? (
            <>
              <p>
                This order already has one or more shipments associated with it, so Order Protection
                can no longer be cancelled.
              </p>
              <p>
                You can still refund the customer the cost of Order Protection, but the order will
                still be protected.
              </p>
              <p>Would you like to refund the customer?</p>
            </>
          ) : (
            'Are you sure you want to cancel Order Protection and refund the customer for the cost of Order Protection?'
          )}
        </div>
        <Button className="btn-cancel" onClick={() => props.onCancelProtection()}>
          {props.isLoading === true ? (
            <Spinner animation="grow" />
          ) : (
            <span>
              {!_isEmpty(existingFulfillments) && 'Yes, Refund the Customer'}
              {_isEmpty(existingFulfillments) && !props.isSubscription && 'Yes, Cancel Protection'}
              {_isEmpty(existingFulfillments) && props.isSubscription && 'Yes, For This Order Only'}
            </span>
          )}
        </Button>
        {props.isSubscription && (
          <Button
            className="btn-cancel"
            onClick={() => props.onCancelProtection({ cancelSubscription: true })}
          >
            {props.isLoading === true ? (
              <Spinner animation="grow" />
            ) : (
              <span>Yes, For The Entire Subscription</span>
            )}
          </Button>
        )}
        <span
          className="cancel-link"
          onClick={() => {
            props.setModalShow(false)
          }}
        >
          No, Go Back
        </span>
      </Modal.Body>
    </Modal>
  )
}
