import React, { memo, useCallback, useMemo, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { useAdminContext } from '../../context/admin-context'
import {
  getClaimStatus,
  getClaimSumissionDate,
  getProtectionCost,
  isCancelledOrder,
  isProtectedOrders,
  oneTimeAppeasementOrder,
} from '../../helpers/claim-filter'
import { formatDate, formatMoney } from '../../helpers/date-time-helper'
import withAuthLoading from '../../hoc/with-auth-loading'
import AdminWelcomeBox from '../admin-welcome-box'
import OPBadge from '../../components/OPBadge'
import Badge from '../../components/Badge'
import AwesomeTable from '../../components/AwesomeTable'

const MobileItem = ({ title, children }) => (
  <div className="d-flex flex-column mb-2">
    <label className="font-weight-bold mb-0">{title}</label>
    <div>{children}</div>
  </div>
)

const AdminClaim = ({
  newClaims,
  openClaims,
  approvedClaims,
  deniedClaims,
  closedClaims,
  orders,
  totalOrder,
  changeCurrentPage,
}) => {
  const { pageSize, currentPage, freeClaims } = useAdminContext()
  const match = useRouteMatch('/claims/:filterType') || useRouteMatch('/:filterType')
  const history = useHistory()

  const filterType = useMemo(() => {
    if (match === null) {
      return 'new'
    }

    return match.params.filterType
  }, [match])

  const displayData = useMemo(() => {
    switch (filterType) {
      case 'open':
        return openClaims
      case 'approved':
        return approvedClaims
      case 'denied':
        return deniedClaims
      case 'closed':
        return closedClaims
      case 'orders':
        return orders
      default:
        return newClaims
    }
  }, [filterType, newClaims, openClaims, approvedClaims, deniedClaims, closedClaims, orders])

  const onSelectedRow = useCallback((claim, filterType) => {
    history.push(`/orders/${claim?.protection_order_id}?filterType=${filterType || 'orders'}`)
  }, [])

  const displayText = useMemo(() => (filterType === 'orders' ? 'orders' : 'claims'), [filterType])

  const claimRenderRows = useCallback(
    (row, index) => (
      <tr className="table-row" key={index} onClick={() => onSelectedRow(row, filterType)}>
        <td>#{row.isMagento() ? row.display_order_id : row.store_order_id}</td>
        <td>{formatDate(getClaimSumissionDate(row))}</td>
        <td>{row.customer_name}</td>
        <td>{row.store_name}</td>
        <td>
          {filterType === 'new' ? 'New' : filterType === 'denied' ? 'Denied' : getClaimStatus(row)}
        </td>
        <td className="d-flex justify-content-start">
          {_isEmpty(row.cancelled_at) === false && (
            <Badge className="danger mr-2" text="Cancelled" />
          )}
          <OPBadge
            isProtected={isProtectedOrders(row) === true}
            oneTimeAppeasement={oneTimeAppeasementOrder(row, freeClaims) === true}
          />
        </td>
        <td>${formatMoney(row.order_total)}</td>
        <td>${formatMoney(getProtectionCost(row, false))}</td>
        <td className="mobile-column">
          <div className="mb-col-wrapper">
            <div className="each-item">
              <MobileItem title="Order">#{row.store_order_id}</MobileItem>
              <MobileItem title="Customer">{row.customer_name}</MobileItem>
              <MobileItem title="Store">{row.store_name}</MobileItem>
              <MobileItem title="Order Total">${formatMoney(row.order_total)}</MobileItem>
            </div>
            <div className="each-item">
              <MobileItem title="Claim Date">{formatDate(getClaimSumissionDate(row))}</MobileItem>
              <MobileItem title="Claim Status">
                {filterType === 'new'
                  ? 'New'
                  : filterType === 'denied'
                  ? 'Denied'
                  : getClaimStatus(row)}
              </MobileItem>
              <MobileItem title="Protection Status">
                {_isEmpty(row.cancelled_at) === false && (
                  <Badge className="danger mb-1 justify-content-center py-1" text="Cancelled" />
                )}
                <OPBadge
                  isProtected={isProtectedOrders(row) === true}
                  oneTimeAppeasement={oneTimeAppeasementOrder(row, freeClaims) === true}
                />
              </MobileItem>
              <MobileItem title="Protection Cost">
                ${formatMoney(getProtectionCost(row, false))}
              </MobileItem>
            </div>
          </div>
        </td>
      </tr>
    ),
    [filterType, onSelectedRow, freeClaims]
  )

  const orderRenderRows = useCallback(
    (row, index) => (
      <tr
        className={`table-row ${isCancelledOrder(row) ? 'strike-text' : ''}`}
        key={index}
        onClick={() => onSelectedRow(row)}
      >
        <td className="">#{row.store_order_id}</td>
        <td>{formatDate(row.order_date)}</td>
        <td>{row.customer_name}</td>
        <td>{row.store_name}</td>
        <td className="d-flex justify-content-start">
          <OPBadge
            isProtected={isProtectedOrders(row) === true}
            oneTimeAppeasement={oneTimeAppeasementOrder(row, freeClaims) === true}
          />
        </td>
        <td>${formatMoney(row.order_total)}</td>
        <td>${formatMoney(getProtectionCost(row, false))}</td>
        <td className="mobile-column">
          <div className="mb-col-wrapper">
            <div className="each-item">
              <MobileItem title="Order">#{row.store_order_id}</MobileItem>
              <MobileItem title="Customer">{row.customer_name}</MobileItem>
              <MobileItem title="Store">{row.store_name}</MobileItem>
              <MobileItem title="Order Total">${formatMoney(row.order_total)}</MobileItem>
            </div>
            <div className="each-item">
              <MobileItem title="Order Date">{formatDate(row.order_date)}</MobileItem>
              <MobileItem title="Protection Status">
                {_isEmpty(row.cancelled_at) === false && (
                  <Badge className="danger mb-1 justify-content-center py-1" text="Cancelled" />
                )}
                <OPBadge
                  isProtected={isProtectedOrders(row) === true}
                  oneTimeAppeasement={oneTimeAppeasementOrder(row, freeClaims) === true}
                />
              </MobileItem>
              <MobileItem title="Protection Cost">
                ${formatMoney(getProtectionCost(row, false))}
              </MobileItem>
            </div>
          </div>
        </td>
      </tr>
    ),
    [filterType, onSelectedRow, freeClaims]
  )

  const onChangePage = (newPage) => {
    changeCurrentPage(newPage)
  }

  const isAllOrders = useMemo(() => filterType === 'orders', [filterType])

  const handleSortDate = useCallback((row) => {
    let dateString = getClaimSumissionDate(row)

    if (isNaN(dateString) === false) {
      // this is timestamp
      dateString = new Date(parseInt(dateString)).toString()
    }

    return new Date(dateString).getTime()
  }, [])

  const headerTable = useCallback(() => {
    if (isAllOrders) {
      return [
        'order',
        'date',
        'customer',
        'store',
        'protection status',
        'order total',
        'protection cost',
      ]
    }
    return [
      'order',
      'date',
      'customer',
      'store',
      'claim status',
      'protection status',
      'order total',
      'protection cost',
    ]
  }, [filterType])

  useEffect(() => {
    if (match === null) {
      history.replace('/claims/new')
    }
  }, [match])

  return (
    <div className="d-flex flex-column admin-claim-wrapper">
      <AdminWelcomeBox />
      <div className="table-wrapper">
        <div className="main-table">
          <AwesomeTable
            headers={headerTable()}
            sortingValues={[
              'store_order_id',
              (row) =>
                isAllOrders ? new Date(_get(row, 'order_date', '')).getTime() : handleSortDate(row),
              'customer_name',
              (row) =>
                isAllOrders
                  ? _get(row, 'status_name') || 'Good'
                  : filterType === 'new'
                  ? 'New'
                  : getClaimStatus(row),
              'order_id',
              'order_total',
              (row) => getProtectionCost(row, false),
            ]}
            pageSize={pageSize}
            current={currentPage}
            data={displayData}
            emptyText={`${['No', filterType === 'orders' ? '' : filterType, displayText].join(
              ' '
            )}!`}
            renderRow={isAllOrders ? orderRenderRows : claimRenderRows}
            freeClaims={freeClaims}
            onChangePage={onChangePage}
            size={isAllOrders ? totalOrder : 0}
          />
        </div>
      </div>
    </div>
  )
}

export default withAuthLoading(memo(AdminClaim))
