import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

export const CLAIM_STATUS_ID = {
  PENDING: 1,
  APPROVED: 2,
  DENIED: 3,
  DISMISSED: 4,
  CLOSED: 5,
}

export const isNewClaim = (claim) =>
  _get(claim, 'read_inbox') === null &&
  (_get(claim, 'claim_status_id') === CLAIM_STATUS_ID.PENDING ||
    _get(claim, 'claim_status_id') === CLAIM_STATUS_ID.APPROVED ||
    _get(claim, 'claim_status_id') === CLAIM_STATUS_ID.DENIED)

export const isOpenClaim = (claim) =>
  _get(claim, 'claim_status_id') === CLAIM_STATUS_ID.DISMISSED ||
  (_get(claim, 'claim_status_id') === CLAIM_STATUS_ID.PENDING && _get(claim, 'read_inbox') !== null)

export const isApprovedClaim = (claim, refundIssueOnly) =>
  (_get(claim, 'refund_status', false) === true && _get(claim, 'read_inbox') !== null) ||
  (_get(claim, 'read_inbox') !== null &&
    _get(claim, 'claim_status_id') === CLAIM_STATUS_ID.APPROVED &&
    (refundIssueOnly === false || _get(claim, 'refunded') === false))

export const isDeniedClaim = (claim) =>
  _get(claim, 'claim_status_id') === CLAIM_STATUS_ID.DENIED && _get(claim, 'read_inbox') !== null

export const isClosedClaim = (claim) =>
  _get(claim, 'claim_status_id') === CLAIM_STATUS_ID.CLOSED

export const getNewClaimOrders = (orders) => _filter(orders, isNewClaim)

export const getOpenClaimOrders = (orders) => _filter(orders, isOpenClaim)

export const getApprovedClaimOrders = (orders, refundIssueOnly = false) =>
  _filter(orders, (order) => isApprovedClaim(order, refundIssueOnly))

export const getDeniedClaimOrders = (orders) => _filter(orders, isDeniedClaim)

export const getClosedClaimOrders = (orders) => _filter(orders, isClosedClaim)

const _checkUnprotectedItem = (order) => {
  if (
    order &&
    (order.insurance_cost || parseInt(order.insurance_cost) === 0) &&
    order.op_cancelled !== true
  ) {
    return false
  }

  return true
}

export const isProtectedOrders = (order) =>
  order &&
  (order.insurance_cost || parseInt(order.insurance_cost) === 0) &&
  (order.op_cancelled != true) === true

export const oneTimeAppeasementOrder = (order, freeClaims) =>
  (_get(order, 'one_time_appeasement.enable', false) == true &&
    _filter(freeClaims, function (o) {
      return o.ota_email == order.order_email
    }).length == 0) ||
  (_get(order, 'one_time_appeasement.enable', false) == true &&
    _get(
      _filter(freeClaims, function (o) {
        return o.ota_email == order.order_email
      }),
      '[0].claim_id',
      null
    ) == _get(order, 'claims_id', 0))

export const oneTimeAppeasementOrderPage = (order, freeClaims) =>
  (_get(order, 'one_time_appeasement.enable', false) == true &&
    _filter(freeClaims, function (o) {
      return o.ota_email == order.order_email
    }).length == 0) ||
  (_get(order, 'one_time_appeasement.enable', false) == true &&
    _get(
      _filter(freeClaims, function (o) {
        return o.ota_email == order.order_email
      }),
      '[0].claim_id',
      null
    ) == _get(order, 'claims[0].id', 0))

export const isUnprotectedOrdersClaims = (order) => _checkUnprotectedItem(order) === true

export const isReturnToSender = (order) =>
  _get(order, 'credit_store', false) === true && _get(order, 'claim_type_id') === 4

export const getClaimSumissionDate = (claim) =>
  _get(claim, 'resolution_date') ||
  _get(claim, 'refund_date') ||
  _get(claim, 'submitted_at') ||
  _get(claim, 'claim_date') ||
  _get(claim, 'claims[0].submitted_at') ||
  _get(claim, 'claims[0].claim_date')

export const getClaimSumissionDateForTimeline = (claim) =>
  _get(claim, 'submitted_at') ||
  _get(claim, 'claims[0].submitted_at') ||
  _get(claim, 'claim_date') ||
  _get(claim, 'claims[0].claim_date') ||
  _get(claim, 'resolution_date') ||
  _get(claim, 'refund_date')

export const isReshipOrder = (order) => !_isEmpty(_get(order, 'originalOrder', ''))

export const getProtectionCost = (order, isUnprotectedOrder) => {
  if (isUnprotectedOrder === true) {
    return 0
  }

  return order?.insurance_cost || 0
}

export const getClaimStatus = (claim) => {
  if (claim.claim_status_id === 4) {
    return claim.status_name
  }
  if (claim.claim_status_id === 5) {
    return claim.status_name
  }
  if (claim.claim_status_id === 2) {
    if (claim.reship_status === true) {
      return 'Reshipped'
    }

    if (claim.refunded !== false) {
      if (_isEmpty(claim.refund_response) === false) {
        return 'Refunded Externally'
      }

      return 'Refunded'
    }

    return 'Unable to Refund'
  }

  return 'Communicating'
}

export const getApprovalClaimStatus = (claim) => {
  if (claim.claim_status_id === 2) {
    if (claim.reship_status && claim.refund_status) {
      return 'Refund & Reshipment '
    }

    if (claim.reship_status === true) {
      return 'Reship'
    }

    if (claim.refund_status) {
      if (_isEmpty(claim.refund_response) === false) {
        return 'Refunded Externally'
      }

      return 'Refund'
    }

    return 'Unable to Refund'
  }

  return 'Communicating'
}

export const isCancelledOrder = (order) => _get(order, 'cancelled_at') !== null

export const getResolutionType = (claim) => {
  if (claim.claim_status_id === 2) {
    return claim.reship_status && claim.refund_status
      ? 'Refund & Reshipment'
      : claim.reship_status
      ? 'Reship'
      : claim.refund_status
      ? 'Refund'
      : 'Unable to Refund or Reship'
  }

  if (claim.claim_status_id === 3) {
    return _get(claim, 'resolutionData.resolution', _get(claim, 'denied_reason', 'Unknown'))
  }
  if (claim.claim_status_id === 5) {
    return _get(claim, 'denied_reason', 'Closed')
  }
}
