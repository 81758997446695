import React from 'react'
import './styles.scss'
import { useHistory } from 'react-router-dom'

const HexcladReturns = ({ storeId }) => {
  const history = useHistory()

  const openNewTab = () => {
    const newTabUrl = storeId === 352 ? 'https://hexclad.com/returns' : 'https://hexclad.ca/returns'
    window.open(newTabUrl, '_blank')
  }

  return (
    <>
      <h2 className="title">Start a return to resolve your issue.</h2>
      <p className="sub-text mt-3">
        For damaged and defective items, as well as wrong items, you can start a return.
      </p>
      <button className="btn-submit" onClick={openNewTab}>
        Continue
      </button>
      <div className="back-link" onClick={() => history.go(-1)}>
        Back
      </div>
    </>
  )
}

export default HexcladReturns
