import React, { useMemo } from 'react'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _reverse from 'lodash/reverse'
import './styles.scss'

const OrderStatus = ({ shippingData, orderTime }) => {
  const checkpoints = useMemo(
    () => _get(shippingData, 'orderTracking.checkpoints', []),
    [shippingData]
  )
  const locationRoot = useMemo(() => _get(shippingData, 'origin_location'), [shippingData])
  const locationRootString = useMemo(
    () =>
      `${[
        _get(locationRoot, 'city', ''),
        _get(locationRoot, 'province_code', ''),
        _get(locationRoot, 'country_code', ''),
      ].join(', ')}`,
    [locationRoot]
  )

  return (
    <div className="order-status-wrapper">
      <h5>
        {_get(shippingData, 'orderTracking.courier.name', '')} -{' '}
        {_get(shippingData, 'orderTracking.number', '')}
      </h5>
      <div className="orderstatus done">
        <div className="orderstatus-check">
          <span className="orderstatus-number">1</span>
        </div>
        <div className="orderstatus-text">
          <time>{new Date(orderTime).toLocaleString()}</time>
          <p className="message text-muted">{`Order received by ${_get(
            shippingData,
            'vendor',
            ''
          )}`}</p>
          <p className="location">({locationRootString})</p>
        </div>
      </div>
      {_reverse(
        _map(checkpoints, (item, index) => (
          <div className={`orderstatus ${index === 0 ? 'current' : 'done'}`}>
            <div className="orderstatus-check">
              <span className="orderstatus-number">{checkpoints.length - index + 1}</span>
            </div>
            <div className="orderstatus-text">
              <time>{new Date(_get(item, 'time')).toLocaleString()}</time>
              <p className="message text-muted">{_get(item, 'message', '')}</p>
              <p className="location">({_get(item, 'location') || locationRootString})</p>
            </div>
          </div>
        ))
      )}
    </div>
  )
}

export default React.memo(OrderStatus)
