import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import _get from 'lodash/get'
import _size from 'lodash/size'
import _filter from 'lodash/filter'
import _isEmpty from 'lodash/isEmpty'
import _find from 'lodash/find'
import _map from 'lodash/map'
import _some from 'lodash/some'
import _keys from 'lodash/keys'
import platforms from '../../../../../constants/platforms'

import { Col, OverlayTrigger, Row } from 'react-bootstrap'
import './styles.scss'

import ArrowRight from '../../../../../assets/arrow-right.svg'
import Badge from '../../../../../components/Badge'
import { dateFormatForTracking, formatMoney } from '../../../../../helpers/date-time-helper'
import {
  getDeliveredImg,
  getStatusName,
  getTrackingStatusForFulFillment,
  ShipStatus,
} from '../../../../../helpers/order-helpers'
import { postApi } from '../../../../../helpers/api'
import PlatformItem from '../../../../../helpers/integrations/item-adapter'

const getTrackingItems = (fulFillmentData) =>
  _filter(
    _get(fulFillmentData, 'line_items', []),
    (item) => _get(item, 'title', '').toLowerCase().includes('order protection') === false
  )

const TrackingArea = ({ fulFillmentData, orderData }) => {
  const [deliveredImg, setDeliveredImg] = useState('')
  const trackingData = useMemo(() => _get(fulFillmentData, 'trackingData'), [fulFillmentData])
  const hasDelivered = useMemo(() => _get(trackingData, 'status') === 'Delivered', [trackingData])
  const latestTrackingRecord = useMemo(() => _get(trackingData, 'checkpoints[0]'), [trackingData])
  const deliveredDate = useMemo(() => {
    if (hasDelivered === false || _isEmpty(latestTrackingRecord) === true) {
      return ''
    }

    const theDate = new Date(latestTrackingRecord.time)

    return dateFormatForTracking(theDate)
  }, [hasDelivered, latestTrackingRecord])

  useEffect(() => {
    async function fetchDeliveredImg() {
      const img = await getDeliveredImg(
        _get(latestTrackingRecord, 'courier.name', '').toLowerCase().replaceAll(' ', ''),
        fulFillmentData.tracking_number
      )
      setDeliveredImg(img)
    }
    fetchDeliveredImg()
    return false
  }, [fulFillmentData])

  const renderOverlay = (img) => <img className="delivered-img-hover" alt="deliver" src={img} />

  const trackingStatus = useMemo(
    () => getTrackingStatusForFulFillment(trackingData, orderData, fulFillmentData),
    [trackingData, orderData, fulFillmentData]
  )

  return (
    <>
      <div className="tracking-line-data">
        <div className="shipping-courier">{fulFillmentData.tracking_company}</div>
        <div className="tracking-numbers">
          {_map(fulFillmentData?.tracking_numbers, (trackingNumber, index) =>
            fulFillmentData.tracking_url ? (
              <a
                href={fulFillmentData.tracking_urls?.[index]}
                target="_blank"
                rel="noreferrer"
                className="tracking-link"
              >
                {trackingNumber}
              </a>
            ) : (
              <div className="tracking-link missing">{trackingNumber}</div>
            )
          )}
        </div>
      </div>
      <div>
        <Badge
          text={hasDelivered ? `Delivered (${deliveredDate})` : getStatusName(trackingStatus)}
          className={
            trackingStatus === ShipStatus.CarrierNeverReceived || trackingStatus === ShipStatus.Lost
              ? 'danger'
              : 'info'
          }
        />
      </div>
      <div>
        {deliveredImg && (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderOverlay(deliveredImg)}
          >
            <img
              className="m-2 rounded"
              style={{ width: '50px', height: '50px' }}
              alt="delivered pic"
              src={deliveredImg}
            />
          </OverlayTrigger>
        )}
      </div>
    </>
  )
}

const FulFillmentCard = ({ data, itemsData, claim, orderData, icon }) => {
  const items = useMemo(() => getTrackingItems(data), [data])

  const [backupImages, setBackupImages] = useState({})
  const fillStatus = useMemo(() => _get(items, '[0].fulfillment_status', 'unfulfilled'), [items])
  const requireShipping = useMemo(() => _get(items, '[0].requires_shipping', true), [items])
  const isUnFulfilled = useMemo(() => fillStatus === 'unfulfilled', [fillStatus])

  const processImageError = useCallback(
    (imageId) => {
      if (
        imageId.itemData &&
        _keys(backupImages).includes(imageId.itemData.id.toString()) === false
      ) {
        if (!orderData.isMagento()) {
          postApi(`/stores/orders/refresh-image/${orderData.store_id}`, imageId).then((res) => {
            setBackupImages((currentImages) => ({
              ...currentImages,
              [imageId.itemData.id]: res.data.photoUrl,
            }))
          })
        }
      }
    },
    [orderData, backupImages]
  )

  const getImagePhoto = useCallback(
    (itemLine) => {
      if (itemLine.itemData) {
        return _get(
          backupImages,
          `${itemLine.itemData?.id}`,
          itemLine.itemData?.photo ||
            'https://order-protection-static.s3-us-west-1.amazonaws.com/defaultItemImage.png'
        )
      }

      return (
        itemLine.itemData?.photo ||
        'https://order-protection-static.s3-us-west-1.amazonaws.com/defaultItemImage.png'
      )
    },
    [backupImages]
  )

  const getItemStatus = useCallback((item, claim) => {
    if (_isEmpty(claim) === true) {
      return 'good'
    }

    if (_some(claim.items, (line) => line.item_id === item.itemData?.id)) {
      return _get(claim, 'claim_type_name', 'good').toLowerCase()
    }

    return 'good'
  }, [])

  if (items.length === 0) {
    return null
  }

  const renderVariantName = useCallback(
    (variantData) => {
      if (Array.isArray(variantData)) {
        return _map(variantData, (variantItem, index) => (
          <div key={index} className="product-sku">
            <span>{variantItem.option_display_name}</span>
            <span className="ml-1">{variantItem.label}</span>
          </div>
        ))
      }

      return <div className="product-sku">{variantData}</div>
    },
    [orderData]
  )

  return (
    <div className="fulfillment-card-wrapper card-shadow">
      <div className="headline">
        <div className="headline-title d-flex align-items-center">
          {icon && <img className="mr-2" src={icon} />}
          {isUnFulfilled ? 'Unfulfilled' : 'Fulfilled'}
        </div>
        <div className="headline-tracking">
          {isUnFulfilled === true ? (
            <Badge text="Unfulfilled" className="info" />
          ) : requireShipping === false ? (
            <Badge text="No Shipping Required" className="info" />
          ) : (
            <TrackingArea fulFillmentData={data} orderData={orderData} />
          )}
        </div>
      </div>
      <div className="fulfillment-table">
        <Row className="header">
          <Col>
            <Row>Item(s)</Row>
          </Col>
          <Col sm={2}>
            <Row className="justify-content-center">Quantity</Row>
          </Col>
          <Col sm={2}>
            <Row className="justify-content-center">Price</Row>
          </Col>
          <Col sm={2}>
            <Row className="justify-content-center">Status</Row>
          </Col>
        </Row>
        {_map(items, (item) => {
          let itemData
          if (orderData?.platform_id === 3) {
            itemData = _find(
              itemsData,
              (single) => single.variant_id === item.variant_id.toString()
            )
          } else {
            itemData = _find(
              itemsData,
              (single) =>
                single.variant_id === item.variant_id?.toString() &&
                single.line_item_id === (item.line_item_id || item.id).toString()
            )
          }
          let itemLine = { ...item, itemData }
          const platformItem = new PlatformItem(itemLine, _get(orderData, 'platform_id', 1))
          let itemDiscountAmount = isUnFulfilled
            ? itemLine.tracking?.discount_allocations
            : itemLine.discount_allocations

          //we havent store presentment_currency in orders table
          const currency = orderData?.order_json?.presentment_currency || 'USD'

          if (currency !== 'USD' && orderData.platform_id === platforms.BIGCOMMERCE) {
            itemLine.tracking = {
              price_set: { presentment_money: { amount: Number(itemLine.base_price).toFixed(2) } },
            }
            itemLine.price_set = {
              presentment_money: { amount: Number(itemLine.base_price).toFixed(2) },
            }
          }

          return (
            <Row className="fulfill-item-line" key={itemLine.id}>
              <Col className="product-meta" xs={12} sm={6}>
                <Row className="meta-row">
                  <img
                    src={getImagePhoto(itemLine)}
                    alt="product"
                    onError={() => processImageError(itemLine)}
                  />
                  <div className="meta-data pt-0">
                    <div className="product-title">{itemLine.title}</div>
                    <div className="product-sku">
                      SKU:{' '}
                      {isUnFulfilled
                        ? orderData.isMagento()
                          ? itemLine.variantData.sku
                          : itemLine.sku
                        : itemLine.sku}
                    </div>
                    {renderVariantName(platformItem.getVariantData())}
                    <div>Stock: {platformItem.getStockMessage()}</div>
                    {itemLine.itemData?.refunded_quantity && (
                      <div>
                        {' '}
                        <b>Refunded Quantity:</b> {itemLine?.itemData?.refunded_quantity}
                      </div>
                    )}
                  </div>
                </Row>
              </Col>
              <Col sm={2}>
                <Row className="item-line-center">
                  <span className="item-line-no">Quantity</span>
                  <span>{parseInt(itemLine.quantity)}</span>
                </Row>
              </Col>
              <Col sm={2}>
                <Row className="item-line-center">
                  <span className="item-line-no">Price</span>
                  <span>
                    {currency === 'USD' ? (
                      <>
                        <span className="text-default mr-2 line-through">
                          {itemLine.itemData?.price
                            ? `$${formatMoney(itemLine.itemData?.price || 0)}`
                            : `$${formatMoney(item?.price * 100 || 0)}`}
                        </span>
                        <span className="text-default">
                          {itemLine.itemData?.itemPrice
                            ? `$${formatMoney(itemLine.itemData?.itemPrice || 0)}`
                            : `$${formatMoney(item?.pre_tax_price * 100 || 0)}`}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="text-default mr-2 line-through">
                          {isUnFulfilled
                            ? itemLine.tracking?.price_set.presentment_money.amount || 0
                            : itemLine.price_set.presentment_money.amount || 0}{' '}
                          {currency}
                        </span>
                        {_size(itemDiscountAmount) > 0 ? (
                          <span className="text-default">
                            {itemDiscountAmount[0].amount_set.presentment_money.amount} {currency}
                          </span>
                        ) : (
                          <span className="text-default">
                            {_isEmpty(itemDiscountAmount)
                              ? itemLine.tracking?.price_set.presentment_money.amount
                              : itemDiscountAmount.price_set.presentment_money.amount}{' '}
                            {currency}
                          </span>
                        )}
                      </>
                    )}
                  </span>
                </Row>
              </Col>
              <Col sm={2}>
                <Row className="justify-content-center">
                  <div className={`claim-status ${getItemStatus(itemLine, claim)}`}>
                    {isUnFulfilled ? 'Unfulfilled' : getItemStatus(itemLine, claim)}
                  </div>
                </Row>
              </Col>
            </Row>
          )
        })}
      </div>
    </div>
  )
}

export default memo(FulFillmentCard)
