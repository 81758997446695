import './Onboarding.scss'
import React, { memo, useEffect, useMemo } from 'react'
import _isEmpty from 'lodash/isEmpty'
import { v1 } from 'uuid'
import { Route, Switch, useLocation } from 'react-router'
import GlobalAppbar from '../GlobalAppbar'
import WelcomeOnboarding from '../../assets/claim-portal/welcome-onboarding.svg'
import AccountInfoOnboarding from '../../assets/claim-portal/account-info-onboarding.svg'
import BillingPayoutOnboarding from '../../assets/claim-portal/billing-payout-onboarding.svg'
import CongratulationOnboarding from '../../assets/claim-portal/congratulation-onboarding.svg'
import Welcome from './Welcome'
import AccountInfo from './AccountInfo'
import BillingPayouts from './BillingPayouts'
import Congratulations from './Congratulations'
import { getApi, postApi } from '../../helpers/api'

const getSession = (shop) => {
  const key = `onboarding_${shop}_session`

  if (window.sessionStorage) {
    let sessionFromStorage = window.sessionStorage.getItem(key)

    if (_isEmpty(sessionFromStorage) === true) {
      sessionFromStorage = v1()
      window.sessionStorage.setItem(key, sessionFromStorage)
    }

    return sessionFromStorage
  }

  return ''
}

const Onboarding = () => {
  const location = useLocation()
  const params = new URLSearchParams(window.location.search)
  const hmac = params.get('hmac')
  const code = params.get('code')
  const shop = params.get('shop')
  const salt = params.get('salt')
  const secret = params.get('secret')
  const reinstall = params.get('reinstall')
  const isReinstallMode = useMemo(() => reinstall === 'true', [reinstall])

  const isFirstLoad = useMemo(
    () => _isEmpty(hmac) === false && _isEmpty(code) === true,
    [hmac, shop]
  )

  useEffect(() => {
    if (_isEmpty(hmac) === false && _isEmpty(code) === true) {
      if (_isEmpty(salt) === false && _isEmpty(secret) === false) {
        postApi('/magento/app-onboarding', {
          // Get onboarding URL + session
          hmac: params.get('hmac'),
          secret: params.get('secret'),
          shop: params.get('shop'),
          timestamp: params.get('timestamp'),
          salt: params.get('salt'),
        }).then((res) => {
          window.location.href = res.data
        })
      } else {
        getApi('/public-app-onboarding', {
          // Get onboarding URL + session
          hmac: params.get('hmac'),
          host: params.get('host'),
          session: params.get('session'),
          shop: params.get('shop'),
          timestamp: params.get('timestamp'),
        }).then((res) => {
          window.location.href = res.data.finalUrl
        })
      }
    } else {
      if (_isEmpty(code) === false) {
        postApi('/public-app-onboarding', {
          // save session + store to onboarding candidate table
          shop,
          code,
          session: getSession(shop),
        })
      }
    }

    return null
  }, [hmac, code])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  const stepText = useMemo(() => {
    if (location.pathname === '/onboarding/welcome') {
      return '1 out of 4'
    }
    if (location.pathname === '/onboarding/account-info') {
      return '2 out of 4'
    }
    if (location.pathname === '/onboarding/billing-payouts') {
      return '3 out of 4'
    }
    if (location.pathname === '/onboarding/congratulations') {
      return '4 out of 4'
    }
    return '1 out of 4'
  }, [location.pathname])

  const stepClassName = useMemo(() => {
    if (location.pathname === '/onboarding/welcome') {
      return 'step-1'
    }
    if (location.pathname === '/onboarding/account-info') {
      return 'step-2'
    }
    if (location.pathname === '/onboarding/billing-payouts') {
      return 'step-3'
    }
    if (location.pathname === '/onboarding/congratulations') {
      return 'step-4'
    }
    return 'step'
  }, [location.pathname])

  const imageOnBoarding = useMemo(() => {
    if (location.pathname === '/onboarding/account-info') {
      return AccountInfoOnboarding
    }
    if (location.pathname === '/onboarding/billing-payouts') {
      return BillingPayoutOnboarding
    }
    if (location.pathname === '/onboarding/congratulations') {
      return CongratulationOnboarding
    }
    return WelcomeOnboarding
  }, [location.pathname])

  const session = useMemo(() => getSession(shop), [shop])

  if (isFirstLoad) {
    return <div />
  }

  return (
    <>
      <GlobalAppbar />
      <div className="onboarding-wrapper">
        <div className="onboarding-content">
          <Switch>
            <Route path="/onboarding/account-info" exact>
              <AccountInfo session={session} />
            </Route>
            <Route path="/onboarding/billing-payouts" exact>
              <BillingPayouts />
            </Route>
            <Route path="/onboarding/congratulations" exact>
              <Congratulations />
            </Route>
            <Route path="/onboarding">
              <Welcome
                session={session}
                isFirstLoad={isFirstLoad}
                isReinstallMode={isReinstallMode}
              />
            </Route>
          </Switch>
        </div>
        <div className="onboarding-side">
          <div className="onboarding-step">
            <div className={`step ${stepClassName}`}></div>
            <p className="text-step">{stepText}</p>
          </div>
          <div className="onboarding-image">
            <img src={imageOnBoarding} alt="onboardingImage" />
          </div>
        </div>
      </div>
    </>
  )
}

export default memo(Onboarding)
