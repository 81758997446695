import {
  getShippingAddress,
  getTrackingCompany,
  getTrackingLink,
  getTrackingNumber,
} from '../../helpers/data-transformer'

const customerPresetData = [
  {
    key: 'customer_name',
    name: 'First Name',
    templateCode: '{{customer_first_name}}',
  },
  {
    key: 'customer_name',
    name: 'Last Name',
    templateCode: '{{customer_last_name}}',
  },
  {
    key: 'order_email',
    name: 'Email',
    templateCode: '{{customer_email}}',
  },
]

const orderPresetData = [
  {
    key: 'shipping_address',
    name: 'Shipping Address',
    render: (shippingAddress) => getShippingAddress(shippingAddress),
    templateCode: '{{customer_shipping_address}}',
  },
  {
    key: 'claims[0].items',
    name: 'Tracking Number',
    render: (items) => getTrackingNumber(items),
    templateCode: '{{customer_shipping_tracking_number}}',
  },
  {
    key: 'claims[0].items',
    name: 'Tracking Link',
    render: (items) => getTrackingLink(items),
    templateCode: '{{customer_shipping_tracking_link}}',
  },
  {
    key: 'claims[0].items',
    name: 'Tracking Company',
    render: (items) => getTrackingCompany(items),
    templateCode: '{{customer_shipping_tracking_company}}',
  },
  {
    key: 'claims[0].reshipData.store_order_id',
    name: 'Reship Order Number',
    templateCode: '{{customer_reship_order_id}}',
    render: (id) => `#${id}`,
  },
]

const macroMetaKey = '%%%===META===%%%'

export { customerPresetData, orderPresetData, macroMetaKey }
