import React, { memo, useState, useEffect } from 'react'
import { Map, Marker } from 'google-maps-react'
import _isEmpty from 'lodash/isEmpty'
import mapStyles from './map-styles'

const _mapLoaded = (_mapProps, map) => {
  map.setOptions({
    styles: mapStyles,
    mapTypeControl: false,
  })
}

const ChangeAddressMaps = ({ centerPoint, originalPoint }) => {
  const [currentMap, setCurrentMap] = useState({})

  useEffect(() => {
    if (_isEmpty(centerPoint) === false && _isEmpty(currentMap) === false) {
      setTimeout(() => {
        currentMap.fitBounds(new window.google.maps.LatLngBounds(originalPoint, centerPoint))
      }, 1000)
    }
  }, [centerPoint, currentMap, originalPoint])

  if (typeof window.google === 'undefined') {
    return <div>Loading...</div>
  }

  return (
    <div className="w-100">
      <Map
        google={window.google}
        zoom={_isEmpty(originalPoint) === true ? 5 : 16}
        initialCenter={originalPoint || { lat: -18.3409989, lng: 129.0067204 }}
        onReady={(mapProps, map) => {
          _mapLoaded(mapProps, map)
          setCurrentMap(map)
        }}
      >
        <Marker
          position={originalPoint}
          label={{
            color: 'white',
            text: 'A',
            className: 'text-white',
          }}
        />
        {_isEmpty(centerPoint) === false && (
          <Marker
            position={centerPoint}
            label={{
              color: 'white',
              text: 'B',
              className: 'text-white',
            }}
          />
        )}
      </Map>
    </div>
  )
}

export default memo(ChangeAddressMaps)
