import React, { memo, useCallback, useMemo, useState } from 'react'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _find from 'lodash/find'
import { Spinner } from 'react-bootstrap'

import _map from 'lodash/map'
import _filter from 'lodash/filter'
import { useClaimPortalContext } from '../../../context/claim-portal-context'
import ClaimOrderDetails from '../Message/ClaimOrderDetails'
import './styles.scss'
import { ClaimTypes } from '../configuration'
import { useHistory } from 'react-router'
import ButtonUI from '../../CoreUI/ButtonUI'
import LinkButton from '../../CoreUI/LinkButton'
import { Container } from 'react-bootstrap'
import FulfillmentList from './FulfillmentList'
import TrackShipment from './TrackShipment'

const LineItem = ({ selectedFiles, setSelectedFiles }) => {
  const [width, setWidth] = useState(window.innerWidth)

  const { orderData, orderItems, orderTracking, selectedClaimType, fulfillments, onSubmitItem } =
    useClaimPortalContext()
  const isRequired = useMemo(
    () => _get(selectedClaimType, 'require_image', false) === true,
    [selectedClaimType]
  )
  const [selectedItems, setSelectedItems] = useState([])
  const history = useHistory()
  const [selectedItem, setSelectedItem] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [selectedFulfillments, setSelectedFulfillments] = useState([])

  function onNext() {
    setIsLoading(true)
    for (var i = 0; i < orderItems.length; i++) {
      for (var j = 0; j < fulfillments.length; j++) {
        var id = _find(fulfillments[j].line_items, function (o) {
          return o.id == orderItems[i].line_item_id
        })
        if (id !== undefined && orderItems[i].line_item_id == id.id) {
          orderItems[i].fulfillment_id = fulfillments[j].id
        }
      }
    }

    for (var i = 0; i < orderItems.length; i++) {
      if (selectedItems.includes(orderItems[i].fulfillment_id)) {
        orderItems[i].is_checked = true
      } else {
        orderItems[i].is_checked = false
      }
    }
    history.push('/submitClaim/resolution')
    setIsLoading(false)
  }

  const config = useMemo(() => {
    const id = _get(selectedClaimType, 'id', -1)

    switch (id) {
      case 5:
        return ClaimTypes.WrongItem
      case 2:
        return ClaimTypes.DamagedItem
      case 3:
        return ClaimTypes.DefectiveItem
      case 6:
        return ClaimTypes.MissingItem
      default:
        return ClaimTypes.WrongItem
    }
  }, [selectedClaimType])

  return (
    <div className="fulfillment-item-wrapper">
      <div className="container-select-fulfillment-wrapper">
        <h2 className="title">Select the shipment(s) you're having issues with. </h2>
        <div className="sub-text">
          There are {fulfillments.length} different deliveries associated with your order{' '}
          {orderData.store_order_id}
        </div>
        {fulfillments &&
          fulfillments.map((item) => (
            <FulfillmentList
              key={item.id}
              item={item}
              selected={selectedItems}
              setSelected={setSelectedItems}
              setSelectedItem={setSelectedItem}
            />
          ))}
      </div>

      <ButtonUI disabled={selectedItems.length == 0} onClick={() => onNext()}>
        {isLoading === true ? (
          <Spinner size="sm" className="ml-2" animation="border" variant="light" />
        ) : (
          <span>Continue</span>
        )}
      </ButtonUI>

      <LinkButton className="" onClick={() => history.push('/submitClaim')}>
        Back
      </LinkButton>

      <TrackShipment
        fulfillments={fulfillments}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        selectedItems={selectedItems}
        selectedFulfillments={selectedFulfillments}
        setSelectedFulfillments={setSelectedFulfillments}
      />
    </div>
  )
}

export default memo(LineItem)
