import { memo, useCallback, useState, useContext } from 'react'
import _indexOf from 'lodash/indexOf'
import _filter from 'lodash/filter'
import TornCrushed from '../../../../assets/claim-portal/icon-torn-crushed.svg'
import TornCrushedActived from '../../../../assets/claim-portal/icon-torn-crushed-actived.svg'
import NotPackedProperly from '../../../../assets/claim-portal/icon-not-packed-properly.svg'
import NotPackedProperlyActived from '../../../../assets/claim-portal/icon-not-packed-properly-actived.svg'
import Defective from '../../../../assets/claim-portal/icon-defective.svg'
import DefectiveActived from '../../../../assets/claim-portal/icon-defective-actived.svg'
import NoneOfThese from '../../../../assets/claim-portal/none-of-these.svg'
import NoneOfTheseActived from '../../../../assets/claim-portal/icon-none-of-these-actived.svg'
import DamagesItems from './DamagesItems'
import { useClaimPortalContext } from '../../../../context/claim-portal-context'

const damages = [
  {
    id: 1,
    name: 'Shipping bag was torn / Crushed',
    icon: TornCrushed,
    iconActived: TornCrushedActived,
  },
  {
    id: 2,
    name: 'Package was not packed properly',
    icon: NotPackedProperly,
    iconActived: NotPackedProperlyActived,
  },
  {
    id: 3,
    name: 'Items were defective before ship',
    icon: Defective,
    iconActived: DefectiveActived,
  },
  {
    id: 4,
    name: 'None of these',
    icon: NoneOfThese,
    iconActived: NoneOfTheseActived,
  },
]

const DamageList = ({ items, selected, setSelected, isRequiredTwo }) => {
  const [actived, setActived] = useState('')
  const {
    orderData,
    orderItems,
    orderTracking,
    selectedClaimType,
    onSubmitItem,
    damageId,
    setDamageId,
  } = useClaimPortalContext()
  const onSelected = useCallback(
    (itemId) => {
      console.log(itemId)
      setDamageId(itemId)
      console.log(damageId)
      console.log(selectedClaimType)
      return isRequiredTwo(true)
    },
    [selected]
  )

  return (
    <>
      {damages?.map((item) => (
        <DamagesItems
          key={item.id}
          item={item}
          actived={item.id === actived}
          onClick={(value) => {
            onSelected(item.id)
            setActived(item.id)
          }}
          onSelect={() => onSelected(item.id)}
        />
      ))}
    </>
  )
}

export default memo(DamageList)
