import React, { useState } from 'react'
import { Modal, Button, Spinner } from 'react-bootstrap'
import _map from 'lodash/map'
import _kebabCase from 'lodash/kebabCase'
import './styles.scss'

const deniedReason = [
  'Insufficient Shipper Packaging',
  'Return to Sender',
  'Package Arrived',
  'Claim Timeframe Expired',
  'Claim Type Not Covered',
  'Customer Shipping Address Typo',
  'Delivery Photo Verified',
  'Excessive Claim Threshold Reached',
]

const SelectOption = ({ isSelected, onClick, text }) => (
  <div onClick={onClick} className={`my-2 select-item ${isSelected ? 'selected' : ''}`}>
    {text}
  </div>
)

const ClaimDenyModal = ({ show, onHide, onNext, isLoading }) => {
  const [selected, setSelected] = useState('')

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      show={show}
      className="deny-option-modal-wrapper"
      onHide={() => onHide()}
    >
      <Modal.Header closeButton>
        <div className="font-weight-bold">
          <span>Please select the reason</span>
          {isLoading === true && (
            <Spinner size="sm" className="ml-2" animation="border" variant="success" />
          )}
        </div>
      </Modal.Header>
      <Modal.Body className="deny-modal-body">
        <div className="select-options">
          {_map(deniedReason, (reason) => {
            const key = _kebabCase(reason)

            return (
              <SelectOption
                key={key}
                text={reason}
                isSelected={_kebabCase(selected) === key}
                onClick={() => setSelected(reason)}
              />
            )
          })}
        </div>
        <div className="float-right mt-3">
          <Button
            disabled={isLoading || selected === ''}
            variant="danger"
            className="mr-2"
            onClick={() => onNext(selected)}
          >
            Deny Claim
          </Button>
          <Button disabled={isLoading} variant="secondary" onClick={() => onHide()}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default React.memo(ClaimDenyModal)
