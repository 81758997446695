import React from 'react'
import { Combobox } from '@headlessui/react'
import type { ProductResults } from './types'

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(' ')
}

export function ProductSearchItemComboBox(data: ProductResults) {
  return (
    <Combobox.Option
      key={data.id}
      value={data}
      className={({ active, selected }) =>
        classNames(
          'flex cursor-pointer select-none rounded-xl p-3',
          active && 'bg-gray-100',
          selected && 'bg-gray-200'
        )
      }
    >
      {({ active }) => {
        return (
          <>
            <div className={classNames('flex flex-none items-center justify-center rounded-lg')}>
              <img className="object-fill  h-20 w-14 border-none" src={data.image?.url} alt="" />
            </div>
            <div className="ml-4  flex-auto">
              <div className="flex flex-row justify-between ">
                <p
                  className={classNames(
                    'text-sm font-medium my-0',
                    active ? 'text-gray-900' : 'text-gray-700'
                  )}
                >
                  {data.displayName}
                </p>
                <p
                  className={classNames(
                    'text-sm font-medium my-0',
                    active ? 'text-gray-900' : 'text-gray-700'
                  )}
                >
                  ${data.price}
                </p>
              </div>
              <p className={classNames('text-sm my-0', active ? 'text-gray-700' : 'text-gray-500')}>
                SKU: {data.sku}
              </p>
              <p className={classNames('text-sm my-0', active ? 'text-gray-700' : 'text-gray-500')}>
                Stock: {data.inventoryQuantity}
              </p>
            </div>
          </>
        )
      }}
    </Combobox.Option>
  )
}
