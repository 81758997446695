import React, { memo, useState, useEffect, useCallback, useMemo } from 'react'
import _get from 'lodash/get'
import { Form } from 'react-bootstrap'
import LoadScript from 'load-script'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { googleApiKey } from '../../configuration/google-maps'
import './styles.scss'

const GooglePlacesAddressInput = ({ onAddressChange }) => {
  const [scriptLoaded, setScriptLoaded] = useState(typeof window.google !== 'undefined')

  const [currentAddress, setCurrentAddress] = useState({
    coordinates: {},
    address: '',
  })

  const onChangeAddress = useCallback(
    (newAddress) => {
      setCurrentAddress({ ...currentAddress, address: newAddress })
    },
    [currentAddress]
  )

  const handleSelectItem = useCallback(
    (address) => {
      setCurrentAddress({ ...currentAddress, address })
      geocodeByAddress(address)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          setCurrentAddress({ address, coordinates: latLng })
          onAddressChange({ address, coordinates: latLng })
        })
        .catch((error) => console.error('Error', error))
    },
    [currentAddress]
  )

  const addressText = useMemo(() => _get(currentAddress, 'address', ''), [currentAddress])

  useEffect(() => {
    if (scriptLoaded === false) {
      LoadScript(
        `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`,
        (error, _script) => {
          if (error) {
            console.log(error)
          } else {
            setScriptLoaded(true)
          }
        }
      )
    }
  }, [])

  if (scriptLoaded === false) {
    return null
  }

  return (
    <PlacesAutocomplete
      value={addressText}
      onChange={onChangeAddress}
      onSelect={handleSelectItem}
      debounce={1000}
      shouldFetchSuggestions={addressText.length >= 3}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="position-relative gmap-auto-complete-wrapper">
          <Form.Control
            type="text"
            {...getInputProps({
              placeholder: 'Search for address...',
            })}
            className="w-100"
          />
          <div className="suggestion-container w-100 opacity-50 position-absolute overflow-hidden bg-white">
            {suggestions.map((suggestion, index) => {
              return (
                <div
                  {...getSuggestionItemProps(suggestion)}
                  className="py-1 suggestion-item"
                  key={index}
                >
                  <span className="">{suggestion.description}</span>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default memo(GooglePlacesAddressInput)
