import { memo, useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _find from 'lodash/find'
import _kebabCase from 'lodash/kebabCase'
import format from 'string-template'

import ErrorImage from '../../../assets/claim-portal/error-image.svg'
import WarningImage from '../../../assets/claim-portal/waiting.svg'
import { messageTypes } from '../configuration'
import { useClaimPortalContext } from '../../../context/claim-portal-context'
import ClaimOrderDetails from './ClaimOrderDetails'

import './styles.scss'

const ClaimPortalMessage = () => {
  const { params } = useRouteMatch(`/submitClaim/message/:messageType`)
  const { orderData, orderTracking, orderItems } = useClaimPortalContext()
  const history = useHistory()

  const messageType = useMemo(() => _get(params, 'messageType', ''), [params])
  const currentMessage = useMemo(
    () => _find(messageTypes, (type) => _kebabCase(type.name) === messageType),
    [messageType]
  )
  const isError = useMemo(() => _get(currentMessage, 'isError') === true, [currentMessage])
  const errorText = useMemo(() => (isError === true ? 'Not eligible for claim' : ''), [isError])

  if (_isEmpty(currentMessage) === true) {
    history.push('/submitClaim')

    return <div />
  }

  const searchParams = Object.fromEntries(new URLSearchParams(window.location.search).entries())
  const message = format(currentMessage.text, {
    claim_filing_window: '45',
    ...searchParams,
  })

  return (
    <div className="claim-message-wrapper">
      <h1 className="title">{isError === true ? 'Not Eligible' : 'Hang tight!'}</h1>
      {isError === true && <label className="description">{message}</label>}
      <div className="image-bg d-flex flex-column">
        {isError === false && (
          <>
            <div className="sub-text">{message}</div>
            <div className="info-text bold">
              {history?.location?.state ? 'Claim Eligible: ' + history?.location?.state : ''}
            </div>
          </>
        )}
        <div className="error-text">{errorText}</div>
      </div>

      <div className="centered">
        <img className="center-img" src={isError === true ? ErrorImage : WarningImage} alt="" />
      </div>

      <ClaimOrderDetails
        orderItems={orderItems}
        orderData={orderData}
        orderTracking={orderTracking}
        isError={isError}
      />
    </div>
  )
}

export default memo(ClaimPortalMessage)
