import axios, { type AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'
import config from '@/config'

type RequestConfig = {
  baseURL?: string
  retries?: number
  options?: object
}

export const createRequest = ({ baseURL, retries, options = {} }: RequestConfig): AxiosInstance => {
  const request = axios.create({
    baseURL: baseURL || config.api.url,
    withCredentials: true,
    ...options,
  })

  axiosRetry(request, {
    retries: retries || 3, // number of retries
    retryDelay: (retryCount) => {
      return retryCount * 1000 // delay between retries, in milliseconds
    },
    retryCondition: (error) => {
      return axiosRetry.isNetworkOrIdempotentRequestError(error) // retry on network errors and idempotent requests
    },
  })

  return request
}

const requestServerless = createRequest({ baseURL: config.api.serverless, retries: 0 })

export { requestServerless }
export default createRequest({ baseURL: config.api.url, retries: 3 })
