import React, { memo, useMemo } from 'react'
import _range from 'lodash/range'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _isEmpty from 'lodash/isEmpty'

import { Row, Col, Dropdown } from 'react-bootstrap'
import './styles.scss'

import { formatMoney } from '../../../../helpers/date-time-helper'
import { RESHIP, REFUND, RESOLUTION } from '../../../../constants/admin-order-detail'
import PlatformItem from '../../../../helpers/integrations/item-adapter'

const pecentageOptions = [10, 15, 25, 50, 100]

const ItemLine = ({ item, orderData, onUpdateSelectedData }) => {
  const platformItem = new PlatformItem(item, _get(orderData, 'platform_id', 1))
  const oneTimeAppeasementsSettings = useMemo(
    () => _get(orderData, 'claims[0].oneTimeAppeasementClaim.settings_snapshot', null),
    [orderData]
  )
  const disableInternaltionalShipment = useMemo(
    () => _get(orderData, 'storeSettings.disable_reshipment', false),
    [orderData]
  )
  const isReshipInternaltional = useMemo(() => {
    if (disableInternaltionalShipment === false) {
      return false
    }

    const originalItem = _find(
      _get(orderData, 'order_json.line_items', []),
      (itemLine) => itemLine.id.toString() === item.line_item_id
    )
    const orderShippingAddress = _get(orderData, 'shipping_address', '').split('\n')
    const itemShipFrom = _get(originalItem, 'origin_location.country_code', '').toLowerCase()
    const itemShipTo = _get(
      orderShippingAddress,
      orderShippingAddress.length - 1,
      'toTheMoon'
    ).toLowerCase()

    return itemShipFrom !== itemShipTo
  }, [disableInternaltionalShipment, orderData, item])

  const enableReship = useMemo(() => {
    if (oneTimeAppeasementsSettings === null) {
      return true && isReshipInternaltional === false
    }

    return (
      (oneTimeAppeasementsSettings?.selectedMethods || [].includes('reship')) &&
      isReshipInternaltional === false
    )
  }, [oneTimeAppeasementsSettings, isReshipInternaltional])

  const enableRefund = useMemo(() => {
    if (oneTimeAppeasementsSettings === null) {
      return true
    }

    return (
      oneTimeAppeasementsSettings?.selectedMethods?.includes('original_payment') ||
      oneTimeAppeasementsSettings?.selectedMethods.includes('store_credit')
    )
  }, [oneTimeAppeasementsSettings])
  const continueWhenOutOfStock = useMemo(() => platformItem.continueWhenOutOfStock(), [item])
  const isNotTrack = useMemo(() => item?.variantData?.inventory_management === null, [item])
  const remainingItems = useMemo(() => platformItem.remainingItems(), [item])

  const isOutOfStock = useMemo(() => remainingItems <= 0, [remainingItems])
  const resolutionData = useMemo(() => _get(item, 'resolutionData', {}), [item])

  const isReship = useMemo(() => {
    const oneTimeAppeasement = _get(orderData, 'one_time_appeasement.selectedMethods', [])
    const OTAClaim = _get(orderData, 'claims[0].ota_claim_id', null)
    if (OTAClaim != null && (oneTimeAppeasement.length == 2 || oneTimeAppeasement.length == 0)) {
      if (enableReship && enableRefund === false) {
        return true
      }

      if (enableRefund && enableReship === false) {
        return false
      }
    } else {
      if (enableReship && enableRefund === false) {
        return true
      }

      if (enableRefund && enableReship === false) {
        return false
      }
      if (OTAClaim != null && oneTimeAppeasement.includes('reship')) {
        return true
      } else if (OTAClaim != null && oneTimeAppeasement.includes('original_payment')) {
        return false
      }
    }

    return _get(resolutionData, 'isReship', true)
  }, [resolutionData])

  const autoRefund = useMemo(() => {
    if (isReship && resolutionData.quantity > remainingItems && continueWhenOutOfStock === false) {
      return remainingItems >= 0
        ? resolutionData.quantity - remainingItems
        : resolutionData.quantity
    }
    return 0
  }, [remainingItems, isReship, resolutionData, continueWhenOutOfStock])

  const calculateAutoRefund = (isReship, itemQuantity) => {
    if (!isReship || continueWhenOutOfStock) {
      return 0
    }

    if (itemQuantity > remainingItems) {
      return remainingItems >= 0 ? itemQuantity - remainingItems : itemQuantity
    }
    return 0
  }

  return (
    <div className="border-bottom py-3" key={item?.id}>
      <Row>
        <Col className="mb-2" xs={6} xl={4}>
          <div className="product-group">
            <div
              className={`image-product ${platformItem.isOutOfStockItem() ? 'out-of-stock' : ''}`}
            >
              <img
                className="img-default"
                src={
                  item?.photo ||
                  'https://order-protection-static.s3-us-west-1.amazonaws.com/defaultItemImage.png'
                }
                alt="product"
              />
              <div className="badge">Out of stock</div>
            </div>
            <div className="content-product">
              <div className="d-flex align-items-center justify-content-between">
                <p className="text-default medium bold my-1">{item?.item_name || ''}</p>
                <p className="text-default my-1 d-none-xl">${formatMoney(item?.price || 0)}</p>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <p className="text-default my-1">SKU: {item?.variantData?.sku}</p>
                <p className="text-default my-1 d-none-xl">x {item?.quantity}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="text-default my-1">{item?.variantData?.title}</p>
                <p className="text-default my-1 d-none-xl">
                  <p className="text-default mb-0 line-through">${formatMoney(item?.price || 0)}</p>
                  <p className="text-default mb-0">${formatMoney(item.itemPrice)}</p>
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <p className="text-default my-1">
                  Stock: {isNotTrack ? 'Not Tracked' : item?.variantData?.inventory_quantity}{' '}
                  {continueWhenOutOfStock === false ? '' : '(Continue Selling When Out of Stock)'}
                </p>
              </div>
              {item?.refunded_quantity && (
                <div>
                  {' '}
                  <b>Refunded Quantity:</b> {item?.refunded_quantity}
                </div>
              )}
            </div>
          </div>
        </Col>
        <Col className="mb-2 d-flex-xl align-items-center justify-content-center" xs={3} xl={1}>
          <span className="text-default">{item?.quantity || 1}</span>
        </Col>
        <Col
          className="mb-2 d-flex-xl flex-column align-items-center justify-content-center"
          xs={1}
        >
          <span className="text-default line-through">${formatMoney(item?.price || 0)}</span>
          <span className="text-default mt-1 font-weight-bold">${formatMoney(item.itemPrice)}</span>
        </Col>
        <Col className="mb-2 d-flex align-items-center justify-content-center" xs={3} xl={2}>
          <button className={`btn-default btn-table ${item?.isClaim ? 'pink-200' : 'success'}`}>
            {item.isClaim ? orderData?.claims?.[0]?.claim_type_name : 'Okay'}
          </button>
        </Col>
        <Col xs={2} className="d-flex flex-column align-items-center justify-content-center">
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
              {isReship ? RESHIP : REFUND}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {_map(RESOLUTION, (value) => (
                <Dropdown.Item
                  key={value}
                  onClick={() => {
                    onUpdateSelectedData(item.line_item_id, {
                      ...resolutionData,
                      isReship: value === RESHIP ? true : false,
                      autoRefund: calculateAutoRefund(value === RESHIP, resolutionData.quantity),
                      ...(value === RESHIP ? true : false && { percentage: 100 }),
                    })
                  }}
                  disabled={_isEmpty(item.variant_id) && value === RESHIP ? true : false}
                  className={_isEmpty(item.variant_id) && value === RESHIP ? 'reship-button' : null}
                  title={
                    _isEmpty(item.variant_id) && value === RESHIP
                      ? 'Unable to reship this order due to invalid products'
                      : null
                  }
                >
                  {value}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {autoRefund > 0 && (
            <div className="w-full text-center">{autoRefund} item(s) will be refunded</div>
          )}
        </Col>
        <Col xs={1} className="d-flex align-items-center justify-content-center">
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
              {resolutionData.quantity}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {_map(_range(item?.quantity + 1), (value) => {
                return (
                  <Dropdown.Item
                    key={`${item?.id}${value}`}
                    onClick={() => {
                      onUpdateSelectedData(item?.line_item_id || item?.id, {
                        ...resolutionData,
                        id: item?.id,
                        quantity: parseInt(value),
                        autoRefund: calculateAutoRefund(resolutionData.isReship, value),
                      })
                    }}
                  >
                    {value}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col xs={1} className="d-flex align-items-center justify-content-center">
          <Dropdown>
            <Dropdown.Toggle disabled={isReship} variant="outline-secondary" id="dropdown-basic">
              {resolutionData.percentage}%
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {_map(pecentageOptions, (value) => (
                <Dropdown.Item
                  key={value}
                  onClick={() => {
                    onUpdateSelectedData(item?.line_item_id, {
                      ...resolutionData,
                      percentage: parseInt(value),
                    })
                  }}
                >
                  {value}%
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </div>
  )
}

const ClaimItems = ({ items, orderData, onUpdateSelectedData }) => {
  return (
    <div className="order-items claim-item-wrapper">
      <div className="table-order-items">
        <div className="content-table-order-items">
          <div className="border-bottom py-2">
            <Row>
              <Col className="mb-2" xs={4}>
                <span className="text-default bold big">Selected Items ({items?.length})</span>
              </Col>
              <Col className="mb-2 text-center d-block-xl" xl={1}>
                <span className="text-default bold pr-3">Request Quantity</span>
              </Col>
              <Col className="mb-2 text-center d-block-xl" xl={1}>
                <span className="text-default bold pr-3">Price</span>
              </Col>
              <Col className="mb-2 text-center" xs={2}>
                <span className="text-default bold pr-3">Claim Type</span>
              </Col>
              <Col className="mb-2 text-center" xs={2}>
                <span className="text-default bold pr-3">Resolution</span>
              </Col>
              <Col className="mb-2 text-center" xs={1}>
                <span className="text-default bold pr-3">Selected Quantity</span>
              </Col>
              <Col className="mb-2 text-center" xs={1}>
                <span className="text-default bold pr-3">Percentage</span>
              </Col>
            </Row>
          </div>
          {items?.map((item, index) => (
            <ItemLine
              item={item}
              key={index}
              orderData={orderData}
              onUpdateSelectedData={onUpdateSelectedData}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default memo(ClaimItems)
