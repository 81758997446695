import classNames from 'classnames'
import { HTMLDivProps } from '../../types'
import { ErrorMessage } from './ErrorMessage'
import { Field, FieldProps } from './Field'
import { Form as HeadlessForm, Props as HeadlessFromProps } from './Form'
import { Input, InputProps } from './Input'

export type FormProps = HeadlessFromProps
// eslint-disable-next-line no-empty-pattern
export const Form = (props: FormProps) => <HeadlessForm {...props} />

const FormInput = (props: FieldProps & Omit<InputProps, keyof FieldProps>) => (
  <Field {...props} component={Input} />
)

const FormErrorMessage = ({ name, className, ...restProps }: HTMLDivProps & { name: string }) => (
  <ErrorMessage name={name}>
    {(message) =>
      message ? (
        <p
          className={classNames('text-danger', 'flex-1 whitespace-pre-line', className)}
          {...restProps}
          data-testid={`error_${name}`}
        >
          {message}
        </p>
      ) : null
    }
  </ErrorMessage>
)

Form.Input = FormInput
Form.ErrorMessage = FormErrorMessage
