import React, { memo } from 'react'
import './styles.scss'

const FirstProductItem = ({ item, orderId, resetView }) => {
  console.log(item)
  return (
    <>
      <div className="item-wrapper">
        <div className="wrap-photo">
          <img
            className="photo"
            src={
              item?.photo ||
              'https://order-protection-static.s3-us-west-1.amazonaws.com/defaultItemImage.png'
            }
            alt="order"
          />
        </div>
        <div className="wrap-name">
          <span>{item.item_name}</span>
        </div>
      </div>
    </>
  )
}

export default memo(FirstProductItem)
