import React, { useState } from 'react'
import { ListGroup, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _keys from 'lodash/keys'
import classnames from 'classnames'
import './styles.scss'
import MacroContent from './macro-content'
import { macroNameParser, macrosKeybyFolder } from '../../helpers/macro-helper'

const MacroSettings = ({ macros, onSaveMacro, onClose, onDeleteMacro, storeId }) => {
  const [currentMacro, setCurrentMacro] = useState({})
  const hexcladStore = [352, 353]
  const hiddenMacros = macros.filter((macro) => {
    return (
      macro.store_id === storeId ||
      macro.store_id === -1 ||
      (macro.store_id === 352 && hexcladStore.includes(storeId))
    )
  })
  const macrosObject = macrosKeybyFolder(hiddenMacros)

  return (
    <div className="d-flex flex-column p-4 macro-setting-wrapper">
      <div className="d-flex justify-content-between header">
        <h2 className="header-text">Macros</h2>
        <div onClick={() => onClose()} className="close-button">
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
      <div className="d-flex main-container mt-3">
        <div className="list-macros">
          <ListGroup className="list-group">
            {_map(_keys(macrosObject), (folderName) => (
              <>
                {folderName && (
                  <ListGroup.Item className="item item--folder" key={folderName}>
                    {folderName}
                  </ListGroup.Item>
                )}

                {_map(macrosObject[folderName], (macro) => (
                  <ListGroup.Item
                    onClick={() => setCurrentMacro(macro)}
                    className={classnames('item', {
                      'item--child': !!folderName,
                    })}
                    key={macro.macro_id}
                    {...(_get(currentMacro, 'macro_id') === macro.macro_id && {
                      variant: 'primary',
                    })}
                  >
                    {macroNameParser(macro.macro_name)}
                  </ListGroup.Item>
                ))}
              </>
            ))}
          </ListGroup>
          <Button className="w-100 mt-2" onClick={() => setCurrentMacro({})}>
            Create New Macro
          </Button>
        </div>
        <MacroContent
          macro={currentMacro}
          folders={_keys(macrosObject)}
          onSave={(macroData) => onSaveMacro(macroData, (macroRes) => setCurrentMacro(macroRes))}
          onDeleteMacro={(macroId) => onDeleteMacro(macroId, () => setCurrentMacro({}))}
        />
      </div>
    </div>
  )
}

export default React.memo(MacroSettings)
