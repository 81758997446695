import { Container, Row, Col } from 'react-bootstrap'

import Successfully from '../../../assets/claim-portal/claim-successfully.png'
import ArrowRight from '../../../assets/claim-portal/arrow-right.svg'
import Tracking from '../../../assets/claim-portal/tracking.png'
import './index.scss'

export default function ClaimType() {
  return (
    <div className="body-claim-process">
      <Container>
        <div className="content-claim-process">
          <Row>
            <Col lg={7}>
              <div className="content-left">
                <h1 className="title">Claims</h1>
                <h2 className="heading">
                  There are two different deliveries associated with your order #6287810
                </h2>
                <div className="content-product">
                  <p className="item-total">8 Items Total</p>
                  <div className="group-product">
                    <div className="group-img-product">
                      <img className="img-default" src={Successfully} alt="product" />
                    </div>
                    <div className="content-product">
                      <label className="text name-product">
                        IKONIK LAPTOP SLEEVE UNISEX - Funda para portátil
                      </label>
                      <div className="group-btn-action column">
                        <button className="btn-action in-transit">In Transit</button>
                      </div>
                    </div>
                  </div>
                  <p className="add-item">+ Items in this shipment</p>
                </div>
                <p className="desciption">
                  It looks like your order might be lost. UPS hasn’t updated it’s status since Dec
                  3rd. Is that what you’re trying to resolve today?
                </p>
                <div className="group-btn-action">
                  <button className="btn-action btn-answer">Yes</button>
                  <button className="btn-action btn-answer">No</button>
                </div>
                <div className="group-btn-submit column">
                  <p className="text-link">Locate another order</p>
                  <button className="btn-submit">Continue</button>
                </div>
              </div>
            </Col>
            <Col lg={5}>
              <div className="process-form">
                <h3 className="heading">Jack & Jones - JHERRO CREW NECK Sudader</h3>
                <div className="group-product">
                  <div className="mr-4 group-image-tracking">
                    <img className="img-default" src={Tracking} alt="product" />
                  </div>
                  <div className="content-product">
                    <label className="text mb-1">
                      Tracking ID: <span className="font-weight-bold">EZ9394390202903023</span>
                    </label>
                    <label className="text mb-1">
                      Location: <span className="font-weight-bold">South San Francisco CA, US</span>
                    </label>
                    <label className="text mb-1">
                      Carrier: <span className="font-weight-bold">FedEX</span>
                    </label>
                  </div>
                </div>
                <ul className="claim-timeline">
                  <li className="claim-timeline-item">
                    <div className="claim-timeline-item-tail"></div>
                    <div className="claim-timeline-item-head"></div>
                    <div className="claim-timeline-item-content">
                      <p className="name-time-line">Order Received</p>
                      <p className="date-time-line">03/11/2021 09:10AM</p>
                    </div>
                  </li>
                  <li className="claim-timeline-item">
                    <div className="claim-timeline-item-tail"></div>
                    <div className="claim-timeline-item-head"></div>
                    <div className="claim-timeline-item-content">
                      <p className="name-time-line">Ready to Ship</p>
                      <p className="date-time-line">03/20/2021 09:10AM</p>
                    </div>
                  </li>
                  <li className="claim-timeline-item claim-timeline-item-waiting">
                    <div className="claim-timeline-item-tail"></div>
                    <div className="claim-timeline-item-head"></div>
                    <div className="claim-timeline-item-content">
                      <p className="name-time-line">In Transit</p>
                      <p className="date-time-line">03/20/2021 09:10AM</p>
                      <div className="time-line-child">
                        <div className="claim-timeline-item-content-child">
                          <p className="name-time-line">Origin Scan</p>
                          <p className="date-time-line">03/26/2021 08:11AM</p>
                        </div>
                        <div className="claim-timeline-item-content-child">
                          <p className="name-time-line">Origin Scan</p>
                          <p className="date-time-line">03/26/2021 08:11AM</p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="claim-timeline-item claim-timeline-item-waiting">
                    <div className="claim-timeline-item-tail"></div>
                    <div className="claim-timeline-item-head"></div>
                    <div className="claim-timeline-item-content">
                      <p className="name-time-line">Out for Delivery</p>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}
