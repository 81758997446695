import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import _find from 'lodash/find'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _orderBy from 'lodash/orderBy'
import _startCase from 'lodash/startCase'
import _toLower from 'lodash/toLower'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Accordion, Dropdown, Nav } from 'react-bootstrap'
import { Link, matchPath, useHistory, useLocation } from 'react-router-dom'
import { toLocaleString } from '../../../support/utils/number'

import ClaimIcon from '../../../assets/ClaimIcon.svg'
import SettingsIcon from '../../../assets/SettingsIcon.svg'
import Down from '../../../assets/icon-down.svg'
import Up from '../../../assets/icon-up.svg'
import LockIcon from '../../../assets/lock.svg'
import Logo from '../../../assets/op-logo-black.svg'

import PermissionWrapper from '../../../components/PermissionWrapper'
import SearchBox from '../../../components/SearchBox'
import { PermissionSet } from '../../../configuration/permission-set'
import { useAdminContext } from '../../../context/admin-context'
import { convertSpecialChar } from '../../../helpers/convert-special-char'
import {
  AddIcon,
  AllOrdersIcon,
  AnalyticsIcon,
  ApproveIcon,
  BillingIcon3,
  DeniedIcon,
  ClosedIcon,
  MacroIcon,
  OpenIcon,
  PayoutIcon,
  PolicyIcon,
  SettingIcon2,
  StoreIcon,
  Users,
} from './icon'
import './styles.scss'

const CLAIM_TYPES = {
  NEW: 'new',
  OPEN: 'open',
  APPROVED: 'approved',
  DENIED: 'denied',
  DISMISS: 'dismissed',
  CLOSED: 'closed',
  ALL: 'all',
}

const SubItem = ({ path, label, number, onClick, active, icon }) => {
  return (
    <Nav.Item className={`d-flex sub-item pt-2 pb-2 pl-5 pr-3 ${active ? 'active' : ''}`}>
      <div className="d-flex align-items-center mr-2">
        {icon}
        <Link to={path} className="sidebar-text" onClick={() => onClick && onClick(false)}>
          {label}
        </Link>
      </div>
      {number && <div className="">{number}</div>}
    </Nav.Item>
  )
}

const COLLAPSE_MENU_GROUP_MAP = new Map([
  [
    'collapse-1', // eventKey
    {
      root: ['claims', 'orders'],
    },
  ],
  [
    'collapse-2', // eventKey
    {
      root: ['admin'],
    },
  ],
])

const findActiveCollapseKey = (locationPathName) => {
  const key = Array.from(COLLAPSE_MENU_GROUP_MAP.keys()).find((key) =>
    matchPath(
      locationPathName,
      COLLAPSE_MENU_GROUP_MAP.get(key).root.map((rootPath) => `/${rootPath}`)
    )
  )

  return key || ''
}

const SideBar = ({
  newClaim,
  openClaim,
  approvedClaim,
  deniedClaim,
  closedClaim,
  totalOrder,
  setToggleMenu,
  resetNewOrder,
  setOpenMacro,
}) => {
  const location = useLocation()
  const [active, setActive] = useState(findActiveCollapseKey(location.pathname))
  const history = useHistory()
  const {
    selectedStoreId,
    setSelectedStoreId,
    isOpenMobileMenu,
    onChangeFilter,
    currentPage,
    userPermission,
    setSelectedClaimTab,
    selectedClaimTab,
  } = useAdminContext()
  const showAllStores = useMemo(() => {
    if (typeof userPermission === 'undefined') {
      return false
    }

    return (userPermission.permission || []).includes(PermissionSet.ListAllStore)
  }, [userPermission])

  const selectedStore = useMemo(() => {
    if (typeof userPermission === 'undefined') {
      return {
        store_url: 'Loading...',
      }
    }

    const { stores = [] } = userPermission

    if (selectedStoreId === 0) {
      return {
        store_url: 'all-stores',
      }
    }

    return _find(stores, (store) => store.id === selectedStoreId)
  }, [userPermission, selectedStoreId])

  const hasSelectedStore = useMemo(
    () => !_isEmpty(_find(userPermission?.stores, { id: selectedStoreId })),
    [userPermission, selectedStoreId]
  )

  const onChangeToggleMenu = useCallback(
    (event) => {
      if (currentPage > 1) {
        resetNewOrder()
        onChangeFilter([{ currentPage: 1 }])
      }
      setToggleMenu(event)
    },
    [currentPage, onChangeFilter, resetNewOrder, setToggleMenu]
  )

  const onClaimTabChange = useCallback(
    (event, claimTab) => {
      setSelectedClaimTab(claimTab)
      onChangeToggleMenu(event)
    },
    [onChangeToggleMenu, setSelectedClaimTab]
  )

  const claimMenuItems = useMemo(
    () => [
      {
        icon: <AddIcon />,
        onClick: (event) => onClaimTabChange(event, CLAIM_TYPES.NEW),
        path: '/claims/new',
        label: 'New Claims',
        number: toLocaleString(newClaim),
      },
      {
        icon: <OpenIcon />,
        onClick: (event) => onClaimTabChange(event, CLAIM_TYPES.OPEN),
        path: '/claims/open',
        label: 'Open Claims',
        number: toLocaleString(openClaim),
      },
      {
        icon: <ApproveIcon />,
        onClick: (event) => onClaimTabChange(event, CLAIM_TYPES.APPROVED),
        path: '/claims/approved',
        label: 'Approved Claims',
        number: toLocaleString(approvedClaim),
      },
      {
        icon: <DeniedIcon />,
        onClick: (event) => onClaimTabChange(event, CLAIM_TYPES.DENIED),
        path: '/claims/denied',
        label: 'Denied Claims',
        number: toLocaleString(deniedClaim),
      },
      {
        icon: <ClosedIcon />,
        onClick: (event) => onClaimTabChange(event, CLAIM_TYPES.CLOSED),
        path: '/claims/closed',
        label: 'Closed Claims',
        number: toLocaleString(closedClaim),
      },
      {
        icon: <AllOrdersIcon />,
        onClick: (event) => onClaimTabChange(event, CLAIM_TYPES.ALL),
        path: '/orders',
        label: 'All Orders',
        number: toLocaleString(totalOrder),
      },
    ],
    [approvedClaim, deniedClaim, closedClaim, newClaim, onClaimTabChange, openClaim, totalOrder]
  )
  const adminMenuItems = useMemo(
    () => [
      {
        icon: <StoreIcon />,
        path: '/admin/stores',
        label: 'Stores',
        onClick: () => {
          setToggleMenu()
        },
      },
      {
        icon: <Users />,
        path: '/admin/users',
        label: 'Users',
        onClick: () => {
          setToggleMenu()
        },
      },
      {
        icon: <MacroIcon />,
        onClick: () => {
          setOpenMacro(true)
          setToggleMenu()
        },
        path: '/admin/macros',
        label: 'Macros',
      },
    ],
    []
  )

  const getStoreName = useCallback((url) => _startCase(url?.replace('.myshopify.com', '')), [])

  const isActiveSubMenu = useCallback(
    (pathName) =>
      matchPath(location.pathname, {
        path: pathName,
        exact: true,
      }) !== null,
    [location.pathname]
  )

  const clickMenu = (pathName) => {
    history.push(pathName)
    setToggleMenu()
    setActive()
  }

  const activeMenu = useCallback(
    (name) => {
      setActive(active === name ? '' : name)
    },
    [active, setActive]
  )

  useEffect(() => {
    setActive(findActiveCollapseKey(location.pathname))
  }, [location.pathname])

  useEffect(() => {
    const pathMatch = matchPath(location.pathname, '/claims/:filterType')

    if (
      pathMatch !== null &&
      pathMatch.params.filterType != null &&
      pathMatch.params.filterType !== selectedClaimTab
    ) {
      const predefinedTab = Object.values(CLAIM_TYPES).find(
        (v) => v === _toLower(pathMatch.params.filterType)
      )

      if (predefinedTab) {
        setSelectedClaimTab(predefinedTab)
      }
    }
  }, [])

  return (
    <div className="side-bar-wrapper d-flex flex-column">
      <div className="d-flex flex-column">
        <Link to="/admin">
          <div className="logo">
            <img src={Logo} alt="home" />
          </div>
        </Link>
        <div className={`flex-column search-area ${isOpenMobileMenu ? 'd-flex' : 'd-none'}`}>
          <SearchBox
            stores={userPermission?.stores || []}
            getStoreName={getStoreName}
            closeMenu={setToggleMenu}
          />
          <div className="mt-2 w-100">
            <Dropdown className="select-store side-bar-select-store">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className={classnames({ 'store-selected': hasSelectedStore })}
              >
                <div className="dropdown-title">
                  <img src={LockIcon} alt="" />
                  <div className="dropdown-title__text">
                    {selectedStore?.store_name || getStoreName(selectedStore?.store_url)}
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {showAllStores && (
                  <Dropdown.Item onClick={() => setSelectedStoreId(0)}>All Stores</Dropdown.Item>
                )}

                {_map(
                  _orderBy(
                    userPermission?.stores || [],
                    [
                      (store) => {
                        return store.store_name !== 'Unknown'
                          ? convertSpecialChar(store.store_name).toLowerCase()
                          : convertSpecialChar(getStoreName(store?.store_url)).toLowerCase()
                      },
                    ],
                    ['asc']
                  ).filter((item) => item.hide_in_list !== true),
                  (store, index) => (
                    <Dropdown.Item onClick={() => setSelectedStoreId(store.id)} key={index}>
                      {store?.store_name || getStoreName(store.store_url)}
                    </Dropdown.Item>
                  )
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <Accordion activeKey={active}>
          <Accordion.Toggle
            as={Link}
            variant="link"
            eventKey="collapse-1"
            className="sidebar-text sidebar-parent p-3"
            onClick={() => activeMenu('collapse-1')}
          >
            <img className="sidebar-icon pr-3" alt="claim icon" src={ClaimIcon} />
            <span>Claims</span>
            <img className="ml-auto" src={active === 'collapse-1' ? Up : Down} alt="icon" />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="collapse-1">
            <>
              {claimMenuItems.map((item) => (
                <SubItem
                  icon={item.icon}
                  onClick={item.onClick}
                  active={isActiveSubMenu(item.path)}
                  path={item.path}
                  label={item.label}
                  number={item.number}
                  key={item.path}
                />
              ))}
            </>
          </Accordion.Collapse>
          <Accordion.Toggle
            as={Link}
            variant="link"
            eventKey="analytics"
            className={classnames('sidebar-text sidebar-parent p-3', {
              active: isActiveSubMenu('/analytics'),
            })}
            onClick={() => clickMenu('/analytics')}
          >
            <AnalyticsIcon className="sidebar-icon" alt="analytics icon" />
            <span className="ml-3">Analytics</span>
          </Accordion.Toggle>
          <PermissionWrapper set={[PermissionSet.BillingView]}>
            <Accordion.Toggle
              as={Link}
              variant="link"
              eventKey="setting"
              className={classnames('sidebar-text sidebar-parent p-3', {
                active: isActiveSubMenu('/billing'),
              })}
              onClick={() => clickMenu('/billing')}
            >
              <BillingIcon3 className="sidebar-icon" alt="billing icon" />
              <span className="ml-3">Billing</span>
            </Accordion.Toggle>
            <Accordion.Toggle
              as={Link}
              variant="link"
              eventKey="setting"
              className={classnames('sidebar-text sidebar-parent p-3', {
                active: isActiveSubMenu('/payouts'),
              })}
              onClick={() => clickMenu('/payouts')}
            >
              <PayoutIcon className="sidebar-icon" alt="payout icon" />
              <span className="ml-3">Payouts</span>
            </Accordion.Toggle>
          </PermissionWrapper>
          <PermissionWrapper set={[PermissionSet.User]}>
            <Accordion.Toggle
              as={Link}
              variant="link"
              eventKey="setting"
              className={classnames('sidebar-text sidebar-parent p-3', {
                active: isActiveSubMenu('/users'),
              })}
              onClick={() => clickMenu('/users')}
            >
              <FontAwesomeIcon icon={faUserCircle} />
              <span className="ml-3">Team Members</span>
            </Accordion.Toggle>
          </PermissionWrapper>
          <PermissionWrapper set={[PermissionSet.ViewStoreSettings]}>
            <Accordion.Toggle
              as={Link}
              variant="link"
              eventKey="setting"
              className={classnames('sidebar-text sidebar-parent p-3', {
                active: isActiveSubMenu('/settings'),
              })}
              onClick={() => clickMenu('/settings')}
            >
              <SettingIcon2 className="sidebar-icon" alt="settings toggle icon" />
              <span className="ml-3">Settings</span>
            </Accordion.Toggle>
          </PermissionWrapper>
          <PermissionWrapper set={[PermissionSet.AdminMenu]}>
            <Accordion.Toggle
              as={Link}
              variant="link"
              eventKey="collapse-2"
              className="sidebar-text sidebar-parent p-3"
              onClick={() => activeMenu('collapse-2')}
            >
              <img className="sidebar-icon pr-3" alt="settings icon" src={SettingsIcon} />
              <span>Admin</span>
              <img className="ml-auto" src={active === 'collapse-2' ? Up : Down} alt="icon" />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="collapse-2">
              <>
                {adminMenuItems.map((item) => (
                  <SubItem
                    className={classnames({
                      active: isActiveSubMenu(item.path),
                    })}
                    icon={item.icon}
                    onClick={item.onClick}
                    active={isActiveSubMenu(item.path)}
                    path={item.path}
                    label={item.label}
                    key={item.path}
                  />
                ))}
              </>
            </Accordion.Collapse>
          </PermissionWrapper>
          <Accordion.Toggle
            as={Link}
            variant="link"
            eventKey="policies"
            className={classnames('sidebar-text sidebar-parent p-3', {
              active: isActiveSubMenu('/policies'),
            })}
            onClick={() => clickMenu('/policies')}
          >
            <PolicyIcon className="sidebar-icon" alt="policies icon" />
            <span className="ml-3">Policies</span>
          </Accordion.Toggle>
        </Accordion>
      </div>
    </div>
  )
}

export default memo(SideBar)
