import React, { memo, useMemo } from 'react'
import _some from 'lodash/some'
import _includes from 'lodash/includes'
import _isEmpty from 'lodash/isEmpty'
import { useAdminContext } from '../../context/admin-context'

export const isPermissionIncluded = (permissions, set) =>
  _some(permissions, (permission) => _includes(set, permission))

const PermisionWrapper = ({ set, children }) => {
  const { userPermission } = useAdminContext()
  const allow = useMemo(() => {
    if (_isEmpty(userPermission)) {
      return false
    }

    return isPermissionIncluded(userPermission.permission, set)
  }, [set, userPermission])

  if (!allow) {
    return null
  }

  return <>{children}</>
}

export default memo(PermisionWrapper)
