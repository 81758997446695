import { Container } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import Successfully from '../../../assets/claim-portal/claim-successfully.png'
import './index.scss'
import CommunicationModal from '../CommunicationModal'

export default function ClaimType({ claimNumber, communicationInfo }) {
  const history = useHistory()

  return (
    <div className="body-claim-successfully">
      <Container>
        <div className="content-claim-successfully">
          <h1 className="title">Claim Submitted Successfully</h1>
          <h2 className="heading">Your claim number {claimNumber}</h2>
          <div className="wrap-description text-center">
            <p className="text">
              Thank you for submitting the claim. we have received your request and we will send you
              an email once the claim is approved.
            </p>
          </div>
          <div className="success-image-wrap">
            <img className="image" src={Successfully} alt="Claim Pre Identified" />
          </div>
          <p className="text-link">
            <Link to="/submitClaim">View Claim</Link>
          </p>
        </div>
      </Container>
      {communicationInfo && history?.action == 'REPLACE' && (
        <>
          <CommunicationModal communicationInfo={communicationInfo}></CommunicationModal>
        </>
      )}
    </div>
  )
}
