//import './ClaimItems.scss';

export default function DamagesItems({ item, onClick, actived }) {
  return (
    <div
      className={`damages-container ${actived ? 'actived' : ''}`}
      onClick={() => onClick && onClick(item.id)}
    >
      <div className="wrap-icon">
        <img
          className={`img-default icon-actived`}
          src={item?.iconActived || item?.icon}
          alt="damages"
        />
        <img className={`img-default icon-default`} src={item?.icon} alt="damages" />
      </div>
      {item?.name || ''}
    </div>
  )
}
