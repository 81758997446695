import React, { lazy, Suspense } from 'react'
import '../styles/errorMessage.scss'
import { Auth0Provider } from '@auth0/auth0-react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import MainAdmin from '../containers/admin-main'
import { captureException } from '@sentry/react'

const AdminLogin = lazy(() => import('../containers/admin-login'))


import conf from '../../src/config'

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true })
    console.debug(errorInfo)
    captureException(error)   
  }

  render() {
    if (this.state.hasError)
      return (
          <div className="h-screen w-screen flex items-center justify-center">
            <div className="serverErrorMessage">
              <h1>500</h1>
              <h3>Server Error</h3>
              <h2>Oops, we are experiencing a problem right now. Please try again later.</h2>
            </div>
          </div>
      )
    return this.props.children
  }
}

const AdminRoute = () => {
  return (
      <ErrorBoundary>
        <Auth0Provider
            domain={conf.auth0.domain}
            clientId={conf.auth0.clientId}
            redirectUri={window.location.origin}
            // audience={conf.auth0.audience}
        >
          <Router>
            <Switch>
              <Route exact path="/login">
                <Suspense fallback={<div>Loading...</div>}>
                  <AdminLogin />
                </Suspense>
              </Route>
              <MainAdmin />
            </Switch>
          </Router>
        </Auth0Provider>
      </ErrorBoundary>
  )
}

export default AdminRoute
