import './Register.scss'
import { Row, Col, Form, Button, Alert } from 'react-bootstrap'
import { useState } from 'react'
import axios from '@/support/utils/request'
import Error from '../Error/Error'
import config from '@/config'

axios.defaults.withCredentials = true

export default function Register(props) {
  const proxy = config.api.url

  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [error, setError] = useState({
    active: false,
    message: 'Default',
  })

  async function handleSubmit() {
    const data = {
      email,
      firstName,
      lastName,
      password,
      confirmPassword,
    }
    const contactInfo = {
      email,
      firstName,
      lastName,
    }
    //need to add addressTwo
    console.log(contactInfo)

    //Get Back profile and set the paymentData in state
    const validated = handleValidation(data)
    if (validated) {
      axios
        .post(`${proxy}/register`, data)
        .then(async () => {
          await axios.post(`${proxy}/quickbooks/createCustomer`, contactInfo)
          props.checkUserSession()
        })
        .catch((e) => {
          setError({
            active: true,
            message: e.response.data,
          })
        })
    } else {
      setError({
        active: true,
        message: 'The email address is not valid. Please try again.',
      })
    }
  }

  function handleValidation(data) {
    const email_regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return email_regexp.test(data.email)
  }

  function onKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      handleSubmit()
    }
  }

  return (
    <Col md={3} className="register">
      <Form className="form-container p-4 mt-5">
        <h3 className="text-center py-2">Register as a Merchant</h3>
        <Error error={error} />
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="Enter Email"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value)
            }}
            onKeyDown={onKeyDown}
          />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>
        <Row>
          <Col>
            <Form.Group controlId="formBasicFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter First Name"
                value={firstName}
                required
                onChange={(event) => {
                  setFirstName(event.target.value)
                }}
                onKeyDown={onKeyDown}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formBasicLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter Last Name"
                value={lastName}
                onChange={(event) => {
                  setLastName(event.target.value)
                }}
                onKeyDown={onKeyDown}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value)
            }}
            onKeyDown={onKeyDown}
          />
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder="Enter Password"
            value={confirmPassword}
            onChange={(event) => {
              setConfirmPassword(event.target.value)
            }}
            onKeyDown={onKeyDown}
          />
        </Form.Group>
        <Button
          required
          variant="primary"
          className="w-100 my-2"
          onClick={() => {
            handleSubmit()
          }}
        >
          Register
        </Button>
      </Form>
    </Col>
  )
}
