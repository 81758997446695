import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _indexOf from 'lodash/indexOf'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _some from 'lodash/some'
import _sumBy from 'lodash/sumBy'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { ProductSearchModal } from '../../../components/ProductSearchModal'
import { useAdminContext } from '../../../context/admin-context'
import { formatMoney } from '../../../helpers/date-time-helper'
import orderFactory from '../../../helpers/order-factory'
import ClaimItems from '../tabs/claim-info/claim-items'

import './styles.scss'

const RefundConfirmModel = ({ show, handleClose, onConfirm }) => {
  const onContinue = () => {
    handleClose()
    onConfirm()
  }

  return (
    <Modal animation={false} show={show} centered onHide={handleClose}>
      <Modal.Body className="refund-confirm-body">
        <p className="warning-title">The quantity of items you selected to refund is 0.</p>
        <p className="description">
          If this is correct, click Continue. If this is not correct, please click Cancel and
          correct the quantity before submitting the claim approval again.
        </p>

        <div className="confirm-buttons">
          <Button variant="secondary" className="btn-cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" className="btn-submit" onClick={onContinue}>
            Continue
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const getTotalPrice = (item) => {
  const { itemPrice, resolutionData } = item
  const { quantity, percentage, isReship, autoRefund } = resolutionData

  return itemPrice * (quantity - (isReship ? autoRefund || 0 : 0)) * (percentage / 100)
}

const RowSummaryItem = ({ items, title }) => {
  const renderItems = useMemo(() => {
    return _filter(items, (item) => item.resolutionData.quantity !== 0)
  }, [items])
  const getItemName = useCallback((item) => {
    const { quantity, percentage, isReship, autoRefund } = item.resolutionData
    return `${item.item_name} ($${formatMoney(item.itemPrice)} x ${
      quantity - (isReship ? autoRefund || 0 : 0)
    }) (${percentage}%)`
  }, [])

  if (_isEmpty(renderItems) === true) {
    return null
  }

  return (
    <div className="row-item">
      <div className="title">{title}</div>
      <div className="info">
        {_map(renderItems, (item) => (
          <div className="info-item" key={item.id}>
            <div>{getItemName(item)}</div>
            <div>${formatMoney(getTotalPrice(item))}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

const ClaimQuestion = ({ question, onClickYes, onClickNo, answer, isShowError }) => {
  return (
    <div className="claim-question">
      <div>
        {question}
        {answer === undefined && isShowError && <p className="error">Please make a selection.</p>}
      </div>
      <div className="claim-yes-no">
        <button onClick={onClickYes} className={answer === true ? 'checked' : ''}>
          Yes
        </button>
        <button onClick={onClickNo} className={answer === false ? 'checked' : ''}>
          No
        </button>
      </div>
    </div>
  )
}

const AnswerClaim = ({
  order,
  items,
  tracking,
  reshipItemsRequested,
  isLoading,
  onSubmitClaim,
}) => {
  const [selectedItems, setSelectedItem] = useState([])
  const [additionalItems, setAdditionalItems] = useState([])
  const [processItems, setProcessItems] = useState([])
  const [creditStore, setCreditStore] = useState(undefined)
  const [creditCustomer] = useState(true)
  const [includeShipping, setIncludeShipping] = useState(undefined)
  const [showAddItem, setShowAddItem] = useState(false)
  const [showRefundConfirm, setShowRefundConfirm] = useState(false)
  const [showError, setShowError] = useState(false)
  const { token, selectedStoreId } = useAdminContext()

  const orderWrapper = orderFactory(order, token)

  const shippingPrice = useMemo(() => {
    if (!includeShipping) {
      return 0
    }
    return _get(order, 'order_shipping', 0)
  }, [includeShipping, order])
  const shippingText = useMemo(
    () =>
      `${_get(order, 'order_json.shipping_lines[0].title', '')} (${(
        _get(order, 'order_json.total_weight', 0) / 453
      ).toFixed(2)} lbs)`,
    [order]
  )

  useEffect(async () => {
    let filteredItems = []
    for (const item of [...items, ...additionalItems]) {
      if (item.isClaim) {
        // fetch variant data if new item is being added
        if (item.isNewItem) {
          item.variantData = await orderWrapper.getVariantData(item)
        }
        filteredItems = [...filteredItems, item]
      }
    }
    setSelectedItem(filteredItems)
  }, [items, additionalItems])

  useEffect(() => {
    setProcessItems(
      _map(selectedItems, (item) => ({
        ...item,
        quantity:
          _sumBy(
            _filter(
              tracking,
              (row) => (row.line_item_id || row.id).toString() === item?.line_item_id
            ),
            'quantity'
          ) || item.quantity,
        resolutionData: {
          isReship:
            item?.variantData?.inventory_quantity <= 0
              ? false
              : _indexOf(reshipItemsRequested, item.variant_id) !== -1,
          quantity: 0,
          percentage: 100,
        },
      }))
    )
  }, [selectedItems, reshipItemsRequested])

  const onUpdateSelectedData = useCallback(
    (lineItemId, newResolutionData) => {
      const updatedSelectedData = _map(processItems, (item) => {
        // pop id from newResolutionData
        const { id: newResItemId, ...newResolutionDataArgs } = newResolutionData
        if (
          item.line_item_id?.toString() === lineItemId?.toString() ||
          (item.isNewItem && item.id === newResItemId)
        ) {
          return {
            ...item,
            resolutionData: newResolutionDataArgs,
          }
        }
        return item
      })
      setProcessItems(updatedSelectedData)
    },
    [processItems, setProcessItems]
  )

  const reshipItems = useMemo(
    () => _filter(processItems, (item) => _get(item, 'resolutionData.isReship', false) === true),
    [processItems]
  )
  const autoRefundItems = useMemo(() => {
    const dataItems = _filter(
      processItems,
      (item) =>
        _get(item, 'resolutionData.isReship', false) === true &&
        _get(item, 'resolutionData.autoRefund', 0) > 0
    )

    return _map(dataItems, (item) => ({
      ...item,
      resolutionData: {
        ...item.resolutionData,
        quantity: item.resolutionData.autoRefund,
        autoRefund: 0,
      },
    }))
  }, [processItems])

  const refundItems = useMemo(
    () =>
      autoRefundItems.concat(
        _filter(processItems, (item) => _get(item, 'resolutionData.isReship', false) === false)
      ),
    [processItems, autoRefundItems]
  )

  const totalClaimAmount = useMemo(
    () => _sumBy(processItems.concat(autoRefundItems), (item) => getTotalPrice(item)),
    [processItems, autoRefundItems]
  )

  const totalAmount = useMemo(
    () => totalClaimAmount + shippingPrice,
    [totalClaimAmount, shippingPrice]
  )

  const totalSelectedItems = useMemo(
    () => _sumBy(processItems, 'resolutionData.quantity'),
    [processItems]
  )

  const totalReshipItem = useMemo(
    () => _sumBy(reshipItems, 'resolutionData.quantity'),
    [reshipItems]
  )

  const onSubmit = () => {
    onSubmitClaim(
      _filter(processItems, (item) => item.resolutionData.quantity > 0),
      creditStore,
      creditCustomer,
      includeShipping
    )
  }

  const onSubmitValidation = (processItems) => {
    const hasZeroRefundItem = _some(
      processItems,
      (item) => item.resolutionData.quantity === 0 && item.resolutionData.isReship === false
    )

    if ((includeShipping === undefined && hasZeroRefundItem) || creditStore === undefined) {
      setShowError(true)
      return false
    }

    if (hasZeroRefundItem) {
      setShowRefundConfirm(true)
    } else {
      onSubmit()
    }
  }

  return (
    <div className="answer-claim-admin-wrapper bg-white p-10 rounded mt-8">
      <ClaimItems
        items={processItems}
        orderData={order}
        orderTracking={tracking}
        onUpdateSelectedData={onUpdateSelectedData}
      />
      <div className="add-items d-flex flex-column pt-3">
        <RefundConfirmModel
          show={showRefundConfirm}
          handleClose={() => setShowRefundConfirm(false)}
          onConfirm={onSubmit}
        />
        <ProductSearchModal
          show={showAddItem}
          onClose={(value) => setShowAddItem(value)}
          onSelect={(data) => {
            setAdditionalItems([
              ...additionalItems,
              {
                id: data.id,
                item_name: data.title.toLowerCase().includes('default title')
                  ? data.displayName
                  : data.title,
                itemPrice: Number(data.price) * 100,
                price: Number(data.price) * 100,
                photo: data.image?.url,
                variant_id: data.id,
                store_id: selectedStoreId,
                isClaim: true,
                isNewItem: true,
                quantity: 1,
              },
            ])
          }}
        />
        {order.isShopify() && (
          <div className="align-self-end mb-2">
            <button
              onClick={() => setShowAddItem(true)}
              className="btn-default mb-2 btn-add-products"
            >
              {isLoading && (
                <span className="mr-2">
                  <Spinner animation="border" size="sm" />
                </span>
              )}
              <span className="btn-text">Add New Products</span>
            </button>
          </div>
        )}
      </div>

      <div className="claim-container">
        <div className="claim-approve card-shadow">
          <h2>Finalize Claim Approval</h2>
          {refundItems.length ? (
            <ClaimQuestion
              question={'Refund shipping to the customer?'}
              onClickNo={() => setIncludeShipping(false)}
              onClickYes={() => setIncludeShipping(true)}
              answer={includeShipping}
              isShowError={showError}
            />
          ) : null}
          <ClaimQuestion
            question={'Credit the store for this claim?'}
            onClickNo={() => setCreditStore(false)}
            onClickYes={() => setCreditStore(true)}
            answer={creditStore}
            isShowError={showError}
          />
          <div className="summary-area">
            <RowSummaryItem title="Reship Items" items={reshipItems} />
            <RowSummaryItem title="Refund Items" items={refundItems} />
            {includeShipping && (
              <div className="row-item shipping">
                <div className="title">Shipping</div>
                <div className="info">
                  <div className="info-item">
                    <div>{shippingText}</div>
                    <div className="total">${formatMoney(shippingPrice)}</div>
                  </div>
                </div>
              </div>
            )}

            <div className="row-item total">
              <div className="title title-total">Total</div>
              <div className="info">
                <div className="info-item">
                  <div>{_sumBy(processItems, 'resolutionData.quantity')} item(s)</div>
                  <div className="price-total">${formatMoney(totalAmount)}</div>
                </div>
              </div>
            </div>
          </div>
          <button
            disabled={
              isLoading ||
              (totalSelectedItems === 0 && includeShipping === false) ||
              (reshipItems.length > 0 && totalReshipItem === 0)
            }
            onClick={() => onSubmitValidation(processItems)}
            className="btn-default purple"
          >
            {isLoading ? (
              <span className="mr-2">
                <Spinner animation="border" size="sm" />
              </span>
            ) : (
              <span className="btn-icon icon-check" />
            )}
            <span className="btn-text">Submit Claim Approval</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default memo(AnswerClaim)
