import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Form, Dropdown } from 'react-bootstrap'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'
import _filter from 'lodash/filter'
import _some from 'lodash/some'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import TextareaAutosize from 'react-textarea-autosize'
import { customerPresetData, orderPresetData, macroMetaKey } from './macro-preset-data'
import { uploader } from '../../helpers/uploader'
import { macroParser, macroNameParser, parseAttachmentMeta } from '../../helpers/macro-helper'
import AttachmentDisplay from '../AttachmentDisplay'

const MacroContent = ({ macro, onSave, onDeleteMacro, folders }) => {
  const [data, setData] = useState(macro)
  const [currentMeta, setCurrentMeta] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedAttachment, setSelectedAttachment] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const fileInput = useRef(null)
  const isNewMacro = useMemo(() => _isEmpty(macro) === true, [macro])
  const [showSuggestion, setShowSuggestion] = useState(false)

  useEffect(() => {
    const newMacro = { ...macro }
    const macro_name = _get(newMacro, 'macro_name', '')
    const folder_name = macro_name.split('//')[0]
    setData({ ...newMacro, folder_name: folder_name !== macro_name ? folder_name : '' })
  }, [macro])

  useEffect(() => {
    if (_isEmpty(data) === false) {
      const metaData = macroParser(data.macro_content)
      setCurrentMeta(metaData.meta)
    }
  }, [data])

  const [showError, setShowError] = useState(false)
  const onSubmit = useCallback(
    (event) => {
      event.preventDefault()

      const newData = { ...data }

      if (_isEmpty(_get(newData, 'macro_name', ''))) {
        setShowError(true)
      } else {
        setLoading(true)

        const folder_name = _get(newData, 'folder_name', '')
        const macro_name = _get(newData, 'macro_name', '')

        if (!_isEmpty(folder_name)) {
          _set(newData, 'macro_name', folder_name + '//' + macro_name)
        }

        const attachmentName = _get(selectedAttachment, 'name', '')

        if (_isEmpty(attachmentName) === true) {
          if (_isEmpty(currentMeta) === true) {
            const parsed = macroParser(newData.macro_content)
            onSave({ ...newData, macro_content: parsed.content })
          } else {
            const finalData = `${newData.macro_content}${macroMetaKey}${currentMeta}`
            onSave({ ...newData, macro_content: finalData })
          }
          setLoading(false)
        } else {
          uploader(selectedAttachment).then((res) => {
            const finalData = `${newData.macro_content}${macroMetaKey}{{attachment:${attachmentName}:${res}}}`

            onSave({ ...newData, macro_content: finalData })
            setData({ ...newData, macro_content: finalData })
            setSelectedAttachment(null)
            setLoading(false)
          })
        }
      }
    },
    [data, selectedAttachment, currentMeta]
  )

  const onShowDialog = useCallback(() => {
    fileInput.current.click()
  }, [fileInput])

  const renderingMacro = useMemo(() => macroParser(_get(data, 'macro_content', '')), [data])

  const onClickDeleteMacro = useCallback(() => {
    if (window.confirm(`Do you really want to delete ${_get(data, 'macro_name', '')} macro?`)) {
      onDeleteMacro(_get(data, 'macro_id', ''))
      setSelectedIndex(0)
      setSelectedAttachment(null)
      setCurrentMeta('')
    }
  }, [data])

  const currentFolderName = useMemo(() => _get(data, 'folder_name', ''), [data])
  const displayFolders = useMemo(() => {
    if (_some(folders, (item) => item === currentFolderName)) {
      return folders
    }

    return _filter(folders, (folder) =>
      folder.toLowerCase().includes(currentFolderName.toLowerCase())
    )
  }, [folders, currentFolderName])

  return (
    <div className="editor-content">
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Macro name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Macro name"
            id="macro_name"
            onFocus={() => setShowError(false)}
            onBlur={(event) => setShowError(_isEmpty(_get(event, 'target.value')))}
            value={macroNameParser(_get(data, 'macro_name', ''))}
            onChange={(event) => setData({ ...data, macro_name: event.target.value })}
          />
          {showError === true && (
            <Form.Text className="text-danger">You can't leave this as blank</Form.Text>
          )}
        </Form.Group>
        <Form.Group className="position-relative">
          <Form.Label>Folder</Form.Label>
          <Form.Control
            type="text"
            placeholder="Folder name"
            id="folder"
            autoComplete="off"
            onFocus={() => setShowSuggestion(true)}
            onBlur={() => setTimeout(() => setShowSuggestion(false), 150)}
            value={currentFolderName}
            onChange={(event) => setData({ ...data, folder_name: event.target.value })}
          />
          <div
            className={`macro-folder-suggestion position-absolute border w-100 flex-column bg-white p-2 ${
              showSuggestion ? 'd-flex' : 'd-none'
            }`}
          >
            {_map(displayFolders, (folder) => (
              <div
                onClick={() => setData({ ...data, folder_name: folder })}
                className="macro-folder-item cursor-pointer"
                key={`macro-folder-item-${folder}`}
              >
                {folder}
              </div>
            ))}
          </div>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Macro content</Form.Label>
          <TextareaAutosize
            placeholder="Your macro content here..."
            className="macro-input"
            value={_get(renderingMacro, 'content', '')}
            onChange={(event) => setData({ ...data, macro_content: event.target.value })}
            onBlur={(event) => setSelectedIndex(event.target.selectionStart)}
          />
        </Form.Group>
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <input
              type="file"
              ref={fileInput}
              onChange={(e) => setSelectedAttachment(e.target.files[0])}
              className="hidden"
            />
            <Button variant="outline-secondary" className="mr-2" onClick={() => onShowDialog()}>
              <FontAwesomeIcon icon={faPaperclip} />
            </Button>
            <Dropdown className="mr-2">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Customer
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {_map(customerPresetData, (item, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      const currentMessage = _get(data, 'macro_content', '')
                      let messageToUse = ''

                      if (selectedIndex === 0) {
                        messageToUse = `${currentMessage}${item.templateCode}`
                      } else {
                        messageToUse =
                          currentMessage.substring(0, selectedIndex) +
                          item.templateCode +
                          currentMessage.substring(selectedIndex, currentMessage.length)
                      }

                      setData({ ...data, macro_content: messageToUse })
                    }}
                  >
                    {item.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Order
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {_map(orderPresetData, (item, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      const currentMessage = _get(data, 'macro_content', '')
                      let messageToUse = ''

                      if (selectedIndex === 0) {
                        messageToUse = `${currentMessage}${item.templateCode}`
                      } else {
                        messageToUse =
                          currentMessage.substring(0, selectedIndex) +
                          item.templateCode +
                          currentMessage.substring(selectedIndex, currentMessage.length)
                      }

                      setData({ ...data, macro_content: messageToUse })
                    }}
                  >
                    {item.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div>
            <Button
              variant="danger"
              disabled={loading || isNewMacro}
              onClick={() => onClickDeleteMacro()}
            >
              Delete
            </Button>
            <Button className="ml-2" variant="primary" type="submit" disabled={loading}>
              {loading ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </div>
        <AttachmentDisplay
          text={_get(selectedAttachment, 'name', parseAttachmentMeta(currentMeta).fileName)}
          onClick={() => setCurrentMeta('')}
        />
      </Form>
    </div>
  )
}

export default React.memo(MacroContent)
