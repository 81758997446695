import React from 'react'
import { FieldValues, FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form'
import { useStateToggle } from '../../hooks/useStateToggle'

export type Props<T = any> = Omit<
  React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
  'onSubmit'
> & {
  children: React.ReactNode
  onSubmit?: SubmitHandler<T>
  methods: UseFormReturn<T>
}

const FormForceRerendererContext = React.createContext(() => null)

export const Form = <TFieldValues extends FieldValues>({
  children,
  onSubmit,
  methods,
  ...props
}: Props<TFieldValues>) => {
  const [, toggleState] = useStateToggle()

  const value = React.useCallback(toggleState, [toggleState])

  return (
    <FormForceRerendererContext.Provider value={value as any}>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit && methods.handleSubmit(onSubmit)} {...props}>
          {children}
        </form>
      </FormProvider>
    </FormForceRerendererContext.Provider>
  )
}
