import React, { memo } from 'react'
import './styles.scss'

const CheckboxUI = (props) => (
  <label className="container-checkbox">
    <input type="checkbox" {...props} />
    <span className={`checkmark ${props.disabled === true ? 'disabled' : ''}`} />
  </label>
)

export default memo(CheckboxUI)
