import React, { useState } from 'react'
import { Modal, Button, Spinner } from 'react-bootstrap'
import _map from 'lodash/map'
import _kebabCase from 'lodash/kebabCase'
import './styles.scss'

const GlobalEModal = ({ show, globaleId, onHide, onNext }) => {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      show={show}
      className="confirm-globale-modal-wrapper"
      onHide={() => onHide()}
    >
      <Modal.Header closeButton>
        <div className="font-weight-bold header">Global-e Order</div>
      </Modal.Header>
      <Modal.Body className="globale-modal-body">
        <p className="selec-options">
          Please refund this order in Global-e before approving the claim.{' '}
          <a
            target="_blank"
            className="store-link leading-6"
            rel="noreferrer"
            href={`https://web.global-e.com/GlobaleAdmin/Orders/Order?orderId=${globaleId?.value}`}
          >
            View order in Global-e
          </a>
        </p>
        <p className="selec-options">Did you already process the refund in Global-e?</p>

        <div className="mt-3">
          <Button variant="primary" className="btn-confirm" onClick={() => onNext()}>
            Yes, Continue
          </Button>
          <span
            className="cancel-link"
            onClick={() => {
              onHide(false)
            }}
          >
            No, Go Back
          </span>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default React.memo(GlobalEModal)
