import React, { memo, useState } from 'react'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { useAuth0 } from '@auth0/auth0-react'
import _compact from 'lodash/compact'
import { Modal } from 'react-bootstrap'

import TimelineLetterAvatar from '../../../../components/OrderTimeline/components/timeline-letter-avatar'
import ReadMore from '../../../../helpers/read-more'
import './styles.scss'

const getSourceMessage = (gateway) => {
  const prefix = ' - Sent via'
  switch (gateway) {
    case 'dashboard':
      return `${prefix} Concierge`
    case 'text':
      return `${prefix} SMS`
  }
  return null
}

const CommunicationCard = ({ message, orderData }) => {
  const [imageShow, setImageShow] = useState('')
  const [video, setVideo] = useState('')
  const { user } = useAuth0()

  const getAttachmentName = (url) => {
    if (url.startsWith('http')) {
      return {
        fileName: url,
        isImage: true,
      }
    }

    const parts = url.split('/')
    const fileName = parts[parts.length - 1]
    const fileParts = fileName.split('.')
    const fileExt = fileParts[fileParts.length - 1].toLowerCase()
    const isImage =
      fileExt === 'png' || fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'gif'

    return {
      fileName,
      isImage,
    }
  }

  const getVideoName = (url) => {
    if (url.startsWith('http')) {
      return {
        fileName: url,
        isVideo: true,
      }
    }

    const parts = url?.split('/')
    const fileName = _get(parts, [parts?.length - 1], '')
    const fileParts = fileName?.split('.')
    const fileExt = _get(fileParts, [fileParts?.length - 1], '')?.toLowerCase()
    const isVideo =
      fileExt === 'm4v' ||
      fileExt === 'avi' ||
      fileExt === 'mpg' ||
      fileExt === 'mp4' ||
      fileExt === 'webm'
    return {
      fileName,
      isVideo,
    }
  }

  const getName = (currentUser, alias = true) => {
    if (!currentUser?.sender_id) {
      return (
        _compact([
          orderData?.order_json?.customer?.first_name,
          orderData?.order_json?.customer?.last_name,
        ]).join(' ') ||
        orderData?.customer_name ||
        ''
      )
    }
    if (currentUser?.alias_name && alias) {
      return currentUser?.alias_name
    }

    return `${currentUser?.first_name || ''} ${currentUser?.last_name || user?.name || ''}`
  }

  return (
    <div
      className={`py4 content-message ${
        message?.sender_id ? 'content-message-admin' : 'content-message-customer'
      }`}
    >
      <div className="d-flex align-items-end mb-1">
        <div className="avatar-item-line">
          <TimelineLetterAvatar name={getName(message)} />
        </div>
        <p className="text-default bold big mx-2 mb-0" title={getName(message, false)}>
          {getName(message)}
        </p>
        <p className="text-date my-0 mr-1">{moment(message?.created_at).format('h:mm a')}</p>
        <p className="text-date my-0">{getSourceMessage(message?.gateway)}</p>
      </div>
      <div className="message">
        <ReadMore text={message?.message} />
      </div>
      {!_isEmpty(message?.twilio_media) && (
        <div className="media-container">
          {_map(message?.twilio_media, (media, index) => {
            return JSON.parse(media).type.includes('video') ? (
              <video key={index} controls>
                <source src={JSON.parse(media).url} type={JSON.parse(media).type} />
              </video>
            ) : (
              <a
                key={index}
                className="twilio-image"
                target="_blank"
                href={JSON.parse(media).url}
                rel="noreferrer"
              >
                <img width="100px" height="100%" src={JSON.parse(media).url}></img>
              </a>
            )
          })}
        </div>
      )}
      {!_isEmpty(message?.attachments) && (
        <div className="attachment-content">
          {_map(message?.attachments, (url, index) => {
            const attachmentDetails = getAttachmentName(url)

            if (attachmentDetails.isImage === true) {
              const imgUrl = _get(message?.attachmentUrls, index, url)

              return (
                <div className="group-file">
                  <img
                    onClick={() => setImageShow(imgUrl)}
                    className="w-100 image-attachment"
                    key={index}
                    src={imgUrl}
                    alt="Attachment"
                  />
                </div>
              )
            } else {
              const videoDetails = getVideoName(url)
              if (videoDetails?.isVideo) {
                return (
                  <div className="group-file">
                    <video
                      width="100%"
                      onClick={() => setVideo(_get(message?.attachmentUrls, index, ''))}
                      src={_get(message?.attachmentUrls, index, '')}
                    />
                  </div>
                )
              }
              return (
                <a
                  className="text-default"
                  href={_get(message?.attachmentUrls, index, '')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {attachmentDetails.fileName}
                </a>
              )
            }
          })}
        </div>
      )}
      <Modal
        className="modal-video"
        show={!!video}
        onHide={() => setVideo('')}
        backdrop="static"
        keyboard={false}
        animation={false}
      >
        <div className="group-icon-close" onClick={() => setVideo('')}>
          <span className="icon-close" />
        </div>
        <video src={video} autoPlay controls />
      </Modal>
      {!_isEmpty(imageShow) && (
        <Lightbox mainSrc={imageShow} onCloseRequest={() => setImageShow('')} />
      )}
    </div>
  )
}

export default memo(CommunicationCard)
