import { Combobox, Dialog, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { SearchNotFoundIcon } from '../../components/icons/SearchNotFoundIcon'
import { useAdminContext } from '../../context/admin-context'
import { getApi } from '../../helpers/api'

import { ProductSearchItemComboBox } from './ProductSearchItemComboBox'
import type { ProductResults, ProductSearchModalProps } from './types'

export function ProductSearchModal({ onClose, show, onSelect }: ProductSearchModalProps) {
  const [query, setQuery] = useState('')
  const { selectedStoreId, token } = useAdminContext()
  const [productResults, setProductResults] = useState<Array<ProductResults>>([])

  const queryProductsDebounced = useCallback(
    debounce((term) => {
      if (!isEmpty(term)) {
        getApi(`/stores/${selectedStoreId}/products`, { search: term }, token).then((res) => {
          setProductResults(res.data)
        })
      }
    }, 250),
    [selectedStoreId, token]
  )

  useEffect(() => {
    queryProductsDebounced(query)
  }, [query])

  return (
    <Transition.Root
      show={show}
      as={Fragment}
      afterLeave={() => {
        setQuery('')
        setProductResults([])
      }}
      appear
    >
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto mt-20 max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox onChange={(item) => onSelect(item)}>
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search for products ..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                {productResults.length > 0 && (
                  <Combobox.Options static className="max-h-96 scroll-py-3 overflow-y-auto p-3">
                    {productResults.map((pr) => (
                      <ProductSearchItemComboBox {...pr} key={pr.id} />
                    ))}
                  </Combobox.Options>
                )}

                {query !== '' && productResults.length === 0 && (
                  <div className="py-14 px-6 text-center text-sm sm:px-14">
                    <SearchNotFoundIcon
                      type="outline"
                      name="search-not-found"
                      className="mx-auto h-16 text-gray-400"
                    />
                    <div className="flex flex-column ">
                      <p className="mt-2 text-gray-500 flex-1">
                        No results found for "
                        <span className="font-semibold text-black">{query}</span>"
                      </p>
                    </div>
                  </div>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
