const config = {
  api: {
    get url() {
      return (
        import.meta.env.VITE_PROXY_PRODUCTION ||
        import.meta.env.VITE_PROXY_DEVELOPMENT ||
        'http://localhost:8080'
      )
    },
    get serverless() {
      return (
        import.meta.env.VITE_PROXY_API_BASE_PRODUCTION ||
        import.meta.env.VITE_PROXY_API_BASE_DEVELOPMENT ||
        'https://api.orderprotection.com/v1/dev'
      )
    },
  },
  sentry: {
    get shouldReport(): boolean {
      return this.environment !== 'development'
    },
    get environments(): { [key: string]: string } {
      return {
        'localhost:3000': 'development',
        'stage.orderprotection.com': 'stage',
        'orderprotection.com': 'production',
        'claims.orderprotection.com': 'production-claims',
      }
    },
    dsn: 'https://b8bff4c105364633a485ae296d1713a4@o4504514430959616.ingest.sentry.io/4504514433843200',
    get environment(): string {
      return this.environments[location.host] || 'development'
    },
    release: import.meta.env.SENTRY_RELEASE,
  },
  auth0: {
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID || 'dJ7pIiFbcDvEpSE40YU3cB6XSMYa16Nl',
    domain: import.meta.env.VITE_AUTH0_DOMAIN || 'orderprotection-dev.us.auth0.com',
    // audience: import.meta.env.VITE_AUTH0_AUDIENCE || 'https://development.orderprotection.com',
  },
}

export default config
