import './Notification.scss'
import { Alert } from 'react-bootstrap'
import React from 'react'

export default class Notification extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)
    this.state = {
      show: false,
      message: props.message,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.message !== this.props.message) {
      this.setState({ show: false })

      // eslint-disable-next-line
      const parent = this
      setTimeout(function () {
        parent.setState({ show: true })
      }, 500)
    }
  }

  render() {
    return (
      <div className="notification-message" style={{ display: this.state.show ? 'block' : 'none' }}>
        <Alert variant="dark" onClose={() => this.setState({ show: false })} dismissible>
          <Alert.Heading>{this.props.message}</Alert.Heading>
        </Alert>
      </div>
    )
  }
}
