import React from 'react'
import './ClaimImageUpload.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImage } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import axios from '@/support/utils/request'

export default function ClaimImageUpload(props) {
  return (
    <div className="claim-image-upload">
      <label className="claim-image-label" htmlFor="image-input">
        <FontAwesomeIcon icon={faFileImage} />+
      </label>
      <input
        id="image-input"
        type="file"
        multiple
        onChange={(event) => props.onFileSelect(event)}
      />
    </div>
  )
}
