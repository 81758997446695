import './ClaimMissing.scss'
import _get from 'lodash/get'
import { useState, useEffect } from 'react'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector'
import { views } from '../../../helpers/appViews'

import { Col, Row, Button, Nav, Accordion, Form } from 'react-bootstrap'
import ClaimItemsOption from '../ClaimItemsOption/ClaimItemsOption'
import ClaimCustomerInformation from '../ClaimCustomerInformation/ClaimCustomerInformation'
import ClaimNextButton from '../ClaimNextButton/ClaimNextButton'
import ClaimImageUpload from '../ClaimImageUpload/ClaimImageUpload'
import Error from '../../Error/Error'
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core'

export default function ClaimMissing(props) {
  const userHasChangedShippingAddress =
    _get(props, 'state.customerData.shipping_address_changed_time', 0) > 0
  const [editAddress, enableEditAddress] = useState(false)

  const [addressOne, setAddressOne] = useState('')
  const [addressTwo, setAddressTwo] = useState('')

  const [postalCode, setPostalCode] = useState('')
  const [city, setCity] = useState('')
  const [region, setRegion] = useState('')
  const [country, setCountry] = useState('')
  const handleCheckboxClick = (event) => {
    const customerData = { ...props.state.customerData }

    customerData.orderItems.forEach((item) => {
      let prevItemChecked = item.is_checked

      if (item.id == event.target.value) {
        item.is_checked = !prevItemChecked
      }
    })

    props.setState({
      ...props.state,
      customerData: customerData,
    })
    console.log(props.state.customerData)
  }

  const handleAddBillingProfile = async () => {
    const contactInfo = {
      firstName: props.state.userData.first_name,
      lastName: props.state.userData.last_name,
      email: props.state.userData.email,
      storeName:
        props.state.connectedStores[0] !== undefined
          ? props.state.connectedStores[0].store_url.replace('.myshopify.com', '')
          : '',
      addressOne,
      postalCode,
      city,
      region,
      country,
    }
    enableEditAddress(false)
  }

  function claimSummary() {
    props.changeView(views.CLAIMITEMS)
  }
  useEffect(() => {
    console.log(props.state)
  }, [])

  return (
    <>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <div className="greeting-container my-4">
            <h2>We're sorry you didn't receive your package.</h2>
            <div>
              We’ve confirmed from your tracking number your package was delivered. Sometimes
              carriers can deliver to the wrong building, or leave packages at a front gate, or
              unattended high traffic area and get taken by the wrong person. Please check with your
              nearby neighbors, and anyone else living at the residence to ensure no one else has
              received your order.
            </div>
            <h2 className="text-muted">How can we resolve this for you?</h2>
            <Error error={props.error} />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={{ span: 6, offset: 5 }}>
          <p className="recommended" onClick={() => enableEditAddress(true)}>
            Recommended
          </p>

          <RadioGroup aria-label="gender" name="gender1">
            <Accordion as={Nav.Item} defaultActiveKey="0">
              <Accordion.Toggle as={Nav.Link} eventKey="collapse-1">
                <FormControlLabel
                  color="success"
                  onClick={(event) => {
                    event.preventDefault()
                  }}
                  value="Reship"
                  control={<Radio color="primary" />}
                  label="Reship"
                />
                <p>We’ll reship your order to you to your current or new address.</p>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="collapse-1">
                <div className="p-3 mb-3 shipping-address">
                  {!editAddress ? (
                    <h2>Confirm this address is best to receive packages:</h2>
                  ) : (
                    <h2>Confirm your address</h2>
                  )}
                  {props.state.customerData &&
                    props.state.customerData.order_json &&
                    props.state.customerData.order_json.shipping_address &&
                    !editAddress && (
                      <>
                        <p className="edit" onClick={() => enableEditAddress(true)}>
                          Edit
                        </p>
                        <p className="m-0 text-muted">
                          {props.state.customerData.order_json.shipping_address.address1},{' '}
                          {props.state.customerData.order_json.shipping_address.address2}
                        </p>
                        <p className="m-0 text-muted">
                          {props.state.customerData.order_json.shipping_address.city},{' '}
                          {props.state.customerData.order_json.shipping_address.province_code}
                        </p>
                        <p className="m-0 text-muted">
                          {props.state.customerData.order_json.shipping_address.country},{' '}
                          {props.state.customerData.order_json.shipping_address.zip}
                        </p>
                      </>
                    )}
                  {editAddress && (
                    <>
                      <Form>
                        <Form.Group controlId="formShopifyStoreName">
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            type="text"
                            className="inputField"
                            placeholder="Address 1"
                            value={addressOne}
                            onChange={(event) => {
                              setAddressOne(event.target.value)
                            }}
                          />
                        </Form.Group>

                        <Form.Group controlId="formShopifyStoreName">
                          <Form.Label>Apartment, Suite etc.(optional)</Form.Label>
                          <Form.Control
                            type="text"
                            className="inputField"
                            placeholder="Address 1"
                            value={addressOne}
                            onChange={(event) => {
                              setAddressTwo(event.target.value)
                            }}
                          />
                        </Form.Group>
                        <Form.Group controlId="formShopifyStoreName">
                          <Form.Label>City</Form.Label>

                          <Form.Control
                            type="text"
                            className="inputField"
                            placeholder="City"
                            value={city}
                            onChange={(event) => {
                              setCity(event.target.value)
                            }}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="formShopifyStoreName"
                          className="address-input-container"
                        >
                          <Row>
                            <Col>
                              <Form.Label>Country/Region</Form.Label>

                              <CountryDropdown
                                className="country-input-dropdown text-muted inputField"
                                value={country}
                                onChange={(val) => setCountry(val)}
                              />
                            </Col>
                            <Col>
                              <Form.Label>State/Province</Form.Label>

                              <RegionDropdown
                                className="region-input-dropdown text-muted inputField"
                                disableWhenEmpty={true}
                                country={country}
                                value={region}
                                onChange={(val) => setRegion(val)}
                              />
                            </Col>
                            <Col>
                              <Form.Label>Postal Code</Form.Label>

                              <Form.Control
                                type="text"
                                placeholder="Postal Code"
                                value={postalCode}
                                onChange={(event) => {
                                  setPostalCode(event.target.value)
                                }}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                        <Button
                          variant="primary"
                          onClick={() => {
                            handleAddBillingProfile()
                          }}
                        >
                          Deliver to this address
                        </Button>
                      </Form>
                    </>
                  )}
                </div>
              </Accordion.Collapse>
            </Accordion>

            <FormControlLabel
              color="success"
              onClick={(event) => {
                event.preventDefault()
              }}
              value="Refund"
              control={<Radio color="primary" />}
              label="Refund"
            />
            <p>
              We’ll refund your original form of payment for $192.98. This may take up to 10
              business days for your bank to deposit the funds.
            </p>
          </RadioGroup>
          <Button
            variant="primary"
            type="submit"
            className="my-2"
            onClick={(event) => {
              claimSummary()
              event.preventDefault()
            }}
          >
            Continue
          </Button>
        </Col>
      </Row>
    </>
  )
}
