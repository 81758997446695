import React, { memo, useEffect, useMemo, useState } from 'react'
import _get from 'lodash/get'
import { useHistory } from 'react-router'
import _map from 'lodash/map'
import _filter from 'lodash/filter'

import { Spinner } from 'react-bootstrap'
import './styles.scss'

import { useClaimPortalContext } from '../../../context/claim-portal-context'
import ClaimOrderDetails from '../Message/ClaimOrderDetails'
import { ClaimTypes } from '../configuration'
import ButtonUI from '../../CoreUI/ButtonUI'
import LinkButton from '../../CoreUI/LinkButton'
import Reship from '../../../../src/assets/claim-portal/reship.svg'
import Refund from '../../../../src/assets/claim-portal/refund.svg'

const RecommendResolution = ({
  enableReship: enableReshipFromSettings,
  selectedResolution,
  setSelectedResolution,
  onNext,
}) => {
  const {
    orderData,
    orderItems,
    orderTracking,
    selectedClaimType,
    variantData,
    fulfillments,
    claimSubmitted,
  } = useClaimPortalContext()
  const [outStock, setOutStock] = useState(false)
  const [width] = useState(window.innerWidth)
  const [isLoading] = useState(false)

  const [selectedItems, setSelectedItems] = useState(
    _map(_filter(orderItems, (item) => item.is_checked === true))
  )
  const history = useHistory()
  const config = useMemo(() => {
    const id = _get(selectedClaimType, 'id', -1)

    switch (id) {
      case 1:
        for (var i = 0; i < orderItems.length; i++) {
          orderItems[i].is_checked = true
        }
        return ClaimTypes.Stolen
      case 2:
        return ClaimTypes.DamageItem
      case 3:
        return ClaimTypes.DefectiveItem
      case 4:
        var isCheckedItems = _filter(orderItems, (item) => item.is_checked === true)

        if (fulfillments && isCheckedItems.length === 0) {
          for (let i = 0; i < orderItems.length; i++) {
            orderItems[i].is_checked = true
          }
        }
        setSelectedItems(_map(_filter(orderItems, (item) => item.is_checked === true)))

        return ClaimTypes.DeliveredNotReceived
      case 5:
        return ClaimTypes.WrongItem
      case 6:
        return ClaimTypes.MissingItem
      case 7:
        for (let i = 0; i < orderItems.length; i++) {
          orderItems[i].is_checked = true
        }
        return ClaimTypes.ReturnedToSender
      case 8:
        for (let i = 0; i < orderItems.length; i++) {
          orderItems[i].is_checked = true
        }
        return ClaimTypes.TrackingNotMoving
      default:
        return ClaimTypes.WrongItem
    }
  }, [selectedClaimType])

  useEffect(() => {
    console.log(selectedItems)
    for (var i = 0; i < orderItems?.length; i++) {
      if (orderItems[i].is_checked == true) {
        variantData[i].is_checked = true
      }
    }
    let totalOutOfStock = 0
    if (selectedItems.length > 0) {
      for (let i = 0; i < selectedItems.length; i++) {
        const { variantData: selectedVariant } = variantData.find(
          (elm) => elm.variant_id === selectedItems[i].variant_id
        )
        if (
          selectedVariant.inventory_quantity < 1 &&
          selectedVariant.inventory_management === 'shopify' &&
          selectedVariant.inventory_policy !== 'continue'
        ) {
          totalOutOfStock += 1
        } else {
          break
        }
      }
      if (totalOutOfStock === selectedItems.length) {
        setOutStock(true)
      }
    }
  }, [selectedItems])

  const oneTimeSettings = useMemo(() => _get(orderData, 'one_time_appeasement', null), [orderData])
  const enableReship = useMemo(() => {
    if (!enableReshipFromSettings) {
      return false
    }

    if (oneTimeSettings === null) {
      return true
    }
    return oneTimeSettings?.selectedMethods.includes('reship')
  }, [oneTimeSettings])

  const enableRefund = useMemo(() => {
    if (oneTimeSettings === null || !oneTimeSettings?.enable) {
      return true
    }
    return (
      oneTimeSettings?.selectedMethods.includes('original_payment') ||
      oneTimeSettings?.selectedMethods.includes('store_credit')
    )
  }, [oneTimeSettings])

  return (
    <div className="resolution-wrapper">
      <div className="inner-column left">
        <label className="title">{config.text}</label>
        <label className="order-number">
          Order #
          {orderData?.platform_id === 3 ? orderData.display_order_id : orderData?.store_order_id}
        </label>
        {width && width < 500 && (
          <>
            <div className="inner-column right">
              <ClaimOrderDetails
                orderItems={orderItems}
                orderData={orderData}
                orderTracking={orderTracking}
                isError={false}
                hideItemList={true}
              />
            </div>
          </>
        )}

        <label className="question sub-text-missing">How would you like to resolve this?</label>

        {enableReship && !claimSubmitted && (
          <div
            className={`select-option ${selectedResolution === 2 && !outStock ? 'selected' : ''} ${
              outStock ? 'disabled-resolution' : ''
            }`}
            onClick={() => setSelectedResolution(2)}
          >
            <img src={Reship} alt="reship" />
            <p id="text-option">Reship them to me</p>
            <div className="status-option">
              <span>Recommended</span>
            </div>
          </div>
        )}
        {enableRefund && claimSubmitted === false && (
          <div
            className={`select-option ${selectedResolution === 1 ? 'selected' : ''}`}
            onClick={() => setSelectedResolution(1)}
          >
            <img src={Refund} alt="refund" />
            <p id="text-option">I want a refund</p>
            <div className="status-option bg-lpurple">
              <span>Up to 5 business days</span>
            </div>
          </div>
        )}
        {claimSubmitted && <p>Sorry, it seems like your claim has been submitted!</p>}
        {claimSubmitted === false && (
          <div className="">
            <ButtonUI disabled={selectedResolution === 0} onClick={() => onNext()}>
              {isLoading === true ? (
                <Spinner size="sm" className="ml-2" animation="border" variant="light" />
              ) : (
                <span>Continue</span>
              )}
            </ButtonUI>
            <LinkButton
              className=""
              onClick={() =>
                selectedClaimType?.id == 2
                  ? history.push('/submitClaim/damage-item')
                  : selectedClaimType?.id == 3 ||
                    selectedClaimType?.id == 5 ||
                    selectedClaimType?.id == 6
                  ? history.push('/submitClaim/select-item')
                  : history.push('/submitClaim/')
              }
            >
              Back
            </LinkButton>
          </div>
        )}
      </div>
      {width && width > 500 && (
        <>
          <div className="inner-column right">
            <ClaimOrderDetails
              orderItems={orderItems}
              orderData={orderData}
              orderTracking={orderTracking}
              isError={false}
              hideItemList={true}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default memo(RecommendResolution)
