import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _includes from 'lodash/includes'
import _isEmpty from 'lodash/isEmpty'
import _kebabCase from 'lodash/kebabCase'
import _map from 'lodash/map'
import _some from 'lodash/some'
import { Route, Switch, useHistory } from 'react-router'
import widgetImage from '../../../assets/op_demo.png'
import ButtonUI from '../../CoreUI/ButtonUI'
import Checkbox from '../../CoreUI/Checkbox'
import ClaimConfirmation from '../ClaimConfirmation/ClaimConfirmation'
import ClaimCredentials from '../ClaimCredentials/ClaimCredentials'
import ClaimDetails from '../ClaimDetails/ClaimDetails'
import ClaimItems from '../ClaimItems/ClaimItems'
import ClaimSignature from '../ClaimSignature/ClaimSignature'
import ClaimType from '../ClaimType/ClaimType'
import ScrollToTop from '../ScrollToTop/ScrollToTop'
import './ClaimForm.scss'
import { Helmet } from 'react-helmet'

import moment from 'moment'
import ClaimPortalCarrier from '../Carrier'
import ClaimProcess from '../ClaimProcess'
import ClaimRefund from '../ClaimRefund/ClaimRefund'
import ClaimSuccessfully from '../ClaimSuccessfully'

import BeginMissing from '../BeginMissing/BeginMissing'
import ClaimMissing from '../ClaimMissing/ClaimMissing'

import axios from '@/support/utils/request'
import { useCallback, useState } from 'react'
import { ClaimPortalContext } from '../../../context/claim-portal-context'
import { views } from '../../../helpers/appViews'

import { useEffect, useMemo } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { isUnprotectedOrdersClaims } from '../../../helpers/claim-filter'
import { getClaimResolution, messageTypes } from '../../ClaimPortalRoutes/configuration'
import ClaimPortalMessage from '../../ClaimPortalRoutes/Message'
import MultipleFulfillments from '../../ClaimPortalRoutes/MultipleFulfillments'
import ClaimResolutions from '../../ClaimPortalRoutes/Resolution'
import SelectItems from '../../ClaimPortalRoutes/SelectItems'
import GlobalAppbar from '../../GlobalAppbar'

import DamageList from '../../ClaimPortalRoutes/DamageList'

import config from '@/config'
import { isProtectedOrders } from '../../../helpers/claim-filter'
import orderFactory from '../../../helpers/order-factory'
import RecommendResolution from '../../ClaimPortalRoutes/RecommendResolution'
import Submission from '../../ClaimPortalRoutes/Submission'
import StoreService from '@/services/store'
import WaitToClaimModal from '../WaitToClaimModal/WaitToClaim'
import HexcladReturns from '../HexcladReturns/HexcladReturns'
import { useWhiteLabel } from '../../../hooks/useWhiteLabel'

const CLAIM_FILING_MAX_HOURS = 1080
const basePath = '/submitClaim'

const FulFillState = Object.freeze({
  RetrievingData: 0,
  Unfulfilled: 1,
  Fulfilled: 2,
})

export default function ClaimForm(props) {
  const [storeSettings, setStoreSettings] = useState({
    claim_filing_max_hours: CLAIM_FILING_MAX_HOURS,
    disable_reshipment: false,
  })
  const [waitToClaim, setWaitToClaim] = useState({
    status: false,
    formattedWaitDate: null,
  })

  const [selectedFiles, setSelectedFiles] = useState(null)
  const [imagesSelected, setImagesSelected] = useState([])
  const [imageUploadMessage, setImageUploadMessage] = useState(null)
  const [, setSignaturePad] = useState(null)
  const [orderData, setOrderData] = useState({})
  const [damageId, setDamageId] = useState(null)
  const [communicationInfo, setCommunicationInfo] = useState(null)

  const [oneTimeModal, setOneTimeModal] = useState(null)
  const [brandLogo, setBrandLogo] = useState(null)
  const [appeasementCheckbox, setAppeasementCheckbox] = useState(false)

  const [variantData, setVariantData] = useState([])
  const [orderTrackingFetched, setOrderTrackingFetched] = useState(false)
  const [orderTracking, setOrderTracking] = useState([])
  const [selectedClaimType, setSelectedClaimType] = useState(
    _get(props, 'state.customerData.claimType')
  )
  const history = useHistory()
  const [selectedResolution, setSelectedResolution] = useState(0)
  const [claimNumber, setClaimNumber] = useState(-1)
  const [reimbursementGateway, setReimbursementGateway] = useState('original')
  const [claimSubmitted, setClaimSubmitted] = useState(false)

  const orderId = useMemo(() => _get(props, 'state.customerData.id'), [props])
  const orderItems = useMemo(() => _get(props, 'state.customerData.orderItems'), [props])
  const fulfillments = useMemo(() =>
    _filter(_get(props, 'state.fulfillments.fulfillments'), function (o) {
      return o.line_items[0].title != 'Order Protection'
    })
  )
  const storeData = useMemo(() => _get(props, 'state.store'), [props])
  const isProtectedOrder = isProtectedOrders(orderData)

  const storeId = useMemo(
    () => _get(props, 'state.customerData.store_id', sessionStorage.getItem('__op:store_id') || -1),
    [props]
  )

  const hasFulfilled = useMemo(() => {
    if (_isEmpty(orderTracking) === true) {
      return FulFillState.RetrievingData
    }

    return _some(orderTracking, (item) => item.fulfillment_status)
      ? FulFillState.Fulfilled
      : FulFillState.Unfulfilled
  }, [orderTracking])

  const { loading, logo, page_title, store_id, hide_powered_by } = useWhiteLabel(
    window.location.hostname.includes('orderprotection')
      ? _get(props, 'state.customerData.store_id', sessionStorage.getItem('__op:store_id') || null)
      : null
  )

  useEffect(() => {
    setSelectedClaimType(_get(props, 'state.customerData.claimType'))
  }, [props.state.customerData])

  useEffect(() => {
    setSelectedClaimType(_get(props, 'state.customerData.claimType'))
  }, [props.state.customerData.claimType])

  useEffect(() => {
    if (typeof orderId !== 'undefined') {
      axios.get(`${proxy}/orders/${orderId}`).then((res) => {
        const theOrder = res.data

        setOrderData(theOrder)
        if (theOrder?.platform_id === 1) {
          axios
            .get(
              `${proxy}/spf-proxy/${theOrder?.store_id}/orders/${theOrder?.shopify_order_id}/order-tracking`
            )
            .then((res) => {
              setOrderTracking(res.data)
              setOrderTrackingFetched(true)
              const latestTrackingRecord = res?.data?.[0]?.orderTracking?.checkpoints?.[0]
              if (
                latestTrackingRecord?.status === 'InfoReceived' &&
                moment().diff(latestTrackingRecord?.time, 'hours') > 72
              ) {
                setView(views.NOTRECEIVE)
              }
            })
        } else if (theOrder?.platform_id === 3) {
          axios
            .get(
              `${proxy}/magento-proxy/${theOrder?.store_id}/orders/${theOrder?.order_id}/order-tracking`
            )
            .then((res) => {
              setOrderTracking(res.data)
              setOrderTrackingFetched(true)
            })
        } else if (theOrder?.platform_id === 2) {
          axios
            .get(
              `${proxy}/bc-proxy/${theOrder?.store_id}/orders/${theOrder?.shopify_order_id}/order-tracking`
            )
            .then((res) => {
              setOrderTracking(res.data)
              setOrderTrackingFetched(true)
            })
        } else {
          setOrderTracking([])
          setOrderTrackingFetched(true)
        }
      })
    }
  }, [orderId])

  const [view, setView] = useState(views.CLAIM)

  const getVariantInfo = useCallback(
    (storeData, productId, variantId, storeId) =>
      new Promise((resolve) => {
        axios
          .post(
            `${proxy}/${
              storeData.isShopify() ? 'shopify' : 'bigcommerce'
            }/products/variants/${variantId}`,
            {
              storeID: storeId,
              variantID: variantId,
              productID: productId,
            }
          )
          .then((res) => {
            resolve(_get(res, 'data.variant', {}))
          })
          .catch((e) => {
            console.error('Error when getting data', e)
            resolve({})
          })
      }),
    []
  )

  const updateClaimWaitData = (data) => {
    setWaitToClaim({
      status: data.status,
      formattedWaitDate: data.formattedWaitDate,
    })
  }

  const checkOrderIsValid = () => {
    if (view === views.CLAIMTYPE) {
      if (
        isUnprotectedOrdersClaims(orderData) &&
        _get(orderData, 'oneTimeAppeasement', null) === null
      ) {
        setView(views.CLAIM)

        return history.replace(
          `${basePath}/message/${_kebabCase(messageTypes.UnprotectedOrder.name)}`
        )
      }

      if (hasFulfilled === FulFillState.Unfulfilled) {
        setView(views.CLAIM)

        return history.replace(`${basePath}/message/${_kebabCase(messageTypes.UnfulFilled.name)}`)
      }
    }
  }

  useEffect(() => {
    if (
      _isEmpty(orderItems) === false &&
      storeId !== -1 &&
      _isEmpty(variantData) === true &&
      _isEmpty(storeData) === false
    ) {
      Promise.all(
        _map(orderItems, async (item) => {
          const variantData = !props?.state?.store?.isMagento()
            ? await getVariantInfo(storeData, item.product_id, item.variant_id, storeId)
            : {}
          return {
            ...item,
            variantData,
          }
        })
      ).then((variantData) => setVariantData(variantData))
    }
  }, [orderItems, storeId, variantData, setVariantData])

  useEffect(() => {
    if (_isEmpty(orderData) === false) {
      const claimStatusId = _get(orderData, 'claims[0].claim_status_id', 0)

      if (_includes([1, 2, 3, 4], claimStatusId) && view !== views.CLAIM) {
        setView(views.CLAIMCONFIRMATION)
        setClaimSubmitted(true)
        return history.replace(
          `/submitClaim/response/${_kebabCase(getClaimResolution(claimStatusId).name)}`
        )
      }
      if (view === views.CLAIMTYPE) {
        if (isUnprotectedOrdersClaims(orderData)) {
          if (_get(orderData, 'oneTimeAppeasement', null) === null) {
            setView(views.CLAIM)

            return history.push(
              `${basePath}/message/${_kebabCase(messageTypes.UnprotectedOrder.name)}`
            )
          } else {
            setOneTimeModal(_get(orderData, 'oneTimeAppeasement', null))
          }
        }
        if (hasFulfilled === FulFillState.Unfulfilled) {
          setView(views.CLAIM)
          setOneTimeModal(null)

          return history.push(`${basePath}/message/${_kebabCase(messageTypes.UnfulFilled.name)}`)
        }
      }

      const orderDate = new Date(_get(orderData, 'order_date')).getTime()
      const today = new Date().getTime()
      const days = Math.ceil((today - orderDate) / (1000 * 3600 * 24))

      const maxDays = storeId === 274 ? 7200 / 24 : storeSettings.claim_filing_max_hours / 24

      if (days > maxDays) {
        return history.replace(
          `${basePath}/message/${_kebabCase(messageTypes.PastTimeframe.name)}?claim_filing_window=${
            storeSettings.claim_filing_max_hours / 24
          }`
        )
      }
    }
  }, [orderData, hasFulfilled, view])

  const [identityUploadMessage, setIdentityUploadMessage] = useState(null)

  const [error, setError] = useState({
    active: false,
    message: 'Default',
  })

  const resetView = useCallback(() => {
    setView(views.CLAIM)
  }, [])

  const proxy = config.api.url

  const fileSelectedHandler = (event) => {
    if (event.target.files.length) {
      for (let i = 0; i < event.target.files.length; i++) {
        const currentFile = event.target.files[i]
        if (
          !(
            currentFile.type === 'image/png' ||
            currentFile.type === 'image/jpeg' ||
            currentFile.type === 'image/jpg'
          )
        ) {
          setError({
            active: true,
            message:
              'One or more of the files is not the appropriate type. Please choose files of type JPEG or PNG.',
          })
          setImageUploadMessage(null)
          setIdentityUploadMessage(null)

          return
        }
      }
      setSelectedFiles(event.target.files)
      setImageUploadMessage('Image uploaded successfully.')
      setIdentityUploadMessage('Image uploaded successfully.')

      setError({
        active: false,
        message: 'Default',
      })
    } else {
      setSelectedFiles(null)
    }
  }

  const fileUploadHandler = async (claimId) => {
    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        if (selectedFiles[i].type.includes('image')) {
          const formData = new FormData()
          formData.append('image', selectedFiles[i], selectedFiles[i].name)

          axios.post(`${proxy}/aws/claims/${claimId}/image/upload`, formData).then((res) => {})
        }
      }
    }
  }

  const onUploadPhotos = async (claimId) => {
    if (imagesSelected.length > 0) {
      for (let i = 0; i < imagesSelected.length; i++) {
        if (imagesSelected[i].type.includes('image')) {
          const formData = new FormData()
          formData.append('image', imagesSelected[i], imagesSelected[i].name)

          axios.post(`${proxy}/aws/claims/${claimId}/image/upload`, formData).then((res) => {
            console.log('Done upload image', res)
          })
        }
      }
    }
  }

  const identityImageUpload = async (claimId) => {
    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        if (selectedFiles[i].type.includes('image')) {
          const formData = new FormData()
          formData.append('image', selectedFiles[i], selectedFiles[i].name)

          axios.post(`${proxy}/aws/claims/${claimId}/identity/upload`, formData).then((res) => {
            return res
          })
        }
      }
    }
  }

  const handleUpdateCheckItem = (item) => {
    let orderId = props.state.customerData.id
    let itemId = item.id
    axios.post(`${proxy}/claims/update/${orderId}/${itemId}`, item.is_checked).then((res) => {})
  }

  const successfulFileUploadCheck = () => {
    if (!selectedFiles && props.state.customerData.claimType.require_image) {
      return false
    }
    return true
  }

  const claimType = useMemo(() => _get(orderData, 'claims[0].claim_type_name', ''), [orderData])

  const claimTypeName = useMemo(() => {
    return claimType.toLowerCase() === 'defective' ? 'Return to sender' : claimType
  }, [orderData])

  const onSubmitItem = useCallback(
    (selectedItems) => {
      const newOrderItems = _map(_get(props, 'state.customerData.orderItems', []), (item) => ({
        ...item,
        is_checked: selectedItems.indexOf(item.id) !== -1 ? true : false,
      }))

      props.setState({
        ...props.state,
        customerData: {
          ...props.state.customerData,
          orderItems: newOrderItems,
        },
      })
    },
    [props]
  )

  const onSubmitClaimFinal = useCallback(
    (canvasImage, claimMessage, variantData, updatedAddress) => {
      for (var i = 0; i < orderItems.length; i++) {
        if (orderItems[i].is_checked == true) {
          var item = variantData.find((variant) => variant.product_id == orderItems[i].product_id)
          orderItems[i].inventory_quantity = item?.variantData?.inventory_quantity || 0
          orderItems[i].inventory_management = item?.variantData?.inventory_management || 0
        }
      }
      const selectedItems = _filter(orderItems, (item) => item.is_checked === true)
      const selectedReshipItems = _filter(
        selectedItems,
        (item) => item.inventory_quantity > 1 && item.inventory_management == 'shopify'
      )

      const isReship = selectedResolution === 2
      const data = {
        claimType: selectedClaimType.id,
        orderProtectionId: orderData.protection_order_id,
        claimDetails: claimMessage,
        claimItems: selectedItems,
        orderId: orderData.id,
        claimDate: new Date().toISOString().split('T')[0],
        userId: _get(props, 'state.userData.id', null),
        reimbursementType: selectedResolution,
        reshipItems: isReship ? _map(selectedReshipItems, 'variant_id') : [],
        updatedAddress: updatedAddress,
        damageId: damageId,
        reimbursementGateway: reimbursementGateway,
        storeInformation: props.state.store,
        shopifyOrderId: _get(orderData, 'order_json.id', _get(orderData, 'shopify_order_id', '')),
        storeOrderId: props.state.customerData.store_order_id,
        isFreeClaim:
          _get(orderData, 'one_time_appeasement', null) !== null &&
          _get(orderData, 'oneTimeAppeasement.ota_claim_id', null) === null &&
          isProtectedOrder !== true,
      }

      axios.post(`${proxy}/claims/create`, data).then((response) => {
        const claimId = response.data.id
        setClaimNumber(claimId)

        if (canvasImage) {
          canvasImage.toBlob((blob) => {
            const formData = new FormData()
            formData.append('image', blob, claimId + '.png')
            axios.post(`${proxy}/aws/claims/${claimId}/signature/upload`, formData).then(() => {
              if (selectedClaimType.id === 1 && selectedFiles) {
                identityImageUpload(claimId).then(() => {
                  onUploadPhotos(claimId)
                })
              } else {
                onUploadPhotos(claimId)
              }
            })
          }, 'image/png')
        } else {
          if (selectedClaimType.id === 1 && selectedFiles) {
            identityImageUpload(claimId).then(() => {
              onUploadPhotos(claimId)
            })
          } else {
            onUploadPhotos(claimId)
          }
        }

        const communication = {
          claimId: response.data.claim_id,
          phoneNumber: response.data.phone_number,
          email: response.data.email,
          desiredOption: response.data.desired_communication,
          claimCommunication: response?.data?.claimCommunication,
        }
        props.setState({
          ...props.state,
          customerData: {
            ...props.state.customerData,
            claim_id: response.data.claim_id,
            claim: response.data,
          },
        })
        //setCommunicationInfo(communication)
        history.replace('/submitClaim/successfully')
        setView(views.CLAIMCONFIRMATION)
        setClaimSubmitted(true)
      })
    },
    [
      selectedClaimType,
      orderData,
      orderItems,
      props,
      selectedResolution,
      imagesSelected,
      setClaimNumber,
      reimbursementGateway,
    ]
  )

  useEffect(() => {
    setBrandLogo(logo || _get(orderData, 'store_logo', null))
  }, [orderData, logo])

  async function getStoreSettings() {
    const settings = await StoreService.settings(storeId)

    setStoreSettings(settings)
  }

  useEffect(() => {
    getStoreSettings()
  }, [orderId])

  useEffect(() => {
    if (store_id !== 352 || store_id !== 353) return

    const icon = document.head.querySelector('link[rel="icon"]')
    if (icon) {
      icon.href =
        'https://op-media-asset.s3.us-west-1.amazonaws.com/assets/whitelabel/hexclad/favicon/favicon-32x32.png'
    }

    const appleIcon = document.head.querySelector('link[rel="apple-touch-icon"]')
    if (appleIcon) {
      appleIcon.href =
        'https://op-media-asset.s3.us-west-1.amazonaws.com/assets/whitelabel/hexclad/favicon/apple-touch-icon.png'
    }

    const manifest = document.head.querySelector('link[rel="manifest"]')
    if (manifest) {
      manifest.href =
        'https://op-media-asset.s3.us-west-1.amazonaws.com/assets/whitelabel/hexclad/favicon/site.webmanifest'
    } else {
      const link = document.createElement('link')
      link.rel = 'manifest'
      link.href =
        'https://op-media-asset.s3.us-west-1.amazonaws.com/assets/whitelabel/hexclad/favicon/site.webmanifest'
      document.head.appendChild(link)
    }

    const meta1 = document.head.querySelector('meta[itemprop="name"]')
    if (meta1) {
      meta1.content = 'Hexclad'
    }

    const meta2 = document.head.querySelector('meta[itemprop="description"]')
    if (meta2) {
      meta2.content =
        "Get help with damaged items, wrong items, lost or stolen packages, and more. We'll take care of your issues fast so you'll be cooking in no time."
    }
  }, [store_id])

  const computed = {
    get shouldShowReship() {
      if (storeSettings.disable_reshipment) {
        return (
          orderData?.shipping_address?.includes('United States') ||
          orderData?.shipping_address?.includes('USA') ||
          orderData?.shipping_address?.includes('US')
        )
      }

      return true
    },
  }

  if (
    loading ||
    ((_isEmpty(orderData) || orderTrackingFetched === false) && view !== views.CLAIM)
  ) {
    return (
      <div className="flex flex-col pt-5 text-center">
        <Spinner animation="border" variant="success" />
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>{page_title || 'Submit a Claim | OrderProtection.com'}</title>
      </Helmet>
      <ClaimPortalContext.Provider
        value={{
          claimSubmitted,
          orderData: orderFactory(orderData),
          setOrderData,
          orderItems,
          orderTracking,
          claimTypeName,
          selectedClaimType,
          onSubmitItem,
          variantData,
          damageId,
          fulfillments,
          setDamageId: (id) => {
            setDamageId(id)
          },
          communicationInfo,
          setCommunicationInfo: (info) => {
            setCommunicationInfo(info)
          },
          storePlan: _get(props, 'state.plan', ''),
        }}
      >
        <GlobalAppbar brandLogo={brandLogo} />
        <div className="claim-portal-wrapper">
          <div className="inner-concierge">
            <ScrollToTop />
            <Switch onUpdate={() => window.scrollTo(0, 0)}>
              <Route path={`${basePath}/hexclad-returns`}>
                <HexcladReturns storeId={storeId} />
              </Route>
              <Route path={`${basePath}/message/:messageType`}>
                <ClaimPortalMessage />
              </Route>
              <Route path={`${basePath}/response/:responseType`}>
                <ClaimResolutions />
              </Route>
              <Route path={`${basePath}/successfully`}>
                <ClaimSuccessfully
                  claimNumber={claimNumber}
                  communicationInfo={communicationInfo}
                />
              </Route>
              <Route path={`${basePath}/process`}>
                <ClaimProcess />
              </Route>
              <Route path={`${basePath}/select-item`}>
                <SelectItems selectedFiles={imagesSelected} setSelectedFiles={setImagesSelected} />
              </Route>
              <Route path={`${basePath}/multiple-fulfillments`}>
                <MultipleFulfillments
                  selectedFiles={imagesSelected}
                  setSelectedFiles={setImagesSelected}
                />
              </Route>
              <Route path={`${basePath}/damage-item`}>
                <DamageList selectedFiles={imagesSelected} setSelectedFiles={setImagesSelected} />
              </Route>
              <Route path={`${basePath}/waitToClaim`}>
                <WaitToClaimModal waitDate={waitToClaim.formattedWaitDate} />
              </Route>
              <Route path={`${basePath}/resolution`}>
                <RecommendResolution
                  enableReship={computed.shouldShowReship}
                  selectedResolution={selectedResolution}
                  setSelectedResolution={setSelectedResolution}
                  onNext={() => {
                    history.push('/submitClaim/submission')
                  }}
                />
              </Route>
              <Route path={`${basePath}/submission`}>
                <Submission
                  selectedResolution={selectedResolution}
                  setSelectedResolution={setSelectedResolution}
                  onNext={() => {
                    history.push('/submitClaim/submission')
                  }}
                  onSubmitClaim={onSubmitClaimFinal}
                  state={props.state}
                  setState={props.setState}
                  reimbursementGateway={reimbursementGateway}
                  setReimbursementGateway={setReimbursementGateway}
                />
              </Route>
              <Route>
                {view === views.NOTRECEIVE && (
                  <ClaimPortalCarrier
                    changeView={setView}
                    state={props.state}
                    setState={props.setState}
                    resetView={resetView}
                    orderTracking={orderTracking}
                  />
                )}
                {view === views.CLAIM && (
                  <ClaimCredentials
                    changeView={setView}
                    setState={props.setState}
                    state={props.state}
                    updateClaimWaitData={updateClaimWaitData}
                  />
                )}
                {view === views.CLAIMTYPE && (
                  <ClaimType
                    changeView={setView}
                    state={props.state}
                    setState={props.setState}
                    resetView={resetView}
                    checkOrderIsValid={checkOrderIsValid}
                    waitToClaim={waitToClaim}
                  />
                )}
                {view === views.CLAIMSIGNATURE && (
                  <ClaimSignature
                    signaturePad={setSignaturePad}
                    identityUploadMessage={identityUploadMessage}
                    onFileSubmit={identityImageUpload}
                    onFileSelect={fileSelectedHandler}
                    changeView={setView}
                    state={props.state}
                    setState={props.setState}
                  />
                )}
                {view === views.CLAIMREFUND && (
                  <ClaimRefund
                    signaturePad={setSignaturePad}
                    identityUploadMessage={identityUploadMessage}
                    onFileSubmit={identityImageUpload}
                    onFileSelect={fileSelectedHandler}
                    changeView={setView}
                    state={props.state}
                    setState={props.setState}
                  />
                )}
                {view === views.CLAIMMISSING && (
                  <ClaimMissing
                    view={view}
                    onNextClick={() => setView(views.CLAIMDETAILS)}
                    state={props.state}
                    setState={props.setState}
                    onFileSelect={fileSelectedHandler}
                    selectedFiles={selectedFiles}
                    setError={setError}
                    error={error}
                    successfulFileUploadCheck={successfulFileUploadCheck}
                    imageUploadMessage={imageUploadMessage}
                    handleUpdateCheckItem={handleUpdateCheckItem}
                  />
                )}
                {view === views.BEGINMISSING && (
                  <BeginMissing
                    view={view}
                    changeView={setView}
                    onNextClick={() => setView(views.CLAIMDETAILS)}
                    state={props.state}
                    setState={props.setState}
                    onFileSelect={fileSelectedHandler}
                    selectedFiles={selectedFiles}
                    setError={setError}
                    error={error}
                    successfulFileUploadCheck={successfulFileUploadCheck}
                    imageUploadMessage={imageUploadMessage}
                    handleUpdateCheckItem={handleUpdateCheckItem}
                  />
                )}
                {view === views.CLAIMITEMS && (
                  <ClaimItems
                    view={view}
                    onNextClick={() => setView(views.CLAIMDETAILS)}
                    state={props.state}
                    setState={props.setState}
                    onFileSelect={fileSelectedHandler}
                    selectedFiles={selectedFiles}
                    setError={setError}
                    error={error}
                    successfulFileUploadCheck={successfulFileUploadCheck}
                    imageUploadMessage={imageUploadMessage}
                    handleUpdateCheckItem={handleUpdateCheckItem}
                  />
                )}
                {view === views.CLAIMDETAILS && (
                  <ClaimDetails
                    onNextClick={() => setView(views.CLAIMCONFIRMATION)}
                    changeView={setView}
                    state={props.state}
                    setState={props.setState}
                    onFileSubmit={fileUploadHandler}
                  />
                )}
                {view === views.CLAIMCONFIRMATION && (
                  <ClaimConfirmation
                    view={view}
                    state={props.state}
                    setState={props.setState}
                    onFileSubmit={fileUploadHandler}
                    onIdentitySubmit={identityImageUpload}
                    selectedFiles={selectedFiles}
                  />
                )}
                {/* Keeping ids here to prevent accidental display of Powered By for specific brands. */}
                {logo &&
                  !window.location.hostname.includes('hexclad') &&
                  ![352, 353].includes(store_id) && !hide_powered_by && (
                    <a
                      href="https://orderprotection.com/for-shoppers"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="mx-auto pt-8"
                        src="https://op-media-asset.s3.us-west-1.amazonaws.com/assets/whitelabel/powered-by.svg"
                        alt="powered by OrderProtection.com"
                      />
                    </a>
                  )}
              </Route>
            </Switch>

            <Modal
              show={oneTimeModal !== null}
              className="appeasement-modal"
              backdrop="static"
              animation={false}
              onHide={() => setOneTimeModal(null)}
            >
              <Modal.Header className="appeasement-header">
                <b>You’re in luck!</b>
              </Modal.Header>
              <Modal.Body>
                {oneTimeModal?.selectedMethods?.includes('reship') &&
                  oneTimeModal?.selectedMethods?.includes('original_payment') &&
                  oneTimeModal?.refundPercentage == 100 && (
                    <p>You’re eligible for a one-time reshipment or refund.</p>
                  )}
                {oneTimeModal?.selectedMethods?.includes('reship') &&
                  oneTimeModal?.selectedMethods?.includes('original_payment') &&
                  oneTimeModal?.refundPercentage < 100 && (
                    <p>
                      You’re eligible for a one-time reshipment or refund at{' '}
                      {oneTimeModal?.refundPercentage}% of the order amount.
                    </p>
                  )}
                {oneTimeModal?.selectedMethods?.length == 1 &&
                  oneTimeModal?.selectedMethods?.includes('original_payment') &&
                  oneTimeModal?.refundPercentage == 100 && (
                    <p>You’re eligible for a one-time refund.</p>
                  )}
                {oneTimeModal?.selectedMethods?.length == 1 &&
                  oneTimeModal?.selectedMethods?.includes('original_payment') &&
                  oneTimeModal?.refundPercentage < 100 && (
                    <p>
                      You’re eligible for a one-time refund at {oneTimeModal?.refundPercentage}% of
                      the order amount.
                    </p>
                  )}
                {oneTimeModal?.selectedMethods?.length == 1 &&
                  oneTimeModal?.selectedMethods?.includes('reship') && (
                    <p>You’re eligible for a one-time reshipment.</p>
                  )}

                <p>
                  This is a{' '}
                  <span>
                    <b>one-time</b>
                  </span>{' '}
                  reimbursement offered as a courtesy.
                </p>
                <p>
                  In order to be eligible to file claims in the future, you’ll need to select Order
                  Protection at checkout.{' '}
                </p>
                <img
                  className="w-full mx-auto border border-neutral-300 rounded mt-4 mb-8"
                  src={widgetImage}
                  alt=""
                />
                <hr></hr>
                <div className="appeasement-checkbox flex items-center gap-4 mt-4 mb-2">
                  <Checkbox
                    checked={appeasementCheckbox}
                    onChange={() => setAppeasementCheckbox(!appeasementCheckbox)}
                  />
                  <span className="ml-2 text-xs">
                    I understand I must purchase Order Protection to be eligible for future claims.
                  </span>
                </div>
                <div className="appeasement-button">
                  <ButtonUI disabled={!appeasementCheckbox} onClick={() => setOneTimeModal(null)}>
                    <span>Continue</span>
                  </ButtonUI>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </ClaimPortalContext.Provider>
    </>
  )
}
