import { useAuth0 } from '@auth0/auth0-react'
import _forEach from 'lodash/forEach'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _reject from 'lodash/reject'
import axios from '@/support/utils/request'
import queryString from 'query-string'
import _isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import SlidingPanel from 'react-sliding-side-panel'
import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  useHistory,
  useLocation,
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'

import { setStoreAction } from '../../state/reducers/storeReducer'
import MacroSettingsPanel from '../../components/MacroSettingsPanel'
import './layout.scss'

import { AdminContext } from '../../context/admin-context'
import { getApi, postApi } from '../../helpers/api'
import {
  getApprovedClaimOrders,
  getClosedClaimOrders,
  getDeniedClaimOrders,
  getNewClaimOrders,
  getOpenClaimOrders,
} from '../../helpers/claim-filter'
import { getInitialDateRange } from '../../helpers/date-time-helper'
import withAuthLoading from '../../hoc/with-auth-loading'
import AdminClaim from '../admin-claim'
import AdminOrderDetails from '../admin-order-details'
import AdminAppbar from './admin-appbar'
import './layout.scss'
import SideBar from './side-bar'
import useWindowDimensions from '../../helpers/component-hooks'
import { getUserId } from '../../helpers/order-helpers'
import useToggle from '../../hooks/useToggle'
import { PermissionSet } from '../../configuration/permission-set'
import AdminProfile from '../admin-profile'
import config from '@/config'
import useSWR from 'swr'
import StoreService from '@/services/store'

const AdminAnalyticsContainer = lazy(() => import('../../containers/admin-analytics'))
const AdminPaymentContainer = lazy(() => import('../admin-payment'))
const AdminPayoutContainer = lazy(() => import('../admin-payout'))
const AdminSettingsContainer = lazy(() => import('../../containers/admin-settings'))
const AdminUserContainer = lazy(() => import('../../containers/admin-users'))
const StoreSettingsDetails = lazy(() => import('../../containers/admin-store-setting-details'))
const ProfileContainer = lazy(() => import('../../containers/profile'))

const AdminMain = ({ role, userPermission }) => {
  const proxy = config.api.url

  const { isAuthenticated, isLoading, getIdTokenClaims, user, getAccessTokenSilently } = useAuth0()
  const { width } = useWindowDimensions()
  const isStaff = useMemo(
    () => (userPermission?.permission || []).includes(PermissionSet.ListAllStore),
    [userPermission]
  )

  const isOpMember = useMemo(() => role === 'op_member', [role])

  const [stores, setStores] = useState([])
  const selectedStoreId = useSelector((state) => state.store.storeId)
  const storeCandidate = useMemo(() => {
    if (selectedStoreId === 0 && isOpMember) {
      return _map(stores, 'id')
    } else {
      return selectedStoreId === 0 && isStaff ? -1 : selectedStoreId
    }
  }, [selectedStoreId, isOpMember, isStaff, stores])
  const { data: claims, mutate } = useSWR(
    `stores/${storeCandidate}/claims`,
    () => {
      if (selectedStoreId === -1) {
        return []
      }

      const convertedDate = {
        startDate: moment(fromDate).utc().format('YYYY-M-D HH:mm:ss'),
        endDate: moment(toDate)
          .utc()
          .add(23, 'hours')
          .add(59, 'minutes')
          .add(59, 'seconds')
          .format('YYYY-M-D HH:mm:ss'),
      }
      return StoreService.getClaims(storeCandidate, { ...convertedDate }, token)
    },
    {
      revalidateOnMount: false,
    }
  )
  const { data: newAndOpenClaims } = useSWR(
    `stores/${storeCandidate}/claims-new-open`,
    () => StoreService.getClaims(storeCandidate, { newAndOpenOnly: true }, token),
    {
      revalidateOnMount: false,
    }
  )

  const [macros, setMacros] = useState([])

  const [token, setToken] = useState('')
  const [totalOrders, setTotalOrders] = useState(0)
  const [totalProtectedOrders, setTotalProtectedOrders] = useState(0)
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [totalProtectedRevenue, setTotalProtectedRevenue] = useState(0)
  const [orders, setOrders] = useState({})
  const [freeClaims, setFreeClaims] = useState({})
  const currentUserId = useMemo(() => getUserId(user), [user])
  const currentStoreId = useMemo(() => selectedStoreId)
  const [isOpenMenu, setToggleMenu] = useState(false)
  const [filterRefundIssueOnly, setFilterRefundIssue] = useState(false)
  const [openMacro, setOpenMacro] = useState(false)
  const [orderLoading, setOrderLoading] = useToggle(false)
  const [selectedClaimTab, setSelectedClaimTab] = useState('new')
  const history = useHistory()

  const newClaims = useMemo(() => getNewClaimOrders(newAndOpenClaims), [newAndOpenClaims])
  const openClaims = useMemo(() => getOpenClaimOrders(newAndOpenClaims), [newAndOpenClaims])
  const approvedClaims = useMemo(() => getApprovedClaimOrders(claims), [claims])
  const deniedClaims = useMemo(() => getDeniedClaimOrders(claims), [claims])
  const closedClaims = useMemo(() => getClosedClaimOrders(claims), [claims])

  const isMobile = useMemo(() => width <= 576, [width])

  const location = useLocation()
  const filters = useMemo(() => queryString.parse(location.search), [location])
  const pageSize = useMemo(() => _get(filters, 'pageSize', 25), [filters])
  const currentPage = useMemo(() => _get(filters, 'currentPage', 1), [filters])
  const initialDate = useMemo(() => getInitialDateRange(), [])
  const fromDate = useMemo(
    () => _get(filters, 'fromDate', initialDate.fromDate.toISOString()),
    [filters, initialDate]
  )
  const toDate = useMemo(
    () => _get(filters, 'toDate', initialDate.toDate.toISOString()),
    [filters, initialDate]
  )
  const dispatch = useDispatch()

  const onChangeFilter = useCallback(
    (pairs) => {
      let newFilter = filters

      _forEach(pairs, (item) => {
        newFilter = {
          ...newFilter,
          ...item,
        }
      })
      history.push(`${window.location.pathname}?${queryString.stringify(newFilter)}`)
    },
    [filters, history, location]
  )

  const resetCurrentPage = () => {
    let newFilter = {
      ...filters,
      currentPage: 1,
    }
    history.push(`${window.location.pathname}?${queryString.stringify(newFilter)}`)
  }

  const changeCurrentPage = (currentPage) => {
    let newFilter = {
      ...filters,
      currentPage,
    }
    history.push(`${window.location.pathname}?${queryString.stringify(newFilter)}`)
    if (_isEmpty(orders[currentPage])) {
      fetchNewOrder(currentPage)
    }
  }

  useEffect(() => {
    fetchStores()
  }, [token])

  useEffect(() => {
    const retrieveToken = async () => {
      let user = await getIdTokenClaims()

      if (user) {
        setToken(user.__raw)
      } else {
        await getAccessTokenSilently({ ignoreCache: true })
        user = await getIdTokenClaims()
        setToken(user.__raw)
      }
    }

    if (isAuthenticated === true && _isEmpty(token) === true) {
      retrieveToken()
    }
  }, [isAuthenticated, token, getIdTokenClaims, setToken])

  useEffect(() => {
    if (isLoading === false && isAuthenticated === false) {
      const params = new URLSearchParams(window.location.search)
      const token = params.get('authToken')
      history.push(`/login${_isEmpty(token) === false ? `?authToken=${token}` : ''}`)
    }
  }, [isAuthenticated, isLoading])

  const fetchStores = () => {
    const key = `stores_${user?.sub}`
    const sessionData = window.sessionStorage.getItem(key)

    if (sessionData) {
      setStores(JSON.parse(sessionData))
    } else {
      if (_isEmpty(token) === false) {
        getApi('/admin/my-stores', {}, token).then((res) => {
          const stores = _get(res, 'data', [])
          setStores(stores)

          if (_isEmpty(stores) === false) {
            window.sessionStorage.setItem(key, JSON.stringify(stores))
          }
        })
      }
    }
  }

  const fetchNewOrder = useCallback(
    (selectedPage) => {
      setOrderLoading(true)

      const convertedDate = {
        fromDate: moment(fromDate).utc().format('YYYY-M-D HH:mm:ss'),
        toDate: moment(toDate)
          .utc()
          .add(23, 'hours')
          .add(59, 'minutes')
          .add(59, 'seconds')
          .format('YYYY-M-D HH:mm:ss'),
      }
      postApi(
        `/stores/${storeCandidate}/orders/paginate/${pageSize}/${selectedPage}`,
        {
          startDate: convertedDate.fromDate,
          endDate: convertedDate.toDate,
        },
        token
      )
        .then((res) => {
          setOrders((prev) => ({ ...prev, [selectedPage]: res.data }))
          if (_get(res, 'data[0].one_time_appeasement.enable', false)) {
            fetchFreeClaims(res.data)
          }
        })
        .finally(() => setOrderLoading(false))
    },
    [pageSize, fromDate, toDate, setOrders, orders, selectedStoreId, token, storeCandidate]
  )

  const fetchFreeClaims = useCallback(
    (orderData) => {
      const storeCandidate = selectedStoreId === 0 ? -1 : selectedStoreId
      const oneTimeAppeasement = orderData[0].one_time_appeasement

      var date
      if (_get(oneTimeAppeasement, 'often', null) == 'once') {
        date = moment('01-01-2022').unix()
      } else if (_get(oneTimeAppeasement, 'often', null) == '6_months') {
        date = moment().subtract(6, 'months').unix()
      } else if (_get(oneTimeAppeasement, 'often', null) == '12_months') {
        date = moment().subtract(12, 'months').unix()
      }
      postApi(`/stores/${storeCandidate}/orders/free-claims`, { date }, token).then((res) => {
        setFreeClaims(res.data)
      })
    },
    [pageSize, fromDate, toDate, setOrders, orders, selectedStoreId, token]
  )

  const onDeleteMacro = useCallback(
    (macroId, onDone) => {
      axios
        .delete(`${proxy}/macro/${macroId}`)
        .then(() => {
          const newList = _reject(macros, (macro) => macro.macro_id === macroId)
          setMacros(newList)

          if (typeof onDone !== 'undefined') {
            onDone()
          }
        })
        .catch((error) => {
          console.error('Delete macro with error', error)
        })
    },
    [macros]
  )

  const onSaveMacro = useCallback(
    (macroData, callback) => {
      const body = {
        ...macroData,
        store_id: currentStoreId,
        user_id: currentUserId,
      }

      const candidateId = _get(macroData, 'macro_id', '')

      axios.post(`${proxy}/macro/${candidateId}`, body).then((res) => {
        const newMacro = res.data

        if (newMacro.macro_id !== candidateId) {
          setMacros([...macros, res.data])
          callback(newMacro)
        } else {
          const newList = _map(macros, (item) => {
            if (item.macro_id === newMacro.macro_id) {
              return newMacro
            }

            return item
          })
          setMacros(newList)
        }
      })
    },
    [macros]
  )
  const listingMacros = useCallback(() => {
    axios.get(`${proxy}/macro`).then((res) => setMacros(res.data))
  }, [])

  useEffect(() => {
    listingMacros()
  }, [])

  useEffect(() => {
    if (selectedStoreId !== -1) {
      var storeCandidate
      if (selectedStoreId === 0 && isOpMember) {
        storeCandidate = _map(stores, 'id')
      } else {
        storeCandidate = selectedStoreId === 0 ? -1 : selectedStoreId
      }
      const requestBody = {
        startDate: moment(fromDate).utc().format('YYYY-M-D HH:mm:ss'),
        endDate: moment(toDate)
          .utc()
          .add(23, 'hours')
          .add(59, 'minutes')
          .add(59, 'seconds')
          .format('YYYY-M-D HH:mm:ss'),
        storeId: storeCandidate,
      }

      postApi(`/stores/${storeCandidate}/orders/length`, requestBody, token).then((res) => {
        const { count, protected: protectOrder } = res.data

        setTotalOrders(count)
        setTotalProtectedOrders(protectOrder)
      })

      postApi(`/stores/${storeCandidate}/orders/sum`, requestBody, token).then((res) => {
        const { protected: protectRevenue, total } = res.data

        setTotalRevenue(total)
        setTotalProtectedRevenue(protectRevenue)
      })
    }
    //Triggers claim call
    mutate()
  }, [selectedStoreId, fromDate, toDate])

  useEffect(() => {
    if (selectedStoreId !== -1) {
      fetchNewOrder(currentPage)
    }
  }, [selectedStoreId, fromDate, toDate, pageSize])

  const resetNewOrder = () => {
    fetchNewOrder(1)
  }

  return (
    <AdminContext.Provider
      value={{
        openMacroSettings: openMacro,
        setOpenMacroSettings: setOpenMacro,
        token,
        userPermission,
        stores,
        freeClaims,
        selectedStoreId,
        setSelectedStoreId: (storeId) => {
          if (selectedStoreId === storeId) return
          dispatch(setStoreAction(storeId))
          setOrders({})
          resetCurrentPage()
        },
        pageSize,
        currentPage,
        onChangeFilter,
        fromDate: new Date(fromDate),
        toDate: new Date(toDate),
        totalOrders,
        totalProtectedOrders,
        totalRevenue,
        totalProtectedRevenue,
        role,
        onFetchClaims: mutate,
        isOpenMobileMenu: isOpenMenu,
        toggleMenu: () => setToggleMenu(!isOpenMenu),
        fetchStores,
        refundIssueOnly: filterRefundIssueOnly,
        setFilterRefundIssue: setFilterRefundIssue,
        orderLoading,
        setSelectedClaimTab,
        selectedClaimTab,
      }}
    >
      <Router>
        <div className="d-flex flex-column admin-wrapper row">
          <div className="admin-body">
            <div className={`side-bar ${isOpenMenu ? 'openMenu' : ''}`}>
              <div className="wrap-icon-menu">
                <span className="icon-close" onClick={() => setToggleMenu(false)} />
              </div>
              <SideBar
                newClaim={newClaims.length}
                openClaim={openClaims.length}
                approvedClaim={approvedClaims.length}
                deniedClaim={deniedClaims.length}
                closedClaim={closedClaims.length}
                totalOrder={totalOrders}
                setToggleMenu={() => setToggleMenu(false)}
                resetNewOrder={resetNewOrder}
                setOpenMacro={setOpenMacro}
              />
            </div>
            <div className="admin-main-content">
              <AdminAppbar isAuthenticated={isAuthenticated} />
              <div className="inner">
                <Switch>
                  <Route exact path={`/analytics`}>
                    <Suspense fallback={'Loading ...'}>
                      <AdminAnalyticsContainer />
                    </Suspense>
                  </Route>
                  <Route exact path={`/orders/:protectionId/:currentTab?`}>
                    <AdminOrderDetails />
                  </Route>
                  <Route exact path={`/users`}>
                    <Suspense fallback={'Loading...'}>
                      <AdminUserContainer label="Team Members" />
                    </Suspense>
                  </Route>
                  <Route exact path={`/settings`}>
                    <Suspense fallback={'Loading...'}>
                      <StoreSettingsDetails />
                    </Suspense>
                  </Route>
                  <Route exact path={`/payment/:type?`}>
                    <Suspense fallback={'Loading...'}>
                      <AdminPaymentContainer />
                    </Suspense>
                  </Route>
                  <Route exact path={`/billing`}>
                    <Suspense fallback={'Loading...'}>
                      <AdminPaymentContainer />
                    </Suspense>
                  </Route>
                  <Route exact path={`/payouts`}>
                    <Suspense fallback={'Loading ...'}>
                      <AdminPayoutContainer />
                    </Suspense>
                  </Route>

                  <Route path="/policies" exact>
                    <iframe
                      title="policies page"
                      src="https://admin.orderprotection.com/policies"
                      style={{ height: 'calc(100vh - 76px)', width: '100%' }}
                    ></iframe>
                  </Route>

                  <Route path={`/profile/:email`}>
                    <Suspense fallback={'Loading ...'}>
                      <ProfileContainer />
                    </Suspense>
                  </Route>
                  <Route path={['/claims/:filterType?', '/orders']}>
                    <AdminClaim
                      newClaims={newClaims}
                      openClaims={openClaims}
                      approvedClaims={approvedClaims}
                      deniedClaims={deniedClaims}
                      closedClaims={closedClaims}
                      orders={orders}
                      totalOrder={totalOrders}
                      changeCurrentPage={changeCurrentPage}
                    />
                  </Route>
                  <Route exact path={`/admin/stores`}>
                    <Suspense fallback={'Loading ...'}>
                      <AdminSettingsContainer />
                    </Suspense>
                  </Route>
                  <Route exact path={`/admin/users`}>
                    <Suspense fallback={'Loading...'}>
                      <AdminUserContainer allowAllStores displayAlias />
                    </Suspense>
                  </Route>
                  <Route exact path={`/admin/macros`}>
                    <Suspense fallback={'Loading...'}>
                      <AdminUserContainer allowAllStores />
                    </Suspense>
                  </Route>
                  <Route path={`/profile`}>
                    <Suspense fallback={'Loading ...'}>
                      <AdminProfile />
                    </Suspense>
                  </Route>
                  <Redirect to="/claims" />
                </Switch>
              </div>
            </div>
          </div>
        </div>
        <SlidingPanel
          type="right"
          panelContainerClassName="slider-wrapper"
          panelClassName="slider-container"
          isOpen={openMacro}
          backdropClicked={() => setOpenMacro(false)}
          size={isMobile ? 100 : 70}
        >
          <MacroSettingsPanel
            storeId={selectedStoreId}
            macros={macros}
            onSaveMacro={onSaveMacro}
            onDeleteMacro={onDeleteMacro}
            onClose={() => setOpenMacro(false)}
          />
        </SlidingPanel>
      </Router>
    </AdminContext.Provider>
  )
}

export default withAuthLoading(AdminMain)
