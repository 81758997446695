import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useState } from 'react'
import _get from 'lodash/get'
import { getApiV1 } from '../helpers/api'

class NoActiveUserError extends Error {
  constructor(message = "No active user found") {
    super(message)
    this.name = 'NoActiveUserError'
  }
}

class NoTokenError extends Error {
  constructor(message = "User token not found") {
    super(message)
    this.name = 'NoTokenError'
  }
}

/**
 * Util function to return a promise which is resolved in provided milliseconds
 */
function waitFor(milliSeconds) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, milliSeconds);
  });
}
const retry = 4;
const wait = 3500;
const getMyRole = rawToken => retryPromiseWithDelay(
    getApiV1('/rest/auth/my-role', {}, rawToken),
    retry,
    wait
)

async function retryPromiseWithDelay(promise, nthTry, delayTime) {
  try {
    return await promise;
  } catch (e) {
    if (nthTry === 1) {
      return Promise.reject(e);
    }
    // wait for delayTime amount of time before calling this method again
    await waitFor(delayTime);
    return retryPromiseWithDelay(promise, nthTry - 1, delayTime);
  }
}
function createStorage(sub) {
  return {
    key: `roles_${sub}`,
    get() {
      try {
        return JSON.parse(window.sessionStorage.getItem(this.key))
      } catch {
        return null
      }
    },
    set(data) {
      window.sessionStorage.setItem(this.key, JSON.stringify(data))
    },
  }
}

const withAuthLoading = (Component) => (props) => {
  const [role, setRole] = useState()
  const [userPermission, setUserPermission] = useState()
  const { isLoading, getIdTokenClaims, user } = useAuth0()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function loadInfo() {
      if (typeof role !== 'undefined' || isLoading || typeof getIdTokenClaims === 'undefined') {
        return
      }

      if (!user) {
        throw new NoActiveUserError()
      }

      const storage = createStorage(user.sub)
      const token = await getIdTokenClaims()

      if (!token) {
        throw new NoTokenError()
      }

      const sessionData = storage.get()

      if (sessionData) {
        setRole(sessionData.role)
        setUserPermission(sessionData)
        return
      }

      const authRoleResponse = await getMyRole(token.__raw)

      setRole(_get(authRoleResponse, 'data.role'))
      setUserPermission(authRoleResponse.data)
      storage.set(authRoleResponse.data)
    }

    loadInfo().catch((err) => {
      setError(err)
    }).finally(() => {
      setLoading(false)
    })
  }, [isLoading, role])

  if (isLoading || loading) {
    return <div className="fullpage-loading" />
  }

  if (error) {
    throw error
  }

  return <Component {...props} role={role} userPermission={userPermission} />
}

export default withAuthLoading
