import classnames from 'classnames'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _flatten from 'lodash/flatten'
import _map from 'lodash/map'
import React, { memo, useCallback, useMemo, useRef, useState } from 'react'

import { Dropdown } from 'react-bootstrap'
import 'react-sliding-side-panel/lib/index.css'
import './styles.scss'

import IconFolder from '../../assets/claim-portal/icon-folder.svg'
import IconSearch from '../../assets/claim-portal/icon-search.svg'
import IconSetting from '../../assets/claim-portal/icon-settings.svg'
import { useAdminContext } from '../../context/admin-context'

const ChildrenFolder = ({ title, children = [], onChoose }) => {
  const [active, setActive] = useState(false)

  return (
    <div
      className={classnames('select-folder-item', {
        active: active,
      })}
    >
      <div className="select-folder-sub" onClick={() => setActive((prev) => !prev)}>
        <i className="select-icon-arrow-right" />
        <img src={IconFolder} alt="folder" />
        <span>{title}</span>
      </div>
      <div className="select-folder-children">
        {children?.map((item) => (
          <Dropdown.Item className="children-item" key={item.id} onClick={() => onChoose(item)}>
            {item?.name}
          </Dropdown.Item>
        ))}
      </div>
    </div>
  )
}

const SelectFolder = ({ title, items = [], onSearch, onChoose, macros }) => {
  const { setOpenMacroSettings } = useAdminContext()
  const [matchingMacros, setMatchingMacros] = useState(null)

  const recommendedMacroTagIds = useRef([
    'e9c26d20-a275-11ed-9a31-c9daecac72d8',
    'e5bf2b40-3148-11ec-b984-8127b5d2e0ab',
    '8c565fe0-0a01-11ee-96c6-f945ef8a8c00',
  ])

  const getMacroFromId = useCallback(
    (id) => {
      let macroItem

      items.forEach((item) => {
        const result = _find(item?.children || [], ['id', id])

        if (result) {
          macroItem = result
          return
        }
      })

      return macroItem
    },
    [items]
  )

  const tags = useMemo(
    () => recommendedMacroTagIds.current.map((macroId) => getMacroFromId(macroId)),
    [getMacroFromId]
  )

  const searchedValue = function (value) {
    if (value !== '') {
      const matchedMacros = _filter(_flatten(_map(macros, 'children')), function (o) {
        return o.name.includes(value)
      })
      setMatchingMacros(matchedMacros)
    } else {
      setMatchingMacros(null)
    }
  }
  console.log(matchingMacros)

  return (
    <Dropdown className="ml-2">
      <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="select-folder-content">
          <div className="select-folder-header">
            <h4 className="select-folder-title">Recommended Macros</h4>
            <button className="select-folder-button" onClick={() => setOpenMacroSettings(true)}>
              <img src={IconSetting} alt="Setting" />
              Settings
            </button>
          </div>
          <div className="select-folder-tags">
            {matchingMacros !== null
              ? matchingMacros?.map((item, index) => (
                  <div
                    onClick={() => onChoose(item)}
                    className="tag-item cursor-pointer"
                    key={index}
                  >
                    <span>{item?.name}</span>
                  </div>
                ))
              : tags?.map((item, index) => (
                  <div
                    onClick={() => onChoose(getMacroFromId(item.id))}
                    className="tag-item cursor-pointer"
                    key={index}
                  >
                    <span>{item?.name}</span>
                  </div>
                ))}
          </div>
          <div className="select-folder-search">
            <img src={IconSearch} alt="Search" />
            <input
              placeholder="Search"
              onChange={(e) => [onSearch(e.target.value), searchedValue(e.target.value)]}
            />
          </div>
          <div className="select-folder-list">
            {items?.map(({ title, children = [] }, index) => (
              <ChildrenFolder title={title} key={index} onChoose={onChoose}>
                {children}
              </ChildrenFolder>
            ))}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default memo(SelectFolder)
