import React, { memo } from 'react'
import _isEmpty from 'lodash/isEmpty'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import './styles.scss'

const AttachmentDisplay = ({ text, onClick }) =>
  _isEmpty(text) === true ? (
    <></>
  ) : (
    <div className="mt-1 text-muted">
      <span>{text}</span>
      <span className="close-icon ml-1" onClick={() => onClick()}>
        <FontAwesomeIcon icon={faTimes} />
      </span>
    </div>
  )

export default memo(AttachmentDisplay)
