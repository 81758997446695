import _some from 'lodash/some'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _keys from 'lodash/keys'
import _indexOf from 'lodash/indexOf'
import _startCase from 'lodash/startCase'
import { getLaserShipImage } from './requests'
import { User } from '@auth0/auth0-react'

const shippingStatus = {
  LoadingData: -1,
  Unfulfilled: 0,
  Packed: 1,
  CarrierReceived: 2,
  InTransit: 3,
  Delivered: 4,
  Lost: 5,
  ReturnedToSender: 6,
  CarrierNeverReceived: 7,
}

export const ShipStatus = Object.freeze(shippingStatus)

export const getStatusName = (id: string) => {
  const allStatus = _keys(ShipStatus)
  const allIds = _map(ShipStatus)

  return _startCase(_get(allStatus, _indexOf(allIds, parseInt(id))))
}

const _isNotFulfillAllItems = (trackingData: any) =>
  _some(trackingData, (product) => _get(product, 'fulFillments', []).length === 0)

const _isDifferentCountry = (orderData: any, itemData: any) =>
  _get(orderData, 'order_json.shipping_address.country_code', 'mmz').toLowerCase() ===
  _get(itemData, 'origin_location.country_code', 'nnz').toLowerCase()

export const getTrackingStatusForFulFillment = (
  shippingData: any,
  orderData: any,
  fulFillmentLine: any
) => {
  const latestUpdate = _get(shippingData, 'checkpoints[0]', {})
  if (
    _isEmpty(shippingData) === true ||
    (_get(shippingData, 'checkpoints', []).length === 1 &&
      _get(latestUpdate, 'status') !== 'InTransit')
  ) {
    return ShipStatus.Packed
  }

  const latestUpdateStatus = _get(latestUpdate, 'status', '')

  if (latestUpdateStatus === 'InfoReceived') {
    return ShipStatus.CarrierReceived
  }

  if (latestUpdateStatus === 'Delivered') {
    return ShipStatus.Delivered
  }

  if (latestUpdateStatus === 'InTransit') {
    const today = new Date()
    const latestUpdateDate = new Date(_get(latestUpdate, 'time'))
    const dateGap = (today - latestUpdateDate) / 1000 / 60 / 60 / 24
    const isCrossCountry = _isDifferentCountry(orderData, fulFillmentLine)

    if (shippingData.status === 'Exception') {
      return ShipStatus.CarrierNeverReceived
    }

    if ((dateGap > 7 && isCrossCountry === false) || (dateGap > 14 && isCrossCountry === true)) {
      return ShipStatus.Lost
    }

    if (_get(latestUpdate, 'message', '').toLowerCase().indexOf('return to sender') > -1) {
      return ShipStatus.ReturnedToSender
    }

    return ShipStatus.InTransit
  }
}

export const getOrderTrackingBadge = (trackingData: any, orderData: any) => {
  if (_isNotFulfillAllItems(trackingData) === true) {
    return ShipStatus.Unfulfilled
  }

  const shippingData = _get(trackingData, '[0].orderTracking', {})

  return getTrackingStatusForFulFillment(shippingData, orderData, _get(trackingData, 0))
}

export const getUserId = (user?: User) => {
  if (!user) {
    return ''
  }

  return Number(user?.sub?.split('|')?.pop() || 0)
}

export const isOutOfStockItem = (item: any) =>
  _get(item, 'variantData.inventory_quantity', 9999) <= 0 &&
  _get(item, 'variantData.inventory_policy', null) !== 'continue'

export const inventoryManagementShopify = (item: any) =>
  _get(item, 'variantData.inventory_management', 0) == 'shopify'

export const getDeliveredImg = (shippingCompany: any, shipId: any) => {
  switch (shippingCompany) {
    case 'lasership':
      return getLaserShipImage(shipId)
    // todo: add more shipping vendor here
    default:
      return ''
  }
}
