import React, { memo } from 'react'
import './styles.scss'

const StoreMetricItem = ({ title, value, isRate }) => {
  return (
    <div className={`store-metric-item card-shadow ${isRate === true ? 'rate' : ''}`}>
      <div className="w-full text-center">
        <span>{title}</span>
      </div>
      <div className="number">{value}</div>
    </div>
  )
}

export default memo(StoreMetricItem)
