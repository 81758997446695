import { combineReducers } from '@reduxjs/toolkit'
import storeReducer from './storeReducer'
import { settingsSlice } from './settings'

const rootReducer = combineReducers({
  store: storeReducer,
  settings: settingsSlice.reducer,
})

export default rootReducer
