import React, { memo, useRef, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import SignaturePad from 'react-signature-pad-wrapper'
import ButtonUI from '../../../CoreUI/ButtonUI'
import LinkButton from '../../../CoreUI/LinkButton'
import './styles.scss'

const SignatureModal = ({ show, onFinish, onHide, isLoading, className }) => {
  const thePad = useRef(null)

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      animation={false}
      className={className + ' signature-modal'}
    >
      <Modal.Body>
        <div className="d-flex signature flex-column">
          <div id="warning-signature">
            <b>WARNING: You are about to submit a claim</b>
          </div>
          <div id="submission-text">
            Please note, we will be unable to process a return of your original order once a refund
            or reshipment has been sent.
            <br />
            <br />
            This information may be submitted to the US Post Office. Any falsified information will
            be taken as mail fraud and prosecuted to the fullest extent of the law. In the event
            that your claim is investigated and reveals any fraudulent activity, you may be denied
            the right to return your product(s) for a refund or reshipment.
          </div>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <p id="signature-header" className="m-0">
              Signature
            </p>
            <svg
              onClick={() => thePad.current?.clear()}
              className="icon-reset"
              width="25"
              height="35"
              viewBox="0 0 39 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.9865 7.82296V0.818848L11.5472 9.07027L19.9865 17.3217V10.2216C23.7873 10.2875 27.4474 11.6386 30.3443 14.0451C33.2412 16.4516 35.1961 19.7649 35.8765 23.4216C36.5569 27.0783 35.9208 30.8525 34.0763 34.1023C32.2318 37.3522 29.2929 39.877 25.7594 41.2474C22.2259 42.6179 18.316 42.7492 14.6946 41.6192C11.0732 40.4892 7.96392 38.1675 5.89564 35.0491C3.82736 31.9307 2.92775 28.2081 3.3498 24.5144C3.77185 20.8207 5.48951 17.384 8.21065 14.7887L8.38729 14.6352L6.73866 12.9081L6.56203 13.0808C4.74675 14.7978 3.30509 16.8557 2.32364 19.131C1.34219 21.4063 0.841185 23.8521 0.850719 26.3215C0.850963 29.9711 1.95536 33.5391 4.02492 36.5765C6.09448 39.6138 9.03672 41.9847 12.4813 43.3908C15.9259 44.7969 19.719 45.1754 23.3831 44.4785C27.0472 43.7817 30.4186 42.0407 33.0729 39.4747C35.7273 36.9087 37.5461 33.6324 38.3003 30.058C39.0546 26.4837 38.7105 22.7711 37.3116 19.3875C35.9126 16.0038 33.5213 13.1004 30.4384 11.0426C27.3556 8.98482 23.7191 7.86461 19.9865 7.82296Z"
                fill="#444444"
              />
            </svg>
          </div>
          <div className="content-signature-pad">
            <SignaturePad ref={thePad} options={{ minWidth: 1, maxWidth: 3, penColor: 'black' }} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonUI
          className="px-3"
          disabled={isLoading}
          onClick={() => {
            onFinish(thePad.current?.signaturePad?.canvas)
            onHide()
          }}
        >
          {isLoading === true ? (
            <Spinner size="sm" className="ml-2" animation="border" variant="light" />
          ) : (
            <span>Submit Claim</span>
          )}
        </ButtonUI>
        <LinkButton onClick={onHide}>Cancel</LinkButton>
      </Modal.Footer>
    </Modal>
  )
}

export default memo(SignatureModal)
