import React, { memo } from 'react'
import { Modal, Button } from 'react-bootstrap'

const ConfirmationModal = ({ title, children, onCancel, onConfirm, show }) => {
  const { text: cancelText = 'Cancel', action: cancelAction } = onCancel
  const { text: confirmText = 'OK', action: confirmAction } = onConfirm

  return (
    <Modal show={show} onHide={cancelAction} backdrop="static" keyboard={false} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={cancelAction}>
          {cancelText}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            confirmAction()
            cancelAction()
          }}
        >
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default memo(ConfirmationModal)
