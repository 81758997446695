import React, { memo, useCallback } from 'react'

const CarrierInfo = ({ carrierName = '' }) => {
  const renderInfo = useCallback(() => {
    switch (carrierName.toLowerCase()) {
      case 'ups':
        return (
          <>
            <div>Support Number: 1-800-742-5877 (1-800-PICK-UPS®)</div>
            <div>For international shipping: 1-800-782-7892</div>
            <div>
              <a
                href="https://www.ups.com/us/en/help-center/contact.page"
                target="_blank"
                rel="noreferrer"
              >
                Contact Page
              </a>
            </div>
          </>
        )

      default:
        return <></>
    }
  }, [carrierName])

  return <div className="carrier-info-wrapper">{renderInfo()}</div>
}

export default memo(CarrierInfo)
