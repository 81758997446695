import './ClaimItems.scss'
import { useEffect, useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import _map from 'lodash/map'
import ClaimItemsOption from '../ClaimItemsOption/ClaimItemsOption'
import ClaimCustomerInformation from '../ClaimCustomerInformation/ClaimCustomerInformation'
import ClaimNextButton from '../ClaimNextButton/ClaimNextButton'
import ClaimImageUpload from '../ClaimImageUpload/ClaimImageUpload'
import Error from '../../Error/Error'
import { getClaimTypeFullText } from '../../../helpers/date-time-helper'

export default function ClaimItems(props) {
  const handleCheckboxClick = (event) => {
    const customerData = { ...props.state.customerData }

    customerData.orderItems.forEach((item) => {
      let prevItemChecked = item.is_checked

      if (item.id == event.target.value) {
        item.is_checked = !prevItemChecked
      }
    })

    props.setState({
      ...props.state,
      customerData: customerData,
    })
  }

  function handleItemsAffectedSubmitted(skipStep = false) {
    const customerData = { ...props.state.customerData }

    if (skipStep === true) {
      const items = _map(customerData.orderItems, (item) => ({
        ...item,
        is_checked: true,
      }))

      return props.setState(
        {
          ...props.state,
          customerData: {
            ...customerData,
            orderItems: items,
          },
        },
        props.onNextClick()
      )
    }

    let minItemsCheckedFlag = false

    customerData.orderItems.forEach((item) => {
      if (item.is_checked) {
        minItemsCheckedFlag = true
        return
      }
    })

    if (!minItemsCheckedFlag) {
      props.setError({
        active: true,
        message: 'Please select an affected item.',
      })
    } else if (!props.successfulFileUploadCheck()) {
      props.setError({
        active: true,
        message: 'Please select an image.',
      })
    } else {
      if (props.error.active) {
        props.setError({
          active: false,
          message: 'Default',
        })
      }

      props.onNextClick()
    }
  }

  function getFulfilledOption(options) {
    return options.filter((op) => op.fulfilled_quantity > 0)
  }

  function getUnfulfilledOptions(options) {
    return options.filter((op) => op.fulfilled_quantity === 0)
  }

  const claimItemsOptions = props.state.customerData.orderItems
    ? getFulfilledOption(props.state.customerData.orderItems)
    : null

  const unclaimedItemsOptions = props.state.customerData.orderItems
    ? getUnfulfilledOptions(props.state.customerData.orderItems)
    : null

  const claimType = useMemo(() => {
    return props.state.customerData.claimType.claim_type_name.toLowerCase() === 'defective'
      ? 'Return to sender'
      : props.state.customerData.claimType.claim_type_name
  }, [props])

  useEffect(() => {
    if (claimType.toLowerCase() === 'stolen') {
      handleItemsAffectedSubmitted(true)
    }
  }, [claimType])

  const claimDisplayText = useMemo(() => getClaimTypeFullText(claimType), [claimType])

  return (
    <>
      <Row>
        <Col md={{ span: 5, offset: 3 }}>
          <div className="greeting-container my-4">
            <h1 className="font-weight-bold">{claimDisplayText}</h1>
            <h2 className="text-muted">Which items were affected?</h2>
            <Error error={props.error} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 5, offset: 3 }}>
          {unclaimedItemsOptions &&
            unclaimedItemsOptions.map((orderItem, id) => {
              return (
                <ClaimItemsOption
                  view={props.view}
                  changeView={props.changeView}
                  state={props.state}
                  setState={props.setState}
                  key={id}
                  claimItem={orderItem}
                  handleCheckboxClick={handleCheckboxClick}
                  fulfilled={false}
                />
              )
            })}

          {claimItemsOptions &&
            claimItemsOptions.map((orderItem, id) => {
              return (
                <ClaimItemsOption
                  view={props.view}
                  changeView={props.changeView}
                  state={props.state}
                  setState={props.setState}
                  key={id}
                  claimItem={orderItem}
                  handleCheckboxClick={handleCheckboxClick}
                  fulfilled={true}
                />
              )
            })}

          <ClaimImageUpload onFileSelect={props.onFileSelect} />
          {props.imageUploadMessage && (
            <div className="image-upload-message__wrapper">
              <span>{props.imageUploadMessage}</span>
            </div>
          )}

          <ClaimNextButton onNextClick={handleItemsAffectedSubmitted} />
        </Col>

        <Col md={2}>
          <ClaimCustomerInformation state={props.state} />
        </Col>
      </Row>
    </>
  )
}
