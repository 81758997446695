import { useAuth0 } from '@auth0/auth0-react'
import { useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Form } from '../../components/Form'
import { useAdminContext } from '../../context/admin-context'
import { postApi } from '../../helpers/api'
import { getUserId } from '../../helpers/order-helpers'

export const ResetPassword = () => {
  const { token } = useAdminContext()
  const { user } = useAuth0()
  const [loading, setLoading] = useState(false)
  const userId = useMemo(() => getUserId(user), [user])
  const methods = useForm({
    defaultValues: {
      newPassword: '',
      repeatedNewPassword: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })
  const { handleSubmit, watch } = methods
  const newPassword = watch('newPassword')
  const repeatedNewPassword = watch('repeatedNewPassword')
  const disabledSubmitPassword = !newPassword || !repeatedNewPassword

  const onSubmitPassword = async (data: any) => {
    setLoading(true)
    try {
      await postApi(`/users/changePassword`, { ...data, userId, authId: user?.sub }, token)
      window.location.reload()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form methods={methods}>
      <div className="section card-wrapper">
        <h3>Reset Password</h3>
        <Form.Input
          name="newPassword"
          label="New Password"
          type="password"
          rules={{
            required: { value: true, message: 'This field is required' },
            pattern: {
              value:
                /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z@#$%^&+!=])(?=.*[0-9])(?=.{8,}).*$/g,
              message:
                'Password request: 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number',
            },
          }}
        ></Form.Input>
        <Form.ErrorMessage name="newPassword"></Form.ErrorMessage>
        <Form.Input
          name="repeatedNewPassword"
          label="Confirm New Password"
          type="password"
          rules={{
            required: { value: true, message: 'This field is required' },
            validate: {
              matchesPreviousPassword: (value) => {
                const { newPassword } = methods.getValues()
                return newPassword === value || 'Passwords should match!'
              },
            },
          }}
        ></Form.Input>
        <Form.ErrorMessage name="repeatedNewPassword"></Form.ErrorMessage>

        <Button
          disabled={disabledSubmitPassword && !loading}
          onClick={handleSubmit(onSubmitPassword)}
        >
          Change Password
        </Button>
      </div>
    </Form>
  )
}
