import React, { memo, useMemo } from 'react'
import { formatMoney } from '../../../../helpers/date-time-helper'
import './styles.scss'
import { isOutOfStockItem, inventoryManagementShopify } from '../../../../helpers/order-helpers'
import PlatformItem from '../../../../helpers/integrations/item-adapter'
import { useClaimPortalContext } from '../../../../context/claim-portal-context'

const ClaimPortalItem = ({ item, displayOutOfStock = false }) => {
  const isOutOfStock = useMemo(
    () => displayOutOfStock === true && isOutOfStockItem(item),
    [item, displayOutOfStock]
  )
  const inventoryManagement = useMemo(() => inventoryManagementShopify(item), [item])
  console.log(inventoryManagement)
  console.log(isOutOfStock)
  const { orderData, variantData } = useClaimPortalContext()
  const platformItem = new PlatformItem(item, orderData.platform_id)
  const variant = variantData.find((variant) => variant.id === item.id)
  const variantOptionLabel = variant?.variantData?.option_values?.[0]?.label ?? null
  const variantOptionName = variant?.variantData?.option_values?.[0]?.option_display_name ?? null
  return (
    <div
      className={`claim-portal-item-wrapper ${
        platformItem.conciergeRefundable() ? 'out-stock' : ''
      } `}
    >
      <div
        className={`item-photo ${platformItem.conciergeRefundable() ? 'out-of-stock' : ''}`}
        style={{
          backgroundImage: `url(${item?.photo})`,
        }}
      />
      <div className="out-of-stock-item">Out of stock</div>
      <div className={`item-meta  ${platformItem.conciergeRefundable() ? 'out-of-stock' : ''}`}>
        <div className="item-name">{item.item_name}</div>
        <div className="item-sku">
          ${formatMoney(item.price * item.quantity - (item.discount || 0))}
        </div>
        {variantOptionLabel && variantOptionName && (
          <div className="item-sku">
            {variantOptionName}: {variantOptionLabel}
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(ClaimPortalItem)
