import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import IconDollar from '../../../assets/claim-portal/icon-onboarding-dollar.svg'
import './style.scss'
import LinkButton from '../../../components/CoreUI/LinkButton'
import ButtonUI from '../../CoreUI/ButtonUI'
import { postApi } from '../../../helpers/api'

export default function BillingPayouts() {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const params = new URLSearchParams(window.location.search)
  const session = params.get('session')

  const onStart = useCallback(() => {
    setLoading(true)
    postApi('/billing/subscription', { session }).then((res) => {
      const { paymentUrl } = res.data
      window.location.href = paymentUrl
      setLoading(false)
    })
  }, [setLoading, session])

  return (
    <>
      <div className="onboarding-block onboarding-block-billing">
        <div className="onboarding-header">
          <h1 className="onboarding-header__title">
            <img src={IconDollar} alt="Icon dollar" className="icon" />
            Billing and Payouts
          </h1>
          <div className="onboarding-list">
            <div className="onboarding-item">
              <div className="onboarding-item__circle" />
              <p className="onboarding-item__text">
                You collect Order Protection revenue like any other product.
              </p>
            </div>
            <div className="onboarding-item">
              <div className="onboarding-item__circle" />
              <p className="onboarding-item__text">
                Then, you pay us the revenue. This is a pass-through cost.
              </p>
            </div>
            <div className="onboarding-item">
              <div className="onboarding-item__circle" />
              <p className="onboarding-item__text">
                We use this revenue to pay out claims, improve the customer experience, and keep the
                engine running.
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center">
          <button className="btn-submit btn-actived" onClick={() => setShowModal(true)}>
            I Understand How Billing and Payouts Work
          </button>
          <LinkButton
            className="mt-5"
            href="https://op-media-asset.s3.us-west-1.amazonaws.com/assets/Accounting+Onboarding+-+General_0425+update.pdf"
          >
            Download Our Accounting Overview PDF
          </LinkButton>
        </div>
      </div>
      {showModal && (
        <Modal
          className="billing-approval-modal"
          show={showModal}
          onHide={() => setShowModal(false)}
          centered={true}
          backdrop="static"
          keyboard={false}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Approve Recurring Billing</Modal.Title>
          </Modal.Header>
          <Modal.Body className="select-store-modal">
            <div className="d-flex flex-column text-center">
              <div className="px-3">
                You'll now be redirected to Shopify to approve recurring billing. After that, we'll
                come back here to finish the last step!
              </div>
              <div className="my-4">
                <ButtonUI disabled={loading} onClick={() => onStart()}>
                  {loading ? 'Redirecting... Please wait...' : 'Go to Shopify Now'}
                </ButtonUI>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}
