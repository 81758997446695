import React, { memo, useRef, useState } from 'react'
import { Modal, Dropdown, Spinner } from 'react-bootstrap'
import SignaturePad from 'react-signature-pad-wrapper'
import ButtonUI from '../../CoreUI/ButtonUI'
import LinkButton from '../../CoreUI/LinkButton'
import './styles.scss'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import _get from 'lodash/get'
import _capitalize from 'lodash/capitalize'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { putApi, postApi } from '../../../helpers/api'
import { useClaimPortalContext } from '../../../context/claim-portal-context'

import { faCaretLeft } from '@fortawesome/free-solid-svg-icons'

const CommunicationModal = ({ communicationInfo = null }) => {
  const { orderData } = useClaimPortalContext()

  const [information, setInformation] = useState(
    _get(communicationInfo, 'desiredOption', _get(communicationInfo, 'email', null))
  )
  const [changeInformation, setChangeInformation] = useState(false)
  const [newInformation, setNewInformation] = useState(false)
  const [newValue, setNewValue] = useState(
    _get(communicationInfo, 'phoneNumber', _get(communicationInfo, 'email', null))
  )
  const [loading, setLoading] = useState(false)
  const [warningText, setWarningText] = useState(null)

  const [show, setShow] = useState(true)
  const [preference, setPreference] = useState('text')

  const updatePreference = () => {
    setLoading(true)
    const changedInformation = {
      claimId: communicationInfo.claimId,
      desiredCommunication: preference,
      newValue: newValue,
    }
    putApi(`/orders/${communicationInfo.claimId}/update-communication`, changedInformation).then(
      (res) => {
        if (res.data.message == 'Existing Value') {
          setWarningText(
            'We only allow 3 active claims per phone number. Please change the entered phone number if you want to receive SMS text communication. Otherwise we will email you at the email address associated with your order.'
          )
        } else {
          setNewInformation(true)
          setWarningText(null)
          if (preference == 'text') {
            sendConfirmationText(res.data.message)
          }
        }
        setLoading(false)
      }
    )
  }
  const sendConfirmationText = () => {
    setLoading(true)
    const changedInformation = {
      textMessage: `Your claim has been submitted for order #${orderData.store_order_id}. We'll message you here as soon as we have an update for you! \n \nIf at any point you have any trouble responding via text, you can go to claims.orderprotection.com, enter your order information, and click "View Claim" to respond there. `,
      claimId: communicationInfo.claimId,
      phoneNumber: newValue,
    }

    setShow(false)
    setNewInformation(true)

    if (!newValue.includes('@')) {
      postApi(`/twilio/outgoing/update-communication`, changedInformation).then((res) => {})
    }
  }
  console.log('show is')
  console.log(show)
  return (
    <Modal
      show={show}
      backdrop="static"
      className="communication-modal"
      onHide={!show}
      keyboard={false}
      animation={false}
    >
      <Modal.Header>
        {changeInformation && !newInformation && (
          <>
            <FontAwesomeIcon
              onClick={() => {
                setChangeInformation(false)
              }}
              className="fa-caret"
              viewBox="0 0 20 20"
              icon={faCaretLeft}
            />
          </>
        )}
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex communication-preference flex-column">
          <div id="warning-signature">
            <b>Add Phone Number</b>
          </div>
          <div>
            {!changeInformation ? (
              <>
                <p className="communication-subheading">
                  We require a phone number that we can text and/or call in case we need to
                  communicate with you about your claim.
                </p>
                {communicationInfo.phoneNumber && communicationInfo.claimCommunication == null ? (
                  <div>
                    Right now, we're planning to communicate via text at the following number:
                    <div className="fixed-phone">
                      <PhoneInput
                        readOnly
                        international
                        defaultCountry="US"
                        placeholder="Enter phone number"
                        value={_get(communicationInfo, 'phoneNumber', '')}
                      />
                    </div>
                  </div>
                ) : (
                  <p>
                    {communicationInfo.claimCommunication && (
                      <div className="limit-text">
                        We only allow 3 active claims per phone number. Please change the entered
                        phone number if you want to receive SMS text communication. Otherwise we
                        will email you at the email address associated with your order.
                      </div>
                    )}
                    <p>Enter Your Phone Number</p>
                    <div className="variable-phone">
                      <PhoneInput
                        placeholder="Enter phone number"
                        international
                        defaultCountry="US"
                        countryCallingCodeEditable={false}
                        onChange={(event) => {
                          setNewValue(event)
                        }}
                      />
                    </div>
                    {warningText != null && <span className="warning-text">{warningText}</span>}
                  </p>
                )}
                <Modal.Footer>
                  {communicationInfo.phoneNumber && !communicationInfo.claimCommunication && (
                    <ButtonUI
                      className="px-3"
                      onClick={() => {
                        setChangeInformation(!changeInformation)
                      }}
                    >
                      Change Number
                    </ButtonUI>
                  )}
                  <ButtonUI
                    disabled={newValue?.length == 0 || newValue == null}
                    className="px-3"
                    onClick={() => {
                      communicationInfo.phoneNumber && !communicationInfo.claimCommunication
                        ? sendConfirmationText()
                        : updatePreference()
                    }}
                  >
                    Continue
                  </ButtonUI>
                </Modal.Footer>
              </>
            ) : (
              <>
                {preference == 'text' && !newInformation ? (
                  <>
                    <p className="add-subheading">Enter Your Phone Number</p>
                    <div className="variable-phone">
                      <PhoneInput
                        placeholder="Enter phone number"
                        international
                        defaultCountry="US"
                        countryCallingCodeEditable={false}
                        onChange={(event) => {
                          setNewValue(event)
                        }}
                      />
                    </div>
                  </>
                ) : preference == 'email' && !newInformation ? (
                  <>
                    <p>Enter Your Email Address</p>
                    <input
                      onChange={(event) => {
                        setNewValue(event.target.value)
                      }}
                      type="email"
                    ></input>
                  </>
                ) : (
                  <p className="updated-text">Your preferences were updated!</p>
                )}
                <Modal.Footer>
                  <ButtonUI
                    className="px-3 update-number"
                    onClick={() => {
                      if (newInformation) {
                        setShow(false)
                      } else {
                        updatePreference()
                      }
                    }}
                  >
                    {newInformation ? (
                      'Done'
                    ) : loading ? (
                      <Spinner animation="border" variant="primary" />
                    ) : (
                      'Update Number'
                    )}
                  </ButtonUI>

                  {!newInformation && (
                    <>
                      <span
                        onClick={() => {
                          setChangeInformation(!changeInformation)
                          setNewInformation(false)
                        }}
                        className="go-back"
                      >
                        Go Back
                      </span>
                      {warningText != null && <span className="warning-text">{warningText}</span>}
                    </>
                  )}
                </Modal.Footer>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default memo(CommunicationModal)
