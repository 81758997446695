import _get from 'lodash/get'
import _compact from 'lodash/compact'
import _omit from 'lodash/omit'
import _map from 'lodash/map'
import _concat from 'lodash/concat'
import _reject from 'lodash/reject'
import _flatten from 'lodash/flatten'

export const getShippingAddress = (shipping) =>
  _compact([
    [_get(shipping, 'first_name', ''), _get(shipping, 'last_name', '')].join(' ').trim(),
    _get(shipping, 'address1', ''),
    _get(shipping, 'address2', ''),
    [_get(shipping, 'city', ''), _get(shipping, 'province_code', '')].join(' '),
    _get(shipping, 'zip', ''),
    _get(shipping, 'country', ''),
    _get(shipping, 'phone', ''),
  ]).join('\n')

const getDetailAddress = (shipping) =>
  _compact([
    _get(shipping, 'address1', ''),
    _get(shipping, 'address2', ''),
    _get(shipping, 'city', ''),
  ]).join('\n')

const getOverallAddress = (shipping) =>
  _compact([
    _get(shipping, 'province_code', _get(shipping, 'province', '')),
    _get(shipping, 'zip', ''),
    _get(shipping, 'country', ''),
  ]).join('\n')

export const getShippingAddressFromRawJson = (shipping) =>
  _compact([getDetailAddress(shipping), getOverallAddress(shipping)]).join(', ')

export const transformToOrderTraditionalObject = (order) => ({
  ...order,
  claims_id: _get(order, 'claims[0].id', null),
  ..._omit(_get(order, 'claims[0]'), ['id, item']),
  order_id: order.id,
})

const getTrackingFulfill = (items) => {
  const fullFillments = _concat(_flatten(_map(items, 'fulfillments')))
  const results = _reject(fullFillments, ['tracking_number', null])

  return _get(results, 0)
}

export const getTrackingNumber = (items) => _get(getTrackingFulfill(items), 'tracking_number', '')

export const getTrackingCompany = (items) => _get(getTrackingFulfill(items), 'tracking_company', '')

export const getTrackingLink = (items) => _get(getTrackingFulfill(items), 'tracking_url', '')

export const getArrayFromJSONString = (json_string) => {
  try {
    const result = JSON.parse(json_string)
    return result || []
  } catch (error) {
    return []
  }
}

export const getSingleValueFromArray = (value) => {
  try {
    const array = JSON.parse(value)
    if (array.length) {
      return array[0]
    }
    return value || ''
  } catch (error) {
    return value || ''
  }
}

export const renderStringArray = (string) => {
  try {
    let array = JSON.parse(string)
    if (array.length > 1) {
      array = array.map((address, index) => `${index + 1}. ${address}`)
      return (
        <>
          {array.map((address, i) => (
            <div key={i}>{address}</div>
          ))}
        </>
      )
    }
    if (array.length === 1) {
      return array[0]
    }
    return string || ''
  } catch (error) {
    return string || ''
  }
}
