import { memo } from 'react'
import { Container } from 'react-bootstrap'

import { ProfileDetail } from './ProfileDetail'
import { ResetPassword } from './ResetPassword'
import './styles.scss'

export type infoUser = {
  name: string
  first_name?: string
  last_name?: string
  email: string
  newPassword?: string
  confirmPassword: string
}

export const AdminProfile = () => {
  return (
    <Container className="admin-profile">
      <ProfileDetail />
      <ResetPassword />
    </Container>
  )
}

export default memo(AdminProfile)
